import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export type UmbracoCheckbox = Omit<BasicProperty, 'value'> & { editorAlias: 'Noa.LimitedCheckboxList'; value: { value?: string[] } };

export class LimitedCheckboxListConverter {
	static UmbracoType: UmbracoCheckbox;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Noa.LimitedCheckboxList';
	}

	public static mapProperty(prop: typeof this.UmbracoType): string[] {
		return prop?.value?.value || [];
	}
}
