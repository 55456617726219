import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export type UmbracoFormPicker = Omit<BasicProperty, 'value'> & {
	editorAlias: 'UmbracoForms.FormPicker';
	value: {
		value?: string;
	};
};

export class FormPickerConverter {
	static UmbracoType: UmbracoFormPicker;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'UmbracoForms.FormPicker';
	}

	public static mapProperty(prop: typeof this.UmbracoType): string | undefined {
		return prop.value.value;
	}
}
