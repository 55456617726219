import cn from 'classnames';

import { vars } from '@dansk-metal/theme';
import { Button, Icon, Icons } from '@dansk-metal/ui';

import { useHeaderContext } from '@web/components/header/context';

import styles from './navigation-icon.module.scss';

type NavigationItemProps = {
	name: Icons;
	onClick?: (e: MouseEvent) => void;
	filled?: boolean;
	ariaLabel: string;
};

export function NavigationIcon({ name, onClick, filled, ariaLabel }: NavigationItemProps) {
	const { currentMenu, setCurrentMenu } = useHeaderContext();

	const handleClick = (e: MouseEvent) => {
		if (onClick) {
			onClick(e);
			return;
		}

		e.preventDefault();
		e.stopPropagation();
		setCurrentMenu(name);
	};

	return (
		<Button
			className={cn(styles.icon_wrapper, {
				[styles.icon_wrapper__pressed]: currentMenu === name,
				[styles.icon_wrapper__filled]: filled,
			})}
			aria-label={ariaLabel}
			onClick={(e) => handleClick(e)}
		>
			<Icon icon={name} size={24} color={vars['--color-neutral-white']} />
		</Button>
	);
}
