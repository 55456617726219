export type optionTimeValue = 'hour' | 'month';

export type timeOptionsType = {
	value: optionTimeValue;
	label: string;
};

export const timeOptions: timeOptionsType[] = [
	{ value: 'hour', label: 'Timelønnet' },
	{ value: 'month', label: 'Månedslønnet' },
];

export type Benefit = {
	title: string;
	subtitle: string;
	result: number;
};

export type BenefitsGroup = {
	title: string;
	benefits: Benefit[];
};


