import { Guid } from '@web/services/umbraco/types/basic/Guid';

const prefixTracker = new Map<string, number>();

export function getGuidFixture(prefix = 'default', index?: number): Guid {
	const currentIndex = index || prefixTracker.get(prefix) || 0;
	const guidFixture: Guid = `${prefix}-0-0-0-${currentIndex}`;

	if (typeof index !== undefined) {
		prefixTracker.set(prefix, currentIndex + 1);
	}

	return guidFixture;
}
