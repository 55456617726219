import { LinkStyle } from '@web/services/umbraco/types/elements/Action';

export function getUmbracoButtonVariant(linkStyle: LinkStyle) {
	switch (linkStyle) {
		case LinkStyle.TextLink:
			return 'inline-thick';
		case LinkStyle.OutlinedButton:
			return 'stroke';
		default:
			return linkStyle === LinkStyle.DarkBlueButton ? 'fill-primary' : 'fill-secondary';
	}
}
