/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.6.0
 */
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	MemberDto,
	PutMemberBody,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get a specified member
 */
export const getMemberById = (memberId: string, options?: SecondParameter<typeof crmAxios>, signal?: AbortSignal) => {
	return crmAxios<MemberDto>({ url: `/api/members/${memberId}`, method: 'get', signal }, options);
};

export const getGetMemberByIdQueryKey = (memberId: string) => [`/api/members/${memberId}`] as const;

export const getGetMemberByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberByIdQueryKey(memberId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberById>>> = ({ signal }) =>
		getMemberById(memberId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberById>>>;
export type GetMemberByIdQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get a specified member
 */
export const useGetMemberById = <
	TData = Awaited<ReturnType<typeof getMemberById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberByIdQueryOptions(memberId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Update member details
 */
export const putMember = (
	memberId: string,
	putMemberBody: PutMemberBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<MemberDto>(
		{
			url: `/api/members/${memberId}`,
			method: 'put',
			headers: { 'Content-Type': 'application/json' },
			data: putMemberBody,
		},
		options,
	);
};

export const getPutMemberMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof putMember>>,
		TError,
		{ memberId: string; data: PutMemberBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof putMember>>,
	TError,
	{ memberId: string; data: PutMemberBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof putMember>>,
		{ memberId: string; data: PutMemberBody }
	> = (props) => {
		const { memberId, data } = props ?? {};

		return putMember(memberId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PutMemberMutationResult = NonNullable<Awaited<ReturnType<typeof putMember>>>;
export type PutMemberMutationBody = PutMemberBody;
export type PutMemberMutationError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Update member details
 */
export const usePutMember = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof putMember>>,
		TError,
		{ memberId: string; data: PutMemberBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getPutMemberMutationOptions(options);

	return useMutation(mutationOptions);
};
