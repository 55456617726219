import { formatDate } from '@dansk-metal/utils/date';

export function getOverdueText(deadlineDate?: string | null, isNotReady?: boolean, readyDate?: string | null): string {
	if (isNotReady && readyDate) {
		const readyDateISO = new Date(readyDate);

		return `Bliver muligt at gøre ${formatDate(readyDateISO.toString(), '22. feb 2022')}`;
	}

	if (!deadlineDate) {
		return 'Skal gøres hurtigst muligt';
	}

	const currentDate = new Date();
	const dateToCheck = new Date(deadlineDate);

	if (currentDate < dateToCheck) {
		return `Skal gøres inden ${formatDate(dateToCheck.toString(), '22. feb 2022')}`;
	}

	if (currentDate > dateToCheck) {
		return `Skulle gøres inden ${formatDate(dateToCheck.toString(), '22. feb 2022')}`;
	}

	return 'Skal gøres i dag';
}
