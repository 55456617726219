import { gql } from 'urql/core';

import { PageWithChildrenQuery, PageWithChildrenQueryVariables } from '@apps/web/src/services/gql/graphql';
import { baseContentFragment } from '@apps/web/src/services/umbraco/fragments/baseContent';
import { propertyFragment } from '@apps/web/src/services/umbraco/fragments/propertyFragment';

export type { PageWithChildrenQuery, PageWithChildrenQueryVariables };

export const pageWithChildrenQuery = gql<PageWithChildrenQuery, PageWithChildrenQueryVariables>`
query PageWithChildren ($slug: String!, $baseUrl: String!, $preview: Boolean) {
	contentByAbsoluteRoute (route: $slug, preview: $preview, baseUrl: $baseUrl, routeMode: CACHE_OR_ROUTING) {
		...BaseContentFragment
		properties {
			...PropertyFragment,
		}

		children(where: { sortOrder: { lt: 50 } }) {
			...BaseContentFragment,
			properties {
				...PropertyFragment,
			}
		}
	}
}

${baseContentFragment}
${propertyFragment}
`;
