/**
 *
 * @param number
 * @param numberOfFractions numer of decimal values to display, default 0
 * @returns string of number with thousands seperated by a dot and decimals by a coma, with always 2 decimals
 */
export function formatNumberThousandsDots(number: number | null, numberOfFractions = 0) {
	if (number === null) {
		return '-';
	}

	const formattedNumber = new Intl.NumberFormat('da-DK', { minimumFractionDigits: numberOfFractions }).format(number);

	return formattedNumber;
}
