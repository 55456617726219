/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.6.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	EventDto,
	EventsResponse,
	GetEventsByFilterParams,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Retrieves all events. This can optionally be filtered by type, group and department.
Optionally a string can be used to query title, intro and body text of events
Uses pagination through limit and offset.
 */
export const getEventsByFilter = (
	params?: GetEventsByFilterParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<EventsResponse>({ url: '/api/events', method: 'get', params, signal }, options);
};

export const getGetEventsByFilterQueryKey = (params?: GetEventsByFilterParams) =>
	['/api/events', ...(params ? [params] : [])] as const;

export const getGetEventsByFilterQueryOptions = <
	TData = Awaited<ReturnType<typeof getEventsByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetEventsByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getEventsByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getEventsByFilter>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetEventsByFilterQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsByFilter>>> = ({ signal }) =>
		getEventsByFilter(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetEventsByFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsByFilter>>>;
export type GetEventsByFilterQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves all events. This can optionally be filtered by type, group and department.
Optionally a string can be used to query title, intro and body text of events
Uses pagination through limit and offset.
 */
export const useGetEventsByFilter = <
	TData = Awaited<ReturnType<typeof getEventsByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetEventsByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getEventsByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetEventsByFilterQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves a specific event.
 */
export const getEventById = (eventId: string, options?: SecondParameter<typeof crmAxios>, signal?: AbortSignal) => {
	return crmAxios<EventDto>({ url: `/api/events/${eventId}`, method: 'get', signal }, options);
};

export const getGetEventByIdQueryKey = (eventId: string) => [`/api/events/${eventId}`] as const;

export const getGetEventByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getEventById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	eventId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getEventById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getEventById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetEventByIdQueryKey(eventId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventById>>> = ({ signal }) =>
		getEventById(eventId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!eventId, staleTime: 10000, ...queryOptions };
};

export type GetEventByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventById>>>;
export type GetEventByIdQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves a specific event.
 */
export const useGetEventById = <
	TData = Awaited<ReturnType<typeof getEventById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	eventId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getEventById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetEventByIdQueryOptions(eventId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
