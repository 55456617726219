import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export type UmbracoCheckbox = Omit<BasicProperty, 'value'> & { editorAlias: 'Umbraco.TrueFalse'; value: { value?: boolean } };

export class CheckboxConverter {
	static UmbracoType: UmbracoCheckbox;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.TrueFalse';
	}

	public static mapProperty(prop: typeof this.UmbracoType): boolean {
		return !!prop.value.value;
	}
}
