import { BonsaiPickerModel } from '@web/services/gql/graphql';
import { Guid } from '@web/services/umbraco/types/basic/Guid';

const editorAlias = 'Bonsai.CustomPicker' as const;

export type UmbracoBonsaiPicker = {
	editorAlias: typeof editorAlias;
	value: Omit<BonsaiPickerModel, 'value' | 'model'> & {
		value: Guid | Guid[];
	};
};

export class BonsaiPickerConverter {
	static UmbracoType: UmbracoBonsaiPicker;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === editorAlias;
	}

	public static mapProperty(prop: typeof this.UmbracoType): Guid[] | Guid | undefined {
		if (prop.value.multiPicker) {
			if (!prop.value.value) {
				return [];
			}

			return Array.isArray(prop.value.value) ? prop.value.value : [prop.value.value];
		}

		if (!prop.value.value) {
			return;
		}

		return Array.isArray(prop.value.value) ? prop.value.value[0] : prop.value.value;
	}
}
