import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { Link } from '@web/services/umbraco/types/basic/Link';
import { RichTextContent } from '@web/services/umbraco/types/basic/RichTextContent';
import { UmbracoDocument } from '@web/services/umbraco/types/documents/UmbracoDocument';

export const blockName = 'membershipsOverview' as const;

export type Price = UmbracoBlock<
	'price',
	{
		price: string;
		priceDescription: RichTextContent;
	}
>;

export type Benefit = UmbracoBlock<
	'benefit',
	{
		title: string;
		bodyText?: RichTextContent;
		link?: Link;
	}
>;

export type ListPrices = UmbracoBlock<
	'pricesList',
	{
		title?: string;
		prices: Price[];
	}
>;

export type ListBenefits = UmbracoBlock<
	'benefitsList',
	{
		title?: string;
		benefits: Benefit[];
	}
>;

export type Membership = UmbracoDocument<
	'membership',
	{
		title: string;
		type: ('union' | 'a-kasse' | 'education')[];
		description?: RichTextContent;
		price: string;
		priceDescription?: RichTextContent;
		publicPriceDescription?: RichTextContent;
		link: Link;
		lists: (ListBenefits | ListPrices)[];
	}
>;
