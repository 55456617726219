import { Container } from '@dansk-metal/ui';

import { RegionData } from '@web/blocks/salary-calculator-block/calculation/salary-calculation';

import DefinitionList from '@web/components/definition-list/definition-list';

import { formatNumberThousandsDots } from '@web/utils/format-number-thousands-dots';

import styles from './drawer-details.module.scss';

interface SalaryDrawerDetailsProps {
	region: RegionData;
	timePeriod: 'hourly' | 'monthly';
}

export function SalaryDrawerDetails({ region, timePeriod }: SalaryDrawerDetailsProps) {
	const timeMeasure = timePeriod === 'hourly' ? 'time' : 'måned';

	const basicSalaryData = [
		{ key: 'Gennemsnitsløn', value: `${formatNumberThousandsDots(region.basicSalaryData.average)} kr./${timeMeasure}` },
		{ key: 'Årlig stigning', value: `${formatNumberThousandsDots(region.basicSalaryData.increase)} %` },
	];
	const pensionData = [
		{ key: 'Arbejdsgiverbetalt', value: `${formatNumberThousandsDots(region.pensionData.employerPaid)} kr./${timeMeasure}` },
		{ key: 'Egenbetalt', value: `${formatNumberThousandsDots(region.pensionData.selfPaid)} kr./${timeMeasure}` },
	];

	const totalData = [
		{ key: 'Gennemsnitsløn (inkl. arbejdsgiverbetalt pension)', value: `${formatNumberThousandsDots(region.totalData.average)} kr./${timeMeasure}` },
		{ key: 'Årlig stigning (inkl. arbejdsgiverbetalt pension)', value: `${formatNumberThousandsDots(region.totalData.increase)} %` },
	];

	return (
		<Container>
			<div className={styles.title}>
				<h2>{region.name} - detaljer</h2>
				<p>Tallene er baseret på Dansk Metals lønstatistik og omfatter {region.people} personer.</p>
			</div>
			<div className={styles.content}>
				<div>
					<h3>Grundløn</h3>
					<DefinitionList data={basicSalaryData} />
				</div>
				<div>
					<h3>Pension</h3>
					<DefinitionList data={pensionData} />
				</div>
				<div>
					<h3>I alt</h3>
					<DefinitionList data={totalData} />
				</div>
			</div>
		</Container>
	);
}
