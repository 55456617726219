import { googleAds } from '@web/services/google-ads';
import { EventTypes } from '@web/services/google-ads/config';
import { Siteimprove } from '@web/services/siteimprove/feedback';
import { Action, Context, Modifier } from '@web/services/tracking/config';
import { EventOutput, EventTreeValue, getData, InputMapper, ValidEvent } from '@web/services/tracking/helpers';

/**
 * Track an event
 *
 * @example
 * ```ts
 * await trackEvent({ event: [Context.documents, Action.click, Modifier.pdf], options: data });
 * ```
 *
 * @note trackEventSync is preferred for onClick handlers
 */
export async function trackEvent<
	C extends Context,
	A extends Action | never,
	M extends Modifier | never,
	F extends EventTreeValue<C, A, M>,
	O extends F extends InputMapper ? Parameters<F>[0] : never,
>({ event, options }: {
	event: ValidEvent<C, A, M>;
	options?: O;
}) {
	const [context, action, modifier] = event;
	const dataOrFunction = getData(context, action, modifier);

	let data: EventOutput;

	if (typeof dataOrFunction === 'function') {
		data = dataOrFunction(options);
	} else {
		data = dataOrFunction;
	}

	const trackingEvents: Promise<void>[] = [];

	if (data.googleAds) {
		// eslint-disable-next-line no-console
		console.debug('Google Ads:', data.googleAds);
		trackingEvents.push(new Promise((resolve, reject) => {
			// Wrapping in a promise to prevent any errors from blocking the other tracking events
			try {
				googleAds.trackConversion(data.googleAds as EventTypes);
				resolve();
			} catch (e) {
				reject(e);
			}
		}));
	}

	if (data.siteimprove) {
		// eslint-disable-next-line no-console
		console.debug('Siteimprove:', [data.context || context, data.action || action, data.modifier || modifier]);
		trackingEvents.push(Siteimprove.trackEvent(data.context || context, data.action || action || 'event', data.modifier || modifier));
	}

	return Promise.all(trackingEvents);
}

/**
 * Does not actually execute the tracking synchronously,
 * but catches any errors that might occur and logs them to the console.
 *
 * Useful for onClick handlers, where you don't want to block the user from navigating away.
 * @example
 * ```tsx
 * 	<button onClick={() => trackEventSync({ event: [Context.documents, Action.click, Modifier.pdf], options: data })}>Text</button>
 * ```
 */
export function trackEventSync<
	C extends Context,
	A extends Action | never,
	M extends Modifier | never,
	F extends EventTreeValue<C, A, M>,
	O extends F extends InputMapper ? Parameters<F>[0] : never,
>({ event, options }: {
	event: ValidEvent<C, A, M>;
	options?: O;
}) {
	trackEvent({ event, options })
		.catch((e) => {
			// eslint-disable-next-line no-console
			console.warn('Error tracking event', e);
		});
}
