import { useEffect, useState } from 'react';
import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import { EmployeeCard } from '@web/blocks/persons-list-block/components/employee-card';
import { CMSPersonType, PhoneBookPerson, PhonebookPersonCard } from '@web/blocks/persons-list-block/components/phonebook-person-card';
import { PersonsCRMList } from '@web/blocks/persons-list-block/types';

import { Theme } from '@web/components/theme/theme';
import { ThemedSkeleton } from '@web/components/themed-skeleton/themed-skeleton';

import { EmployeeDto, getEmployeeById } from '@web/services/crm-api/rest';
import { axiosUrl } from '@web/services/crm-api/rest/axios';
import { reportException } from '@web/services/errorhandler';
import { crmError } from '@web/services/errorhandler/appError';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ThemeDefinition } from '@web/services/umbraco/types/basic/Color';

import styles from './persons-list-block.module.scss';

const blockName = 'personsListBlock' as const;

export type PersonsListBlockProps = {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: string;
			persons: (PhoneBookPerson | PersonsCRMList)[];
		},
		{
			colorTheme?: ThemeDefinition;
		}
	>;
};

// Height of loader for cards in list, corresponds to the average height of the cards components and height figma
const cardHeight = 540;

export function PersonsListBlock({ block }: PersonsListBlockProps) {
	const { settingsProperties = {} } = block;
	const { colorTheme } = settingsProperties || {};
	const [isLoading, setIsLoading] = useState(true);
	const [resolvedPersons, setResolvedPersons] = useState<(PhoneBookPerson | EmployeeDto)[]>();

	const {
		title,
		persons,
	} = block.contentProperties || {};

	function isPhoneBookPerson(person: PhoneBookPerson | EmployeeDto): person is PhoneBookPerson {
		return (person as PhoneBookPerson).contentAlias === CMSPersonType;
	}

	const skeletonCount = persons.reduce((count, person) => {
		if (person.contentAlias === CMSPersonType) {
			return count + 1;
		}

		return count + person.contentProperties.persons.length;
	}, 0);


	useEffect(() => {
		try {
			setIsLoading(true);

			const personsPromises = persons.map(async (person) => {
				if (person.contentAlias === CMSPersonType) {
					return person;
				}

				const employees = person.contentProperties.persons
					.map((personId) => getEmployeeById(personId, {
						url: `/employees/${personId}`,
						baseURL: axiosUrl,
					})
						.catch((error) => {
							reportException({ message: `Failed to fetch employee with id ${personId}`, cause: error, context: { personId } });
							return null;
						}));

				return Promise.all(employees);
			});

			Promise.all(personsPromises)
				.then((result) => {
					const flattened = result.flat(1)
						.filter((val): val is PhoneBookPerson | EmployeeDto => !!val)
						.filter((i) => (!isPhoneBookPerson(i) ? i.showOnWeb : true));

					setResolvedPersons(flattened);
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		} catch (error) {
			setIsLoading(false);
			throw crmError({ message: 'Error resolving persons', cause: error as unknown as Error });
		}
	}, [persons]);

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme?.theme),
			})}
		>
			<Container className={styles.container}>
				<div className={cn(styles.content)}>
					<h2>{title}</h2>
					{isLoading ? (
						<ThemedSkeleton
							inline={true}
							containerClassName={styles.persons_list}
							className={styles.persons_list__loader}
							height={cardHeight}
							count={skeletonCount}
						/>
					) : (
						<div className={styles.persons_list}>
							{resolvedPersons && resolvedPersons.map((person: PhoneBookPerson | EmployeeDto) => {
								if (isPhoneBookPerson(person)) {
									return (
										<PhonebookPersonCard
											key={person.key}
											block={person as PhoneBookPerson}
										/>
									);
								}

								return <EmployeeCard key={person.employeeId} employee={person} />;
							})}
						</div>
					)}
				</div>
			</Container>
		</Theme>
	);
}

PersonsListBlock.blockName = blockName;
