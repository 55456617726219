import { getEventById } from '@web/services/crm-api/rest';
import { AppError, appError, umbracoFetchError } from '@web/services/errorhandler/appError';
import {
	BasicContent,
	ContentTypeQuery,
	ContentTypeQueryVariables,
	DepartmentsQuery,
	PageUrlQuery,
} from '@web/services/gql/graphql';
import { urqlClient } from '@web/services/umbraco/client';
import { fetchTagsRecursive } from '@web/services/umbraco/fetchers/get-tags';
import { resolvePageContext } from '@web/services/umbraco/fetchers/page-context';
import { fetchAllSubjects } from '@web/services/umbraco/fetchers/subjects';
import { getFirstNodeUrl } from '@web/services/umbraco/helpers';
import { contentTypeQuery } from '@web/services/umbraco/queries/content-type';
import { departmentsQuery, mapDepartmentNodes } from '@web/services/umbraco/queries/departments';
import { MappedRootData, RootQuery, rootQuery, RootQueryVariables } from '@web/services/umbraco/queries/root.query';
import { pageUrlQuery } from '@web/services/umbraco/queries/url-by-content-type';
import { BaseDocument, mapDocument } from '@web/services/umbraco/type-mapping';
import { MappedServicebanner } from '@web/services/umbraco/types/entities/Servicebanner';

import { DocumentTypeMap, GraphQLResult, GraphQLVariables, SlugMap, TemplateKey, TemplateMap, templates } from '@web/templates';
import { ErrorPageData } from '@web/templates/errorpage';

import { getApiSearchNews, getApiSearchPublications } from './rest/search/search';
import { getRootUrl } from './route-mapping';

export async function fetchRouteData<T extends TemplateKey>(slugMap: SlugMap<T>): Promise<DocumentTypeMap[T] | null> {
	const { query, selector } = templates[slugMap.docType];
	const response = await urqlClient.query<GraphQLResult<T>>(query, slugMap.queryParams).toPromise();

	if (response.error) {
		if (response.error.message === '[Network] No Content') {
			return null;
		}

		throw umbracoFetchError({
			message: 'Error loading routeData from umbraco',
			cause: response.error,
			info: {
				graphQLVariables: slugMap.queryParams,
			},
		});
	}

	const data = response?.data?.[selector];

	if (!data) {
		// Content not found
		return null;
	}

	const mappedData = mapDocument<TemplateMap[T]>(data);


	if (slugMap.docType === 'eventPage') {
		const parent = (mappedData as TemplateMap['eventPage']);
		const event = await getEventById((slugMap.queryParams as GraphQLVariables<'eventPage'>).eventId);

		if (event.status === 'Draft' && !slugMap.queryParams.preview) {
			// Draft event, and not in preview mode should result in a Not found page
			return null;
		}

		const userEventPageUrl = await urqlClient.query<PageUrlQuery>(pageUrlQuery, { contentType: 'userEventsOverviewPage' }).toPromise();
		const userContactInfoPageUrl = await urqlClient.query<PageUrlQuery>(pageUrlQuery, { contentType: 'userContactInfoPage' }).toPromise();

		(mappedData as TemplateMap['eventPage']).userEventsPageUrl = getFirstNodeUrl(
			(userEventPageUrl.data?.contentByContentType?.nodes || []) as { url: string }[],
		);
		(mappedData as TemplateMap['eventPage']).userContactInfoPageUrl = getFirstNodeUrl(
			(userContactInfoPageUrl.data?.contentByContentType?.nodes || []) as { url: string }[],
		);
		return {
			...mappedData,
			id: 0, // Set to 0 as Event doesn't have an Umbraco document
			url: `${parent.url}/${event.eventId}`,
			contentType: 'eventPage',
			properties: {
				event,
				headerType: parent.properties.headerType,
				breadcrumbs: {
					...parent.properties.breadcrumbs,
					items: parent.properties.breadcrumbs?.items && [
						...parent.properties.breadcrumbs.items,
						{
							contentType: 'eventPage',
							title: event.title,
						},
					],
				},
				footerType: parent.properties.footerType,
				customContactComponent: parent.properties?.customContactComponent || null,
			},
		};
	}

	if (slugMap.docType === 'memberBookingsDetailPage') {
		const parent = (mappedData as TemplateMap['memberBookingsOverviewPage']);

		return {
			...mappedData,
			id: 0, // Set to 0 as CalendarItem doesn't have an Umbraco document
			url: `${parent.url}/${(slugMap.queryParams as GraphQLVariables<'memberBookingsDetailPage'>).calendarItemId}`,
			contentType: 'memberBookingsOverviewPage',
			properties: {
				headerType: parent.properties.headerType,
				hero: parent.properties.hero,
				breadcrumbs: parent.properties.breadcrumbs,
				blocks: parent.properties.blocks,
				footerType: parent.properties.footerType,
				calendarItemId: (slugMap.queryParams as GraphQLVariables<'memberBookingsDetailPage'>).calendarItemId,
			},
		};
	}

	if (slugMap.docType === 'newsPage') {
		(mappedData as TemplateMap['newsPage']).relatedContent = reduceNodes(
			(response.data as GraphQLResult<'newsPage'>)?.relatedContentByRoute?.nodes || [],
		);
	}

	const slug = slugMap.queryParams.slug;

	if (slugMap.docType === 'newsOverviewPage') {
		(mappedData as TemplateMap['newsOverviewPage']).tags = await fetchTagsRecursive({ contentType: 'newsTag' });
		(mappedData as TemplateMap['newsOverviewPage']).news = await getApiSearchNews({
			limit: 24,
			skip: 0,
			route: slug,
		});
	}

	if (slugMap.docType === 'publicationsOverviewPage') {
		(mappedData as TemplateMap['publicationsOverviewPage']).tags = await fetchTagsRecursive({ contentType: 'publicationTag' });
		(mappedData as TemplateMap['publicationsOverviewPage']).publications = await getApiSearchPublications({
			limit: 24,
			skip: 0,
			route: slug,
		});
	}

	if (slugMap.docType === 'userMembershipInfoPage' || slugMap.docType === 'unionRepOverviewPage') {
		const departments = await urqlClient.query<DepartmentsQuery>(departmentsQuery, {}).toPromise();

		(mappedData as TemplateMap['userMembershipInfoPage' | 'unionRepOverviewPage']).departments = mapDepartmentNodes(
			(departments.data?.contentByContentType?.nodes || []) as BasicContent[],
		);
	}

	return mappedData;
}

export function reduceNodes<T extends BaseDocument>(nodes: BasicContent[]): T[] {
	return nodes.map((data) => mapDocument(data));
}

export async function fetchRootData(slugMap: SlugMap): Promise<MappedRootData> {
	const rootVariables: RootQueryVariables = {
		...slugMap.queryParams,
		rootSlug: getRootUrl(slugMap.queryParams.slug),
	};

	const [response, subjects] = await Promise.all([
		urqlClient.query<RootQuery>(rootQuery, rootVariables).toPromise(),
		fetchAllSubjects(slugMap),
	]);

	if (response.error) {
		throw umbracoFetchError({
			message: 'Error loading root data',
			cause: response.error,
			info: {
				graphQLVariables: rootVariables,
			},
		});
	}

	const data = response?.data?.contentByAbsoluteRoute;

	if (!data) {
		throw umbracoFetchError({
			message: 'No content found for root',
			info: {
				graphQLVariables: rootVariables,
			},
		});
	}

	const rootData = mapDocument<MappedRootData>(data);
	const servicebannerNodes = (response?.data?.servicebanners?.nodes || []) as BasicContent[];
	const servicebanners = reduceNodes<MappedServicebanner>(servicebannerNodes);
	const unauthorized = mapDocument<ErrorPageData>(response?.data?.unauthorized as BasicContent);
	const forbidden = mapDocument<ErrorPageData>(response?.data?.forbidden as BasicContent);

	const memberPageUrl = getFirstNodeUrl(
		(response?.data?.memberPageUrl?.nodes || []) as { url: string }[],
	);

	const searchPageUrl = getFirstNodeUrl(
		(response?.data?.searchPageUrl?.nodes || []) as { url: string }[],
	);

	const rootTransportPageUrl = getFirstNodeUrl(
		(response?.data?.rootTransportPageUrl?.nodes || []) as { url: string }[],
	);

	return {
		...rootData,
		subjects,
		servicebanners,
		unauthorized,
		forbidden,
		memberPageUrl,
		searchPageUrl,
		rootTransportPageUrl,
	};
}

export async function fetchUmbracoData(slugMap: SlugMap) {
	try {
		const [rootData, routeData] = await Promise.all([
			fetchRootData(slugMap),
			fetchRouteData(slugMap),
		]);

		const pageContext = await resolvePageContext(routeData);

		return {
			rootData,
			routeData,
			pageContext,
		};
	} catch (ex) {
		if (AppError.isAppError(ex)) {
			throw ex;
		} else {
			throw appError({ message: 'Error retrieving umbraco content', cause: ex });
		}
	}
}

export async function fetchContentType(queryParams: {
	route: string;
	preview?: boolean;
	baseUrl: string;
}): Promise<string | null> {
	const response = await urqlClient
		.query<ContentTypeQuery, ContentTypeQueryVariables>(contentTypeQuery, queryParams)
		.toPromise();

	if (response.error) {
		throw umbracoFetchError({
			message: 'Error loading contentType',
			cause: response.error,
			info: {
				graphQLVariables: queryParams,
			},
		});
	}

	const data = response?.data?.contentByAbsoluteRoute;

	if (!data) {
		// Content not found
		return null;
	}

	const alias = data?.contentType?.alias;

	if (!alias) {
		throw umbracoFetchError({
			message: 'Unable to locate content type in recieved response',
			info: {
				data,
				queryParams,
			},
		});
	}

	return alias;
}
