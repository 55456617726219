import { toTitleCase } from '@dansk-metal/utils/common';

import { dayJS } from './dayjs';

/**
 * Use this date as a reference to define types for the `formatDate` function.
 * 2022-02-22T09:00:00+01:00
 */
type DateFormatPreset = 'default'
	| 'Tir 22. feb'
	| 'Tirsdag 22. feb, kl. 09.00'
	| 'Tir 22. feb, 09.00'
	| '22. feb 2022'
	| '22. februar'
	| '22 FEB'
	| 'Februar 2022'
	| '22. februar 2022 kl. 09.00'
	| '22/2 2022';

/**
 * Formats a date string or Date object to a human readable date in the CET timezone.
 * If no timezone is provided, the date will be assumed to be in the CET timezone.
 */
export function formatDate(value: string | Date, type: DateFormatPreset) {
	switch (type) {
		case '22. feb 2022': {
			return dayJS(value)
				.format('D. MMM YYYY')
				.split(' ')
				.map((part, index) => {
					if (index === 1) return part.replace('.', '');
					return part;
				})
				.join(' ');
		}

		case '22 FEB': {
			return dayJS(value)
				.format('DD MMM')
				.replace('.', '')
				.toUpperCase();
		}

		case 'Tir 22. feb': {
			return dayJS(value)
				.format('ddd DD. MMM')
				.split(' ')
				.map((part, index) => {
					if (index === 0) return toTitleCase(part).replace('.', '').substring(0, 3);
					if (index === 2) return part.replace('.', '');
					return part;
				})
				.join(' ');
		}

		case 'Tir 22. feb, 09.00': {
			return dayJS(value)
				.format('ddd DD. MMM, HH.mm')
				.split(' ')
				.map((part, index) => {
					if (index === 0) return toTitleCase(part).replace('.', '').substring(0, 3);
					if (index === 2) return part.replace('.', '');
					return part;
				})
				.join(' ');
		}

		case 'Februar 2022': {
			return toTitleCase(dayJS(value)
				.format('MMMM YYYY'),
			);
		}

		case '22/2 2022': {
			return dayJS(value).format('D/M YYYY');
		}

		default:
			return dayJS(value).format('DD. MMMM YYYY');
	}
}
