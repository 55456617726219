import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { getTestId } from '@dansk-metal/service/testing';
import { Container } from '@dansk-metal/ui';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';

import { BlockType } from '@apps/web/src/blocks';
import { BasicTextBlockType } from '@apps/web/src/blocks/basic-text-block/basic-text-block';
import { ContentBlock } from '@apps/web/src/components/block-list/content-block';
import { ContentDrawer } from '@apps/web/src/components/content-drawer/content-drawer';
import { LinkList } from '@apps/web/src/components/link-list/link-list';
import { UmbracoDocument } from '@apps/web/src/services/umbraco/types/documents/UmbracoDocument';

import { FAQListBlockSchemaOrg } from './faq-list-block-schema-org';

import styles from './faq-list-block.module.scss';

const blockName = 'faqListBlock' as const;

type FAQItem = UmbracoDocument<
	'faqItem',
	{
		title: string;
		blocks: BlockType[];
	}
>;

export type FAQListBlockType = UmbracoBlock<
	typeof blockName,
	{
		title: HTMLString;
		faqItems: FAQItem[];
	}
>;

export interface FAQListBlockProps {
	block: FAQListBlockType;
}

export function FAQListBlock({ block }: FAQListBlockProps) {
	const { title, faqItems } = block.contentProperties;
	const router = useRouter();
	const queryName = 'emne';
	const { [queryName]: currentQueryName } = router.query;

	const { properties: { title: drawerTitle = '', blocks = [] } = {} } = currentQueryName
		? faqItems.find((item) => item.url === `/${currentQueryName}`) || {}
		: {};

	const handleContentDrawerClose = () => {
		const pathname = typeof window !== 'undefined' ? window.location.pathname : '/';
		router.push({ pathname }, undefined, { shallow: true });
	};

	const isAvailablePath = () => {
		if (!currentQueryName) return false;
		return faqItems.some((item) => item.url === `/${currentQueryName}`);
	};

	return (
		<div className={styles.faq}>
			<FAQListBlockSchemaOrg
				id={`faq-schema-${title.replace(/\s/g, '-')}`}
				entities={faqItems.map((item) => ({
					question: item.properties.title,
					answer: item.properties.blocks
						.filter((answer) => answer.contentAlias === 'basicTextBlock')
						.map((answer: BasicTextBlockType) => answer.contentProperties.text),
				}))}
			/>
			<LinkList
				title={title}
				links={faqItems.map((item) => (
					<Link
						href={{
							pathname: router.asPath,
							query: { [queryName]: item.url.slice(1) },
						}}
						scroll={false}
						shallow
						key={`faq-item-${item.id}`}
					>
						{item.properties.title}
					</Link>
				))}
			/>
			<ContentDrawer
				open={isAvailablePath()}
				queryParam={queryName}
				paths={faqItems.map((item) => item.url.slice(1))}
				onClose={handleContentDrawerClose}
				testId={getTestId('content_drawer')}
			>
				<Container>
					<h2>{drawerTitle}</h2>
				</Container>
				<ContentBlock blocks={blocks} className={cn('blocks', styles.blocks)} />
			</ContentDrawer>
		</div>
	);
}

FAQListBlock.blockName = blockName;
