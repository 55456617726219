import { AddressDto } from '@web/services/crm-api/rest';

export function omitCountry(address: AddressDto, countriesToOmit = ['denmark', 'danmark']): AddressDto {
	if (address?.country && countriesToOmit.includes(address?.country?.toLowerCase())) {
		const { country: _, ...rest } = address;

		return rest;
	}

	return address;
}
