import { EventTypes } from '@web/services/google-ads/config';
import type { EventTreeResult } from '@web/services/tracking/helpers';

/**
 * Define the possible contexts.
 *
 * The "Context" represents the broader category or type of event.
 * It helps you understand the general area or subject of the interaction.
 * For example, if you're tracking events on a website,
 * the context could be related to specific sections like "Documents," "Users," or "Products."
 */
export const Context = {
	Membership: 'membership',
	MemberArea: 'memberarea',
	SalaryCalculator: 'SalaryCalculator',
	ApprenticeSalaryCalculator: 'ApprenticeSalaryCalculator',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Context = typeof Context[keyof typeof Context];

/**
 * Define the possible Actions
 *
 * The "Action" describes the type of user interaction or behavior.
 * It signifies what the user did. Common actions include "Click," "Submit," "View," "Download," etc.
 * This provides information about how the user engaged with the content or functionality.
 */
export const Action = {
	Register: 'register',
	Signin: 'signin',
	Complete: 'complete',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Action = typeof Action[keyof typeof Action];

/**
 * Define the possible modifiers
 *
 * The "Modifier" further refines the event by specifying additional details or characteristics.
 * It's an optional parameter that adds more context to the action.
 * For instance, if the action is "Download," the modifier could be "PDF," "Video," or "Image," indicating the type of file that was downloaded.
 */
export const Modifier = {
	Positive: 'positive',
	Negative: 'negative',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Modifier = typeof Modifier[keyof typeof Modifier];

/**
 * Define possible events - and what happens when they are triggered.
 * Nesting will determine the valid interactions between contexts, actions and modifiers.
 * `[Context.documents, Action.click, Modifier.pdf]`
 */
export const eventTree = {
	[Context.MemberArea]: {
		[Action.Signin]: {
			siteimprove: true,
			googleAds: EventTypes.Login,
		},
	},
	[Context.Membership]: {
		[Action.Register]: (data: { membership: string }) => ({
			siteimprove: true,
			googleAds: EventTypes.Signup,
			modifier: data.membership,
		}),
	},
	[Context.SalaryCalculator]: {
		[Action.Complete]: {
			googleAds: EventTypes.SalaryCalculator,
			siteimprove: true,
		},
	},
	[Context.ApprenticeSalaryCalculator]: {
		[Action.Complete]: {
			googleAds: EventTypes.ApprenticeSalaryCalculator,
			siteimprove: true,
		},
	},
} as const satisfies EventTreeResult;
