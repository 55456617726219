import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export type UmbracoSimpleText = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Umbraco.TextBox' | 'Umbraco.TextArea';
	value: { value?: string };
};

export class SimpleTextConverter {
	static UmbracoType: UmbracoSimpleText;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.TextBox'
			|| input.editorAlias === 'Umbraco.TextArea';
	}

	public static mapProperty(prop: typeof this.UmbracoType): string | undefined {
		return prop.value.value;
	}
}
