import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import { useGlobalContext } from '@web/layouts/page/context';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';

import { Richtext } from '@apps/web/src/components/richtext/richtext';
import { Theme } from '@apps/web/src/components/theme/theme';
import { ColorTheme, ThemeDefinition } from '@apps/web/src/services/umbraco/types/basic/Color';

import styles from './basic-text-block.module.scss';

const blockName = 'basicTextBlock' as const;

export type BasicTextBlockType = UmbracoBlock<
	typeof blockName,
	{
		text: string;
	},
	{
		colorTheme?: ThemeDefinition;
	}
>;

export interface BasicTextBlockProps {
	block: BasicTextBlockType;
}

export function BasicTextBlock({ block }: BasicTextBlockProps) {
	const { text } = block.contentProperties;
	const { colorTheme = { theme: ColorTheme.None } } = block.settingsProperties;
	const { routeData } = useGlobalContext();

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme.theme),
				[styles.article_margin]: routeData?.contentType === 'newsPage',
			})}
		>
			<Container>
				<div className={cn(styles.content)}>
					<Richtext content={text} />
				</div>
			</Container>
		</Theme>
	);
}

BasicTextBlock.blockName = blockName;
