export enum AuthHandling {
	Ignore = 'ignore',
	Prompt = 'prompt',
}

export enum IframeType {
	DMS1 = 'dms1',
	JOBLOG = 'joblog',
	Resizer = 'resizer',
	Other = 'other',
}
