import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import { useBlockContext } from '@web/components/block-list/provider';
import { Aspect } from '@web/components/media/aspectRatios';
import { QBankMediaDisplay } from '@web/components/qbank-media/qbank-media';
import { Richtext } from '@web/components/richtext/richtext';
import { Theme } from '@web/components/theme/theme';
import { UmbracoAction } from '@web/components/umbraco-action/umbraco-action';

import { TextPosition } from '@web/services/umbraco/types/basic/Alignment';
import { MediaAspectRatio } from '@web/services/umbraco/types/basic/Aspectratio';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ColorTheme, ThemeDefinition } from '@web/services/umbraco/types/basic/Color';
import { QBankMedia } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString, RichTextContent } from '@web/services/umbraco/types/basic/RichTextContent';
import { Action } from '@web/services/umbraco/types/elements/Action';

import styles from './text-and-media-block.module.scss';

const blockName = 'textAndMediaBlock' as const;

export interface TextAndMediaBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: HTMLString;
			bodyText: RichTextContent;
			media: QBankMedia;
			actions: Action[];
		},
		{
			textPosition?: TextPosition;
			mediaAspectRatio?: MediaAspectRatio;
			colorTheme?: ThemeDefinition;
		}
	>;
}

export function TextAndMediaBlock({ block }: TextAndMediaBlockProps) {
	const { title, bodyText, media, actions } = block.contentProperties;
	const { row } = useBlockContext();

	const {
		textPosition = TextPosition.Last,
		mediaAspectRatio = MediaAspectRatio['4:3'],
		colorTheme = { theme: ColorTheme.None },
	} = block.settingsProperties;

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme.theme),
			})}
		>
			<Container
				className={cn(styles.container, { [styles.has_color_theme]: hasColorPallette(colorTheme.theme) })}
			>
				<div className={cn(styles.content, { [styles[`content_${textPosition}`]]: !!textPosition })}>
					<div className={styles.media_container}>
						<QBankMediaDisplay
							media={media}
							imageProps={{
								priority: row === 0,
								initialHeight: true,
								sizes: '(min-width: 1280px) 40vw, (min-width: 768px) 60vw, 90vw',
							}}
							aspectRatio={mediaAspectRatio === MediaAspectRatio.Original ? undefined : Aspect['4:3']}
						/>
					</div>
					<div className={styles.text_content}>
						<div className={styles.text_content__inner}>
							<h2 className="h3" dangerouslySetInnerHTML={{ __html: title }} />
							<Richtext content={bodyText} />
							{actions.length !== 0 && (
								<div className={styles.button_wrapper}>
									{actions.map((item) => (
										<UmbracoAction key={item.key} action={item} />
									))}
								</div>
							)}
						</div>
					</div>
				</div>
			</Container>
		</Theme>
	);
}

TextAndMediaBlock.blockName = blockName;
