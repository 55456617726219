import { useEffect, useState } from 'react';
import { UseQueryOptions } from 'react-query';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { Return } from 'ts-toolbelt/out/Function/Return';

import { AuthorizationErrorResult, ErrorResult, getEmployedContactDto, getMemberById, MemberDto, ValidationErrorResult } from '@web/services/crm-api/rest';
import { crmAxios } from '@web/services/crm-api/rest/axios';
import { CRMUserType } from '@web/services/crm-api/token';
import { isUserType } from '@web/services/crm-api/user-type.guard';
import { AppError, crmError } from '@web/services/errorhandler/appError';
import { ErrorType } from '@web/services/umbraco/rest/axios';

type RequestConfig = { baseURL: string };

type UseAuthenticatedCRMRequestParams<T> = {
	request: (session: Session, config: RequestConfig) => Promise<T>;
};

export function useAuthenticatedCRMRequest<T>({ request }: UseAuthenticatedCRMRequestParams<T>) {
	const [isLoading, setIsloading] = useState(false);
	const [isLoaded, setIsloaded] = useState(false);
	const [error, setError] = useState<AppError | null>(null);
	const [loadedData, setLoadedData] = useState<T | null>(null);
	const { status, data, requestConfig } = useAuthenticatedCRMRequestConfig();

	const fetchData = async () => {
		if (status === 'authenticated' && !isLoaded && !isLoading) {
			setIsloading(true);

			request(data, requestConfig)
				.then((responseData) => {
					setLoadedData(responseData);
					setIsloaded(true);
					setIsloading(false);
				})
				.catch((err) => {
					setIsloaded(true);
					setIsloading(false);
					const ex = crmError({
						message: 'Error loading authenticated request',
						cause: err,
						info: {
							user: data.user,
						},
					});

					setError(ex);
				});
		}
	};

	useEffect(() => {
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps -- False positive on data.user
	}, [data, data?.user, isLoaded, isLoading, request, status]);

	return {
		data: loadedData,
		refetch: fetchData,
		error,
		isLoading,
		isLoaded,
	};
}

type UseAuthenticatedCRMRequestConfigReturn = Return<typeof useSession> & {
	requestConfig: RequestConfig;
	memberId: string;
	isMember: boolean;
};

export function useAuthenticatedCRMRequestConfig(): UseAuthenticatedCRMRequestConfigReturn {
	const session = useSession();

	return {
		...session,
		memberId: session?.data?.user?.memberId || '',
		isMember: session?.data ? isUserType(session.data, CRMUserType.Member) : false,
		requestConfig: {
			baseURL: '/api/crm',
		},
	};
}


type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * Polymorphic user request
 * Will return a MemberDTO if the user is a member, otherwise it will fetch an EmployedContactDTO, and construct a MemberDTO from it
 */
export function useGetUser<
	TData = Awaited<ReturnType<typeof getMemberById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
) {
	return useAuthenticatedCRMRequest({
		request: async (session, config) => {
			if (isUserType(session, CRMUserType.Member)) {
				return getMemberById(session.user.memberId, { ...(options || {}), ...config });
			}

			const response = await getEmployedContactDto(session.user.memberId, { ...(options || {}), ...config });

			return {
				memberId: session.user.memberId,
				flags: {
					isTillidsRepresentant: true,
					isArbejdsmarkedRepresentant: true,
				},
				firstName: response.name,
				lastName: '',
				email: response.email,
			} satisfies MemberDto;
		},
	});
}
