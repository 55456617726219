
import { Button, ButtonProps } from '@dansk-metal/ui';

import styles from './contact-message-confirmation.module.scss';

type ContactMessageConfirmationProps = {
	onClose: ButtonProps['onClick'];
};

export function ContactMessageConfirmation({ onClose }: ContactMessageConfirmationProps) {
	return (
		<div className={styles.container}>
			<div className={styles.head}>
				<h2>Tak for din besked</h2>
			</div>

			<div className={styles.block}>
				<p>
					Tak for din besked. Vi svarer dig hurtigst muligt, men der kan gå op til 2 hverdage, før du får svar fra os.
				</p>
			</div>

			<div className={styles.block}>
				<div>
					<div className={styles.button}>
						<Button icon={{ icon: 'arrow-right', size: 24 }} onClick={onClose}>
							Luk
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
