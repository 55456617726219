const fakeHost = 'https://example.com';

/** A version of the native URL class which supports relative urls */
export class RelativeUrl {
	public href: string;
	public hash: string;
	public pathname: string;
	public search: string;
	readonly searchParams: URLSearchParams;

	/**
	 * @param url - A relative URL
	 * @example new RelativeUrl('/foo/bar?baz=qux#quux')
	 */
	constructor(url: string) {
		const fakeUrl = new URL(url, fakeHost);
		this.href = fakeUrl.href.replace(fakeHost, '');
		this.hash = fakeUrl.hash;
		this.pathname = fakeUrl.pathname;
		this.search = fakeUrl.search;
		this.searchParams = fakeUrl.searchParams;
	}

	public toJSON() {
		return this.href;
	}

	public toString() {
		return this.href;
	}
}

/**
 * Compares two relative urls and returns true if their paths are equal.
 * @param a - A relative URL
 * @param b - A relative URL
 * @example ```ts
 * comparePaths('/foo/bar?baz=qux#quux', '/foo/bar?shoop=de#woop') // true
 * comparePaths('/foo/bar', '/foo/baz') // false
 * ```
 */
export function isSamePathname(a: string, b: string): boolean {
	return new RelativeUrl(a).pathname === new RelativeUrl(b).pathname;
}
