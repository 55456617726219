import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';

import { Button } from '@dansk-metal/ui';
import { dayJS } from '@dansk-metal/utils/date';

import { Modal } from '@web/components/modal/modal';

import styles from './re-auth-modal.module.scss';

export interface ReAuthModalProps {
	onDismiss: () => void;
}

export function ReAuthModal({ onDismiss }: ReAuthModalProps) {
	const { data: session } = useSession();
	const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

	useEffect(() => {
		let interval: ReturnType<typeof setInterval>;

		if (session?.user?.ssoExp) {
			const now = new Date();

			const expiresAt = dayJS(session.user.ssoExp)
				.diff(now, 'minutes');

			setTimeRemaining(expiresAt);

			interval = setInterval(() => {
				setTimeRemaining((prev) => {
					if (prev === null) {
						return null;
					}

					if (prev <= 0) {
						return 0;
					}

					return dayJS(session.user.ssoExp)
						.diff(now, 'minutes');
				});
			}, 1000);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [session?.user?.ssoExp]);

	return (
		<Modal open={true} canCloseOutside={false} onClose={onDismiss}>
			<div className={styles.modal_content}>
				<h3>Du bliver logget ud om {timeRemaining} minutter</h3>
				<p>
					Du kan kun være logget ind i 60 minutter af gangen.
					Du er desværre nødt til at logge ind igen, hvis du vil fortsætte i længere tid end 60 minutter.
				</p>
				<div className={styles.modal_buttons}>
					<Button variant='fill-primary' icon={{ size: 24, icon: 'checkmark' }} onClick={onDismiss}>Okay - det er forstået</Button>
				</div>
			</div>
		</Modal>
	);
}
