import { useRef } from 'react';
import cn from 'classnames';
import Swiper from 'swiper';
import useDeepCompareEffect from 'use-deep-compare-effect';

import 'swiper/css';

import styles from './tab-bar.module.scss';

export interface TabBarProps {
	tabs: string[];
	activeTab: string;
	setActiveTab: (tab: string) => void;
}

export function TabBar({ tabs, activeTab,	setActiveTab }: TabBarProps) {
	const tabListRef = useRef<HTMLDivElement>(null);

	useDeepCompareEffect(() => {
		const tabList = tabListRef?.current;
		if (!tabList) {
			return;
		}

		const swiper = new Swiper(tabList, {
			slidesPerView: 'auto',
			spaceBetween: 24,
			freeMode: true,
			grabCursor: true,
			allowTouchMove: true,
		});

		swiper.on('slideChange', () => {
			setActiveTab(tabs[swiper.realIndex]);
		});

		return () => {
			swiper.destroy(true, true);
		};
	}, [setActiveTab, tabs]);

	return (
		<div className={styles.tabs_container}>
			<div className={styles.tab_list} ref={tabListRef}>
				<div className={cn('swiper-wrapper', styles.swiper)}>
					{tabs.map((tab, index) => (
						<button
							role='button'
							key={index}
							className={cn(styles.tab,
								'swiper-slide',
								{
									[styles.tab__active]: tab === activeTab,
								})}
							onClick={() => {
								setActiveTab(tab);
							}}>
							{tab}
						</button>
					))}
				</div>
			</div>
		</div>
	);
}
