import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { AnimatePresence } from 'framer-motion';

import { isAuthenticatedTemplate } from '@web/services/umbraco/helpers';

import { TemplateKey } from '@web/templates';

import { ServiceBanner, ServiceBannerProps } from '@apps/web/src/components/service-banner/service-banner';
import { useGlobalContext } from '@apps/web/src/layouts/page/context';
import { UmbracoPage } from '@apps/web/src/services/umbraco/types/documents/UmbracoDocument';
import { MappedServicebanner } from '@apps/web/src/services/umbraco/types/entities/Servicebanner';

export function ServicebannerManager() {
	const { banners, closeBanner } = useBannerManager();

	return (
		<AnimatePresence>
			{banners.map((props) => (
				<ServiceBanner {...props} onClose={() => closeBanner(props.key)} />
			))}
		</AnimatePresence>
	);
}


function useBannerManager() {
	const { rootData, routeData } = useGlobalContext();
	const { status } = useSession();
	const [filteredBanners, setFilteredBanners] = useState<MappedServicebanner[]>([]);
	const [banners, setBanners] = useState<(ServiceBannerProps & { key: number })[]>([]);

	// We don't show service banners on error pages or if the user is not authenticated.
	const isErrorPage = routeData?.contentType === 'errorPage' || (
		isAuthenticatedTemplate(routeData?.contentType as TemplateKey)
		&& status !== 'authenticated'
	);

	useEffect(() => {
		setFilteredBanners(isErrorPage ? [] : filterClosedBanners(rootData.servicebanners));
	}, [rootData.id, rootData.servicebanners, isErrorPage]);

	useEffect(() => {
		setBanners(mapServicebanner(filteredBanners, routeData));
	}, [routeData?.id, filteredBanners, routeData]);

	function closeBanner(key: number) {
		setBanners((items) =>
			items.map((banner) => {
				if (banner.key === key) {
					localStorage.setItem(getStorageKey(banner.key), 'closed');

					return {
						...banner,
						isOpen: false,
					};
				}

				return banner;
			}),
		);
	}

	return {
		closeBanner,
		banners,
	};
}

function filterClosedBanners(servicebanners: MappedServicebanner[]): MappedServicebanner[] {
	return servicebanners.filter((e) => !localStorage || !localStorage.getItem(getStorageKey(e.id)));
}

function mapServicebanner(
	servicebanners: MappedServicebanner[],
	routeData?: UmbracoPage,
): (ServiceBannerProps & { key: number })[] {
	return servicebanners
		.filter((e) => {
			if (localStorage && localStorage.getItem(getStorageKey(e.id))) {
				return false;
			}

			const pageMatch = (e.properties.pages || []).find((page) => page.id === routeData?.id);

			if (pageMatch) {
				return true;
			}

			const match = (e.properties.pagesPlusChildren || []).find((page) =>
				routeData?.properties.breadcrumbs?.items.find((item) => item.id === page.id),
			);

			return !!match;
		})
		.map((e) => {
			return {
				key: e.id,
				message: e.properties.text,
				bannerType: e.properties.severity,
				isClosable: e.properties.dismissible === 'true',
				isOpen: true,
			};
		});
}

function getStorageKey(id: number): `servicebanner_${number}` {
	return `servicebanner_${id}`;
}
