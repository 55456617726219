import { locale } from './dayjs';
import { formatDate } from './format-date';

export function formatDateRange(startTime: string | Date, endTime: string | Date) {
	const startDate = new Date(startTime);
	const endDate = new Date(endTime);
	const startDateFormatted = formatDate(startDate, 'Tir 22. feb, 09.00');
	const endHour = endDate.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });

	// Check if start and end time are on same day
	if (startDate.toDateString() === endDate.toDateString()) {
		return `${startDateFormatted} - ${endHour}`;
	}

	// Start and end date are on different days
	return `${startDateFormatted} - ${formatDate(endDate, 'Tir 22. feb')}, ${endHour}`;
}
