import { gql } from '@urql/core';

import { BlockType } from '@web/blocks';

import type { RootQuery, RootQueryVariables } from '@web/services/gql/graphql';
import { baseContentFragment } from '@web/services/umbraco/fragments/baseContent';
import { propertyFragment } from '@web/services/umbraco/fragments/propertyFragment';
import { Link } from '@web/services/umbraco/types/basic/Link';

import { ContactComponent } from '@apps/web/src/components/contact/contact';
import { MappedSubject } from '@apps/web/src/services/umbraco/queries/subjects.query';
import { UmbracoPage } from '@apps/web/src/services/umbraco/types/documents/UmbracoDocument';
import { MappedServicebanner } from '@apps/web/src/services/umbraco/types/entities/Servicebanner';
import { ErrorPageData } from '@apps/web/src/templates/errorpage';

export type { RootQuery, RootQueryVariables };

export const rootQuery = gql<RootQuery, RootQueryVariables>`
	query Root($rootSlug: String!, $baseUrl: String!, $preview: Boolean) {
		contentByAbsoluteRoute(route: $rootSlug, preview: $preview, baseUrl: $baseUrl, routeMode: CACHE_OR_ROUTING) {
			...BaseContentFragment
			properties {
				...PropertyFragment
			}
		}

		servicebanners: contentByContentType(contentType: "serviceBanner", first: 50) {
			nodes {
				...BaseContentFragment
				properties {
					... on BasicProperty {
						editorAlias,
						alias
						value {
							...BasicPropertyValueFragment
							... on CustomContentPicker {
								multiPicker,
								contentList {
									id,
									url,
									name,
									updateDate,
									contentType {
										alias,
									},
									properties (where: { editorAlias: { eq: "Noa.BreadcrumbsToggle" } } ) {
										editorAlias
										value {
											... on BreadcrumbsToggle {
												enabled,
												breadcrumbs {
													contentType,
													name,
													path,
													id,
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		unauthorized: contentByAbsoluteRoute(
			route: "/401"
			preview: $preview
			baseUrl: $baseUrl
			routeMode: CACHE_OR_ROUTING
		) {
			...BaseContentFragment
			properties {
				...PropertyFragment
			}
		}

		forbidden: contentByAbsoluteRoute(
			route: "/403"
			preview: $preview
			baseUrl: $baseUrl
			routeMode: CACHE_OR_ROUTING
		) {
			...BaseContentFragment
			properties {
				...PropertyFragment
			}
		}

		memberPageUrl: contentByContentType(contentType: "userMemberPage", first: 1, order: { level: ASC, sortOrder: ASC } ) {
			nodes {
				url
			}
		}

		searchPageUrl: contentByContentType(contentType: "searchResultPage", first: 1, order: { level: ASC, sortOrder: ASC } ) {
			nodes {
				url
			}
		}

		rootTransportPageUrl: contentByContentType(contentType: "transportPage", first: 1, order: { level: ASC, sortOrder: ASC } ) {
			nodes {
				url
			}
		}
	}

	${baseContentFragment}
	${propertyFragment}
`;

export type MappedRootData = UmbracoPage<
	'siteRoot',
	{
		umbracoUrlName?: string;
		blocks?: BlockType[];
		header: {
			primaryMenu: Link[];
			secondaryMenu: Link[];
			accountShortcuts: Link[];
		};
		footer: {
			primaryMenu: Link[];
			secondaryMenu: Link[];
			tertiaryMenu: Link[];
			facebookLink?: string;
			instagramLink?: string;
			linkedinLink?: string;
			twitterLink?: string;
		};
		default: {
			contactComponent: ContactComponent;
		};
	},
	{
		subjects: MappedSubject[];
		servicebanners: MappedServicebanner[];
		unauthorized: ErrorPageData;
		forbidden: ErrorPageData;
		memberPageUrl?: string;
		searchPageUrl?: string;
		rootTransportPageUrl?: string;
	}
>;
