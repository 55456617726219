export type DecodedCRMToken = {
	clientId: 'DANSK_METAL_HOMEPAGE';
	iss: string | 'https://dame-crmt-lba01.dame.nchosting.dk';
	aud: string | 'https://test-danskmetal.dk';
	memberId: string;
	nbf: number;
	exp: number;
	userTypes: CRMUserType[];
	UnifiedApiUniqueIdentifier: string;
};

export const CRMUserType = {
	Member: 'Member',
	EmployedAtDM: 'EmployedAtDM',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare -- ignore object assertion enum
export type CRMUserType = (typeof CRMUserType)[keyof typeof CRMUserType];
