import Img, { ImageProps } from 'next/legacy/image';
import cn from 'classnames';
import { OmitStrict } from 'type-zoo/types';

import { determineAltText } from '@web/components/qbank-image/alt-text';
import { buildUrl } from '@web/components/qbank-image/loader';

import { QBankVectorType } from '@web/services/umbraco/types/basic/QBank';

import styles from './qbank-vector.module.scss';

export type QBankVectorProps = {
	media: QBankVectorType;
	height?: string | number;
	width?: string | number;
	alt?: string;
} & OmitStrict<ImageProps, 'src' | 'width' | 'height'>;

export function QBankVector({
	media,
	className,
	width,
	height,
	alt,
	...rest
}: QBankVectorProps) {
	return (
		<div style={{ width, height }} className={cn(styles.container, className)}>
			<Img
				unoptimized
				alt={determineAltText(media, alt)}
				src={buildUrl(media.filename)}
				layout="fill"
				loader={({ src }) => src}
				{...rest}
			/>
		</div>
	);
}
