import Head from 'next/head';

import config from '@web/config';

import { buildUrl } from '@web/components/qbank-image/loader';

import { QBankMediaType } from '@web/services/umbraco/types/basic/QBank';
import { getContentPreview, isContentPreviewable, PreviewFields } from '@web/services/umbraco/types/documents/ContentPreview';

import { removeShy } from '@web/utils/remove-shy';

import { useGlobalContext } from './context';

export function SeoTags() {
	const { routeData } = useGlobalContext();

	const contentPreview: PreviewFields = routeData && isContentPreviewable(routeData)
		? getContentPreview(routeData)
		: {} as PreviewFields;

	const pageMeta = routeData?.properties.meta || {};
	const title = removeShy((pageMeta.title || contentPreview.title || routeData?.name || ''));
	const description = pageMeta.description || contentPreview?.subtitle;

	const image = pageMeta.image
		|| (contentPreview.media?.type === QBankMediaType.Image
			? contentPreview.media
			: undefined);

	return (
		<Head>
			<title>{`${title}${title ? ' | ' : ''}Dansk Metal`}</title>
			{title && <meta name="title" content={title} />}
			{description && (
				<>
					<meta name="description" content={description} />
					<meta property="og:description" content={description} />
				</>
			)}
			{routeData?.id && <meta name="contentId" content={routeData.id.toString()} />}
			{routeData?.writerId && <meta name="editorId" content={routeData.writerId.toString()} />}
			{routeData?.updateDate && <meta name="revised" content={new Date(routeData.updateDate).toISOString()} />}
			<meta property="og:type" content={'website'} />
			{routeData?.url && <meta property="og:url" content={config.BASE_URL + routeData.url} />}
			{routeData?.url && <link rel="canonical" href={config.BASE_URL + routeData.url} />}
			{image && <meta property="og:image" content={buildUrl(image.filename)} />}
		</Head>
	);
}
