import { ReactNode, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { Container } from '@dansk-metal/ui';

import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import { useAuthenticatedCRMRequestConfig } from '@web/services/crm-api/hooks';
import { useGetMemberUnreadDocumentsCount, useGetMemberUnreadUnionDocumentsCount } from '@web/services/crm-api/rest';

import { Link } from '@apps/web/src/services/umbraco/types/basic/Link';

import styles from './navigation-horizontal-scroll.module.scss';

export type NavigationHorizontalScrollProps = {
	title: ReactNode;
	menu: Link[];
};

export const NavigationHorizontalScroll = ({ title, menu }: NavigationHorizontalScrollProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const menuRef = useRef<HTMLUListElement>(null);
	const [scrollStatus, setScrollStatus] = useState<{ start: boolean; end: boolean }>({ start: true, end: false });
	const pastScrollY = useRef<number>(0);
	const { asPath } = useRouter();
	const { requestConfig, memberId, isMember } = useAuthenticatedCRMRequestConfig();

	const {
		data: unreadDocuments,
	} = useGetMemberUnreadDocumentsCount(
		memberId,
		{
			request: requestConfig,
			query: {
				enabled: isMember,
				refetchInterval: 10000,
				keepPreviousData: true,
			},
		},
	);

	const {
		data: ureadUnionDocuments,
	} = useGetMemberUnreadUnionDocumentsCount(
		memberId,
		{
			request: requestConfig,
			query: {
				enabled: isMember,
				refetchInterval: 10000,
				keepPreviousData: true,
			},
		},
	);

	const totalNumberOfDocs = (unreadDocuments?.count || 0) + (ureadUnionDocuments?.count || 0);

	const mostSignificantActiveLink = menu.reduce((acc: string, item) => {
		if (asPath && asPath.indexOf(item.url) > -1) {
			if (!acc || item.url.length > acc.length) {
				return item.url;
			}
		}

		return acc;
	}, null);

	const isActive = (item: Link) => {
		return item.url === mostSignificantActiveLink;
	};

	useEffect(() => {
		const { current } = menuRef;
		const activeItem: HTMLLIElement | null | undefined = current?.querySelector(`.${styles.active}`);

		// Scroll to active item
		if (current && activeItem) {
			const { offsetLeft, clientWidth } = activeItem;
			const activeItemRight = offsetLeft + clientWidth;
			const { scrollLeft, clientWidth: containerWidth } = current;

			if (activeItemRight > scrollLeft + containerWidth) {
				current?.scrollTo({
					left: activeItemRight - containerWidth + (menuRef.current?.clientWidth || 0),
					behavior: 'smooth',
				});
			}
		}

		// Hide gradient when at scroll end
		const handleScroll = () => {
			if (!current) {
				return null;
			}

			const { scrollWidth, clientWidth, scrollLeft } = current;

			if (scrollWidth > clientWidth) {
				setScrollStatus({
					start: scrollLeft === 0,
					end: Math.floor(scrollWidth - scrollLeft) <= clientWidth,
				});
			}
		};

		current?.addEventListener('scroll', handleScroll);
		return () => current?.removeEventListener('scroll', handleScroll);
	}, [menuRef]);

	useEffect(() => {
		let distance: number | null = null;
		// Sticky menu
		const onScroll = () => {
			const { current } = ref;
			const parent = current?.parentElement;

			if (current && parent) {
				const { height: headerHeight = 0 } = document.querySelector('header')?.getBoundingClientRect() || {};
				const parentOffset = parent.offsetTop;
				const { height } = current.getBoundingClientRect();
				const { scrollY } = window;
				const scrollingBackwards = scrollY - pastScrollY.current < 0;
				const step = pastScrollY.current - scrollY;

				if (scrollingBackwards) {
					if (distance === null) {
						distance = height + headerHeight;
					} else {
						distance = Math.max(distance - step, 0);
					}
				} else {
					distance = null;
				}

				const d = distance || 0;
				const shouldStick = scrollY > d + (parentOffset - headerHeight);
				current.style.position = shouldStick && scrollingBackwards ? 'fixed' : 'static';
				if (shouldStick) current.style.transform = `translateY(${-d}px)`;
				parent.style.height = `${height}px`;
				pastScrollY.current = scrollY;
			}
		};

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, [ref]);

	return (
		<div className={styles.relative}>
			<div className={cn(styles.wrapper, styles.has_color_theme)} ref={ref}>
				<Container className={styles.container}>
					{(typeof title === 'string')
						? <h1 dangerouslySetInnerHTML={{ __html: title }} />
						: <h1>{title}</h1>
					}
					<nav className={styles.nav}>
						<ul ref={menuRef}>
							{menu.map((item) => (
								<li key={item.key} className={styles.menu_link_wrapper}>
									<div className={cn({ [styles.active]: isActive(item) })}>
										<UmbracoLink link={item} />
									</div>
									{item.name === 'Post' && totalNumberOfDocs > 0 && (
										<div className={styles.notification}>{totalNumberOfDocs}</div>
									)}
								</li>
							))}
						</ul>
						<div
							className={cn(styles.shadow, {
								[styles.scroll_start]: scrollStatus.start,
								[styles.scroll_end]: scrollStatus.end,
							})}
						/>
					</nav>
				</Container>
			</div>
		</div>
	);
};
