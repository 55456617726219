export function sortDanish(a: string, b: string): number {
	const replacedStrinA = replaceDoubleA(a);
	const replacedStringB = replaceDoubleA(b);

	return replacedStrinA.localeCompare(replacedStringB, 'da');
}

function replaceDoubleA(c: string): string {
	return c.toLowerCase().replace(/^aa/g, 'a');
}

