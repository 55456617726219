import { useEffect, useRef } from 'react';
import cn from 'classnames';

import { vars } from '@dansk-metal/theme';
import { Icon } from '@dansk-metal/ui';

import { useHeaderContext } from '@web/components/header/context';

import { AccountView } from '@apps/web/src/components/header/menu/navigation-card/account-view/account-view';
import { MenuView } from '@apps/web/src/components/header/menu/navigation-card/menu-view/menu-view';
import { SearchView } from '@apps/web/src/components/header/menu/navigation-card/search-view/search-view';
import { useAdjustedDropdownHeight } from '@apps/web/src/hooks/useAdjustedDropdownHeight';

import styles from './navigation-card.module.scss';

export function NavigationCard() {
	const ref = useRef<HTMLDivElement>(null);
	const { currentMenu, setCurrentMenu } = useHeaderContext();
	const { updateHeight } = useAdjustedDropdownHeight(ref);

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			const { current } = ref;
			if (current && !current?.contains(e.target as Node | null)) {
				setCurrentMenu(null);
			}
		};

		window.addEventListener('click', handleClickOutside);
		return () => window.removeEventListener('click', handleClickOutside);
	}, [setCurrentMenu]);

	useEffect(() => {
		updateHeight();
	}, [currentMenu, updateHeight]);

	if (!currentMenu || currentMenu === 'showMore') {
		return null;
	}

	return (
		<div ref={ref} className={cn(styles.navigation_card, { [styles[`navigation_card__${currentMenu}`]]: currentMenu })}>
			<button aria-label='Luk menu' className={styles.dismiss} onClick={() => setCurrentMenu(null)}>
				<Icon icon="dismiss" size={24} color={vars['--color-neutral-white']} className={styles.dismiss__icon} />
			</button>
			<div className={styles.scroll}>
				{currentMenu === 'account' && <AccountView />}
				{currentMenu === 'search' && <SearchView />}
				{currentMenu === 'menu' && <MenuView />}
			</div>
		</div>
	);
}
