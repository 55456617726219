import Link from 'next/link';

import { getTestAttr } from '@dansk-metal/service/testing';
import { vars } from '@dansk-metal/theme';
import { Container, Icon } from '@dansk-metal/ui';

import styles from './campaign-footer.module.scss';

export interface CampaignFooterProps {
	enableLogoLink?: boolean;
}

export function CampaignFooter({ enableLogoLink }) {
	return (
		<footer className={styles.campaignfooter} {...getTestAttr('campaign-footer')}>
			<Container className={styles.logoContainer}>
				{enableLogoLink ? (
					<Link href="/">
						<Icon icon="logo" size={{ width: 82, height: 24 }} color={vars['--color-secondary']} />
					</Link>
				) : (
					<Icon icon="logo" size={{ width: 82, height: 24 }} color={vars['--color-secondary']} />
				)}
			</Container>
		</footer>
	);
}
