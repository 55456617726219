import { Container } from '@dansk-metal/ui';

import { Richtext } from '@web/components/richtext/richtext';
import { UmbracoAction } from '@web/components/umbraco-action/umbraco-action';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { HTMLString, RichTextContent } from '@web/services/umbraco/types/basic/RichTextContent';
import { Action } from '@web/services/umbraco/types/elements/Action';

import { getPhoneNumber } from '@web/utils/format-number';

import styles from './contact-card.module.scss';

export type ContactCardBlock = UmbracoBlock<'contactCard', {
	title: HTMLString;
	subtitle: RichTextContent;
	phoneNumber?: string;
	openingHours?: RichTextContent;
	actions?: Action[];
	id?: string;
}>;

export interface ContactCardProps {
	card: ContactCardBlock;
}

export function ContactCard({ card }: ContactCardProps) {
	const {
		title,
		subtitle,
		phoneNumber,
		openingHours,
		actions,
	} = card.contentProperties;

	return (
		<Container className={styles.container}>
			<div>
				<h3 className={styles.header}>{title}</h3>
				<Richtext content={subtitle} />
			</div>
			<div>
				<h2 className={styles.phoneNumber}>{phoneNumber ? getPhoneNumber(phoneNumber) : ''}</h2>
				{openingHours && (
					<Richtext className={styles.openHours} content={openingHours} />
				)}
				<div className={styles.button_container}>
					{actions?.map((action) => (
						<UmbracoAction key={action.key} action={action} />
					))}
				</div>
			</div>
		</Container>
	);
}
