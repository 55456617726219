import { useQueryValue } from '@noaignite-dk/utils-next';

import { SkipToMainContent } from '@dansk-metal/ui';

import Config from '@web/config';

import { CampaignFooter } from '@web/components/campaign-footer/campaign-footer';
import { Contact } from '@web/components/contact/contact';
import { ErrorBoundary } from '@web/components/error-boundary/error-boundary';
import { SiteimproveFeedback } from '@web/components/feedback/siteimprove-feedback';
import { Footer } from '@web/components/footer/footer';
import { Header } from '@web/components/header/header';
import { Preview } from '@web/components/preview/preview';
import { ServicebannerManager } from '@web/components/service-banner/service-banner-manager';
import { Evaluate } from '@web/components/utils/Evaluate';

import { useGlobalContext } from '@web/layouts/page/context';

import { buildLinkForEdit } from '@web/services/umbraco/helpers';

import { useGlobalAuthHandler } from '@web/hooks/use-global-auth';

import { SeoTags } from './seo-tags';

interface PageProps {
	children: React.ReactNode;
}

export default function Page({ children }: PageProps) {
	const { hideFooter, hideHeader } = useQueryValue<{ hideHeader?: 'true'; hideFooter?: 'true' }>();
	const { routeData, rootData, preview } = useGlobalContext();
	const isCampaign = routeData?.properties?.footerType === 'campaign';
	const enableContact = routeData?.properties?.enableContact || false;
	const enableRating = routeData?.properties?.enableRating || false;
	const enableCampaignFooterLink = routeData?.properties.linkFooterToSiteRoot || false;
	const { AuthModal } = useGlobalAuthHandler();

	return (
		<>
			<SeoTags />
			<SkipToMainContent />
			<ServicebannerManager />
			{hideHeader !== 'true' && (
				<Header />
			)}
			<main role={'main'} id={SkipToMainContent.mainContentId}>
				<ErrorBoundary>
					{children}
					{enableRating && (
						<SiteimproveFeedback />
					)}
					{enableContact && (
						<Contact
							data={routeData?.properties?.customContactComponent || rootData.properties.default.contactComponent}
						/>
					)}
				</ErrorBoundary>
			</main>
			<Evaluate input={() => {
				if (hideFooter === 'true') {
					return null;
				}

				if (isCampaign) {
					return (
						<CampaignFooter enableLogoLink={enableCampaignFooterLink} />
					);
				}

				return (
					<Footer />
				);
			}} />
			<AuthModal />
			{routeData && preview && (
				<Preview editUrl={buildLinkForEdit(routeData.id)} redeployUrl={Config.VERCEL_REDEPLOY_URL} />
			)}
		</>
	);
}
