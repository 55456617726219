import styles from './iframe-skeleton.module.scss';

interface IframeSkeletonProps {
	text?: string;
}

export function IframeSkeleton({ text }: IframeSkeletonProps) {
	return (
		<div className={styles.skeleton}>
			{text && (
				<p>{text}</p>
			)}
		</div>
	);
}
