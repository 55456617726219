// format time and get the correct date for when a user should be contacted
export function getContactTimeDate(time: string) {
	const [hours, minutes] = time.split(':').map(Number);
	const currentDate = new Date();
	const nextDay = new Date(currentDate);

	// Calculate the next working day considering weekends (Saturday and Sunday)
	if (nextDay.getDay() === 5) {
		// If today is Friday, move to Monday
		nextDay.setDate(currentDate.getDate() + 3);
	} else if (nextDay.getDay() === 6) {
		// If today is Saturday, move to Monday
		nextDay.setDate(currentDate.getDate() + 2);
	} else {
		// For other days, move to the next working day
		nextDay.setDate(currentDate.getDate() + 1);
	}

	nextDay.setHours(hours);
	nextDay.setMinutes(minutes);
	nextDay.setSeconds(0);
	nextDay.setMilliseconds(0);

	const formattedDate = nextDay.toISOString();

	return formattedDate;
}

