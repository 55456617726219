import { gql } from '@urql/core';

import type { ResolveGuidQuery, ResolveGuidQueryVariables } from '@apps/web/src/services/gql/graphql';

export type { ResolveGuidQuery, ResolveGuidQueryVariables };

export const resolveGuidQuery = gql<ResolveGuidQuery, ResolveGuidQueryVariables>`
query ResolveGuid ($GUID: UUID!, $preview: Boolean) {
	contentByGuid (id: $GUID, preview: $preview) {
		id,
		url,
		updateDate,
		name,
	}
}
`;

export type MappedResolveGuid = {
	id: number;
	url: string;
	updateDate: string;
	name: string;
};
