import { signIn } from 'next-auth/react';

import { Button, ButtonProps } from '@dansk-metal/ui';

import { useHeaderContext } from '@web/components/header/context';

import { useGlobalContext } from '@web/layouts/page/context';

import { trackEventSync } from '@web/services/tracking';
import { Action, Context } from '@web/services/tracking/config';

import { NavigationTextItem } from '@apps/web/src/components/navigation/navigation-text-item/navigation-text-item';
import { Link } from '@apps/web/src/services/umbraco/types/basic/Link';

import styles from '../navigation-card.module.scss';

type AccountViewProps = {
	primaryMenu?: Link[];
	secondaryMenu?: Link[];
};

export function AccountView({}: AccountViewProps) {
	const { rootData } = useGlobalContext();
	const { setCurrentMenu } = useHeaderContext();
	const links: Link[] = rootData.properties.header.accountShortcuts;

	const handleClick = (url) => {
		setCurrentMenu(null);
		signIn('signaturgruppen', { callbackUrl: url });
		trackEventSync({ event: [Context.MemberArea, Action.Signin] });
	};

	return (
		<div>
			<p className={styles.header}>Mit metal</p>
			<Button
				className={styles.log_in_button}
				onClick={() => handleClick(rootData.memberPageUrl)}
				children={'LOG IND'}
				variant={'fill-secondary'}
				size={'medium'}
				icon={
					{
						justifyContentSpaceBetween: true,
						position: 'after',
						size: 24,
						x: 0,
						y: 0,
						scale: 1,
						rotate: 0,
						icon: 'lock',
					} as ButtonProps['icon']
				}
			/>
			<p className={styles.not_logged_in_info}>
				Som medlem af Dansk Metal kan du logge ind og få adgang til forskellige selvbetjeningsmuligheder.
			</p>

			<div className={styles.shortcuts}>
				<p className={styles.shortcuts__title}>Genveje</p>
				<ul>
					{links.map((link, index) => (
						<li key={index}>
							<NavigationTextItem
								text={link.name}
								onClick={() => handleClick(link.url)}
								icon={'arrow-right'}
								iconSize={16}
								reverse
								toUpperCase={false}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
