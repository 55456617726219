import { EventTypes } from '@web/services/google-ads/config';
import { Action, Context, eventTree, Modifier } from '@web/services/tracking/config';

export type EventOutput = {
	siteimprove?: boolean;
	googleAds?: EventTypes;
	context?: Context | string;
	action?: Action | string;
	modifier?: Modifier | string;
};

export type InputMapper = (data: unknown) => EventOutput;

export type EventTree = typeof eventTree;

export type EventTreeResult = {
	[C in Context]?: InputMapper | EventOutput | {
		[A in Action]?: InputMapper | EventOutput | {
			[M in Modifier]?: InputMapper | EventOutput;
		};
	};
};

export type ValidActions<C extends Context> = keyof EventTree[C];
export type ValidModifiers<C extends Context, A extends ValidActions<C>> = keyof EventTree[C][A];

export type ValidEvent<C extends Context, A extends Action | never, M extends Modifier | never> =
	A extends ValidActions<C>
		? M extends ValidModifiers<C, A>
			? [C, A, M]
			: [C, A]
		: [C];

export type EventTreeValue<C extends Context, A extends Action | never, M extends Modifier | never> =
	A extends ValidActions<C>
		? M extends ValidModifiers<C, A>
			? EventTree[C][A][M]
			: EventTree[C][A]
		: EventTree[C];

export function getData(
	context: Context,
	action?: Action,
	modifier?: Modifier,
): EventOutput | InputMapper {
	if (context && action && modifier) {
		return eventTree[context][action][modifier];
	}

	if (context && action) {
		return eventTree[context][action];
	}

	if (context) {
		return eventTree[context] as InputMapper | EventOutput;
	}

	throw new Error('Invalid event');
}
