import { useMemo } from 'react';
import { ImageLoader } from 'next/image';

import { buildUrl, getImageUrl, ImageBuilderOptions } from '@apps/web/src/components/umbraco-image/helper';
import { UmbracoImageType } from '@apps/web/src/services/umbraco/types/basic/Media';

export function useImageBuilder(image: UmbracoImageType, options: ImageBuilderOptions) {
	return useMemo(() => {
		const loader: ImageLoader = (props) => {
			const params = getImageUrl(image, { ...options, width: props.width });

			return buildUrl(props.src, params);
		};

		const params = getImageUrl(image, options);
		const width = parseInt(params.get('width') || '0', 10) || options.forceWidth || image.width;
		const height = parseInt(params.get('height') || '0', 10) || options.forceHeight || image.height;

		return {
			loader,
			width,
			height,
		};
	}, [
		image,
		options,
	]);
}
