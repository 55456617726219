import { useEffect, useRef } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { Container } from '@dansk-metal/ui';
import { MOTIONS } from '@dansk-metal/utils/animations';

import { useHeaderContext } from '@web/components/header/context';

import { useGlobalContext } from '@web/layouts/page/context';

import { NavigationTextItem } from '@apps/web/src/components/navigation/navigation-text-item/navigation-text-item';
import { useAdjustedDropdownHeight } from '@apps/web/src/hooks/useAdjustedDropdownHeight';

import styles from './dropdown-menu.module.scss';

export const DropdownMenu = () => {
	const { rootData: { subjects } = {} } = useGlobalContext();
	const { currentMenu, setCurrentMenu } = useHeaderContext();
	const ref = useRef<HTMLDivElement>(null);
	const { updateHeight } = useAdjustedDropdownHeight(ref);

	useEffect(() => {
		updateHeight();
	}, [currentMenu, updateHeight]);

	if (!subjects || currentMenu !== 'showMore') {
		return null;
	}

	return (
		<AnimatePresence>
			<motion.div ref={ref} className={styles.dropdown_menu} {...MOTIONS['fade']['out-down']()}>
				<Container className={styles.container}>
					{subjects[0].children.map((subject, index) => {
						const { children } = subject;
						const showChildren = index < 4;

						return (
							<ul key={subject.id} className={cn(styles.item)}>
								<li className={styles.item__title}>
									<NavigationTextItem
										text={subject.name}
										icon={'arrow-right'}
										reverse
										link={subject.url}
										iconSize={16}
									/>
								</li>
								{showChildren &&
									children.slice(0, 3).map((child) => (
										<li key={child.id} className={styles.item__child}>
											<NavigationTextItem
												key={child.id}
												text={child.name}
												icon={'arrow-right'}
												reverse
												link={child.url}
												iconSize={16}
												toUpperCase={false}
											/>
										</li>
									))}
								{showChildren && children.length > 3 && (
									<li>
										<Link href={subject.url} onClick={() => setCurrentMenu(null)} className={styles.item__more}>
											Se mere
										</Link>
									</li>
								)}
							</ul>
						);
					})}
				</Container>
			</motion.div>
		</AnimatePresence>
	);
};
