export enum AspectRatio {
	'21:9' = 21 / 9,
	'16:9' = 16 / 9,
	'5:4' = 5 / 4,
	'4:3' = 4 / 3,
	'3:4' = 3 / 4,
	'3:2' = 3 / 2,
	'1:1' = 1 / 1,
}

export enum Aspect {
	'21:9' = '21:9',
	'16:9' = '16:9',
	'5:4' = '5:4',
	'4:3' = '4:3',
	'3:4' = '3:4',
	'3:2' = '3:2',
	'1:1' = '1:1',
}
