
import { useCallback, useState } from 'react';
import cn from 'classnames';
import type { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { Button, Container, Icon } from '@dansk-metal/ui';

import { AuthenticatedFileItem } from '@web/blocks/authenticated-publications-list-block-block/authenticated-publication-item/authenticated-file-item';

import { ErrorFrame } from '@web/components/error-frame/error-frame';
import { LinkButton } from '@web/components/link-button/link-button';
import { Theme } from '@web/components/theme/theme';

import { useAuthenticatedCRMRequestConfig } from '@web/services/crm-api/hooks';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { UmbracoErrorType } from '@web/services/umbraco/types/basic/ErrorTypes';
import { Link as UmbracoLinkType } from '@web/services/umbraco/types/basic/Link';
import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';
import { LinkStyle } from '@web/services/umbraco/types/elements/Action';

import 'swiper/css';

import styles from './authenticated-files-block.module.scss';

const publicationItemBlockName = 'authenticatedFileItem' as const;

export type FileItemBlock = UmbracoBlock<
		typeof publicationItemBlockName,
		{
			title: string;
			fileId: string;
			downloadAs?: string;
			tag: string;
			publishDate: string;
			image?: QBankImageType;
		}
	>;

const blockName = 'authenticatedFilesList' as const;

export type AuthenticatedFilesListBlockProps = {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: string;
			files: FileItemBlock[];
			moreLink?: UmbracoLinkType;
		}
	>;
};

export function AuthenticatedFilesListBlock({ block }: AuthenticatedFilesListBlockProps) {
	const { title, files } = block.contentProperties;
	const { status } = useAuthenticatedCRMRequestConfig();
	const [activeIndex, setActiveIndex] = useState(0);
	const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);

	function getSpaceBetween(items: number) {
		return files.length <= items ? 0 : 16;
	}

	const slidesPerView = (number: number) => {
		return files.length < number ? files.length : number;
	};

	const numberOfSlides = () => {
		if (swiperRef) {
			if (swiperRef.currentBreakpoint < 375) return slidesPerView(1);
			if (swiperRef.currentBreakpoint < 640) return slidesPerView(1);
			if (swiperRef.currentBreakpoint < 960) return slidesPerView(2);
			if (swiperRef.currentBreakpoint < 1280) return slidesPerView(3);
			return slidesPerView(4);
		}
	};

	const swiperProps: SwiperProps = {
		breakpoints: {
			1: {
				slidesPerView: 1,
				spaceBetween: getSpaceBetween(1),
			},
			375: {
				slidesPerView: 1,
				spaceBetween: getSpaceBetween(1),
			},
			640: {
				slidesPerView: 2,
				spaceBetween: getSpaceBetween(2),
			},
			960: {
				slidesPerView: 3,
				spaceBetween: getSpaceBetween(3),
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: getSpaceBetween(4),
			},
		},
	};

	const handleLeftClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slidePrev();
	}, [swiperRef]);

	const handleRightClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slideNext();
	}, [swiperRef]);

	const handleOnSwiper = (swiper: SwiperClass) => {
		if (swiperProps?.onSwiper) {
			swiperProps.onSwiper(swiper);
		}

		if (!swiperRef) {
			setSwiperRef(swiper);
		}
	};

	const onSwiperChange = (swiper: SwiperClass) => {
		if (swiper.realIndex || swiper.realIndex === 0) {
			setActiveIndex(swiper.realIndex);
		} else {
			setActiveIndex(swiper.clickedIndex);
		}
	};

	if (status === 'unauthenticated') {
		return <ErrorFrame errorType={UmbracoErrorType.Unauthorized} />;
	}

	return (
		<Theme className={styles.publication_list}>
			<Container className={styles.container}>
				<h2>{title}</h2>
				{files?.length === 1 ? (
					<div className={cn({ [styles.one_item]: files.length === 1 })}>
						<AuthenticatedFileItem
							publication={files[0]}
							extendedVersion={true}
						/>
					</div>
				) : (
					<Swiper
						{...swiperProps}
						className={cn(
							styles.swiper,
							{ [styles.two_items]: files.length === 2 },
							{ [styles.three_items]: files.length === 3 },
							{ [styles.four_items]: files.length === 4 },
						)}
						onSwiper={handleOnSwiper}
						onSlideChange={onSwiperChange}
					>
						{files.map((publicationItem) => {
							return (
								<SwiperSlide className={styles.swiper_item} key={publicationItem.contentProperties.fileId}>
									<AuthenticatedFileItem publication={publicationItem} />
								</SwiperSlide>
							);
						})}
						<Button
							className={cn(styles.nav_left, { [styles.disable]: activeIndex === 0 })}
							onClick={handleLeftClick}
							variant="fill-secondary"
						>
							<Icon size={24} icon="arrow-left" />
						</Button>
						<Button
							className={cn(styles.nav_right,
								{ [styles.disable]: activeIndex === files.length - (numberOfSlides() || 0) },
							)}
							onClick={handleRightClick}
							variant="fill-secondary"
						>
							<Icon size={24} icon="arrow-right" />
						</Button>
					</Swiper>
				)}
				{block.contentProperties.moreLink && (
					<LinkButton link={block.contentProperties.moreLink} type={LinkStyle.TextLink} />
				)}
			</Container>
		</Theme>
	);
}

AuthenticatedFilesListBlock.blockName = blockName;
