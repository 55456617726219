import { gql } from '@urql/core';

export const propertyFragment = gql`
fragment PropertyFragment on BasicProperty {
	editorAlias,
	alias
	value {
		...BlockListFragment
		...BasicPropertyValueFragment
		...ContentPickerFragment
	}
}

fragment BasicPropertyValueFragment on PropertyValue {
	... on BasicPropertyValue {
		value,
	}
	... on BasicLabel {
		value
	}
	... on BasicRichText {
		rte: value
	}
	... on NoADropdownModel {
		multiple,
		value
	}
	... on LimitedCheckboxListModel {
		value,
		limit
	}
	... on CustomMultiUrlPicker {
		isMultiPicker
		links {
			key,
			url,
			type,
			target,
			name,
			overrideTitle
		}
	}
	... on CustomMediaPicker {
		...Media
	}
	... on QBank {
		multiPicker,
		items {
			width,
			height,
			filename,
			mediaId,
			mediaName,
			mediaType,
			mediaUrl,
			extension,
			metaData {
				key,
				value,
			},
			deployedFiles {
				deploymentSiteId
				remoteFile
				templateName
				height,
				width
			}
		}
	}
	... on BasicDateTimePicker {
		date: value
	}
	... on LimboTable{
		value
	}
	... on BreadcrumbsToggle {
		enabled,
		breadcrumbs {
			contentType,
			name,
			path,
			id,
		}
	}
	... on BonsaiPickerModel {
		multiPicker,
		value,
	}
}

fragment Media on CustomMediaPicker {
	multiPicker
	mediaItems {
		id,
		url,
		size,
		__typename
		... on FilePickerItem {
			extension,
		},
		... on ImagePickerItem {
			width,
			height,
			localCrops { ...Crop },
			crops { ...Crop },
			localFocalPoint {
				left
				top
			}
			focalPoint {
				left
				top
			}
		}
	}
}

fragment Crop on ImageCropperCrop {
	alias
	height
	width
	coordinates {
		x1
		y1
		x2
		y2
		__typename
	}
}

fragment ContentPickerFragment on CustomContentPicker {
	multiPicker,
	contentList {
		id,
		url,
		name,
		updateDate,
		contentType {
			alias,
		},
		properties {
			alias,
			editorAlias,
			value {
				...BasicPropertyValueFragment
				...SecondLevelBlockListFragment
			}
		}
	}
}

fragment BlockListFragment on CustomBlockListModel {
	isMultiPicker,
	blocks {
		key
		contentAlias,
		settingsProperties {
			alias
			editorAlias
			value {
				...BasicPropertyValueFragment,
				...ContentPickerFragment
			}
		}
		contentProperties {
			alias
			editorAlias
			value {
				...BasicPropertyValueFragment
				...ContentPickerFragment
				... on CustomBlockListModel {
					isMultiPicker,
					blocks {
						key
						contentAlias,
						settingsProperties {
							alias
							editorAlias
							value {
								...BasicPropertyValueFragment
								...ContentPickerFragment
							}
						}
						contentProperties {
							alias
							editorAlias
							value {
								...BasicPropertyValueFragment
								...ContentPickerFragment
								... on CustomBlockListModel {
									isMultiPicker,
									blocks {
										key
										contentAlias,
										settingsProperties {
											alias
											editorAlias
											value {
												...BasicPropertyValueFragment
												...ContentPickerFragment
											}
										}
										contentProperties {
											alias
											editorAlias
											value {
												...BasicPropertyValueFragment
												...ContentPickerFragment
												... on CustomBlockListModel {
													isMultiPicker,
													blocks {
														key
														contentAlias,
														settingsProperties {
															alias
															editorAlias
															value {
																...BasicPropertyValueFragment
																...ContentPickerFragment
															}
														}
														contentProperties {
															alias
															editorAlias
															value {
																...BasicPropertyValueFragment
																...ContentPickerFragment
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

fragment SecondLevelBlockListFragment on CustomBlockListModel {
	isMultiPicker,
	blocks {
		key
		contentAlias,
		settingsProperties {
			alias
			editorAlias
			value {
				...BasicPropertyValueFragment,
			}
		}
		contentProperties {
			alias
			editorAlias
			value {
				...BasicPropertyValueFragment
				...SecondLayerContentPickerFragment
				... on CustomBlockListModel {
					isMultiPicker,
					blocks {
						key
						contentAlias,
						settingsProperties {
							alias
							editorAlias
							value {
								...BasicPropertyValueFragment
							}
						}
						contentProperties {
							alias
							editorAlias
							value {
								...BasicPropertyValueFragment
								...SecondLayerContentPickerFragment
								... on CustomBlockListModel {
									isMultiPicker,
									blocks {
										key
										contentAlias,
										settingsProperties {
											alias
											editorAlias
											value {
												...BasicPropertyValueFragment
											}
										}
										contentProperties {
											alias
											editorAlias
											value {
												...BasicPropertyValueFragment
												...SecondLayerContentPickerFragment
												... on CustomBlockListModel {
													isMultiPicker,
													blocks {
														key
														contentAlias,
														settingsProperties {
															alias
															editorAlias
															value {
																...BasicPropertyValueFragment
															}
														}
														contentProperties {
															alias
															editorAlias
															value {
																...BasicPropertyValueFragment
																...SecondLayerContentPickerFragment
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

fragment SecondLayerContentPickerFragment on CustomContentPicker {
	multiPicker,
	contentList {
		id,
		url,
		name,
		updateDate,
		contentType {
			alias,
		},
		properties {
			alias,
			editorAlias,
			value {
				...BasicPropertyValueFragment
			}
		}
	}
}
`;
