import { LinkList } from '@web/components/link-list/link-list';
import { Theme } from '@web/components/theme/theme';
import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { Link as BasicLink } from '@web/services/umbraco/types/basic/Link';

import styles from './link-list-block.module.scss';

const blockName = 'linkListBlock' as const;

export type LinkListBlockType = UmbracoBlock<typeof blockName, {
	title: string;
	links: BasicLink[];
}>;

export interface LinkListBlockProps {
	block: LinkListBlockType;
}

export function LinkListBlock({ block }: LinkListBlockProps) {
	const { title, links } = block.contentProperties;

	return (
		<Theme className={styles.theme}>
			<LinkList
				title={title}
				links={links.map((link, i) => (
					<UmbracoLink key={`list-link-${i}`} link={link} target={link.target} />
				))}
			/>
		</Theme>
	);
}

LinkListBlock.blockName = blockName;
