import { useState } from 'react';

import { Button } from '@dansk-metal/ui';

import { ContactCallDrawer } from '@web/components/contact-call-drawer';
import { ContactMessageDrawer } from '@web/components/contact-message-drawer';

import { StaticForms } from '@web/services/umbraco/types/elements/Action';
import { FormEntityAction } from '@web/services/umbraco/types/entities/form.entity';

import styles from './static-form-drawer-button.module.scss';
export interface StaticFormDrawerButtonProps {
	action: StaticForms;
}

export function StaticFormDrawerButton({ action }: StaticFormDrawerButtonProps) {
	const [visibleMessageDrawer, setVisibleMessageDrawer] = useState(false);
	const [visibleCallDrawer, setVisibleCallDrawer] = useState(false);
	const closeMessageDrawer = () => setVisibleMessageDrawer(false);
	const closeCallDrawer = () => setVisibleCallDrawer(false);

	const actionButtonLabels = {
		[FormEntityAction.CRM_Contact]: 'Send besked',
		[FormEntityAction.CRM_CallMe]: 'Bestil opringning',
	};

	function openDrawer() {
		if (action.contentProperties.type === FormEntityAction.CRM_Contact) {
			setVisibleMessageDrawer(true);
		}

		if (action.contentProperties.type === FormEntityAction.CRM_CallMe) {
			setVisibleCallDrawer(true);
		}
	}

	return (<>
		<Button
			onClick={openDrawer}
			icon={{ icon: 'arrow-right', size: 24, position: 'after' }}
			className={styles.button}
		>{actionButtonLabels[action.contentProperties.type]}</Button>
		<ContactMessageDrawer
			drawer={{
				testId: 'drawer',
				open: visibleMessageDrawer,
				onClose: closeMessageDrawer,
			}}
		/>
		<ContactCallDrawer
			drawer={{
				testId: 'drawer',
				open: visibleCallDrawer,
				onClose: closeCallDrawer,
			}}
		/>
	</>);
}
