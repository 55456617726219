export enum EventType {
	Default = 'default',
	InviteOnly = 'invite-only',
	RegisteredUser = 'registered-user',
	NoMoreSeats = 'no-more-seats',
	DeadlinePassed = 'deadline-passed',
	HasTakenPlace = 'has-taken-place',
	InvitedUser = 'invited-user',
	NotInvitedUser = 'not-invited-user',
	OpenForAll = 'open-for-all',
	NotLoggedInInvitationRequired = 'not-logged-in-invitation-required',
	NotLoggedInOpenForAll = 'not-logged-in-open-for-all',
	Cancelled = 'cancelled',
}

export const eventMessages = {
	[EventType.Default]: {},
	[EventType.InviteOnly]: {
		message: 'Kun for inviterede',
	},
	[EventType.RegisteredUser]: {
		message: 'Du er tilmeldt dette arrangement',
		overviewMessage: 'Du er tilmeldt',
	},
	[EventType.NoMoreSeats]: {
		message: 'Der er ikke flere ledige pladser',
		description: 'Kontakt eventuelt din lokalafdeling.',
		overviewMessage: 'Ingen pladser',
	},
	[EventType.DeadlinePassed]: {
		message: 'Tilmelding lukket',
		description: 'Kontakt din lokalafdeling for at høre om andre muligheder.',
		overviewMessage: 'Tilmelding lukket',
	},
	[EventType.HasTakenPlace]: {
		message: 'Arrangementet er afholdt',
		overviewMessage: 'Afholdt',
	},
	[EventType.InvitedUser]: {
		message: 'Arrangementet er kun for medlemmer, der har modtaget en invitation på e-mail.',
		description: 'Har du ikke modtaget en invitation, kan du kontakte din lokalafdeling, hvis du er interesseret i at deltage.',
		overviewMessage: 'Kun for inviterede',
	},
	[EventType.NotInvitedUser]: {
		message: 'Arrangementet er kun for medlemmer, der har modtaget en invitation på e-mail. ',
		description: 'Har du ikke modtaget en invitation, kan du kontakte din lokalafdeling, hvis du er interesseret i at deltage.',
		overviewMessage: 'Kun for inviterede',
	},
	[EventType.OpenForAll]: {},
	[EventType.NotLoggedInInvitationRequired]: {
		message: 'Arrangementet er kun for medlemmer, der har modtaget en invitation på e-mail. ',
		description: 'Har du ikke modtaget en invitation, kan du kontakte din lokalafdeling, hvis du er interesseret i at deltage.',
		overviewMessage: 'Kun for inviterede',
	},
	[EventType.NotLoggedInOpenForAll]: {},
	[EventType.Cancelled]: {
		message: 'Arrangementet er aflyst',
		overviewMessage: 'Aflyst',
	},
} as const;
