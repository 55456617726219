import { getTestAttr } from '@dansk-metal/service/testing';
import { vars } from '@dansk-metal/theme';
import { Button, Container, Icon } from '@dansk-metal/ui';

import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import { useGlobalContext } from '@web/layouts/page/context';

import styles from './footer.module.scss';

const SocialButton = ({ link, icon }) => {
	if (!link) {
		return null;
	}

	return (
		<Button aria-label={`Dansk Metal ${icon}`} href={link} unstyled icon={{ icon, size: 32, color: vars['--color-neutral-white'] }} />
	);
};

export function Footer() {
	const { rootData } = useGlobalContext();

	const { primaryMenu, secondaryMenu, tertiaryMenu, facebookLink, instagramLink, twitterLink, linkedinLink } =
		rootData.properties.footer || {};

	const { subjects } = rootData;

	return (
		<footer className={styles.footer} {...getTestAttr('footer')}>
			<Container className={styles.top_menu}>
				<nav className={styles.uppercase}>
					{subjects.map((subject) => {
						return (
							<ul key={subject.id}>
								<li>
									<div className={styles.subject__title}>
										<UmbracoLink link={{ type: 'CONTENT', name: subject.name, url: subject.url }} />
										<Icon icon="chevron-up" size={24} color={vars['--color-secondary']} />
									</div>
									<ul className={styles.subject__items}>
										{subject.children.map((item) => (
											<li key={item.id}>
												<Icon icon="arrow-right" size={16} color={vars['--color-secondary']} />
												<UmbracoLink
													key={item.id}
													link={{
														name: item.name,
														type: 'CONTENT',
														url: item.url,
													}}
												/>
											</li>
										))}
									</ul>
								</li>
							</ul>
						);
					})}
				</nav>
				<section className={styles.split_menu}>
					{primaryMenu.length !== 0 && (
						<nav className={styles.uppercase}>
							<ul>
								{primaryMenu.map((link) => (
									<li key={link.key}>
										<Button size="medium" href={link.url} unstyled>
											{link.name}
										</Button>
									</li>
								))}
							</ul>
						</nav>
					)}
					{secondaryMenu.length !== 0 && (
						<nav>
							<ul>
								{secondaryMenu.map((link) => (
									<li key={link.key}>
										<Button size="medium" href={link.url} unstyled>
											{link.name}
										</Button>
									</li>
								))}
							</ul>
						</nav>
					)}
				</section>
			</Container>
			<section className={styles.bottom}>
				<Container className={styles.bottom__inner}>
					{tertiaryMenu.length !== 0 && (
						<nav>
							<ul>
								{tertiaryMenu.map((link) => (
									<li key={link.key}>
										<Button size="medium" href={link.url} unstyled>
											{link.name}
										</Button>
									</li>
								))}
							</ul>
						</nav>
					)}
					<div className={styles.social_menu} {...getTestAttr('footer-social-media-links')}>
						<SocialButton link={facebookLink} icon="facebook" />
						<SocialButton link={instagramLink} icon="instagram" />
						<SocialButton link={linkedinLink} icon="linkedin" />
						<SocialButton link={twitterLink} icon="twitter" />
					</div>
				</Container>
			</section>
		</footer>
	);
}
