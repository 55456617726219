import cn from 'classnames';
import { OmitStrict } from 'type-zoo/types';

import Config from '@web/config';

import { Aspect } from '@web/components/media/aspectRatios';
import { QBankDocument, QBankDocumentProps } from '@web/components/qbank-document/qbank-document';
import { QBankImage, QBankImageProps } from '@web/components/qbank-image/qbank-image';
import { QBankVector, QBankVectorProps } from '@web/components/qbank-vector/qbank-vector';
import { QBankVideo, QBankVideoProps } from '@web/components/qbank-video/qbank-video';

import { QBankMedia, QBankMediaType } from '@web/services/umbraco/types/basic/QBank';

import styles from './qbank-media.module.scss';

export type QBankMediaDisplayProps = {
	media: QBankMedia;
	aspectRatio?: Aspect;
	sizes?: string;
	className?: string;
	imageProps?: OmitStrict<QBankImageProps, 'media'>;
	documentProps?: OmitStrict<QBankDocumentProps, 'media'>;
	vectorProps?: OmitStrict<QBankVectorProps, 'media'>;
	videoProps?: OmitStrict<QBankVideoProps, 'media'>;
	initialHeightImage?: boolean;
};

export function QBankMediaDisplay({
	media,
	aspectRatio,
	sizes,
	className,
	imageProps,
	videoProps,
	vectorProps,
	...rest
}: QBankMediaDisplayProps) {
	if (!hasMedia(media)) {
		return null;
	}

	if (media.type === QBankMediaType.Image) {
		return (
			<QBankImage
				media={media}
				aspectRatio={aspectRatio}
				sizes={sizes}
				className={className}
				{...imageProps}
				{...rest}
			/>
		);
	}

	if (media.type === QBankMediaType.Document) {
		return (
			<QBankDocument
				className={cn(className, styles.media_file)}
				aspectRatio={aspectRatio}
				media={media}
				{...vectorProps}
				{...rest}
			/>
		);
	}

	if (media.type === QBankMediaType.Video) {
		return <QBankVideo media={media} aspectRatio={aspectRatio} className={className} {...videoProps} {...rest} />;
	}

	if (media.type === QBankMediaType.Vector) {
		return <QBankVector className={cn(className, styles.media_file)} media={media} {...vectorProps} {...rest} />;
	}

	if (Config.APP_ENV === 'production') {
		console.warn('Missing block type:', media);
		return null;
	}

	return (
		<pre className={className} {...rest}>
			{JSON.stringify(media, null, 4)}
		</pre>
	);
}

/**
 * @returns Whether a media exist
 */
function hasMedia(media?: QBankMediaDisplayProps['media']) {
	return !!media?.filename;
}
