import { RichTextContent } from '@web/services/umbraco/types/basic/RichTextContent';
import { UmbracoDocument } from '@web/services/umbraco/types/documents/UmbracoDocument';

export type FormEntity = UmbracoDocument<'formEntity', {
	title: string;
	subtitle?: RichTextContent;
	action: FormEntityAction;
	definition: string;
	confirm: {
		title: string;
		subtitle?: RichTextContent;
	};
}>;

export enum FormEntityAction {
	SaveInUmbraco = 'saveInUmbraco',
	CRM_Contact = 'crmContact',
	CRM_CallMe = 'crmCallMe',
}
