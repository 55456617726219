import { Container } from '@dansk-metal/ui';

import { QBankMediaDisplay } from '@web/components/qbank-media/qbank-media';
import { Richtext } from '@web/components/richtext/richtext';
import { UmbracoAction } from '@web/components/umbraco-action/umbraco-action';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString, RichTextContent } from '@web/services/umbraco/types/basic/RichTextContent';
import { Action } from '@web/services/umbraco/types/elements/Action';

import styles from './cta-card.module.scss';

export type CTACardBlock = UmbracoBlock<'ctaCard', {
	title: HTMLString;
	bodyText: RichTextContent;
	image: QBankImageType;
	action?: Action;
	id?: string;
}>;

export interface CTACardProps {
	card: CTACardBlock;
}

export function CTACard({ card }: CTACardProps) {
	const {
		title,
		bodyText,
		image,
		action,
	} = card.contentProperties;

	return (
		<Container className={styles.container}>
			<div className={styles.img_container}>
				<QBankMediaDisplay
					sizes="(min-width: 960px) 50vw, 100vw"
					media={image}
				/>
			</div>
			<div className={styles.text_container}>
				<h3 className={styles.header}>{title}</h3>
				<Richtext className={styles.link} content={bodyText} />
				{action && (
					<div className={styles.button_container}>
						<UmbracoAction key={action.key} action={action} />
					</div>
				)}
			</div>
		</Container>
	);
}
