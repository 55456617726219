import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, Container, RadioGroup, TextArea } from '@dansk-metal/ui';

import { Theme } from '@web/components/theme/theme';
import { Evaluate } from '@web/components/utils/Evaluate';

import { ColorTheme } from '@web/services/umbraco/types/basic/Color';

import styles from './feedback.module.scss';

export interface FeedbackProps {
	onSubmit: (data: { rating?: number; comment?: string }) => void;
	text: {
		title: string;
		question: string;
		comment: string;
		button: string;
		confirmation: string;
		supportUrl: string;
		commentHeader?: string;
		supportLinkText?: string;
	};
}

export function Feedback({ onSubmit, text }: FeedbackProps) {
	const [submitted, setSubmitted] = useState(false);

	const { control, handleSubmit, getValues, watch } = useForm({
		defaultValues: {
			rating: {
				1: false,
				5: false,
			},
			message: undefined,
		},
	});

	const ratingOptions = [{ value: '5', label: 'Ja' }, { value: '1', label: 'Nej' }];
	const watchRating = watch('rating');

	function submitRating() {
		const { rating, message } = getValues();
		const ratingValue = Number(rating);
		onSubmit({ rating: ratingValue, comment: message });
		setSubmitted(true);
	}

	return (
		<Theme themeDef={{ theme: ColorTheme.None }}>
			<Container className={styles.container}>
				<div className={styles.content}>
					<Evaluate input={() => {
						if (submitted) {
							return (
								<>
									<h4>{text.confirmation}</h4>
									<p>Nu har vi noget at arbejde med.</p>
								</>
							);
						}

						return (
							<form onSubmit={handleSubmit(submitRating)}>
								<div className={styles.question}>
									<h4>{text.question}</h4>
									<p>{text.comment}</p>
									<Controller
										control={control}
										name="rating"
										render={({ field }) => (
											<RadioGroup
												{...field}
												className={styles.radio_group}
												options={ratingOptions}
												noOptionalLabel
											/>
										)}
									/>
								</div>
								{typeof watchRating === 'string' && (
									<>
										<div>
											<Controller
												control={control}
												name="message"
												render={({ field }) => (
													<TextArea
														{...field}
														label={text.commentHeader}
														description={(
															<>
																Skriv IKKE personlig info som fx navn, telefon- eller cpr-nummer.
															</>
														)}
														noOptionalLabel
													/>
												)}
											/>
										</div>
										<Button icon={{ icon: 'checkmark', size: 24 }} aria-atomic={true} onClick={submitRating}>{text.button}</Button>
									</>
								)}
							</form>
						);
					}}
					/>
				</div>
			</Container>
		</Theme>
	);
}
