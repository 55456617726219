import Link from 'next/link';

import { Container } from '@dansk-metal/ui';
import { formatDate } from '@dansk-metal/utils/date';

import styles from './news-metadata.module.scss';

export interface NewsMetadataProps {
	date: string;
	author?: string;
	tags?: string[];
	parentUrl?: string;
}

export function NewsMetadata({ date, author, tags, parentUrl }: NewsMetadataProps) {
	const allTags = tags || [];

	return (
		<Container>
			<div className={styles.content}>
				<span>{formatDate(date, '22. feb 2022')}</span>
				{author && (
					<>
						&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
						{author}
					</>
				)}
				{allTags.length !== 0 && (
					<div className={styles.tags}>
						{allTags.map((tag) => (parentUrl
							? (
								<Link key={tag} className={styles.tag} href={`${parentUrl}?tag=${tag}`}>
									{tag}
								</Link>
							)
							: (
								<span key={tag} className={styles.tag}>{tag}</span>
							)),
						)}
					</div>
				)}
			</div>
		</Container>
	);
}
