import React from 'react';
import cn from 'classnames';

import { getPallette, Palette } from '@dansk-metal/theme';

import { ColorTheme, ThemeDefinition } from '@web/services/umbraco/types/basic/Color';

import styles from './theme.module.scss';

export type ThemeProps = {
	children: React.ReactNode;
	palette?: Palette;
	themeDef?: ThemeDefinition;
	className?: string;
};

export function Theme({ children, palette, themeDef, className }: ThemeProps) {
	const paletteTheme = themeDef?.theme
		? getPallette(themeDef.theme)
		: palette;

	const customTheme = themeDef?.theme === ColorTheme.Custom
		? {
			'--palette-primary': themeDef.primary,
			'--palette-secondary': themeDef.secondary,
			'--palette-tertiary': themeDef.tertiary,
		} as React.CSSProperties
		: undefined;

	return (
		<div
			className={cn(
				className,
				{
					[styles[`theme__${paletteTheme}`]]: !!paletteTheme,
				},
			)}
			style={customTheme}
		>
			{children}
		</div>
	);
}
