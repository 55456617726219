/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Default API
 * All endpoints not defined under specific APIs
 * OpenAPI spec version: Latest
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { umbracoAxios } from '../axios';
import type { GetApiDocumentsParams, RecordDTO } from '../umbraco.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Returns a list of document ids published in umbraco
 */
export const getApiDocuments = (
	params?: GetApiDocumentsParams,
	options?: SecondParameter<typeof umbracoAxios>,
	signal?: AbortSignal,
) => {
	return umbracoAxios<RecordDTO>({ url: '/api/documents', method: 'get', params, signal }, options);
};

export const getGetApiDocumentsQueryKey = (params?: GetApiDocumentsParams) =>
	['/api/documents', ...(params ? [params] : [])] as const;

export const getGetApiDocumentsQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiDocuments>>,
	TError = ErrorType<unknown>,
>(
	params?: GetApiDocumentsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiDocuments>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getApiDocuments>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiDocumentsQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiDocuments>>> = ({ signal }) =>
		getApiDocuments(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetApiDocumentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiDocuments>>>;
export type GetApiDocumentsQueryError = ErrorType<unknown>;

/**
 * @summary Returns a list of document ids published in umbraco
 */
export const useGetApiDocuments = <TData = Awaited<ReturnType<typeof getApiDocuments>>, TError = ErrorType<unknown>>(
	params?: GetApiDocumentsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiDocuments>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiDocumentsQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
