import { HeroBlockType, HeroType } from '@web/services/umbraco/types/elements/Hero';

import { HeroFullWidthImage } from './hero-full-width-image/hero-full-width-image';
import { HeroTitle } from './hero-title/hero-title';

export interface HeroBlockProps {
	block: HeroBlockType;
}

export function HeroBlock({ block }: HeroBlockProps) {
	if (block.contentProperties.heroType === HeroType.FullWidthImage) {
		return (
			<HeroFullWidthImage
				title={block.contentProperties.heroTitle}
				prefixTitle={block.contentProperties.heroPrefixTitle}
				image={block.contentProperties.heroImage}
			/>
		);
	}

	return (
		<HeroTitle
			title={block.contentProperties.heroTitle}
			prefixTitle={block.contentProperties.heroPrefixTitle}
			subtitle={block.contentProperties.heroSubtitle}
			illustration={block.contentProperties.illustration}
			alignment={block.contentProperties.heroAlignment}
			background={block.contentProperties.heroBackground}
		/>
	);
}
