import { SiteImproveGlobal } from '@web/services/siteimprove/types';

import { waitForProp } from '@web/utils/wait-for-prop';

let siteimproveGlobal: SiteImproveGlobal | null = null;

export async function getSiteimproveGlobal(): Promise<SiteImproveGlobal> {
	if (siteimproveGlobal) {
		return Promise.resolve(siteimproveGlobal);
	}

	await waitForProp(() => !!(window['_sz'] as SiteImproveGlobal)?.core?.ready?.(), 50, 5000);
	await waitForProp(() => !!(window['_sz'] as SiteImproveGlobal)?.analytics?.endpoint?.configured, 50, 5000);
	siteimproveGlobal = window['_sz'] as SiteImproveGlobal;
	return siteimproveGlobal;
}

export async function submitFeedback(rating: number, comment: string | null = null) {
	const sz = await getSiteimproveGlobal();

	const payload = {
		smiley: rating,
		comment,
		accountid: sz.analytics.opts('accountid'),
		url: sz.core.curr,
		feedbackid: sz.feedback.feedbackid,
		luid: sz.analytics.opts('luid'),
	};

	const queryParams: string[] = Object
		.keys(payload)
		.map((key) => `${key}=${encodeURIComponent(payload[key])}`);

	const image = new Image();
	image.src = `${sz.feedback.endpoint}?${queryParams.join('&')}&rt=img`;

	await trackFeedback(rating, comment);
}

export async function trackFeedback(rating: number, comment: string | null = null) {
	const sz = await getSiteimproveGlobal();
	sz.push(['feedback_response', sz.feedback.feedbackid, sz.feedback.feedbackUid, rating, comment]);
}

export async function trackEvent(category: string, action: string, label?: string) {
	const sz = await getSiteimproveGlobal();

	if (!label) {
		sz.push(['event', category, action]);
		return;
	}

	sz.push(['event', category, action, label]);
}

export async function trackVisit(url: string, ref: string, title: string) {
	const sz = await getSiteimproveGlobal();
	sz.push(['trackdynamic', { url, ref, title }]);
}

export const Siteimprove = {
	getSiteimproveGlobal,
	submitFeedback,
	trackFeedback,
	trackEvent,
	trackVisit,
};
