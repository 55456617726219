import { gql } from '@urql/core';

import type { ContentTypeQuery, ContentTypeQueryVariables, ContentVariation, PublishedItemType } from '@web/services/gql/graphql';

export { ContentTypeQuery, ContentTypeQueryVariables };

export const contentTypeQuery = gql<ContentTypeQuery, ContentTypeQueryVariables>`
query ContentType ($route: String!, $baseUrl: String!, $preview: Boolean) {
	contentByAbsoluteRoute (route: $route, preview: $preview, baseUrl: $baseUrl, routeMode: CACHE_OR_ROUTING) {
		contentType {
			alias,
			variations,
			key,
			id,
			itemType,
			compositionAliases,
			isElement,
			__typename
		}
	}
}
`;

export type MappedContentType = {
	contentType?: {
		alias?: string | null;
		variations?: ContentVariation | null;
		key?: string | null;
		id?: number | null;
		itemType?: PublishedItemType | null;
		compositionAliases?: Array<string> | null;
		isElement?: boolean;
	};
};
