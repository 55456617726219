export function getBase64FromFile(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			if (e.target && typeof e.target.result === 'string') {
				const fileBase64 = e.target.result.split(',')[1];
				resolve(fileBase64);
			}
		};

		reader.onerror = (error) => reject(error);
		reader.readAsDataURL(file);
	});
}
