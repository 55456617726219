import Link from 'next/link';
import cn from 'classnames';

import { vars } from '@dansk-metal/theme';
import { Button, Icon, Icons } from '@dansk-metal/ui';

import { useHeaderContext } from '@web/components/header/context';

import styles from './navigation-text-item.module.scss';

export type NavigationTextItemProps = {
	text: string;
	link?: string;
	onClick?: () => void;
	withIcon?: boolean;
	icon?: Icons | undefined;
	reverse?: boolean;
	className?: string;
	iconSize?: number;
	toUpperCase?: boolean;
	resetMenuOnClick?: boolean;
	active?: boolean | undefined;
};

export const NavigationTextItem = ({
	text,
	onClick,
	icon,
	reverse,
	className,
	link,
	iconSize = 24,
	toUpperCase = true,
	resetMenuOnClick = true,
	active = false,
}: NavigationTextItemProps) => {
	const { setCurrentMenu } = useHeaderContext();
	const textCased = toUpperCase ? text.toUpperCase() : text;

	const handleClick = () => {
		if (resetMenuOnClick) {
			setCurrentMenu(null);
		}

		onClick?.();
	};

	if (link) {
		return (
			<Link
				href={link}
				className={cn(
					styles.navigation_text_item,
					{
						[styles.icon]: icon,
						[styles.icon__reverse]: reverse,
						[styles.icon__active]: active,
					},
					className,
				)}
				style={{ textDecoration: 'none' }}
				onClick={handleClick}
			>
				<p>{textCased}</p>
				{icon && <Icon icon={icon} size={iconSize} color={vars['--color-secondary']} />}
			</Link>
		);
	}

	return (
		<Button
			className={cn(
				styles.navigation_text_item,
				{
					[styles.icon]: icon,
					[styles.icon__reverse]: reverse,
					[styles.icon__active]: active,
				},
				className,
			)}
			unstyled
			onClick={handleClick}
		>
			<p>{textCased}</p>
			{icon && <Icon icon={icon} size={iconSize} color={vars['--color-secondary']} />}
		</Button>
	);
};
