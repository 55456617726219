/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Umbraco Forms API
 * Describes the Umbraco Forms API available for rendering and submitting forms. You can find out more about the API in [the documentation](https://docs.umbraco.com/umbraco-forms/v/12.forms.latest/developer/ajaxforms)
 * OpenAPI spec version: Latest
 */
export type GetUmbracoFormsApiV1DefinitionsIdParams = {
	/**
	 * The Id of the content page on which the form is hosted.
	 */
	contentId?: string;
	/**
	 * The culture code for the form's localization context.
	 */
	culture?: string;
};

export interface ProblemDetails {
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
	[key: string]: unknown;
}

export interface IApiContentStartItemModel {
	readonly id?: string;
	readonly path?: string;
}

export interface IApiContentRouteModel {
	readonly path?: string;
	startItem?: IApiContentStartItemModel;
}

export interface FormFileUploadOptionsDto {
	allowAllUploadExtensions?: boolean;
	allowedUploadExtensions?: string[];
	allowMultipleFileUploads?: boolean;
}

export interface FormFieldsetColumnDto {
	caption?: string | null;
	width?: number;
	fields?: FormFieldDto[];
}

export interface FormFieldsetDto {
	id?: string;
	caption?: string | null;
	condition?: FormConditionDto;
	columns?: FormFieldsetColumnDto[];
}

export interface FormPageDto {
	caption?: string | null;
	condition?: FormConditionDto;
	fieldsets?: FormFieldsetDto[];
}

export interface FormFieldTypeDto {
	id?: string;
	name?: string;
	supportsPreValues?: boolean;
	supportsUploadTypes?: boolean;
	renderInputType?: string;
}

export interface FormFieldPrevalueDto {
	value?: string;
	caption?: string | null;
}

export type FormFieldIndication = (typeof FormFieldIndication)[keyof typeof FormFieldIndication];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormFieldIndication = {
	NoIndicator: 'NoIndicator',
	MarkMandatoryFields: 'MarkMandatoryFields',
	MarkOptionalFields: 'MarkOptionalFields',
} as const;

export type FormFieldDtoSettings = { [key: string]: string };

export interface FormFieldDto {
	id?: string;
	caption?: string;
	helpText?: string | null;
	placeholder?: string | null;
	cssClass?: string | null;
	alias?: string;
	required?: boolean;
	requiredErrorMessage?: string | null;
	pattern?: string | null;
	patternInvalidErrorMessage?: string | null;
	condition?: FormConditionDto;
	fileUploadOptions?: FormFileUploadOptionsDto;
	preValues?: FormFieldPrevalueDto[];
	settings?: FormFieldDtoSettings;
	type?: FormFieldTypeDto;
}

export type FormEntryDtoValues = { [key: string]: string[] };

export interface FormEntryDto {
	values?: FormEntryDtoValues;
	contentId?: string | null;
	culture?: string | null;
}

export interface FormDto {
	id?: string;
	name?: string;
	indicator?: string;
	cssClass?: string | null;
	nextLabel?: string | null;
	previousLabel?: string | null;
	submitLabel?: string | null;
	disableDefaultStylesheet?: boolean;
	fieldIndicationType?: FormFieldIndication;
	hideFieldValidation?: boolean;
	messageOnSubmit?: string | null;
	messageOnSubmitIsHtml?: boolean;
	showValidationSummary?: boolean;
	gotoPageOnSubmit?: string | null;
	gotoPageOnSubmitRoute?: IApiContentRouteModel;
	pages?: FormPageDto[];
}

export interface FormConditionRuleDto {
	field?: string;
	operator?: FieldConditionRuleOperator;
	value?: string;
}

export interface FormConditionDto {
	actionType?: FieldConditionActionType;
	logicType?: FieldConditionLogicType;
	rules?: FormConditionRuleDto[];
}

export type FieldConditionRuleOperator = (typeof FieldConditionRuleOperator)[keyof typeof FieldConditionRuleOperator];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldConditionRuleOperator = {
	Is: 'Is',
	IsNot: 'IsNot',
	GreaterThen: 'GreaterThen',
	LessThen: 'LessThen',
	Contains: 'Contains',
	ContainsIgnoreCase: 'ContainsIgnoreCase',
	StartsWith: 'StartsWith',
	StartsWithIgnoreCase: 'StartsWithIgnoreCase',
	EndsWith: 'EndsWith',
	EndsWithIgnoreCase: 'EndsWithIgnoreCase',
	NotContains: 'NotContains',
	NotContainsIgnoreCase: 'NotContainsIgnoreCase',
	NotStartsWith: 'NotStartsWith',
	NotStartsWithIgnoreCase: 'NotStartsWithIgnoreCase',
	NotEndsWith: 'NotEndsWith',
	NotEndsWithIgnoreCase: 'NotEndsWithIgnoreCase',
} as const;

export type FieldConditionLogicType = (typeof FieldConditionLogicType)[keyof typeof FieldConditionLogicType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldConditionLogicType = {
	All: 'All',
	Any: 'Any',
} as const;

export type FieldConditionActionType = (typeof FieldConditionActionType)[keyof typeof FieldConditionActionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldConditionActionType = {
	Show: 'Show',
	Hide: 'Hide',
} as const;
