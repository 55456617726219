import { BasicProperty } from '@web/services/gql/graphql';
import { BreadcrumbElement, BreadcrumbModel } from '@web/services/umbraco/types/basic/Breadcrumb';

export type UmbracoCheckbox = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Noa.BreadcrumbsToggle';
	value: {
		enabled: boolean;
		breadcrumbs: BreadcrumbElement[];
	};
};

export class BreadcrumbsConverter {
	static UmbracoType: UmbracoCheckbox;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Noa.BreadcrumbsToggle';
	}

	public static mapProperty(prop: typeof this.UmbracoType): BreadcrumbModel {
		return {
			enabled: prop?.value.enabled || false,
			items: Array.isArray(prop?.value.breadcrumbs)
				? prop.value.breadcrumbs
				: [],
		};
	}
}
