import { AddressDto } from '@web/services/crm-api/rest';


export interface AddressProps {
	address?: AddressDto;
}

export function Address({ address }: AddressProps) {
	if (!address) {
		return <p>-</p>;
	}

	return (
		<address>
			{address.addressLine && (
				<>
					{address.addressLine}<br />
				</>
			)}
			{`${address.zipCode || ''} ${address.city || ''}`}
			{address.country && (
				<>
					<br />{address.country}
				</>
			)}
		</address>
	);
}
