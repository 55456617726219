import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { ParsedUrlQuery } from 'querystring';

import { RelativeUrl } from '@apps/web/src/utils/routes';

type SearchQueryParams = Record<string, string | string[] | undefined>;

type UseShallowQueryParamsParams = {
	routerQueryParamChanged: (params: SearchQueryParams) => void;
	isDataReady?: boolean;
};

/**
 * Use internal state to manage query params instead of directly calling `router.push`
 * as the query params can be updated in multiple side effects at the same time, which results
 * in wrong query params being set.
 */
export function useShallowQueryParams({ routerQueryParamChanged, isDataReady = true }: UseShallowQueryParamsParams) {
	const router = useRouter();
	const [queryParam, setQueryParam] = useState<ParsedUrlQuery>();
	const [urlStateLoaded, setUrlStateLoaded] = useState(false);

	const updateUrlParam = useCallback((params: SearchQueryParams) => {
		setQueryParam((prev) => pickBy({ ...prev, ...params }, (value) => value));
	}, [setQueryParam]);

	/**
	 * Set queryParam to router.query when the router is ready
	 * then set `urlStateLoaded` to true to indicate that the initial queryParam is loaded
	 */
	useEffect(() => {
		if (!router.isReady || !isDataReady) {
			return;
		}

		setQueryParam(router.query);
		const { slug: _slug, ...routerQueryParams } = router.query || {};
		routerQueryParamChanged(routerQueryParams);
		setUrlStateLoaded(true);
	// eslint-disable-next-line react-hooks/exhaustive-deps -- only run this effect when `isDataReady` changes
	}, [router.isReady, isDataReady]);

	// Update queryParam when internal state changes
	useEffect(() => {
		if (!urlStateLoaded) {
			return;
		}

		const { slug: _, ...stateQueryParams } = queryParam || {};
		const { slug: _slug, ...routerQueryParams } = router.query || {};

		if (isEqual(stateQueryParams, routerQueryParams)) {
			return;
		}

		router.push(
			{
				pathname: new RelativeUrl(router.asPath).pathname,
				query: stateQueryParams,
			},
			undefined,
			{ shallow: true },
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- only run this effect when `queryParam` changes
	}, [JSON.stringify(queryParam)]);

	// Update state when query params change
	useEffect(() => {
		if (!urlStateLoaded) {
			return;
		}

		const { slug: _, ...stateQueryParams } = queryParam || {};
		const { slug: _slug, ...routerQueryParams } = router.query || {};

		if (isEqual(stateQueryParams, routerQueryParams)) {
			return;
		}

		setQueryParam(routerQueryParams);
		routerQueryParamChanged(routerQueryParams);
	// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this effect when `router.query` changes
	}, [JSON.stringify(router.query)]);

	return {
		updateUrlParam,
		urlStateLoaded,
		setUrlStateLoaded,
	};
}
