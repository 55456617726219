/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.6.0
 */
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	Consent,
	ErrorResult,
	RegisterConsentsBody,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Gets active consents based on memberId
 */
export const getActiveConsentsByMember = (
	memberId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<Consent[]>({ url: `/api/members/${memberId}/consents`, method: 'get', signal }, options);
};

export const getGetActiveConsentsByMemberQueryKey = (memberId: string) =>
	[`/api/members/${memberId}/consents`] as const;

export const getGetActiveConsentsByMemberQueryOptions = <
	TData = Awaited<ReturnType<typeof getActiveConsentsByMember>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveConsentsByMember>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getActiveConsentsByMember>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetActiveConsentsByMemberQueryKey(memberId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveConsentsByMember>>> = ({ signal }) =>
		getActiveConsentsByMember(memberId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetActiveConsentsByMemberQueryResult = NonNullable<Awaited<ReturnType<typeof getActiveConsentsByMember>>>;
export type GetActiveConsentsByMemberQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Gets active consents based on memberId
 */
export const useGetActiveConsentsByMember = <
	TData = Awaited<ReturnType<typeof getActiveConsentsByMember>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveConsentsByMember>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetActiveConsentsByMemberQueryOptions(memberId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Register consents for member
 */
export const registerConsents = (
	memberId: string,
	registerConsentsBody: RegisterConsentsBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<Consent[]>(
		{
			url: `/api/members/${memberId}/consents`,
			method: 'put',
			headers: { 'Content-Type': 'application/json' },
			data: registerConsentsBody,
		},
		options,
	);
};

export const getRegisterConsentsMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof registerConsents>>,
		TError,
		{ memberId: string; data: RegisterConsentsBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof registerConsents>>,
	TError,
	{ memberId: string; data: RegisterConsentsBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof registerConsents>>,
		{ memberId: string; data: RegisterConsentsBody }
	> = (props) => {
		const { memberId, data } = props ?? {};

		return registerConsents(memberId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type RegisterConsentsMutationResult = NonNullable<Awaited<ReturnType<typeof registerConsents>>>;
export type RegisterConsentsMutationBody = RegisterConsentsBody;
export type RegisterConsentsMutationError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Register consents for member
 */
export const useRegisterConsents = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof registerConsents>>,
		TError,
		{ memberId: string; data: RegisterConsentsBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getRegisterConsentsMutationOptions(options);

	return useMutation(mutationOptions);
};
