import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export type UmbracoSelect = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Umbraco.DropDown.Flexible'
		| 'Umbraco.RadioButtonList'
		| 'NoA.dropdown';
	value: {
		multiple?: boolean;
		value?: string | string[];
	};
};

export class SelectConverter {
	static UmbracoType: UmbracoSelect;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.DropDown.Flexible'
			|| input.editorAlias === 'Umbraco.RadioButtonList'
			|| input.editorAlias === 'NoA.dropdown';
	}

	public static mapProperty(prop: typeof this.UmbracoType): string[] | string | undefined {
		if (
			prop.editorAlias === 'NoA.dropdown'
			&& Array.isArray(prop.value.value)
			&& !prop.value.multiple
		) {
			return prop.value.value[0];
		}

		return prop.value.value;
	}
}
