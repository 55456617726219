import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { getPallette } from '@dansk-metal/theme';
import { Container, InputText } from '@dansk-metal/ui';

import { EmptyState } from '@web/components/empty-state/empty-state';
import { SearchResult, Suggestion } from '@web/components/search-result/search-result';
import { Theme } from '@web/components/theme/theme';
import { ThemedSkeleton } from '@web/components/themed-skeleton/themed-skeleton';
import { Evaluate } from '@web/components/utils/Evaluate';

import TemplateLayout from '@web/layouts/template/template';

import { useRaffleSearch, useRaffleSuggestions } from '@web/services/raffle/search.hook';
import { ColorTheme } from '@web/services/umbraco/types/basic/Color';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';

import { useClickOutside } from '@web/hooks/use-click-outside';

import styles from './search-result-page.module.scss';

const contentAlias = 'searchResultPage' as const;

export type SearchResultPageData = UmbracoPage<
	typeof contentAlias,
	{
		title: string;
	}
>;

function SearchResultPageTemplate({ pageData }) {
	const router = useRouter();
	const [queryReady, setQueryReady] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchQuery, setSearchQuery] = useState<string | undefined>();
	const { title } = pageData.properties || {};
	const skeletonCount = 10;
	const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false);
	const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);
	const suggestionsListRef = useRef<HTMLUListElement>(null);
	const ref = useClickOutside<HTMLDivElement>(() => setShowAutoComplete(false));

	const {
		isFetching, isFetched, data: results = [],
	} = useRaffleSearch({}, searchQuery);

	const {
		data: suggestions,
	} = useRaffleSuggestions({}, searchValue);

	const handleSubmit = useCallback((e?: FormEvent) => {
		e?.preventDefault();

		if (searchValue) {
			setSearchQuery(searchValue);
			setShowAutoComplete(false);
		}
	}, [searchValue]);

	useEffect(() => {
		// Update url query when searchQuery changes.
		// Note that we actually set the 'searchQuery' instead of 'debouncedQuery' to avoid the search input flickering.
		if (router.query.s !== searchQuery) {
			router.push({ pathname: window.location.pathname, query: { s: searchQuery } }, undefined, { shallow: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Ignore router or searchQuery changes.
	}, [searchQuery, isFetching]);

	useEffect(() => {
		// Update state from url when page is loaded
		if (router.query.s && router.query.s !== searchQuery && router.isReady) {
			setSearchValue(router.query.s as string);
			setSearchQuery(router.query.s as string);
		}

		if (router.isReady) {
			setQueryReady(true);
		}
	}, [router.query.s, router.isReady, searchQuery]);

	const handleSuggestionClick = (suggestion: string) => {
		setSearchValue(suggestion);
		setSearchQuery(suggestion);
		setShowAutoComplete(false);
		setSelectedSuggestionIndex(-1);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (!showAutoComplete || !suggestions) return;

		switch (e.key) {
			case 'ArrowDown':
				e.preventDefault();
				setSelectedSuggestionIndex((prevIndex) =>
					(prevIndex + 1) % suggestions.length,
				);
				break;
			case 'ArrowUp':
				e.preventDefault();
				setSelectedSuggestionIndex((prevIndex) =>
					(prevIndex - 1 + suggestions.length) % suggestions.length,
				);
				break;
			case 'Enter':
				e.preventDefault();
				if (selectedSuggestionIndex >= 0) {
					handleSuggestionClick(suggestions[selectedSuggestionIndex]);
				} else {
					handleSubmit();
				}

				break;
			case 'Escape':
				e.preventDefault();
				setShowAutoComplete(false);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSuggestionIndex, showAutoComplete, suggestions]);

	return (
		<TemplateLayout>
			<Container>
				<div className={styles.search}>
					<h1>{title}</h1>
					<form onSubmit={handleSubmit}>
						<InputText
							name="search"
							type="search"
							placeholder="Fx “dagpengekort”"
							icon={{ icon: 'search', 'aria-label': 'Søg' }}
							value={searchValue}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setSearchValue(e.target.value);
								setShowAutoComplete(true);
							}}
							onIconClick={() => handleSubmit()}
							className={styles.search__input} />
					</form>
					{suggestions && suggestions.length > 0 && showAutoComplete && (
						<ul className={styles.suggestions} ref={suggestionsListRef}>
							<div ref={ref}>
								<>
									{suggestions.map((suggestion, index) => (
										<li
											className={cn(styles.suggestions__item, {
												[styles.selected]: index === selectedSuggestionIndex,
											})}
											key={index}
											onClick={() => handleSuggestionClick(suggestion)}
											onFocus={() => setSelectedSuggestionIndex(index)}
										>
											<Link href={`${router.asPath.split('?')[0]}?s=${suggestion}`}>
												{suggestion}
											</Link>
										</li>
									))}
								</>
							</div>
						</ul>
					)}
				</div>
			</Container>
			<Theme palette={getPallette(ColorTheme.Lightgrey)} className={cn(styles.results, styles.has_color_theme)}>
				<Container>
					<Evaluate input={() => {
						if (!queryReady) {
							return (
								<div className={styles.no_results} />
							);
						}

						if (!searchQuery) {
							return (
								<div className={styles.list}>
									{['Overenskomst', 'CV', 'Ferie', 'Løn', 'Forsikring'].map((e, i) => (
										<Suggestion key={`title-${i}`} link={`${router.asPath.split('?')[0]}?s=${e}`} title={e} />
									))}
								</div>
							);
						}

						if (results.length > 0) {
							return (
								<div className={styles.list}>
									{results.map((result) => (
										<SearchResult key={result.feedback_data} result={result} />
									))}
								</div>
							);
						}

						if (isFetching || !isFetched) {
							return (
								<div className={styles.list}>
									<ThemedSkeleton className={styles.skeleton_theme} count={skeletonCount} />
								</div>
							);
						}

						return (
							<EmptyState
								title='Vi fandt ingenting!'
								bodyText='Din søgning gav desværre ikke noget resultat. Prøv at ændre, hvad du har søgt på.'
							/>
						);
					}} />
				</Container>
			</Theme>
		</TemplateLayout>
	);
}

export default SearchResultPageTemplate;

export const searchSuggestions = ['Overenskomst', 'CV', 'Ferie', 'Løn', 'Forsikring'];
