import cn from 'classnames';

import { Container } from '@dansk-metal/ui';

import { MosaicBlockType, MosaicSection } from '@web/blocks/mosaic-block/types';

import { Theme } from '@web/components/theme/theme';

import { Card } from './cards/card';
import { resolveMosaicSectionReferences } from './utils/resolve-mosaic-section-references';

import styles from './mosaic-block.module.scss';

const blockName = 'mosaicBlock' as const;

export interface MosaicBlockProps {
	block: MosaicBlockType;
}

export function MosaicBlock({ block }: MosaicBlockProps) {
	const sections: MosaicSection[] = resolveMosaicSectionReferences(block.contentProperties.sections);

	return (
		<Theme className={styles.theme}>
			<Container className={styles.container}>
				{sections.map((section) => {
					const layout = section.contentProperties.layout;
					const { direction, cards } = section.contentProperties;

					return (
						<div
							key={section.key}
							className={cn(styles.mosaic, styles[`layout__${layout}`], styles[`length__${cards.length}`], {
								[styles.direction__reverse]: direction === 'reverse',
							})}
							data-layout={layout}
						>
							{section.contentProperties.cards.map((card, index) => (
								<Card
									key={card.key}
									card={card}
									layout={layout}
									length={cards.length}
									index={index + 1}
									className={cn(styles.card, styles[`index__${index + 1}`])}
								/>
							))}
						</div>
					);
				})}
			</Container>
		</Theme>
	);
}

MosaicBlock.blockName = blockName;
