import { useEffect, useState } from 'react';

import { vars } from '@dansk-metal/theme';
import { Button, Icon } from '@dansk-metal/ui';

import { calculate, OKCalculatorConstants } from '@web/blocks/ok-calculator-block/calculation/ok-calculator';
import { BenefitsGroup } from '@web/blocks/ok-calculator-block/types';

import { ThemedSkeleton } from '@web/components/themed-skeleton/themed-skeleton';

import { formatNumberThousandsDots } from '@web/utils/format-number-thousands-dots';
import { parseFloatCommaToDot } from '@web/utils/parse-float-comma-to-dot';

import styles from './ok-calculator-result.module.scss';

interface OKCalculatorResultProps {
	handleReturnToForm: (boolean) => void;
	salary: string;
	unit: 'hour' | 'month';
	constants: OKCalculatorConstants;
	scrollIntoViewHandler: () => void;
}

export function OKCalculatorResult({ handleReturnToForm, salary, unit, constants, scrollIntoViewHandler }: OKCalculatorResultProps) {
	const [isLoading, setIsLoading] = useState(true);
	const results = calculate({ input: { salary: parseFloatCommaToDot(salary), unit }, constants });

	const handleGoBackToForm = () => {
		scrollIntoViewHandler();
		handleReturnToForm(false);
	};

	const benefitsGroups = [
		{
			title: 'Når du er i job',
			benefits: [
				{
					title: 'Fridage med løn',
					subtitle: 'Du har ret til 5 feriefridage.',
					result: results.valueOfFiveFreeDays,
				},
				{
					title: 'Pension',
					subtitle: 'Din arbejdsgiver betaler 10% af din løn til pension (det er den A-skattepligtige lønindkomst).',
					result: results.pension,
				},
				{
					title: 'Kompetenceudvikling',
					subtitle: 'Du har ret til 14 dages selvvalgt uddannelse hvert år.',
					result: results.ikuf,
				},
				{
					title: 'Barnets første sygedag',
					subtitle: 'Du har ret til fuld løn på barnets første sygedag. Værdien er beregnet ud fra 6 dage om året.',
					result: results.childsFirstSickDay,
				},
				{
					title: 'Fritvalgs lønkonto',
					subtitle: '13% af din ferieberettigede løn (9% fritvalgskonto plus 4% SH-betaling).',
					result: results.selectionWageAccount,
				},
			],
		},
		{
			title: 'Hvis du bliver sygemeldt',
			benefits: [
				{
					title: 'Fuld løn under sygdom i op til 5 uger',
					subtitle: 'Eksempel: Du er syg i én uge.',
					result: results.fullWageDuringSickness,
				},
				{
					title: 'Hospitalsindlæggelse med barn',
					subtitle: 'Du har ret til fuld løn i 5 dage.',
					result: results.childInHospital,
				},
			],
		},
		{
			title: 'Hvis du bliver opsagt',
			benefits: [
				{
					title: 'Ret til kursus i 2 uger',
					subtitle: 'Kræver 3 års anciennitet, beløb til kurset er ikke regnet med.',
					result: results.twoWeeksCourse,
				},
				{
					title: 'Fratrædelsesgodtgørelse - 3 års anciennitet',
					subtitle: 'Der afregnes 1 gange særlig fratrædelsesgodtgørelse.',
					result: results.seniority3Years,
				},
				{
					title: 'Fratrædelsesgodtgørelse - 6 års anciennitet',
					subtitle: 'Der afregnes 2 gange særlig fratrædelsesgodtgørelse.',
					result: results.seniority6Years,
				},
				{
					title: 'Fratrædelsesgodtgørelse - 8 års anciennitet',
					subtitle: 'Der afregnes 3 gange særlig fratrædelsesgodtgørelse.',
					result: results.seniority8Years,
				},
				{
					title: '5 feriefridage',
					subtitle: 'Ubrugte feriefridage kan ikke indeholdes i opsigelsesvarslet.',
					result: results.valueOfFiveVacationDays,
				},
			],
		},
	];

	useEffect(() => {
		setTimeout(() => { setIsLoading(false); }, 1000);
	}, []);

	return (
		<>
			<div className={styles.benefits_group_wrapper}>
				{benefitsGroups && benefitsGroups.map((benefitsGroup: BenefitsGroup) => {
					return (
						<div className={styles.benefits_group}>
							<h4>{benefitsGroup.title}</h4>
							{!isLoading ? (
								<div className={styles.benefits_list}>
									{benefitsGroup.benefits.map((benefit) => {
										return (
											<div className={styles.benefit}>
												<div className={styles.benefit__text}>
													<Icon className={styles.tick_icon} icon='tick-alt' size={24} color={vars['--color-signal-green']} />
													<div>
														<p>{benefit.title}</p>
														<p>{benefit.subtitle}</p>
													</div>
												</div>
												<p className={styles.benefit__calc_result}>
													{formatNumberThousandsDots(benefit.result, 2)} kr
												</p>
											</div>
										);
									})}
								</div>
							) : (
								<ThemedSkeleton
									count={benefitsGroup.benefits.length}
									containerClassName={styles.loader_wrapper}
									className={styles.loader}
									inline={true}
								/>
							)}
						</div>
					);
				})}
			</div>
			<div>
				<Button
					onClick={handleGoBackToForm}
					variant='stroke'
					icon={{ icon: 'edit' }}
				>
					Rediger dine oplysninger
				</Button>
			</div>
		</>
	);
}
