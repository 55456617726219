

import { Richtext } from '@apps/web/src/components/richtext/richtext';

import styles from './empty-state.module.scss';

export interface EmptyStateProps {
	title: string;
	bodyText: string;
}

export function EmptyState({ title, bodyText }: EmptyStateProps) {
	return (
		<div className={styles.container}>
			<div className={styles.no_results}>
				<h3>{title}</h3>
				<Richtext content={bodyText} />
			</div>
		</div>
	);
}
