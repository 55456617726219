import { ColorTheme } from '@web/components/block-list/color-theme';

export function blockSpacing(blocks, index: number) {
	if (blocks && blocks[index - 1] && blocks[index] &&
        blocks[index - 1].settingsProperties &&
        blocks[index - 1].settingsProperties.colorTheme &&
        blocks[index].settingsProperties &&
        blocks[index].settingsProperties.colorTheme) {
		const previousBlock = blocks[index - 1];
		const currentBlock = blocks[index];

		if (currentBlock.contentAlias === 'basicCTABlock' || previousBlock.contentAlias === 'basicCTABlock') {
			return false;
		}

		if (currentBlock.settingsProperties.colorTheme.theme !== ColorTheme.NoColor) {
			return previousBlock.settingsProperties.colorTheme.theme === currentBlock.settingsProperties.colorTheme.theme;
		}
	}

	return false;
}
