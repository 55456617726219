import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { Link as LinkValue } from '@web/services/umbraco/types/basic/Link';
import { FormEntity, FormEntityAction } from '@web/services/umbraco/types/entities/form.entity';

export enum LinkStyle {
	LightBlueButton = 'light_blue_button',
	DarkBlueButton = 'dark_blue_button',
	OutlinedButton = 'white_button',
	TextLink = 'text_link',
}

export type LinkItemBlock = UmbracoBlock<'linkItem', {
	link: LinkValue;
	style: LinkStyle;
}>;

export type FormDrawerAction = UmbracoBlock<'formDrawerAction', {
	title: string;
	style: LinkStyle;
	form: FormEntity;
}>;

export type StaticForms = UmbracoBlock<'staticForms', {
	type: FormEntityAction;
}>;

export type Action = LinkItemBlock | FormDrawerAction | StaticForms;
