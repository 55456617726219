import { Dayjs } from 'dayjs';

import { dayJS } from '@dansk-metal/utils/date';

import { AdultApprenticeRateBlock, CalculatorData, IndustryRateBlock } from '@web/blocks/apprentice-salary-calculator-block/apprentice-salary-calculator-block';

/**
 * The claculations made in this script are based on the document found here: https://makingwaves.atlassian.net/wiki/spaces/DM/pages/2596929842/Tjenester
 */

export type ApprenticeCalculatorInput = {
	endDate: string;
	education: string;
	isBasicEducation: boolean;
	isAdultApprentice: boolean;
};

export type Period = {
	isCurrentStage: boolean;
	salary: number;
	period: {
		start: Date;
		end: Date;
	};
	days: number;
};

export type ApprenticeCalculatorProps = {
	input: ApprenticeCalculatorInput;
	data: CalculatorData;
};

export interface mapPeriodInput {
	educationYearStartDate: Date;
	nextRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock;
	currentRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock;
	today: Date;
	year: number;
	isAdultApprentice: boolean;
}


export function apprenticeCalculation({ input, data }: ApprenticeCalculatorProps): Period[] {
	const { endDate: inputEndDate, education: selectedEducation, isBasicEducation, isAdultApprentice } = input;
	const { educations, adultApprenticeRates, industryRates } = data.properties;

	const educationLength = educations.find(
		(education) => education.contentProperties.title === selectedEducation)?.contentProperties.length || 1;

	const endDate = dayJS.utc(inputEndDate).toISOString();
	const educationLengthInYears = Math.floor(educationLength / 12);
	const hasLeftOverMonths = !!(educationLength % 12);
	const startDate = getStartDate(endDate, educationLength);
	const arrayOfYears = [...Array(educationLengthInYears).keys()];
	const rates = isAdultApprentice ? adultApprenticeRates : industryRates;


	return periodCalculation(
		arrayOfYears,
		startDate,
		endDate,
		rates,
		hasLeftOverMonths,
		isBasicEducation,
	);
}

/**
 *
 * Maps Periods by year. Each year has 2 periods (except when education start date is the same as the rate period start date)
 * Periods order goes from the educaion year start date until the the rate period start date followed by rate period start date to a day before of the next year education sarte date.
 */
function periodCalculation(
	years: number[],
	startDate: Date,
	endDate: string,
	rates: IndustryRateBlock[] | AdultApprenticeRateBlock[],
	hasLeftOverMonths: boolean,
	isBasicEducation: boolean,
): Period[] {
	const today = new Date();

	const result = years.reduce((accumulator, year) => {
		const educationYearStartDate = addYearsToDate(startDate, year);
		const currentRatePeriod = findCurrentRatePeriod<IndustryRateBlock | AdultApprenticeRateBlock>(educationYearStartDate, rates);
		const nextRatePeriod = findNextRatePeriod<IndustryRateBlock | AdultApprenticeRateBlock>(educationYearStartDate, rates);
		if (!nextRatePeriod || !currentRatePeriod) {
			throw new Error('Could not find rate period');
		}

		if (year === 0 && isBasicEducation) {
			const yearWithSkippedPeriod = skipBasicPeriod(
				educationYearStartDate, nextRatePeriod, currentRatePeriod, today, year);

			accumulator.push(...yearWithSkippedPeriod);
		} else if (dayJS.utc(educationYearStartDate).isSame(dayJS.utc(currentRatePeriod.contentProperties.startDate))) {
			//  Should map Period that corresponds to the full year that is mapped
			const fullYearPeriod = mapFullYear(educationYearStartDate, nextRatePeriod, currentRatePeriod, today, year);

			accumulator.push(fullYearPeriod);
		} else {
			const firstPeriod = mapFirstPeriod(educationYearStartDate, nextRatePeriod, currentRatePeriod, today, year);
			const secondPeriod = mapSecondPeriod(educationYearStartDate, nextRatePeriod, today, year);
			accumulator.push(firstPeriod, secondPeriod);
		}

		return accumulator;
	}, [] as Period[]);

	if (hasLeftOverMonths) {
		const lastYear = years[years.length - 1];
		const educationYearStartDate = addYearsToDate(startDate, lastYear);
		const nextRatePeriod = findNextRatePeriod<IndustryRateBlock | AdultApprenticeRateBlock>(educationYearStartDate, rates);
		const leftOverYear = lastYear + 1;

		if (!nextRatePeriod) {
			throw new Error('Could not find rate period');
		}

		const leftOverPeriods = mapLeftoverMonths(
			endDate,
			educationYearStartDate,
			nextRatePeriod,
			today,
			rates,
			leftOverYear,
		);
		result.push(...leftOverPeriods);
	}

	return result;
}

/**
 *
 * Maps Period or Periods depending on the new start date (six months after education start date)
 */
function skipBasicPeriod(
	educationYearStartDate: Date,
	nextRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock,
	currentRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock,
	today: Date,
	year: number,
) {
	const newStartDate = dayJS.utc(educationYearStartDate).add(6, 'month').toDate();
	const nextRatePeriodDate = new Date(nextRatePeriod?.contentProperties.startDate);

	if (newStartDate >= nextRatePeriodDate) {
		const skippedPeriodEndDate = removeDay(addYearsToDate(educationYearStartDate, 1));
		const skippedPeriodTimeDifferenceInDays = calculateTimeDiffInYears(skippedPeriodEndDate, newStartDate);
		const skippedPeriodIsCurrent = (dayJS(today).isBetween(newStartDate, skippedPeriodEndDate, 'day', '[]'));

		const rate = isAdultApprenticeRate(nextRatePeriod)
			? nextRatePeriod?.contentProperties.rate
			: nextRatePeriod?.contentProperties.period[year];

		const skippedPeriod = {
			isCurrentStage: skippedPeriodIsCurrent,
			salary: rate,
			period: {
				start: newStartDate,
				end: skippedPeriodEndDate,
			},
			days: skippedPeriodTimeDifferenceInDays,
		};

		return [skippedPeriod];
	}

	const firstPeriodWithNewStartDate = mapFirstPeriod(newStartDate, nextRatePeriod, currentRatePeriod, today, year);
	if (dayJS.utc(educationYearStartDate).isSame(dayJS.utc(currentRatePeriod.contentProperties.startDate))) {
		return [firstPeriodWithNewStartDate];
	}

	const secondPeriod = mapSecondPeriod(educationYearStartDate, nextRatePeriod, today, year);

	return [firstPeriodWithNewStartDate, secondPeriod];
}

/**
 *
 * Map full year Period, since the start dates for education and period are the same
 */
function mapFullYear(
	educationYearStartDate: Date,
	nextRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock,
	currentRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock,
	today: Date,
	year: number,
) {
	const fullYearPeriodStartDate = educationYearStartDate;
	const fullYearPeriodEndDate = removeDay(nextRatePeriod?.contentProperties.startDate);
	const fullYearTimeDifferenceInDays = calculateTimeDiffInYears(fullYearPeriodEndDate, fullYearPeriodStartDate);
	const fullYearIsCurrent = (dayJS(today).isBetween(fullYearPeriodStartDate, fullYearPeriodEndDate, 'day', '[]'));

	const rate = isAdultApprenticeRate(currentRatePeriod)
		? currentRatePeriod?.contentProperties.rate
		: currentRatePeriod?.contentProperties.period[year];

	const fullYearPeriod = {
		isCurrentStage: fullYearIsCurrent,
		salary: rate,
		period: {
			start: fullYearPeriodStartDate,
			end: fullYearPeriodEndDate,
		},
		days: fullYearTimeDifferenceInDays,
	};

	return fullYearPeriod;
}

/**
 *
 * Maps 1st Period (from education start date to rate period start date)
 */
function mapFirstPeriod(
	educationYearStartDate: Date,
	nextRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock,
	currentRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock,
	today: Date,
	year: number,
) {
	const firstPeriodStartDate = educationYearStartDate;
	const firstPeriodEndDate = removeDay(nextRatePeriod?.contentProperties.startDate);
	const firstPeriodTimeDifferenceInDays = calculateTimeDiffInYears(firstPeriodEndDate, firstPeriodStartDate);
	const firstPeriodIsCurrent = (dayJS(today).isBetween(firstPeriodStartDate, firstPeriodEndDate, 'day', '[]'));

	const rate = isAdultApprenticeRate(currentRatePeriod)
		? currentRatePeriod?.contentProperties.rate
		: currentRatePeriod?.contentProperties.period[year];

	const firstPeriod = {
		isCurrentStage: firstPeriodIsCurrent,
		salary: rate,
		period: {
			start: firstPeriodStartDate,
			end: firstPeriodEndDate,
		},
		days: firstPeriodTimeDifferenceInDays,
	};

	return firstPeriod;
}

/**
 *
 * Maps 2nd Period (from rate period start date to education start date)
 */
function mapSecondPeriod(
	educationYearStartDate: Date,
	nextRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock,
	today: Date,
	year: number,
) {
	const secondPeriodStartDate = new Date(nextRatePeriod?.contentProperties.startDate);
	const secondPeriodEndDate = removeDay(addYearsToDate(educationYearStartDate, 1));
	const secondPeriodTimeDifferenceInDays = calculateTimeDiffInYears(secondPeriodEndDate, secondPeriodStartDate);
	const secondPeriodIsCurrent = (dayJS(today).isBetween(secondPeriodStartDate, secondPeriodEndDate, 'day', '[]'));

	const rate = isAdultApprenticeRate(nextRatePeriod)
		? nextRatePeriod?.contentProperties.rate
		: nextRatePeriod?.contentProperties.period[year];

	const secondPeriod = {
		isCurrentStage: secondPeriodIsCurrent,
		salary: rate,
		period: {
			start: secondPeriodStartDate,
			end: secondPeriodEndDate,
		},
		days: secondPeriodTimeDifferenceInDays,
	};

	return secondPeriod;
}

/**
 *
 * Maps left over Period or Periods depending on the education end date
 */
function mapLeftoverMonths(
	endDateSring: string,
	educationYearStartDate: Date,
	nextRatePeriod: IndustryRateBlock | AdultApprenticeRateBlock,
	today: Date,
	adultApprenticeRates: IndustryRateBlock[] | AdultApprenticeRateBlock[],
	year: number,
) {
	const nextRatePeriodDate = addYearsToDate(nextRatePeriod?.contentProperties.startDate, 1);
	const endDate = new Date(endDateSring);
	// If the education end date is further in the future then the next rate period, it means that 2 Periods need to be mapped since a new rate period starts within the left over months
	if (nextRatePeriodDate < endDate) {
		const firstLeftOverPeriodStart = addYearsToDate(educationYearStartDate, 1);
		const firstLeftOverPeriodEnd = removeDay(addYearsToDate(nextRatePeriod?.contentProperties.startDate, 1));
		const firstLeftOverPeriodDifferenceInDays = calculateTimeDiffInYears(firstLeftOverPeriodEnd, firstLeftOverPeriodStart);
		const firstLeftOverIsCurrent = (dayJS(today).isBetween(firstLeftOverPeriodStart, firstLeftOverPeriodEnd, 'day', '[]'));

		let firstPeriodRate = 0;
		if (isAdultApprenticeRate(nextRatePeriod)) {
			firstPeriodRate = nextRatePeriod.contentProperties.rate;
		} else {
			const maxRate = nextRatePeriod.contentProperties.period.length - 1;
			firstPeriodRate = nextRatePeriod.contentProperties.period[year] || nextRatePeriod.contentProperties.period[maxRate];
		}

		const firstLeftOverPeriod = {
			isCurrentStage: firstLeftOverIsCurrent,
			salary: firstPeriodRate,
			period: {
				start: firstLeftOverPeriodStart,
				end: firstLeftOverPeriodEnd,
			},
			days: firstLeftOverPeriodDifferenceInDays,
		};

		const secondLeftOverPeriodStart = nextRatePeriodDate;
		const secondLeftOverPeriodEnd = removeDay(endDate);
		const secondLeftOverPeriodDifferenceInDays = calculateTimeDiffInYears(secondLeftOverPeriodEnd, secondLeftOverPeriodStart);
		const secondLeftOverIsCurrent = (dayJS(today).isBetween(secondLeftOverPeriodStart, secondLeftOverPeriodEnd, 'day', '[]'));

		const secondLeftOverPeriodRateValue = findNextRatePeriod<IndustryRateBlock | AdultApprenticeRateBlock>(
			addYearsToDate(educationYearStartDate, 1), adultApprenticeRates,
		);

		if (!secondLeftOverPeriodRateValue) {
			throw new Error('Could not find rate period');
		}

		let secondPeriodRate = 0;
		if (isAdultApprenticeRate(secondLeftOverPeriodRateValue)) {
			secondPeriodRate = secondLeftOverPeriodRateValue.contentProperties.rate;
		} else {
			const maxRate = secondLeftOverPeriodRateValue.contentProperties.period.length - 1;
			secondPeriodRate = secondLeftOverPeriodRateValue.contentProperties.period[year] ||
				secondLeftOverPeriodRateValue.contentProperties.period[maxRate];
		}

		const secondLeftOverPeriod = {
			isCurrentStage: secondLeftOverIsCurrent,
			salary: secondPeriodRate,
			period: {
				start: secondLeftOverPeriodStart,
				end: secondLeftOverPeriodEnd,
			},
			days: secondLeftOverPeriodDifferenceInDays,
		};

		return [firstLeftOverPeriod, secondLeftOverPeriod];
	}

	const leftOverPeriodStart = addYearsToDate(educationYearStartDate, 1);
	const leftOverPeriodEnd = removeDay(endDate);
	const leftOverPeriodDifferenceInDays = calculateTimeDiffInYears(leftOverPeriodEnd, leftOverPeriodStart);
	const leftOverIsCurrent = (dayJS(today).isBetween(leftOverPeriodStart, leftOverPeriodEnd, 'day', '[]'));

	let rate = 0;
	if (isAdultApprenticeRate(nextRatePeriod)) {
		rate = nextRatePeriod.contentProperties.rate;
	} else {
		const maxRate = nextRatePeriod.contentProperties.period.length - 1;
		rate = nextRatePeriod.contentProperties.period[year] || nextRatePeriod.contentProperties.period[maxRate];
	}

	const leftOverPeriod = {
		isCurrentStage: leftOverIsCurrent,
		salary: rate,
		period: {
			start: leftOverPeriodStart,
			end: leftOverPeriodEnd,
		},
		days: leftOverPeriodDifferenceInDays,
	};

	return [leftOverPeriod];
}

export function getStartDate(date: string | Date | Dayjs, lengthMonths: number): Date {
	const startDate = dayJS.utc(date).subtract(lengthMonths, 'month');

	return dayJS.utc(startDate).toDate();
}

function findCurrentRatePeriod<T extends { contentProperties: { startDate: string } }>(
	date: Date | string | Dayjs,
	rates: T[],
): T | undefined {
	return rates.find((rate, index) => {
		const periodStartDate = new Date(rate.contentProperties.startDate);

		return (periodStartDate <= date &&
				date < new Date(rates[index + 1].contentProperties.startDate));
	});
}

function findNextRatePeriod<T extends { contentProperties: { startDate: string } }>(
	date: Date | string | Dayjs,
	rates: T[],
): T | undefined {
	return rates.find((rate, index) => {
		const periodStartDate = new Date(rate.contentProperties.startDate);

		return (periodStartDate > date &&
				date >= new Date(rates[index - 1].contentProperties.startDate));
	});
}

function removeDay(date: Date | string | Dayjs): Date {
	const newDate = dayJS.utc(date).subtract(1, 'day');

	return dayJS.utc(newDate).toDate();
}

export function addYearsToDate(date: Date | string | Dayjs, years: number): Date {
	const newDate = dayJS.utc(date).add(years, 'year');

	return dayJS.utc(newDate).toDate();
}

function calculateTimeDiffInYears(endDate: Date | string | Dayjs, startDate: Date | string | Dayjs): number {
	const includeEndDate = dayJS.utc(endDate).add(1, 'day');
	const dayDiff = dayJS.utc(includeEndDate).diff(dayJS.utc(startDate), 'day');

	return dayDiff;
}

function isAdultApprenticeRate(rate: AdultApprenticeRateBlock | IndustryRateBlock): rate is AdultApprenticeRateBlock {
	return rate.contentAlias === 'loengrisenApprenticeRate';
}


