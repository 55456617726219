/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.6.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ContributionDto,
	ErrorResult,
	GetMemberContributionsParams,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Calculate future contribution for the specified member within specified dates.
 */
export const getMemberContributions = (
	memberId: string,
	params: GetMemberContributionsParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<ContributionDto | void>(
		{ url: `/api/members/${memberId}/contributions`, method: 'get', params, signal },
		options,
	);
};

export const getGetMemberContributionsQueryKey = (memberId: string, params: GetMemberContributionsParams) =>
	[`/api/members/${memberId}/contributions`, ...(params ? [params] : [])] as const;

export const getGetMemberContributionsQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberContributions>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params: GetMemberContributionsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberContributions>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberContributions>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberContributionsQueryKey(memberId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberContributions>>> = ({ signal }) =>
		getMemberContributions(memberId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberContributionsQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberContributions>>>;
export type GetMemberContributionsQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Calculate future contribution for the specified member within specified dates.
 */
export const useGetMemberContributions = <
	TData = Awaited<ReturnType<typeof getMemberContributions>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params: GetMemberContributionsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberContributions>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberContributionsQueryOptions(memberId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
