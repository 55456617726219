import { EventType } from '@web/components/event-card/types';

import { EventDto } from '@web/services/crm-api/rest';

export function getEventStatus(event: EventDto, isLoggedIn: boolean): EventType {
	const now = new Date();

	if (!event.startTime || !event.endTime) {
		throw new Error('Event has no start or end time');
	}

	const startTime = new Date(event.startTime);
	const endTime = new Date(event.endTime);
	// I
	if (startTime < now && endTime < now) {
		return EventType.HasTakenPlace;
	}

	// J
	if (event.status === 'Cancelled') {
		return EventType.Cancelled;
	}

	// F
	if (isLoggedIn && event.flags?.isRegistered) {
		return EventType.RegisteredUser;
	}


	// G
	if (event.capacity?.remaining === 0) {
		return EventType.NoMoreSeats;
	}

	// H
	if (event.registrationDeadline && new Date(event.registrationDeadline) < now) {
		return EventType.DeadlinePassed;
	}

	if (isLoggedIn) {
		if (event.isInvitationRequired) {
			// C
			if (event.flags?.isInvited) {
				return EventType.InvitedUser;
			}

			// E
			return EventType.NotInvitedUser;
		}

		// A
		return EventType.OpenForAll;
	}

	// D
	if (event.isInvitationRequired) {
		return EventType.NotLoggedInInvitationRequired;
	}

	// B
	return EventType.NotLoggedInOpenForAll;
}
