export function formatPhoneNumber(phoneNumber: string): string {
	const digits = phoneNumber.replace(/\D/g, '');

	if (!digits) {
		return '';
	}

	const countrycode = digits.length > 8 && (!digits.startsWith('45') && !digits.startsWith('0045'))
		? `+${digits.substring(digits.length - 10, digits.length - 8)} `
		: '';

	return `${countrycode}${digits.substring(digits.length - 8, digits.length - 4)} ${digits.substring(digits.length - 4)}`;
}
