import { useEffect } from 'react';
import FocusTrap from 'focus-trap-react';

import styles from './modal.module.scss';

export interface ModalProps {
	children: JSX.Element;
	open: boolean;
	onClose?: Function;
	canCloseOutside?: boolean;
}

export function Modal({
	children,
	open = false,
	onClose = () => {},
	canCloseOutside = true,
}: ModalProps) {
	useEffect(() => {
		const onKeydown = (e) => {
			if (e.key === 'Escape' && canCloseOutside) {
				onClose();
			}
		};

		document.addEventListener('keydown', onKeydown);
		return () => document.removeEventListener('keydown', onKeydown);
	}, [onClose, canCloseOutside]);

	if (!open) {
		return null;
	}

	return (
		<FocusTrap focusTrapOptions={{ initialFocus: false, allowOutsideClick: true }}>
			<div className={styles.container}>
				<div className={styles.modal_overlay} onClick={canCloseOutside ? () => onClose() : () => {}}/>
				<div id="modal-dialog" className={styles.modal} aria-modal="true">
					{children}
				</div>
			</div>
		</FocusTrap>

	);
}
