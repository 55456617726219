import { BasicProperty } from '@apps/web/src/services/gql/graphql';
import { RichTextContent } from '@apps/web/src/services/umbraco/types/basic/RichTextContent';

export type UmbracoRichtext = Omit<BasicProperty, 'value'> & { editorAlias: 'Umbraco.TinyMCE'; value: { rte?: string } };

export class RichTextConverter {
	static UmbracoType: UmbracoRichtext;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.TinyMCE';
	}

	public static mapProperty(prop: typeof this.UmbracoType): RichTextContent {
		return prop.value.rte || '';
	}
}
