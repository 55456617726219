import React from 'react';

import { Task } from '@web/components/task/task';
import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import { TaskDto } from '@web/services/crm-api/rest';
import { Link } from '@web/services/umbraco/types/basic/Link';

import styles from './to-do-list.module.scss';

export interface ToDoListProps {
	title?: string;
	tasks: TaskDto[];
	allTasksLink?: Link;
}

export function ToDoList({ title, tasks, allTasksLink }: ToDoListProps) {
	return (
		<div className={styles.container}>
			{title && (
				<h2 className='h4'>{title}</h2>
			)}
			{tasks.length > 0 ?	(
				<div className={styles.tasks_container}>
					{tasks.map((task) => (
						<Task key={task.taskId} task={task} />
					))}
				</div>
			) : (
				<div className={styles.empty_state_container}>
					<p>Du har ingen aktuelle opgaver lige nu - tjek igen senere.</p>
				</div>
			)}
			{allTasksLink && (
				<div>
					<UmbracoLink
						className={styles.link}
						icon={{ icon: 'arrow-right', size: 24 }}
						link={allTasksLink}
					/>
				</div>
			)}
		</div>
	);
}
