import { BasicProperty, QBankData } from '@web/services/gql/graphql';
import { QBankMedia, QBankMediaType } from '@web/services/umbraco/types/basic/QBank';

export type QbankPropertyEditor = Omit<BasicProperty, 'value'> & {
	editorAlias: 'qbankMediapropertyEditor' | 'NoA.QBankMediaPicker';
	value: {
		multiPicker: boolean;
		items: QBankData[];
	};
};

export class QBankConverter {
	static UmbracoType: QbankPropertyEditor;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'qbankMediapropertyEditor' || input.editorAlias === 'NoA.QBankMediaPicker';
	}

	public static mapProperty(prop: typeof this.UmbracoType): QBankMedia | QBankMedia[] {
		const items = (prop.value.items || []).map((e) => this.convertValue(e));

		if (!prop.value.multiPicker) {
			return items[0];
		}

		return items;
	}

	public static convertValue({ __typename, mediaType, metaData, ...value }: QBankData): QBankMedia {
		const media: QBankMedia = {
			...value,
			type: this.getMediaType(mediaType, value.extension),
		};

		const altText = metaData?.find((e) => e.key === 'alt_text')?.value;
		if (altText) {
			media.altText = altText;
		}

		return media;
	}

	private static getMediaType(mediaType: string, extension: string): QBankMediaType {
		if (extension === 'svg') {
			return QBankMediaType.Vector;
		}

		return mediaType as QBankMediaType;
	}
}
