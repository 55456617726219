import { useEffect, useRef } from 'react';
import { useSession } from 'next-auth/react';
import cn from 'classnames';

import { Container } from '@dansk-metal/ui';

import { IframeSkeleton } from '@web/blocks/authenticated-iframe-block/iframe-skeleton/iframe-skeleton';
import { IframeWrapper } from '@web/blocks/authenticated-iframe-block/iframe-wrapper';
import { AuthHandling, IframeType } from '@web/blocks/authenticated-iframe-block/types';

import { LoginButton } from '@web/components/login-button/login-button';
import { Theme } from '@web/components/theme/theme';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ColorTheme } from '@web/services/umbraco/types/basic/Color';
import { ContentWidth } from '@web/services/umbraco/types/basic/sizes';

import styles from './authenticated-iframe-block.module.scss';
import basicCtaStyles from '@web/blocks/basic-cta-block/basic-cta-block.module.scss';

const blockName = 'authenticatedIframeBlock' as const;

export interface AuthenticatedIframeBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			source: string;
			type: IframeType;
			authHandling: AuthHandling;
		},
		{ width?: ContentWidth }
	>;
}

export function AuthenticatedIframeBlock({ block }: AuthenticatedIframeBlockProps) {
	const iFrameContainer = useRef<HTMLDivElement>(null);
	const { source, type, authHandling } = block.contentProperties;
	const { width = ContentWidth.Medium } = block.settingsProperties;
	const { status } = useSession();
	const prevHeight = useRef(0);

	useEffect(() => {
		if (!iFrameContainer.current) return;

		const resizeObserver = new ResizeObserver((entries) => {
			const resizedHeight = entries[0].contentRect.height;

			if (resizedHeight < prevHeight.current) {
				iFrameContainer.current?.scrollIntoView({
					block: 'start',
				});
			}

			prevHeight.current = resizedHeight;
		});

		resizeObserver.observe(iFrameContainer.current);

		return () => resizeObserver.disconnect();
	}, [iFrameContainer]);


	return (
		<Container className={styles.container} ref={iFrameContainer}>
			{(authHandling === AuthHandling.Ignore || status === 'authenticated') && (
				<IframeWrapper frameId={block.key} source={source} type={type} width={width} />
			)}
			{authHandling === AuthHandling.Prompt && status === 'unauthenticated' && <UnauthorizedFrame />}
			{authHandling === AuthHandling.Prompt && status === 'loading' && <IframeSkeleton />}
		</Container>
	);
}

AuthenticatedIframeBlock.blockName = blockName;

function UnauthorizedFrame() {
	return (
		<Theme themeDef={{ theme: ColorTheme.Lightgrey }}>
			<div className={cn(basicCtaStyles.content, basicCtaStyles.has_color_theme, basicCtaStyles.center)}>
				<div className={cn(basicCtaStyles.content__text, basicCtaStyles.center__text)}>
					<h2 className={'h3'}>Log ind</h2>
					<p>
						Siden du er på har indhold, som kræver, at du er logget ind. Efter du logger ind, vender du tilbage til
						siden.
					</p>
				</div>
				<div className={basicCtaStyles.content__buttons}>
					<LoginButton />
				</div>
			</div>
		</Theme>
	);
}
