import Script from 'next/script';

type FAQListBlockSchemaOrgProps = {
	id: string;
	entities: {
		question: string;
		answer: string[];
	}[];
};

/**
 * Schema.org FAQPage implementation
 * Documentation: https://developers.google.com/search/docs/appearance/structured-data/faqpage
 *
 * TODO: `beforeInteractive` is not supported outside of `_document` file
 * https://nextjs.org/docs/messages/no-before-interactive-script-outside-document
 * The `<Head><script>` combination also does not work
 * Find a solution to include this script in the server response of a page request
 */
export function FAQListBlockSchemaOrg({ id, entities }:FAQListBlockSchemaOrgProps) {
	return (
		<Script
			id={id}
			type="application/ld+json"
			strategy='beforeInteractive'
			dangerouslySetInnerHTML={{
				__html: JSON.stringify({
					'@context': 'https://schema.org',
					'@type': 'FAQPage',
					mainEntity: entities.map((entity) => ({
						'@type': 'Question',
						name: entity.question,
						acceptedAnswer: {
							'@type': 'Answer',
							text: entity.answer.join('\n'),
						},
					})),
				}),
			}}
		/>
	);
}
