import Link from 'next/link';
import cn from 'classnames';

import { vars } from '@dansk-metal/theme';
import { Icon, Icons } from '@dansk-metal/ui';

import { Link as UmbracoLinkType } from '@web/services/umbraco/types/basic/Link';

import styles from './page-card.module.scss';
export interface PageCardProps {
	title: string;
	icon?: Icons;
	link?: UmbracoLinkType;
	contentClassName?: string | undefined;
	children?: React.ReactNode;
}

export function PageCard({ title, icon, link, contentClassName, children }: PageCardProps) {
	const Title = () => <h4 className="h5">{title}</h4>;

	return (
		<CardWrapper link={link}>
			<div className={cn(styles.title, {
				[styles.title__has_icon]: icon,
			})}>
				{icon ? <Icon icon={icon} size={36} /> : <Title />}
				{link && <Icon icon="arrow-right-wide" color={vars['--color-middle-blue-base']} size={32} />}
			</div>

			{(children || (icon && title)) &&
				<div className={cn(styles.content, contentClassName)}>
					{icon && <Title />}
					{children}
				</div>
			}
		</CardWrapper>
	);
}

function CardWrapper({ link, children }: { link?: UmbracoLinkType; children: React.ReactNode }) {
	if (!link) {
		return <div className={cn(styles.card, styles.has_color_theme)}>{children}</div>;
	}

	const isExternal = link.type === 'EXTERNAL';

	return (
		<Link
			className={cn(styles.card, styles.has_color_theme)}
			href={link?.url}
			{...(link.type !== 'CONTENT' && {
				target: link.target,
			})}
			{...(isExternal && {
				rel: 'noreferrer',
				target: '_blank',
			})}
		>
			{children}
		</Link>
	);
}
