import { QBankMedia } from '@web/services/umbraco/types/basic/QBank';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';

import { DepartmentPageData } from '@web/templates/department-page';
import { NewsPageData } from '@web/templates/news-page';
import { PageData } from '@web/templates/page';
import { PublicationPageData } from '@web/templates/publication-page';
import { TransportPageData } from '@web/templates/transport-page';

import { exhaustiveMatchGuard } from '@web/utils/exhaustive-switch';

export type ContentPreviewType = NewsPageData
| TransportPageData
| PublicationPageData
| DepartmentPageData
| PageData;

export type PreviewFields = {
	id: number;
	url: string;
	title: string;
	subtitle?: string;
	media?: QBankMedia | null;
};

const contentPreviewableTypes = [
	'newsPage',
	'contentPage',
	'transportPage',
	'publicationPage',
	'departmentPage',
];

export function isContentPreviewable(content: UmbracoPage): content is ContentPreviewType {
	return contentPreviewableTypes.includes(content.contentType);
}

export function getContentPreview(content: ContentPreviewType): PreviewFields {
	switch (content?.contentType) {
		case 'newsPage':
		case 'contentPage':
		case 'transportPage':
			return {
				id: content.id,
				url: content.url,
				title: content.properties.hero.contentProperties.heroTitle
					.replaceAll('%shy;', ''),
				subtitle: content.properties.hero.contentProperties.heroSubtitle,
				media: content.properties.hero.contentProperties.heroImage,
			};
		case 'publicationPage':
			return {
				id: content.id,
				url: content.url,
				title: content.properties.title,
				media: content.properties.file,
			};
		case 'departmentPage':
			return {
				id: content.id,
				url: content.url,
				title: content.properties.title,
				media: content.properties.heroImage,
			};
		default:
			console.error('Unkown Content type encountered', { content });
			return exhaustiveMatchGuard(content);
	}
}
