import Config from '@web/config';

import { reportException } from '@web/services/errorhandler';

export interface RaffleResult {
	configuration: unknown | null;
	content: string;
	feedback_data: string;
	metadata: unknown | null;
	title: string;
	type: 'paragraph' | string;
	url: string;
}

export async function searchRaffle(query: string): Promise<RaffleResult[]> {
	const url = `${Config.RAFFLE.url}/search?uid=${Config.RAFFLE.uid}&session-id=${Config.RAFFLE.sessionId}&query=${query}`;
	const response = await fetch(url);

	if (!response.ok) {
		const data = await response
			.json()
			.catch(() => 'Failed to parse response body');

		reportException({
			message: 'Failed to fetch search results from Raffle',
			cause: new Error(),
			context: {
				response: data,
				statusCode: response.status,
				query,
			},
		});
	}

	const json = await response.json() as { results: RaffleResult[] };

	return json.results;
}

export async function getRaffleSuggestions(query: string): Promise<string[] | null> {
	const url = `${Config.RAFFLE.url}/autocomplete?uid=${Config.RAFFLE.uid}&query=${query}`;
	const response = await fetch(url);

	if (!response.ok) {
		const data = await response
			.json()
			.catch(() => 'Failed to parse response body');

		reportException({
			message: 'Failed to fetch search suggestions from Raffle',
			cause: new Error(),
			context: {
				response: data,
				statusCode: response.status,
				query,
			},
		});
	}

	const json = await response.json() as { suggestions: string[] | null };

	return json.suggestions;
}


export async function submitRaffleFeedback(feedbackData: string): Promise<void> {
	const url = `${Config.RAFFLE.url}/feedback?uid=${Config.RAFFLE.uid}&session-id=${Config.RAFFLE.sessionId}`;

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			type: 'click',
			feedback_data: feedbackData,
		}),
	});

	if (!response.ok) {
		const data = await response
			.json()
			.catch(() => 'Failed to parse response body');

		reportException({
			message: 'Failed to submit feedback to Raffle',
			cause: new Error(),
			context: {
				response: data,
				statusCode: response.status,
			},
		});
	}
}
