import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import isChromatic from 'chromatic';
import Constants from 'constants';
import { Agent } from 'https';

import Config from '@web/config';

export const axiosUrl = isChromatic()
	? '/api/crm'
	: `${Config.BASE_URL}/api/crm`;

export const AXIOS_INSTANCE = Axios.create({
	baseURL: `${axiosUrl}/public`, // Proxy all CRM request through the API proxy
	httpsAgent: new Agent({
		secureOptions: Constants.SSL_OP_ALLOW_UNSAFE_LEGACY_RENEGOTIATION,
	}),
});

export interface CancellablePromise<T> extends Promise<T> {
	cancel: () => void;
}

// Add a second `options` argument here if you want to pass extra options to each generated query
export const crmAxios = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const source = Axios.CancelToken.source();

	const promise = AXIOS_INSTANCE({
		...config,
		...options,
		cancelToken: source.token,
	}).then(({ data }) => data) as CancellablePromise<T>;

	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};

// Override the return error for React Query
export type ErrorType<Error> = AxiosError<Error>;
