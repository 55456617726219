import Img, { ImageProps } from 'next/legacy/image';
import classNames from 'classnames';
import { OmitStrict } from 'type-zoo/types';

import { Aspect } from '@web/components/media/aspectRatios';
import { determineAltText } from '@web/components/qbank-image/alt-text';

import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';

import { QBankExtension, useQBankImageBuilder } from './loader';

import styles from './qbank-image.module.scss';

export type QBankImageProps = {
	media: QBankImageType;
	extension?: QBankExtension;
	aspectRatio?: Aspect;
	className?: string;
	alt?: string;
	initialHeight?: boolean;
} & OmitStrict<ImageProps, 'src' | 'alt' | 'width' | 'height'>;

export function QBankImage({
	media,
	extension = QBankExtension.WEBP,
	aspectRatio,
	sizes = '100vw',
	layout = 'responsive',
	className,
	alt,
	initialHeight = false,
	...rest
}: QBankImageProps) {
	const { width, height, src } = useQBankImageBuilder(media, {
		extension,
		aspectRatio,
	});

	return (
		<div className={classNames(styles.image, { [styles.initialHeight]: initialHeight }, className)}>
			<Img
				alt={determineAltText(media, alt)}
				objectFit="cover"
				quality="100"
				sizes={layout === 'responsive' || layout === 'fill' ? sizes : undefined}
				layout={layout}
				src={src}
				{...rest}
				{...(layout !== 'fill' && {
					width,
					height,
				})}
			/>
		</div>
	);
}
