import { ReactNode, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Container } from '@dansk-metal/ui';

import { Richtext } from '@web/components/richtext/richtext';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ContentWidth } from '@web/services/umbraco/types/basic/sizes';
import { TableData } from '@web/services/umbraco/types/basic/Table';

import styles from './basic-table-block.module.scss';

const blockName = 'basicTableBlock' as const;

export enum TextWrap {
	Wrap = 'wrap',
	NoWrap = 'nowrap',
}

export interface BasicTableBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			table: TableData;
		},
		{
			width?: ContentWidth;
			textWrapping?: TextWrap;
		}
	>;
}

export function BasicTableBlock({ block }: BasicTableBlockProps) {
	const { rows, useFirstRowAsHeader = false, useFirstColumnAsHeader = false } = block.contentProperties.table;
	const { width = ContentWidth.Medium, textWrapping = TextWrap.Wrap } = block.settingsProperties;
	const headerRow = useFirstRowAsHeader ? rows[0] : null;
	const bodyRows = useFirstRowAsHeader ? rows.slice(1) : rows;
	const [showScrollbar, setShowScrollbar] = useState(false);
	const [scrollPct, setScrollPct] = useState(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const scrollbarRef = useRef<HTMLDivElement>(null);
	const scrollbarHandleRef = useRef<HTMLDivElement>(null);
	const shadowRef = useRef<HTMLDivElement>(null);
	const [isDragging, setIsDragging] = useState(false);

	let previousClientX = 0;
	const getHandleX = () => {
		const { current } = scrollbarHandleRef;
		if (!current) return 0;
		const style = window.getComputedStyle(current);
		const { m41 } = new DOMMatrixReadOnly(style.transform);

		return m41;
	};

	const handleDragMove = (e: MouseEvent) => {
		const { current: container } = containerRef;
		const { current: scrollbar } = scrollbarRef;
		const { current: scrollbarHandle } = scrollbarHandleRef;
		if (!scrollbar || !scrollbarHandle || !container) {
			return;
		}

		const { clientX } = e;
		const move = clientX - previousClientX;
		const maxMove = scrollbar.clientWidth - scrollbarHandle.clientWidth;
		const handlePosition = Math.min(Math.max(0, getHandleX() + move), maxMove);
		scrollbarHandle.style.transform = `translateX(${handlePosition}px)`;

		const pct = handlePosition / maxMove;
		container.scrollLeft = pct * (container.scrollWidth - container.clientWidth);

		previousClientX = clientX;
	};

	const handleDragEnd = () => {
		setIsDragging(false);

		window.removeEventListener('mousemove', handleDragMove);
		window.removeEventListener('mouseup', handleDragEnd);
	};

	const handleDragStart = (e: MouseEvent) => {
		const { clientX } = e;
		e.preventDefault();
		e.stopPropagation();

		previousClientX = clientX;

		setIsDragging(true);

		window.addEventListener('mousemove', handleDragMove);
		window.addEventListener('mouseup', handleDragEnd);
	};

	useEffect(() => {
		const { current: scrollbarHandle } = scrollbarHandleRef;
		if (!scrollbarHandle) {
			return;
		}

		scrollbarHandle.addEventListener('mousedown', handleDragStart);

		return () => scrollbarHandle.removeEventListener('mousedown', handleDragStart);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollbarHandleRef.current]);

	useEffect(() => {
		const { current: scrollbar } = scrollbarRef;
		const { current: scrollbarHandle } = scrollbarHandleRef;
		if (!scrollbar || !scrollbarHandle || isDragging) {
			return;
		}

		const handlePosition = scrollPct * (scrollbar.clientWidth - scrollbarHandle.clientWidth);
		scrollbarHandle.style.transform = `translateX(${handlePosition}px)`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollPct]);

	useEffect(() => {
		const { current } = containerRef;
		const { current: scrollbarHandle } = scrollbarHandleRef;
		if (!current) {
			return;
		}

		const handleResize = () => {
			const { current: shadow } = shadowRef;
			const clientWidth = current.clientWidth;
			const contentWidth = current.children[0].clientWidth;

			setShowScrollbar(contentWidth > clientWidth);

			if (scrollbarHandle) {
				scrollbarHandle.style.width = `${clientWidth * (clientWidth / contentWidth)}px`;
			}

			if (shadow) {
				const firstCol = current.querySelector('td, th');
				if (firstCol && useFirstColumnAsHeader) {
					shadow.style.left = `${firstCol.clientWidth}px`;
				}
			}
		};

		const handleScroll = () => {
			const { scrollLeft = 0, scrollWidth = 0, clientWidth = 0 } = current || {};
			setScrollPct(scrollLeft / (scrollWidth - clientWidth));
		};

		window.addEventListener('resize', handleResize);
		current.addEventListener('scroll', handleScroll);

		handleResize();
		handleScroll();

		return () => {
			window.removeEventListener('resize', handleResize);
			current.removeEventListener('scroll', handleScroll);
		};
	}, [containerRef, useFirstColumnAsHeader]);

	return (
		<Container className={styles.container}>
			<div className={cn(styles.content, styles[`content__${width}`], styles[`content__${textWrapping}`])}>
				<div className={styles.content__inner}>
					<div
						className={cn(
							styles.shadow,
							showScrollbar && {
								[styles['shadow__before']]: scrollPct > 0,
								[styles['shadow__after']]: scrollPct < 1,
							},
						)}
						ref={shadowRef}
					/>
					<div className={cn(styles.table, { [styles['is-sticky']]: useFirstColumnAsHeader })} ref={containerRef}>
						<table>
							{headerRow && (
								<thead>
									<tr>
										{headerRow.cols.map(({ value }, i) => (
											<TableCell key={i} isHeader>
												<Richtext content={value} />
											</TableCell>
										))}
									</tr>
								</thead>
							)}
							<tbody>
								{bodyRows.map(({ cols }, i) => (
									<tr key={`body-row-${i}`}>
										{cols.map((cell, j) => (
											<TableCell key={`body-row-cell-${j}`} isHeader={useFirstColumnAsHeader && j === 0}>
												<Richtext content={cell.value} />
											</TableCell>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className={cn(styles.scrollbar, { [styles.hidden]: !showScrollbar })} ref={scrollbarRef}>
					<div className={styles.scrollbar__handle} ref={scrollbarHandleRef} />
				</div>
			</div>
		</Container>
	);
}

BasicTableBlock.blockName = blockName;

function TableCell({ isHeader, children }: { isHeader: boolean; children: ReactNode }) {
	if (isHeader) return <th>{children}</th>;
	return <td>{children}</td>;
}
