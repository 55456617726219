import { useState } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { useRouter } from 'next/router';

import { Button, Fieldset, useForm, yup, yupResolver } from '@dansk-metal/ui';

import { isValidationError, submitForm } from '@web/components/umbraco-form/helpers/form.loader';
import { ActionType, FormDefinition } from '@web/components/umbraco-form/helpers/types';

import { Field } from './field/field';
import { applyCondition, hasCondition } from './helpers/condition.helper';
import { FormContextProvider } from './helpers/form-context';
import { buildFieldDictionary, mapPage } from './helpers/schema.helpers';

import styles from './umbraco-form.module.scss';

export interface UmbracoDrawerFormProps {
	definition: FormDefinition;
	contentId?: string;
	submitToUmbraco?: boolean;
	onSubmit?: (values: FieldValues) => void | Promise<void>;
}

export function UmbracoDrawerForm({
	definition,
	contentId,
	submitToUmbraco = true,
	onSubmit = () => { },
}: UmbracoDrawerFormProps) {
	const router = useRouter();
	const [remoteErrorMessage, setRemoteErrorMessage] = useState<string | null>(null);
	const pages = definition.pages || [];
	const formDictionary = buildFieldDictionary(pages);
	const formInput = pages.map((page) => mapPage(page, formDictionary));

	const form = useForm<FieldValues>({
		defaultValues: {
			...formInput?.reduce((col, e) => ({
				...col,
				...e.defaultValues,
			}), {}),
		},
		resolver: yupResolver(yup.object({
			...formInput?.reduce((col, e) => {
				return {
					...col,
					...e.yupSchema,
				};
			}, {}),
		})),
	});

	async function handleSuccess(val: FieldValues) {
		try {
			await onSubmit(val);
		} catch (ex) {
			setRemoteErrorMessage(ex.response.data?.message);
			form.setError('serverError', { type: 'server', message: 'Remote error occurred' });
			return;
		}

		if (!submitToUmbraco) {
			return;
		}

		try {
			await submitForm(definition.id as string, val, contentId);
		} catch (ex) {
			if (isValidationError(ex)) {
				Object.entries(ex.response.data.errors)
					.forEach(([key, value]) => {
						form.setError(key, {
							message: value[0],
							type: 'validate',
						});
					});

				setRemoteErrorMessage(ex.response.data.title);
				return;
			}

			throw ex;
		}

		if (definition.gotoPageOnSubmit) {
			router.push(definition.gotoPageOnSubmit.url);
		}
	}

	function handleError(fieldErrors: FieldErrors) {
		console.error('Error validating form', fieldErrors);
	}

	return (
		<form className={styles['container']} onSubmit={form.handleSubmit(handleSuccess, handleError)}>
			<FormContextProvider definition={definition} formHook={form}>
				{definition.pages?.map((page, pageIndex) => (
					<div
						key={pageIndex}
						role="tabpanel"
						aria-labelledby={page.caption || `Page ${pageIndex}`}
					>
						{page.fieldsets?.map((fieldSet) => {
							if (
								hasCondition(fieldSet.condition)
								&& applyCondition(fieldSet.condition, form.getValues(), formDictionary, ActionType.Hide)
							) {
								return null;
							}

							return (
								<Fieldset.Row key={fieldSet.id}>
									<div className={styles.row}>
										{fieldSet.columns?.map((column, columnIndex) => (
											<div key={columnIndex} className={styles.column}>
												{column.caption && (
													<span>{column.caption}</span>
												)}
												{column.fields?.map((field) => {
													if (
														hasCondition(field.condition)
														&& applyCondition(
															field.condition,
															form.getValues(),
															formDictionary,
															ActionType.Hide,
														)
													) {
														return null;
													}

													return (
														<Field key={field.id} data={field} />
													);
												})}
											</div>
										))}
									</div>
								</Fieldset.Row>
							);
						})}
					</div>
				))}

				{form.formState.isSubmitted && remoteErrorMessage && (
					<p className={styles.error}>{remoteErrorMessage}</p>
				)}

				<Button icon={{ icon: 'checkmark', size: 24 }} className={styles.submit} type='submit' variant="fill-primary">
					{definition.submitLabel || 'Submit'}
				</Button>


			</FormContextProvider>
		</form>
	);
}
