import React, { ChangeEvent, useEffect } from 'react';

import { Button, Checkbox, FieldError, Form, Icon, InputText, InputTextProps, Label, RadioGroup, Select, TextArea } from '@dansk-metal/ui';

import { Richtext } from '@web/components/richtext/richtext';
import { useFormContext } from '@web/components/umbraco-form/helpers/form-context';
import { FileType } from '@web/components/umbraco-form/helpers/types';

import { FormFieldDto } from '@web/services/umbraco/rest';

import { getBase64FromFile } from '@web/utils/get-base64-from-file';

import styles from './field.module.scss';

export interface FieldProps {
	data: FormFieldDto;
}

const validInputTypes = ['email', 'number', 'password', 'url', 'text', 'tel', 'search', 'date', 'week'];

export function Field({ data }: FieldProps) {
	const { formHook } = useFormContext();
	const hiddenFileInput = React.useRef<HTMLInputElement>(null);

	useEffect(() => {
		return () => {
			formHook.resetField(data.alias as string);
		};
	}, [data.alias]); // eslint-disable-line react-hooks/exhaustive-deps

	if (data.type?.name === 'Data Consent') {
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				defaultValue={data.settings?.defaultValue || false}
				render={({ field, fieldState }) => (
					<>
						{data.caption && <Label label={data.caption} />}
						<Checkbox
							fieldState={fieldState}
							label={data.settings?.acceptCopy}
							placeholder={data.placeholder || undefined}
							description={data.helpText}
							required={data.required}
							checked={field.value === 'on'}
							onChange={(event) => field.onChange(event.target.value ? 'on' : 'off')}
						/>
					</>
				)}
			/>
		);
	}

	if (data.type?.name === 'Checkbox') {
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				defaultValue={data.settings?.defaultValue || false}
				render={({ field, fieldState }) => (
					<Checkbox
						fieldState={fieldState}
						label={data.caption}
						placeholder={data.placeholder || undefined}
						description={data.helpText}
						required={data.required}
						{...field}
					/>
				)}
			/>
		);
	}

	if (data.type?.name === 'Multiple choice') {
		// TODO: Fixup Multiple choice implementation
		// At the moment, this overwrites the array value with true/false.
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				render={({ field, fieldState }) => {
					const { ...rest } = field;

					return (
						<div className={styles.multiple_checkbox_wrapper}>
							<Label label={data.caption} noOptionalLabel={data.required} />
							{data.preValues?.map((preValue) => {
								return (
									<Checkbox
										key={`${data.id}_${preValue.caption}`}
										label={preValue.caption}
										required={data.required}
										placeholder={data.placeholder || undefined}
										pattern={data.pattern || undefined}
										description={data.helpText}
										multiple
										{...rest}
										checked={field.value.includes(preValue.value)}
										onChange={(checked) => {
											if (checked.target.checked) {
												field.onChange([...field.value, preValue.value]);
											} else {
												field.onChange(field.value.filter((value) => value !== preValue.value));
											}
										}}
									/>
								);
							})}
							<FieldError error={fieldState?.error} />
						</div>
					);
				}}
			/>
		);
	}

	if (data.type?.name === 'Single choice') {
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				defaultValue={data.settings?.defaultValue || ''}
				render={({ field, fieldState }) => (
					<RadioGroup
						fieldState={fieldState}
						label={data.caption}
						required={data.required}
						placeholder={data.placeholder || undefined}
						pattern={data.pattern || undefined}
						description={data.helpText}
						noOptionalLabel
						options={(data.preValues || []).map((preValue) => ({
							label: preValue.caption as string,
							value: preValue.value as string,
						}))}
						checked={field.value}
						{...field} />
				)}
			/>
		);
	}

	if (data.type?.name === 'Dropdown') {
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				defaultValue={data.settings?.defaultValue || ''}
				render={({ field, fieldState }) => {
					const { ref: _, ...rest } = field;

					const options = (data.preValues || []).map((preValue) => ({
						label: preValue.caption as string,
						value: preValue.value as string,
					}));

					return (
						<Select
							fieldState={fieldState}
							label={data.caption}
							required={data.required}
							placeholder={data.placeholder || undefined}
							description={data.helpText}
							options={options}
							{...rest}
							isMulti={false}
							value={options.find((option) => field.value === option.value)}
							onChange={(option) => field.onChange(option.value)}
						/>
					);
				}}
			/>
		);
	}

	if (data.type?.name === 'Long answer') {
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				defaultValue={data.settings?.defaultValue || ''}
				render={({ field, fieldState }) => (
					<TextArea
						fieldState={fieldState}
						label={data.caption}
						required={data.required}
						placeholder={data.placeholder || undefined}
						description={data.helpText}
						{...field}
					/>
				)}
			/>
		);
	}

	if (data.type?.name === 'Short answer') {
		const fieldType = data?.settings?.fieldType as string;
		if (fieldType && !validInputTypes.includes(fieldType)) {
			console.error('Invalid field type recieved', fieldType);
			return null;
		}

		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				defaultValue={data.settings?.defaultValue || ''}
				render={({ field, fieldState }) => {
					return (
						<InputText
							fieldState={fieldState}
							type={(fieldType as InputTextProps<string>['type']) || 'text'}
							label={data.caption}
							required={data.required}
							placeholder={data.placeholder || undefined}
							pattern={data.pattern || undefined}
							description={data.helpText}
							{...field}
						/>
					);
				}}
			/>
		);
	}

	if (data.type?.name === 'Date') {
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				defaultValue={data.settings?.defaultValue || ''}
				render={({ field, fieldState }) => (
					<InputText
						fieldState={fieldState}
						type={'date'}
						label={data.caption}
						required={data.required}
						placeholder={data.placeholder || undefined}
						pattern={data.pattern || undefined}
						description={data.helpText}
						{...field}
					/>
				)}
			/>
		);
	}

	if (data.type?.name === 'Password') {
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				defaultValue={data.settings?.defaultValue || ''}
				render={({ field, fieldState }) => (
					<InputText
						fieldState={fieldState}
						type={'password'}
						label={data.caption}
						required={data.required}
						placeholder={data.placeholder || undefined}
						pattern={data.pattern || undefined}
						description={data.helpText}
						{...field}
					/>
				)}
			/>
		);
	}

	if (data.type?.name === 'Title and description') {
		const Component = (data.settings?.captionTag || 'h2') as 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

		return (
			<>
				{data.settings?.showLabel === 'True' && <Label>{data.caption}</Label>}
				<Component>{data.settings?.caption}</Component>
				<p>{data.settings?.bodyText}</p>
			</>
		);
	}

	if (data.type?.name === 'Rich text') {
		return (
			<>
				{data.settings?.showLabel === 'True' && <Label>{data.caption}</Label>}
				{data.settings?.html && <Richtext content={data.settings.html} />}
			</>
		);
	}

	if (data.type?.name === 'Hidden') {
		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				render={({ field, fieldState }) => (
					<div style={{ display: 'none' }} aria-hidden="true">
						<InputText
							fieldState={fieldState}
							type={'text'}
							label={data.caption}
							required={data.required}
							placeholder={data.placeholder || undefined}
							pattern={data.pattern || undefined}
							description={data.helpText}
							{...field}
						/>
					</div>
				)}
			/>
		);
	}

	const handleUpload = () => {
		hiddenFileInput.current?.click();
	};


	async function handleFileUpload(event: ChangeEvent<HTMLInputElement>, onChange: (files: FileType[]) => void, isMultiple = false) {
		const filesUploaded = event.target.files as FileList;

		if (isMultiple) {
			const filePromises = Array.from(filesUploaded).map(async (file) => {
				const fileBase64 = await getBase64FromFile(file);

				return {
					fileName: file.name,
					fileContents: `data:${file.type};base64,${fileBase64}`,
				};
			});

			const newFileObjects = await Promise.all(filePromises);
			const existingFiles = formHook.getValues(data.alias as string) || [];
			const updatedFiles = [...existingFiles, ...newFileObjects];

			onChange(updatedFiles);
		} else {
			const file = event.target.files?.[0];
			if (!file) return;
			const fileName = file.name;
			const fileBase64 = await getBase64FromFile(file);

			const payloadFile = {
				fileName,
				fileContents: `data:${file.type};base64,${fileBase64}`,
			};
			onChange([payloadFile]);
		}
	}

	const handleFileRemove = (indexToRemove: number) => {
		const uploadedFiles = formHook.getValues(data.alias as string) || [];
		formHook.setValue(data.alias as string, uploadedFiles.filter((_, index) => index !== indexToRemove));
	};

	if (data.type?.name === 'File upload') {
		const isMultiple = data.fileUploadOptions?.allowMultipleFileUploads;
		const uploadedFiles = formHook.getValues(data.alias as string) || [];

		return (
			<Form.Controller
				name={data.alias as string}
				control={formHook.control}
				render={({ field, fieldState }) => (
					<div>
						<Button icon={{ icon: 'attach', size: 24 }} type='button' className={styles.attach} onClick={handleUpload}>
							Vedhæft fil
						</Button>
						<input type="file" ref={hiddenFileInput} onChange={(e) => handleFileUpload(e, field.onChange, isMultiple)} className={styles.file_input} multiple={isMultiple} />
						{uploadedFiles.length > 0 && (
							<div className={styles.uploaded_files}>
								<ul>
									<div className={styles.files_container}>
										{uploadedFiles.map((file, index) => (
											<li className={styles.list_container} key={index}>
												<div className={styles.file_container}>
													{file.fileName}
												</div>
												<button
													type="button"
													onClick={() => handleFileRemove(index)}
													className={styles.remove_button}
												>
													<Icon icon='remove-file' size={24} />
												</button>
											</li>
										))}
									</div>
								</ul>
							</div>
						)}
						<FieldError error={fieldState?.error} />
					</div>
				)}
			/>
		);
	}

	return null;
}
