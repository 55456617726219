import { gql } from '@urql/core';

import type { BasicContent, DepartmentsQuery, DepartmentsQueryVariables } from '@web/services/gql/graphql';
import { mapDocument } from '@web/services/umbraco/type-mapping';
import { Guid } from '@web/services/umbraco/types/basic/Guid';

export { DepartmentsQuery, DepartmentsQueryVariables };

export const departmentsQuery = gql<DepartmentsQuery, DepartmentsQueryVariables>`
query Departments {
	contentByContentType (contentType: "departmentPage") {
		nodes {
			url,
			properties (where: { alias: { eq: "departmentId" }}) {
				editorAlias,
				alias,
				value {
					... on BonsaiPickerModel {
						multiPicker,
						value,
					}
				}
			}
		}
	}
}
`;

export function mapDepartmentNodes(nodes: BasicContent[]): MappedDepartment[] {
	return (nodes || [])
		.map((data) => {
			const doc = mapDocument(data);

			return {
				url: data.url as string,
				departmentId: (doc.properties.departmentId) as Guid,
			};
		})
		.filter((data) => !!data.departmentId);
}

export type MappedDepartment = {
	departmentId: Guid;
	url: string;
};
