import { vars } from '@dansk-metal/theme';
import { Container, Icon } from '@dansk-metal/ui';

import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';

import styles from './link-list.module.scss';

export interface LinkListProps {
	title: HTMLString;
	links: JSX.Element[];
}

export const LinkList = ({ title, links }: LinkListProps) => {
	return (
		<Container className={styles.container}>
			<h2 dangerouslySetInnerHTML={{ __html: title }} />
			<ul className={styles.list}>
				{links.map((link) => (
					<li key={`link-${link.key}`} className={styles.item}>
						{link}
						<Icon icon="arrow-right" size={24} color={vars['--color-secondary']} className={styles.icon} />
					</li>
				))}
			</ul>
		</Container>
	);
};
