import { Container } from '@dansk-metal/ui';

import { Richtext } from '@web/components/richtext/richtext';
import { UmbracoAction } from '@web/components/umbraco-action/umbraco-action';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';
import { Action } from '@web/services/umbraco/types/elements/Action';

import styles from './step-guide-block.module.scss';

const blockName = 'stepGuideBlock' as const;

export interface StepGuideBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: string;
			steps: StepBlock[];
		}
	>;
}

type StepBlock = UmbracoBlock<
	'StepBlock',
	{
		number?: string;
		title: HTMLString;
		bodyText: string;
		actions: Action[];
	}
>;

export function StepGuideBlock({ block }: StepGuideBlockProps) {
	const { title, steps } = block.contentProperties;

	return (
		<Container className={styles.container}>
			<h2 dangerouslySetInnerHTML={{ __html: title }} />
			<div className={styles.container__steps_container}>
				{steps.length !== 0 && (
					<>
						{steps.map((step, index) => (
							<StepBlockItem key={step.key} index={index + 1} step={step} />
						))}
					</>
				)}
			</div>
		</Container>
	);
}

StepGuideBlock.blockName = blockName;

interface StepBlockItemProps {
	index: number;
	step: StepBlock;
}

function StepBlockItem({ step, index }: StepBlockItemProps) {
	const { actions, bodyText, number, title } = step.contentProperties;

	return (
		<div className={styles.step}>
			<div className={styles.step__number_container}>
				<div className={styles.number}>
					<p className={'h4'}>{number || index}</p>
				</div>
				<div className={styles.step__number_container__number_line} />
			</div>
			<div className={styles.content}>
				<h3 className={'h4'}>{title}</h3>
				<Richtext content={bodyText} />
				{actions.length !== 0 && (
					<div className={styles.container__button_wrapper}>
						{actions.map((item) => (
							<UmbracoAction key={item.key} action={item} />
						))}
					</div>
				)}
			</div>
		</div>
	);
}
