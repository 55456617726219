import { treatAsUTC } from '@dansk-metal/utils/date';

/**
 * Calculate the number of days between today and the event date
 */
export function getDaysUntilEvent(eventDate: string) {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const event = new Date(eventDate);
	event.setHours(0, 0, 0, 0);
	const millisecondsPerDay = 24 * 60 * 60 * 1000;

	return Math.ceil((treatAsUTC(event.toDateString()).valueOf() - treatAsUTC(today.toDateString()).valueOf()) / millisecondsPerDay);
}
