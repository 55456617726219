import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { Button, Form, Icon, InputText, Select } from '@dansk-metal/ui';

import { FormValues } from '@web/blocks/apprentice-salary-calculator-block/apprentice-salary-calculator-block';
import { employmentTypeOptions, SelectType, SelectValueType, yesOrNoOptions } from '@web/blocks/apprentice-salary-calculator-block/types';

import { FormFooter, FormWrapper } from '@web/components/form-wrapper/form-wrapper';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';

import { sortDanish } from '@web/utils/sort-danish';

import styles from './apprentice-salary-calculator-form.module.scss';

interface ApprenticeSalaryCalculatorFormProps {
	setValue: Form.UseFormSetValue<FormValues>;
	control: Form.Control<{
		employmentType?: string;
		endDate: string;
		education: SelectValueType;
		isBasicEducation: SelectValueType;
		isAdultApprentice: SelectValueType;
	}, object>;
	educations: UmbracoBlock<'education', { title: string; length: number }>[];
	selectEmploymentType: SelectType;
	formSubmitHandler: () => void;
	formHandleSubmit: Form.UseFormHandleSubmit<{
		employmentType?: string | undefined;
		endDate: string;
		education: SelectValueType;
		isBasicEducation: SelectValueType;
		isAdultApprentice: SelectValueType;
	}, undefined>;
	getValues: (payload?: string | string[]) => string;
}

export function ApprenticeSalaryCalculatorForm(props: ApprenticeSalaryCalculatorFormProps) {
	const {
		control,
		selectEmploymentType,
		educations,
		formSubmitHandler,
		formHandleSubmit,
		setValue,
	} = props;

	const [educationInputText, setEducationInputText] = useState('');

	const educationOptions = educations.map((education) => (
		{ value: education.contentProperties.title, label: education.contentProperties.title }
	)).sort((a, b) => sortDanish(a.label, b.label));

	function handleSearch(inputText: string, event) {
		if (event.action !== 'input-blur' && event.action !== 'menu-close') {
			setEducationInputText(inputText);
			setValue('education', { value: '', label: inputText });
		}
	}

	return (
		<form className={styles.form_wrapper} onSubmit={formHandleSubmit(formSubmitHandler)}>
			<FormWrapper>
				<div className={styles.form_inputs}>
					<Select
						{...selectEmploymentType}
						noOptionalLabel
						required
						label="Hvordan er du ansat?"
						description="Hvis du er ansat i en virksomhed, er du typisk privatansat. Spørg din tillidsrepræsentant eller arbejdsgiver, hvis du er i tvivl."
						options={employmentTypeOptions}
					/>
					<Controller
						control={control}
						name="endDate"
						render={({ field, fieldState }) => (
							<InputText
								label="Hvornår er du udlært?"
								description="Du kan finde svaret i din uddannelsesaftale. Ellers kontakt din uddannelsesinstitution."
								type='date'
								data-testid='date-input'
								required
								fieldState={fieldState}
								{...field}
								noOptionalLabel
							/>
						)}
					/>
					<Controller
						control={control}
						name='education'
						render={({ field, fieldState }) => (
							<Select
								{...field}
								fieldState={fieldState}
								noOptionalLabel
								options={educationOptions}
								required
								onInputChange={(inputText, event) => handleSearch(inputText, event)}
								inputValue={educationInputText}
								label="Hvilken uddannelse er du i gang med?"
								placeholder='Find din uddannelse'
								components={{ DropdownIndicator: () => <Icon icon="search" size="24px" /> }}
								isSearchable
							/>
						)}
					/>
					<Controller
						control={control}
						name='isBasicEducation'
						render={({ field, fieldState }) => {
							return (
								<Select
									{...field}
									fieldState={fieldState}
									noOptionalLabel
									required
									label="Har du gennemført grundforløbet på din uddannelse, før du startede i praktik/lære?"
									options={yesOrNoOptions}
								/>
							);
						}}
					/>
					<Controller
						control={control}
						name='isAdultApprentice'
						render={({ field, fieldState }) => {
							return (
								<Select
									{...field}
									fieldState={fieldState}
									noOptionalLabel
									required
									label="Er du voksenlærling?"
									options={yesOrNoOptions}
								/>
							);
						}}
					/>

				</div>
			</FormWrapper>
			<FormFooter>
				<Button
					icon={{ icon: 'arrow-right', size: 24 }}
					type="submit"
				>
					Se resultat
				</Button>
			</FormFooter>
		</form>
	);
}
