import { FormDrawerButton } from '@web/components/umbraco-action/form-drawer-button/form-drawer-button';
import { LinkItemButton } from '@web/components/umbraco-action/link-item-button/link-item-button';
import { StaticFormDrawerButton } from '@web/components/umbraco-action/static-form-drawer-button/static-form-drawer-button';

import { Action } from '@web/services/umbraco/types/elements/Action';
export interface UmbracoActionProps {
	action: Action;
}
export function UmbracoAction({ action }: UmbracoActionProps) {
	if (action.contentAlias === 'linkItem') {
		return (
			<LinkItemButton action={action} />
		);
	}

	if (action.contentAlias === 'staticForms') {
		return (
			<StaticFormDrawerButton action={action} />
		);
	}

	if (action.contentAlias === 'formDrawerAction') {
		return (
			<FormDrawerButton action={action} />
		);
	}
}
