import Config from '@web/config';

import { authenticatedTemplates, TemplateKey } from '@web/templates';

export function buildLinkForEdit(
	contentId: number,
): string | undefined {
	return `${Config.UMBRACO_URL}/umbraco#/content/content/edit/${contentId}`;
}

export function getFirstNodeUrl(nodes: { url: string }[]): string | undefined {
	return (nodes || [])
		.map((data) => data.url)
		.at(0);
}

export function isAuthenticatedTemplate(template: TemplateKey): boolean {
	return authenticatedTemplates.includes(template);
}
