import { getTestAttr } from '@dansk-metal/service/testing';
import { vars } from '@dansk-metal/theme';
import { Button, Icon } from '@dansk-metal/ui';

import { Benefit } from '@web/blocks/memberships-overview-block/types';

import styles from './info-button.module.scss';

export enum ButtonVariant {
	Primary = '',
	Secondary = '--color-secondary'
}

export interface InfoButtonProps {
	benefitItem?: Benefit;
	selectBenefitHandler?: (benefit: Benefit) => void;
	label?: string;
	color?: ButtonVariant;
}

interface InfoButtonWrapperProps {
	benefitItem?: Benefit;
	selectBenefitHandler?: (benefit: Benefit) => void;
	children: React.ReactNode;
}

export function InfoButton({ benefitItem, selectBenefitHandler, label, color = ButtonVariant.Primary }: InfoButtonProps) {
	return (
		<InfoButttonWrapper benefitItem={benefitItem} selectBenefitHandler={selectBenefitHandler}>
			<Icon className={styles.ticket_icon} icon='tick-alt' size={vars['--size-6']} color={vars[color]} />
			{benefitItem ? (
				<li className={styles.benefit__item}>
					<p>{benefitItem.contentProperties.title}</p>
					{(benefitItem.contentProperties.bodyText || benefitItem.contentProperties.link) && (
						<Icon
							className={styles.link_icon}
							icon={'arrow-right'}
							color={vars['--color-middle-blue-base']}
							size={vars['--size-6']}
						/>
					)}
				</li>
			) : (
				<li className={styles.benefit__item}>
					<p className={styles.info_button_label}>{label}</p>
				</li>
			)}
		</InfoButttonWrapper>
	);
}

function InfoButttonWrapper({ benefitItem, selectBenefitHandler, children }: InfoButtonWrapperProps) {
	if (benefitItem && selectBenefitHandler && benefitItem.contentProperties.bodyText) {
		// OPEN DRAWER
		return (
			<div className={styles.benefit}>
				<Button
					{...getTestAttr('drawer_opener')}
					unstyled
					className={styles.benefit__content}
					onClick={() => {
						selectBenefitHandler(benefitItem);
					}}
				>
					{children}
				</Button>
			</div>
		);
	}

	if (benefitItem && benefitItem.contentProperties.link && !benefitItem.contentProperties.bodyText) {
		return (
			<div className={styles.benefit}>

				<a
					className={styles.benefit__content}
					href={benefitItem.contentProperties.link.url}
					target={benefitItem.contentProperties.link.target}
				>
					{children}
				</a>
			</div>
		);
	}

	return (
		<div className={styles.benefit}>
			<div className={styles.benefit__content}>{children}</div>
		</div>
	);
}
