import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export type UmbracoTags = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Umbraco.Tags';
	value: { value: string[] };
};

export class TagsConverter {
	static UmbracoType: UmbracoTags;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.Tags';
	}

	public static mapProperty(prop: typeof this.UmbracoType): string[] {
		return prop.value.value || [];
	}
}
