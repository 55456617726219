import { signOut, SignOutParams } from 'next-auth/react';

import Config from '@web/config';

const isTest = Config.CRM.url?.includes('test-danskmetal');

const signoutUrls = {
	oauth: isTest
		? 'https://login.test-danskmetal.dk/adfs/oauth2/logout'
		: 'https://login.danskmetal.dk/adfs/oauth2/logout',
	member: isTest
		? 'https://medlem.test-danskmetal.dk/_trust/?wa=wsignoutcleanup1.0'
		: 'https://medlem.danskmetal.dk/_trust/?wa=wsignoutcleanup1.0',
	dms: isTest
		? 'https://dms.test-danskmetal.dk/?wa=wsignoutcleanup1.0'
		: 'https://dms.danskmetal.dk/?wa=wsignoutcleanup1.0',
	nemid: isTest
		? 'https://nemid.test-danskmetal.dk/OcesAuthPage/idp/Metal/sts.aspx?wa=wsignout1.0'
		: 'https://nemid.danskmetal.dk/OcesAuthPage/idp/Metal/sts.aspx?wa=wsignout1.0',
	login: isTest
		? 'https://login.test-danskmetal.dk/adfs/ls?wa=wsignoutcleanup1.0'
		: 'https://login.danskmetal.dk/adfs/ls?wa=wsignoutcleanup1.0',
} as const;

export function useSingleSignOut() {
	async function singleSignOut<R extends boolean = true>(params?: SignOutParams<R>) {
		await Promise.all(Object.keys(signoutUrls).map((key) => {
			return new Promise((resolve) => {
				const iframe = document.createElement('iframe');
				iframe.style.display = 'none';
				let timeoutRef: ReturnType<typeof setTimeout> | undefined;

				const interValRef = setInterval(() => {
					try {
						if (iframe.contentDocument && iframe.contentDocument.readyState === 'complete') {
							clearInterval(interValRef);
							clearTimeout(timeoutRef);
							return resolve(true);
						}
					} catch (e) {
						console.warn(e);
					}

					try {
						if (iframe.contentWindow && iframe.contentWindow.document.readyState === 'complete') {
							clearInterval(interValRef);
							clearTimeout(timeoutRef);
							return resolve(true);
						}
					} catch (e) {
						console.warn(e);
					}
				}, 500);

				timeoutRef = setTimeout(() => {
					clearInterval(interValRef);
					resolve(true);
					iframe?.remove();
				}, 5000);

				iframe.onload = () => {
					clearInterval(interValRef);
					clearTimeout(timeoutRef);
					resolve(true);
					iframe?.remove();
				};

				iframe.onerror = () => {
					clearInterval(interValRef);
					clearTimeout(timeoutRef);
					resolve(true);
					iframe?.remove();
				};

				iframe.src = signoutUrls[key];
				document.getElementsByTagName('body')[0].appendChild(iframe);
			});
		}));

		signOut(params);
	}

	return {
		singleSignOut,
	};
}
