import { SalaryStatisticDto } from '@web/services/crm-api/rest';

/**
 * The claculations made in this script are based on the document found here: https://makingwaves.atlassian.net/wiki/spaces/DM/pages/2596929842/Tjenester
 */

export type TimeRate = 'hourly' | 'monthly';

export type RegionData = {
	name: string;
	selected: boolean;
	people: number;
	basicSalaryData: {
		average: number | null;
		increase: number | null;
	};
	pensionData: {
		selfPaid: number | null;
		employerPaid: number | null;
	};
	totalData: {
		average: number | null;
		increase: number | null;
	};
};

const hoursPerMonth = 160.33;

export function mapRegionData(salaryStatistics: SalaryStatisticDto[], selectedRegionId: string, timeRate: TimeRate): RegionData[] {
	const numberOfRegionsWithData = salaryStatistics
		.filter((salaryStatistic: SalaryStatisticDto) => salaryStatistic.memberCount > 0)
		.length;

	const order = { true: 1, false: 2 };

	const regionSalaryStatisitcs = salaryStatistics
		.map((salaryStatistic: SalaryStatisticDto) => {
			return {
				name: salaryStatistic.regionName,
				selected: salaryStatistic.regionId === Number(selectedRegionId),
				people: salaryStatistic.memberCount,
				basicSalaryData: {
					average: calculateSalary(salaryStatistic.dkkSalaryHourly, timeRate),
					increase: salaryStatistic.pctIncreaseSalaryHourly === undefined ? null : salaryStatistic.pctIncreaseSalaryHourly,
				},
				pensionData: {
					selfPaid: calculateSalary(salaryStatistic.dkkPensionHourlyMemberPaid, timeRate),
					employerPaid: calculateSalary(salaryStatistic.dkkPensionHourlyEmployeePaid, timeRate),
				},
				totalData: {
					average: calculateSalary(salaryStatistic.dkkSalaryAndPensionSumHourly, timeRate),
					increase: salaryStatistic.pctIncreaseSalaryAndPensionSumHourly === undefined ?
						null : salaryStatistic.pctIncreaseSalaryAndPensionSumHourly,
				},
			};
		})
		.sort((a, b) => order[`${a.selected}`] - order[`${b.selected}`]);

	const basicSalarySum = salaryStatistics.reduce((acc, salaryStatistic) => {
		if (salaryStatistic.dkkSalaryHourly) {
			return acc + salaryStatistic.dkkSalaryHourly;
		}

		return acc;
	}, 0);

	const basicSalaryAverage = basicSalarySum	/ numberOfRegionsWithData;

	const basicSalaryIncreaseSum = salaryStatistics.reduce((acc, salaryStatistic) => {
		if (salaryStatistic.pctIncreaseSalaryHourly) {
			return acc + salaryStatistic.pctIncreaseSalaryHourly;
		}

		return acc;
	}, 0);

	const basicSalaryIncreaseAverage = basicSalaryIncreaseSum / numberOfRegionsWithData;

	const pensionSelfPaidSum = salaryStatistics.reduce((acc, salaryStatistic) => {
		if (salaryStatistic.dkkPensionHourlyMemberPaid) {
			return acc + salaryStatistic.dkkPensionHourlyMemberPaid;
		}

		return acc;
	}, 0);

	const pensionSelfPaidAverage = pensionSelfPaidSum / numberOfRegionsWithData;

	const pensionEmployerPaidSum = salaryStatistics.reduce((acc, salaryStatistic) => {
		if (salaryStatistic.dkkPensionHourlyEmployeePaid) {
			return acc + salaryStatistic.dkkPensionHourlyEmployeePaid;
		}

		return acc;
	}, 0);

	const pensionEmployerPaidAverage = pensionEmployerPaidSum / numberOfRegionsWithData;


	const totalSalarySum = salaryStatistics.reduce((acc, salaryStatistic) => {
		if (salaryStatistic.dkkSalaryAndPensionSumHourly) {
			return acc + salaryStatistic.dkkSalaryAndPensionSumHourly;
		}

		return acc;
	}, 0);

	const totalSalaryAverage = totalSalarySum / numberOfRegionsWithData;

	const totalSalaryIncreaseSum = salaryStatistics.reduce((acc, salaryStatistic) => {
		if (salaryStatistic.pctIncreaseSalaryAndPensionSumHourly) {
			return acc + salaryStatistic.pctIncreaseSalaryAndPensionSumHourly;
		}

		return acc;
	}, 0);

	const totalSalaryIncreaseAverage = totalSalaryIncreaseSum / numberOfRegionsWithData;

	const averageOfAllRegions = {
		name: 'Hele landet',
		selected: false,
		people: salaryStatistics.reduce((acc, salaryStatistic) => acc + salaryStatistic.memberCount, 0),
		basicSalaryData: {
			average: calculateSalary(basicSalaryAverage, timeRate),
			increase: roundTwoDecimals(basicSalaryIncreaseAverage),
		},
		pensionData: {
			selfPaid: calculateSalary(pensionSelfPaidAverage, timeRate),
			employerPaid: calculateSalary(pensionEmployerPaidAverage, timeRate),
		},
		totalData: {
			average: calculateSalary(totalSalaryAverage, timeRate),
			increase: roundTwoDecimals(totalSalaryIncreaseAverage),
		},
	};

	regionSalaryStatisitcs.push(averageOfAllRegions);
	return regionSalaryStatisitcs;
}

function calculateSalary(salary: number | undefined | null, timeRate: TimeRate): number | null {
	if (salary === null) {
		return null;
	}

	if (timeRate === 'monthly') {
		return Number(salary) === 0 ? 0 : roundTwoDecimals(Number(salary) * hoursPerMonth);
	}

	return Number(salary) === 0 ? 0 : roundTwoDecimals(Number(salary));
}

function roundTwoDecimals(num: number): number {
	return Math.round((num + Number.EPSILON) * 100) / 100;
}
