import { useCallback, useEffect, useState } from 'react';
import { useKeyPressEffect } from '@noaignite-dk/utils-next';
import cn from 'classnames';
import type { Swiper as SwiperClass } from 'swiper';
import { A11y, Navigation } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { vars } from '@dansk-metal/theme';
import { Button, Container, Icon } from '@dansk-metal/ui';

import { Aspect } from '@web/components/media/aspectRatios';
import { QBankImage } from '@web/components/qbank-image/qbank-image';
import { Theme } from '@web/components/theme/theme';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';

import 'swiper/css';

import styles from './image-gallery-block.module.scss';

const blockName = 'imageGalleryBlock' as const;

export interface ImageGalleryBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			media: QBankImageType[];
		}
	>;
}

export function ImageGalleryBlock({ block }: ImageGalleryBlockProps) {
	const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
	const gallery = block.contentProperties.media;
	const [activeIndex, setActiveIndex] = useState(0);
	const [overlayOpen, setOverlayOpen] = useState(false);

	const swiperProps: SwiperProps = {
		spaceBetween: 16,
		allowTouchMove: true,
		slidesPerView: 1,
		centeredSlides: true,
		className: styles.gallery,
	};

	const modules = [Navigation, A11y];

	const handleLeftClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slidePrev();
	}, [swiperRef]);

	const handleRightClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slideNext();
	}, [swiperRef]);

	const handleOnSwiper = (swiper: SwiperClass) => {
		if (swiperProps?.onSwiper) {
			swiperProps.onSwiper(swiper);
		}

		if (!swiperRef) {
			setSwiperRef(swiper);
		}
	};

	const onSwiperChange = (swiper: SwiperClass) => {
		if (swiper.realIndex || swiper.realIndex === 0) {
			setActiveIndex(swiper.realIndex);
		} else {
			setActiveIndex(swiper.clickedIndex);
		}
	};

	const openOverlay = () => {
		setOverlayOpen(true);
	};

	const closeOverlay = () => {
		setOverlayOpen(false);
	};

	useEffect(() => {
		document.body.style.overflow = overlayOpen ? 'hidden' : 'unset';
	}, [overlayOpen]);

	const handleArrowLeft = () => {
		if (overlayOpen) handleLeftClick();
	};

	const handleArrowRight = () => {
		if (overlayOpen) handleRightClick();
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useKeyPressEffect('Escape', closeOverlay, { deps: [closeOverlay] });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useKeyPressEffect('ArrowLeft', handleArrowLeft, { deps: [handleArrowLeft] });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useKeyPressEffect('ArrowRight', handleArrowRight, { deps: [handleArrowRight] });

	return (
		<Theme className={styles.theme}>
			<Container>
				<div className={cn(styles.content, { [styles.overlay]: overlayOpen })}>
					{gallery.length !== 0 && (
						<>
							<Swiper {...swiperProps} modules={modules} onSwiper={handleOnSwiper} onSlideChange={onSwiperChange}>
								{gallery.map((img, index) => {
									return (
										<SwiperSlide
											className={cn(styles.gallery__item, { [styles.active_slide]: activeIndex === index })}
											key={img.mediaId}
										>
											<div className={styles.media_container} onClick={openOverlay}>
												<QBankImage
													loading="lazy"
													media={img}
													className={styles.from_image_gallery}
													aspectRatio={overlayOpen ? undefined : Aspect['4:3']}
												/>
												<span className={styles.fullscreen_icon}>
													<Icon size={16} icon="fullscreen" color={vars['--color-neutral-white']} />
												</span>
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>

							<div className={styles.utilities}>
								{overlayOpen && (
									<button onClick={closeOverlay} className={styles.utilities__close_overlay}>
										<Icon icon="dismiss" size={24} color={vars['--color-neutral-white']} />
									</button>
								)}

								<span className={styles.utilities__pagination}>
									{activeIndex + 1}/{gallery.length}
								</span>
								<div className={styles.utilities__nav}>
									<Button
										className={cn({ [styles.disabled]: activeIndex === 0 })}
										onClick={handleLeftClick}
										variant="fill-secondary"
									>
										<Icon size={24} icon="chevron-left" />
									</Button>
									<Button
										className={cn({ [styles.disabled]: activeIndex === gallery.length - 1 })}
										onClick={handleRightClick}
										variant="fill-secondary"
									>
										<Icon size={24} icon="chevron-right" />
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</Container>
		</Theme>
	);
}

ImageGalleryBlock.blockName = blockName;
