import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useKeyPressEffect } from '@noaignite-dk/utils-next';
import cn from 'classnames';

import { vars } from '@dansk-metal/theme';
import { Button, Container, Icon } from '@dansk-metal/ui';

import { Aspect } from '@web/components/media/aspectRatios';
import { Modal } from '@web/components/modal/modal';
import { buildUrl, getClosestQbankTemplate, QBankExtension } from '@web/components/qbank-image/loader';

import { QBankVideoType } from '@web/services/umbraco/types/basic/QBank';

import { formatSecondsToMinutes } from '@web/utils/format-seconds-to-minutes';

import { useUserAgent } from '@web/hooks/use-user-agent';

import styles from './qbank-video.module.scss';

export type QBankVideoProps = {
	media: QBankVideoType;
	className?: string;
	aspectRatio?: Aspect;
};

export function QBankVideo({ media, aspectRatio, className, ...rest }: QBankVideoProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [clicked, setClicked] = useState<boolean>(false);
	const [duration, setDuration] = useState<number>();
	const videoRef = useRef<HTMLVideoElement>(null);
	const videoPosterRef = useRef<HTMLVideoElement>(null);
	const { isMobileDevice } = useUserAgent();
	const route = useRouter();
	const taskId = route.query.taskId;

	async function updateTask() {
		try {
			const response = await fetch(`/api/update-task?taskId=${taskId}`);
			if (response.status === 401) {
				setIsModalOpen(true);
			}
		} catch (error) {
			throw new Error('Error updating task');
		}
	}

	const posterTemplate = getClosestQbankTemplate(
		media,
		{ aspectRatio: aspectRatio || Aspect['16:9'], extension: QBankExtension.WEBP },
		media.width || 640,
	);

	const url = buildUrl(media.filename);
	const posterUrl = buildUrl(media.filename, posterTemplate);

	function playVid() {
		videoRef?.current?.play();
	}

	function pauseVid() {
		videoRef?.current?.pause();
	}

	function playVideo() {
		setClicked(true);
		playVid();
		if (taskId) {
			updateTask();
		}
	}

	function dismiss(e: { stopPropagation: () => void }) {
		e.stopPropagation();
		setClicked(false);
		pauseVid();
	}

	useEffect(() => {
		if (videoPosterRef && videoPosterRef.current) {
			if (videoPosterRef.current?.duration) {
				setDuration(videoPosterRef.current?.duration);
			}

			videoPosterRef.current.addEventListener('loadedmetadata', () => {
				setDuration(videoPosterRef.current?.duration);
			});
		}
	}, []);

	useEffect(() => {
		if (clicked) {
			playVid();
		}

		if (!isMobileDevice) {
			document.body.style.overflow = clicked ? 'hidden' : 'unset';
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clicked]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useKeyPressEffect('Escape', dismiss, { deps: [dismiss] });

	return (
		<>
			<div
				className={cn(
					styles.videoContainer,
					aspectRatio && styles[`aspect__${aspectRatio.replace(':', '_')}`],
					className,
				)}
				onClick={playVideo}
			>
				<video preload="metadata" ref={videoPosterRef} poster={posterUrl} src={url} />

				<div className={styles.playOverlay}>
					<div className={styles.iconView}>
						<Icon icon={'videoPlay'} size={48} />
						<p>Afspil video</p>
					</div>
					{duration && <p>{formatSecondsToMinutes(duration)}</p>}
				</div>

				{clicked && (
					<div
						onClick={dismiss}
						className={cn(styles.player, styles.on_play, {
							[styles.on_play__desktop]: !isMobileDevice,
						})}
					>
						<button onClick={dismiss} className={styles.dismiss}>
							<Icon icon="dismiss" size={24} color={vars['--color-neutral-white']} />
						</button>
						<Container>
							<video {...rest} ref={videoRef} src={url} poster={posterUrl} controls onClick={(e) => e.stopPropagation()} />
						</Container>
					</div>
				)}
			</div>
			<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
				<div>
					<h2>Der opstod en fejl under opdatering af din opgave.</h2>
					<Button onClick={() => setIsModalOpen(false)}>Luk</Button>
				</div>
			</Modal>
		</>
	);
}
