import { useRef, useState } from 'react';
import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container, Select, useForm, yup, yupResolver } from '@dansk-metal/ui';
import { dayJS } from '@dansk-metal/utils/date';

import { addYearsToDate, apprenticeCalculation, getStartDate, Period } from '@web/blocks/apprentice-salary-calculator-block/calculation/apprentice-salary-calculation';
import { ApprenticeSalaryCalculatorForm } from '@web/blocks/apprentice-salary-calculator-block/components/apprentice-salary-calculator-form';
import { ApprenticeSalaryCalculatorResult } from '@web/blocks/apprentice-salary-calculator-block/components/apprentice-salary-calculator-result';
import { WarningModalContent } from '@web/blocks/apprentice-salary-calculator-block/components/warning-modal';
import { employmentTypeOptions, SelectValueType, yesOrNoOptions } from '@web/blocks/apprentice-salary-calculator-block/types';

import { Modal } from '@web/components/modal/modal';
import { Richtext } from '@web/components/richtext/richtext';
import { Theme } from '@web/components/theme/theme';

import { validationMessages } from '@web/constants/messages/validation-messages';

import { trackEventSync } from '@web/services/tracking';
import { Action, Context } from '@web/services/tracking/config';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ThemeDefinition } from '@web/services/umbraco/types/basic/Color';
import { UmbracoDocument } from '@web/services/umbraco/types/documents/UmbracoDocument';

import styles from './apprentice-salary-calculator-block.module.scss';

const blockName = 'salaryCalculatorAppretntices' as const;
const { education: education_message, end_date } = validationMessages;

const schema = yup.object({
	employmentType: yup.string(),
	endDate: yup.string().required(end_date.empty),
	education: yup
		.object<SelectValueType>({
			value: yup.string(),
			label: yup.string(),
		})
		.test('has_label', education_message.empty, (value: SelectValueType) => !!value.label)
		.test('has_value', education_message.error, (value: SelectValueType) => !!value.value),
	isBasicEducation: yup.object<SelectValueType>({
		value: yup.string().required(),
		label: yup.string().required(),
	}),
	isAdultApprentice: yup.object<SelectValueType>({
		value: yup.string().required(),
		label: yup.string().required(),
	}),
}).required();

export interface FormValues extends yup.InferType<typeof schema> {
	education: SelectValueType;
	isBasicEducation: SelectValueType;
	isAdultApprentice: SelectValueType;
}

export type EducationBlock = UmbracoBlock<'education', { title: string; length: number }>;
export type IndustryRateBlock = UmbracoBlock<'loengrisenIndustryRates', { startDate: string; period: number[] }>;
export type AdultApprenticeRateBlock = UmbracoBlock<'loengrisenApprenticeRate', { startDate: string; rate: number }>;

export type CalculatorData = UmbracoDocument<
	'loengrisenSettings',
	{
		educations: EducationBlock[];
		industryRates: IndustryRateBlock[];
		adultApprenticeRates: AdultApprenticeRateBlock[];
	},
	{
		children: null;
	}
>;

export type ApprenticeSalaryCalculatorBlockProps = {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: string;
			subtitle?: string;
			data: CalculatorData;
		},
		{
			colorTheme?: ThemeDefinition;
		}
	>;
};

const TOO_FAR_FUTURE_TITLE = 'Dato for langt ude i fremtiden';
const TOO_FAR_FUTURE_BODY = 'Beklager. Den dato du har indtastet, for hvornår du er udlært, er for langt ude i fremtiden til at vi kan vise dig din lønudvikling. Prøv at indtaste en ny dato.';
const TOO_FAR_PAST_TITLE = 'Dato for langt tilbage i fortiden';
const TOO_FAR_PAST_BODY = 'Beklager. Den dato du har indtastet, for hvornår du blev udlært, er for langt tilbage i fortiden til at vi kan vise dig din lønudvikling. Prøv at indtaste en ny dato.';
const PUBLIC_EMPLOYMENT_TITLE = 'Lærling i det offentlige?';
const PUBLIC_EMPLOYMENT_BODY = 'Beregnerens resultat bygger på Industriens Overenskomst. Der gælder andre satser for lærlinge og elever på offentlige arbejdspladser. Kontakt din lokalafdeling og få hjælp til din løn.';

export function ApprenticeSalaryCalculatorBlock({ block }: ApprenticeSalaryCalculatorBlockProps) {
	const { settingsProperties = {} } = block;
	const { colorTheme } = settingsProperties || {};
	const { title, subtitle, data } = block.contentProperties;
	const [showResult, setShowResult] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [warningTitle, setWarningTitle] = useState('');
	const [warningBodyText, setWarningBodyText] = useState('');
	const [calculationResult, setCalculationResult] = useState<Period[]>();
	const ref = useRef<HTMLDivElement>(null);

	const handleScrollIntoView = () => {
		ref?.current?.scrollIntoView();
	};

	const { ...form } = useForm<FormValues, object>({
		defaultValues: {
			employmentType: 'private',
			education: undefined,
			endDate: '',
			isBasicEducation: yesOrNoOptions[0],
			isAdultApprentice: yesOrNoOptions[1],
		},
		resolver: yupResolver(schema),
	});

	const handleSubmit = () => {
		const formValues = form.getValues();
		const employmentTypeValue = form.getValues('employmentType');
		const isBasicEducationBool = form.getValues('isBasicEducation').value === 'true';
		const isAdultApprenticeBool = form.getValues('isAdultApprentice').value === 'true';
		const educationValue = form.getValues('education').value;

		const educationLength = data.properties.educations.find(
			(education) => education.contentProperties.title === educationValue)?.contentProperties.length || 1;


		if (employmentTypeValue === 'public') {
			setWarningTitle(PUBLIC_EMPLOYMENT_TITLE);
			setWarningBodyText(PUBLIC_EMPLOYMENT_BODY);
			setIsModalOpen(true);
			return;
		}

		const endDate = dayJS.utc(form.getValues('endDate')).toDate();
		const startDate = getStartDate(endDate, educationLength);
		if (isAdultApprenticeBool) {
			// check date against adult apprentice rates
			const adultApprenticeRatesStarDates = data.properties.adultApprenticeRates.map((rate) => {
				return rate.contentProperties.startDate;
			}).sort();

			// get last date
			const lastRateStartDate = adultApprenticeRatesStarDates[adultApprenticeRatesStarDates.length - 1];
			// get first date
			const firstRateStartDate = adultApprenticeRatesStarDates[0];
			// check if end date is bigger then the last rate start date plus one year
			if (endDate > addYearsToDate(lastRateStartDate, 1)) {
				// Too far in future
				setWarningTitle(TOO_FAR_FUTURE_TITLE);
				setWarningBodyText(TOO_FAR_FUTURE_BODY);
				setIsModalOpen(true);
				return;
			}

			if (startDate < dayJS.utc(firstRateStartDate).toDate()) {
				// Too far in the past
				setWarningTitle(TOO_FAR_PAST_TITLE);
				setWarningBodyText(TOO_FAR_PAST_BODY);
				setIsModalOpen(true);
				return;
			}
		} else {
			// check date against industry rates
			const industryRatesStarDates = data.properties.industryRates.map((rate) => {
				return rate.contentProperties.startDate;
			}).sort();

			// get last date
			const lastRateStartDate = industryRatesStarDates[industryRatesStarDates.length - 1];
			// get first date
			const firstRateStartDate = industryRatesStarDates[0];
			if (endDate > addYearsToDate(lastRateStartDate, 1)) {
				// Too far in the future
				setWarningTitle(TOO_FAR_FUTURE_TITLE);
				setWarningBodyText(TOO_FAR_FUTURE_BODY);
				setIsModalOpen(true);
				return;
			}

			if (startDate < dayJS.utc(firstRateStartDate).toDate()) {
				// Too far in the past
				setWarningTitle(TOO_FAR_PAST_TITLE);
				setWarningBodyText(TOO_FAR_PAST_BODY);
				setIsModalOpen(true);
				return;
			}
		}

		const input = {
			...formValues,
			education: educationValue,
			isBasicEducation: isBasicEducationBool,
			isAdultApprentice: isAdultApprenticeBool,
		};

		const result = apprenticeCalculation({ input, data });

		setCalculationResult(result);
		handleScrollIntoView();
		setShowResult(true);
		trackEventSync({ event: [Context.ApprenticeSalaryCalculator, Action.Complete] });
	};


	function setEmploymentTypeValue(value) {
		form.setValue('employmentType', value?.value);
		if (value?.value === 'public') {
			setWarningTitle(PUBLIC_EMPLOYMENT_TITLE);
			setWarningBodyText(PUBLIC_EMPLOYMENT_BODY);
			setIsModalOpen(true);
		}
	}

	const selectEmploymentType = Select.useSelect({
		isMulti: false,
		initialSelect: employmentTypeOptions[0],
		onChange: setEmploymentTypeValue,
	});

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme?.theme),
			})}
		>
			<Container className={styles.container} ref={ref}>
				<div className={cn(styles.content)}>
					<div className={styles.header}>
						<h2>{title}</h2>
						{subtitle && (
							<Richtext content={subtitle} />
						)}
					</div>
					{!showResult ? (
						<ApprenticeSalaryCalculatorForm
							setValue={form.setValue}
							control={form.control}
							educations={data.properties.educations}
							selectEmploymentType={selectEmploymentType}
							formSubmitHandler={handleSubmit}
							formHandleSubmit={form.handleSubmit}
							getValues={form.getValues}
						/>
					) : (
						<ApprenticeSalaryCalculatorResult
							handleReturnToForm={setShowResult}
							result={calculationResult || []}
							scrollIntoViewHandler={handleScrollIntoView}
						/>
					)}
				</div>
			</Container>
			<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
				<WarningModalContent
					title={warningTitle}
					bodyText={warningBodyText}
					handleOnClick={() => setIsModalOpen(false)}
				/>
			</Modal>
		</Theme>
	);
}

ApprenticeSalaryCalculatorBlock.blockName = blockName;
