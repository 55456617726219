import { umbracoFetchError } from '@web/services/errorhandler/appError';
import { urqlClient } from '@web/services/umbraco/client';
import { tagsQuery } from '@web/services/umbraco/fetchers/get-tags/tags.query';

import { isTruthy } from '@web/utils/guards';

export async function fetchTagsRecursive(query: { contentType: string }, cursor?: string): Promise<string[]> {
	const queryVariables = {
		cursor,
		...query,
	};

	const { data, error } = await urqlClient.query(tagsQuery, queryVariables).toPromise();

	if (error) {
		throw umbracoFetchError({
			message: 'Error loading umbraco pages',
			cause: error,
			info: {
				graphQLVariables: query,
			},
		});
	}

	if (!data?.contentByContentType) {
		throw umbracoFetchError({
			message: 'No content found for contentType',
			info: {
				graphQLVariables: query,
			},
		});
	}

	const tags = (data.contentByContentType?.nodes || [])
		.map((node) => node?.name)
		.filter(isTruthy);

	if (data.contentByContentType.pageInfo.hasNextPage && data.contentByContentType.pageInfo.endCursor) {
		const subResponse = await fetchTagsRecursive(query, data.contentByContentType.pageInfo.endCursor);

		return [...tags, ...subResponse];
	}

	return tags;
}
