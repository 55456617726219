import Link from 'next/link';
import cn from 'classnames';

import { getPallette, hasColorPallette } from '@dansk-metal/theme';

import { QBankImage } from '@web/components/qbank-image/qbank-image';
import { Theme } from '@web/components/theme/theme';

import { ColorTheme } from '@web/services/umbraco/types/basic/Color';
import { QBankVectorType } from '@web/services/umbraco/types/basic/QBank';

import type { FullImageCard, HalfImageCard, IllustrationCard, MosaicCard, ValueCard } from '../types';

import styles from './card.module.scss';

type MosaicCardProps = {
	card: MosaicCard;
	layout: string;
	length: number;
	index: number;
	className?: string;
};

export function Card({ card, layout, index, length, className }: MosaicCardProps) {
	const { contentAlias, settingsProperties, contentProperties } = card;
	const { theme: colorTheme = { theme: ColorTheme.Lightblue }, textposition } = settingsProperties;
	const { title, subject, reference } = contentProperties;

	const themeDef = (!colorTheme?.theme || colorTheme.theme === ColorTheme.None)
		? { theme: ColorTheme.Lightblue }
		: colorTheme;

	return (
		<Theme
			themeDef={themeDef}
			data-variant={contentAlias}
			className={className}
		>
			<Link
				href={reference?.url || '#'}
				className={cn(
					styles.card,
					styles[contentAlias],
					styles[`theme__${getPallette(colorTheme.theme)}`],
					styles[`index__${index}`],
					styles[`length__${length}`],
					styles[`layout__${layout}`],
					{
						[styles.direction__reverse]: textposition === 'text_first',
						[styles.has_color_theme]: hasColorPallette(colorTheme.theme),
					},
				)}
			>
				{isImageCard(card) && card.contentProperties.image && (
					<QBankImage
						media={card.contentProperties.image}
						sizes="(min-width: 960px) 80vw, (min-width: 640px) 45vw, 90vw"
						className={styles.image}
					/>
				)}
				{isIllustrationCard(card) && card.contentProperties.illustration && (
					<div className={styles.illustration}>
						<span style={{ WebkitMaskImage: `url(${buildProxiedUrl(card.contentProperties.illustration)})` }} />
					</div>
				)}
				<div className={styles.text}>
					<div className={styles.text__inner}>
						{subject && <div className={styles.subject}>{subject}</div>}
						{isValueCard(card) && <div className={styles.value}>{card.contentProperties.value}</div>}
						{title && <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
					</div>
				</div>
			</Link>
		</Theme>
	);
}

// TODO: Replace with cors settings in qbank
function buildProxiedUrl(image: QBankVectorType) {
	return `/api/qbank/${encodeURI(image.filename)}`;
}

function isValueCard(card: MosaicCard): card is ValueCard {
	return card.contentAlias === 'mosaicCardValue';
}

function isIllustrationCard(card: MosaicCard): card is IllustrationCard {
	return card.contentAlias === 'mosaicCardIllustration';
}

function isImageCard(card: MosaicCard): card is FullImageCard | HalfImageCard {
	return card.contentAlias === 'mosaicCardFullImage' || card.contentAlias === 'mosaicCardHalfImage';
}
