import { useState } from 'react';
import { OmitStrict } from 'type-zoo/types';

import { ContentDrawer, ContentDrawerProps } from '../content-drawer/content-drawer';

import { ContactMessageConfirmation } from './contact-message-confirmation';
import { ContactMessageForm } from './contact-message-form';

export type ContactMessageDrawerProps = {
	drawer: OmitStrict<ContentDrawerProps, 'children'>;
};

export function ContactMessageDrawer({ drawer }: ContactMessageDrawerProps) {
	const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false);

	const handleShowConfirmation = (showConfirmation) => {
		setShouldShowConfirmation(showConfirmation);
	};

	const handleClose = () => {
		setShouldShowConfirmation(false);
		if (drawer?.onClose) {
			drawer.onClose();
		}
	};

	return (
		<ContentDrawer {...drawer}>
			{shouldShowConfirmation ? (
				<ContactMessageConfirmation onClose={handleClose} />
			) : (
				<ContactMessageForm onShowConfirmation={handleShowConfirmation} />
			)}
		</ContentDrawer>
	);
}
