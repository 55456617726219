import { MouseEventHandler, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';

import { vars } from '@dansk-metal/theme';
import { Container, Icon } from '@dansk-metal/ui';
import { MOTIONS } from '@dansk-metal/utils/animations';

import styles from './content-drawer.module.scss';

export interface ContentDrawerProps {
	children: ReactNode;
	open?: boolean;
	paths?: string[];
	queryParam?: string;
	onClose?: Function;
	testId?: string;
}

export const animationDuration = 0.5;

const transition = { ease: 'easeInOut', duration: animationDuration };

export function ContentDrawer({
	children,
	paths = [],
	queryParam = 'emne',
	open = false,
	onClose = () => {},
	testId,
}: ContentDrawerProps) {
	const router = useRouter();
	const { query } = router;
	const pathname = typeof window !== 'undefined' ? window.location.pathname : '/';

	const onNavClick = (offset) => {
		const q = query[queryParam];
		if (typeof q === 'string') {
			router.push(
				{
					pathname,
					query: {
						[queryParam]: paths[paths.indexOf(q) + offset],
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		}
	};

	useEffect(() => {
		document.body.style.overflow = open ? 'hidden hidden' : 'hidden auto';
	}, [open, query]);

	useEffect(() => {
		const onKeydown = (e) => {
			if (e.key === 'Escape') {
				onClose();
			}
		};

		document.addEventListener('keydown', onKeydown);
		return () => document.removeEventListener('keydown', onKeydown);
	}, [onClose]);

	const isFirstPath = query[queryParam] === paths[0];
	const isLastPath = query[queryParam] === paths[paths.length - 1];

	return (
		<LazyMotion features={domAnimation}>
			<AnimatePresence>
				{open && (
					<FocusTrap focusTrapOptions={{ initialFocus: false }}>
						<div className={styles.container} data-testid={testId} aria-modal="true">
							<m.div
								{...MOTIONS['fade']['fade']()}
								className={styles.overlay}
								onClick={onClose as MouseEventHandler<HTMLDivElement>}
								transition={transition}
							/>
							<m.div className={styles.drawer} {...MOTIONS['in-left']['out-right'](100)} transition={transition}>
								<Container className={styles.header}>
									{/*
										For the animation to work, the only way is to have the navigation div not visible with opacity.
										if the element is not rendered on the left of the header component the animation of opening the drawwer will be the sane as the closing animation
									*/}
									<div className={cn(styles.navigation, { [styles.not_visible]: paths.length === 0 })}>
										<button
											aria-label="previous"
											className={cn(styles.icon_button, { [styles.disabled]: isFirstPath })}
											onClick={() => onNavClick(-1)}
										>
											<Icon icon="chevron-left" size={24} color={vars['--color-neutral-white']} />
										</button>
										<button
											aria-label="next"
											className={cn(styles.icon_button, { [styles.disabled]: isLastPath })}
											onClick={() => onNavClick(1)}
										>
											<Icon icon="chevron-right" size={24} color={vars['--color-neutral-white']} />
										</button>
									</div>
									<button aria-label="close" onClick={onClose as MouseEventHandler<HTMLButtonElement>} className={styles.icon_button}>
										<Icon icon="dismiss" size={24} color={vars['--color-neutral-white']} />
									</button>
								</Container>
								<div className={styles.content} data-alias="content-drawer">
									{children}
								</div>
							</m.div>
						</div>
					</FocusTrap>
				)}
			</AnimatePresence>
		</LazyMotion>
	);
}
