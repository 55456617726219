import dynamic from 'next/dynamic';
import IframeResizer from 'iframe-resizer-react';

import { IframeType } from '@web/blocks/authenticated-iframe-block/types';

import { ContentWidth } from '@web/services/umbraco/types/basic/sizes';

const DynamicDMS1Iframe = dynamic(() => import('./dms-1-iframe/dms-1-iframe').then((mod) => mod.DMS1Iframe));
const DynamicJoblogIframe = dynamic(() => import('./joblog-iframe/joblog-iframe').then((mod) => mod.JoblogIframe));
const DynamicResizerIframeIframe = dynamic(() => import('./resizer-iframe/resizer-iframe').then((mod) => mod.ResizerIframe));

interface IframeWrapperProps {
	frameId: string;
	type: IframeType;
	source: string;
	width: ContentWidth;
}

export function IframeWrapper({ frameId, type, source }: IframeWrapperProps) {
	if (type === IframeType.DMS1) {
		return <DynamicDMS1Iframe source={source} />;
	}

	if (type === IframeType.JOBLOG) {
		return <DynamicJoblogIframe source={source} />;
	}

	if (type === IframeType.Resizer) {
		return <DynamicResizerIframeIframe frameId={frameId} source={source} />;
	}

	// Handle iframe as regular iframe
	return <IframeResizer src={source} style={{ width: '100%' }} />;
}
