import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import cn from 'classnames';

import { hasColorPallette, vars } from '@dansk-metal/theme';
import { Button, Container, Icon, InputText, RadioGroup, Select, SelectOption, TextArea, yup, yupResolver } from '@dansk-metal/ui';
import { dayJS } from '@dansk-metal/utils/date';

import { Theme } from '@web/components/theme/theme';

import { DepartmentDto, EducationPlanCreateEducationPlanBody, useEducationPlanCreateEducationPlan, useGetDepartmentsByFilter } from '@web/services/crm-api/rest';
import { crmError } from '@web/services/errorhandler/appError';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ThemeDefinition } from '@web/services/umbraco/types/basic/Color';
import { UmbracoDocument } from '@web/services/umbraco/types/documents/UmbracoDocument';

import { sortDanish } from '@web/utils/sort-danish';

import { FormFooter, FormHeader, FormWrapper } from '@apps/web/src/components/form-wrapper/form-wrapper';
import { Richtext } from '@apps/web/src/components/richtext/richtext';
import { validationMessages } from '@apps/web/src/constants/messages/validation-messages';

import styles from './education-form-block.module.scss';

type Educations = UmbracoBlock<
	'educationPlan',
	{
		title: string;
	},
	{
		colorTheme?: ThemeDefinition;
	}
>;

type EducationData = UmbracoDocument<
	'educationsPlanList',
	{
		educations: Educations[];
	},
	{
		children: null;
	}
>;

const blockName = 'educationFormBlock' as const;

export type EducationFormBlockProps = {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: string;
			subtitle?: string;
			educationData: EducationData;
			defaultDepartment: string;
		},
		{
			colorTheme?: ThemeDefinition;
		}
	>;
};

const {
	first_name,
	last_name,
	email,
	phone_number,
	new_education,
	message,
	department: department_validation_message,
	birhtday: birthday_validation_message,
} = validationMessages;

const schema = yup.object({
	firstName: yup.string().required(first_name.empty),
	lastName: yup.string().required(last_name.empty),
	birthdate: yup.string().required(birthday_validation_message.empty).test('validateRange', birthday_validation_message.error, (value) => {
		const valueDate = dayJS.utc(new Date(value));
		const futureLimitDate = dayJS.utc('2099-12-31T23:59:59Z');
		const pastLimitDate = dayJS.utc('1900-01-01T00:00:00Z');
		// check if date is withing range
		if (valueDate.isAfter(futureLimitDate) || valueDate.isBefore(pastLimitDate)) {
			return false;
		}

		return true;
	}),
	phoneNumber: yup.string().required(phone_number.empty)
		.matches(/^[0-9+]+$/, phone_number.error)
		.test('length', phone_number.error, (value) => {
			if (!value) return true;
			return	value?.replaceAll(' ', '').length >= 6 && value?.replaceAll(' ', '').length <= 20;
		}),
	email: yup.string().required(email.empty).matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, email.error),
	department: yup.object({
		value: yup.string(),
		label: yup.string(),
	}).test('has_value', department_validation_message.error, (value) => !!value.value).required(department_validation_message.empty),
	education: yup.object({
		value: yup.string(),
		label: yup.string(),
	}).test('has_value', new_education.error, (value) => !!value.value)
		.required(new_education.empty),
	situation: yup.object().required(),
	comment: yup.string().test('length', message.error, (value) => !value || value.length <= 1000),
}).required();

export interface FormValues extends yup.InferType<typeof schema> {
	department: SelectOption;
	education: SelectOption;
}

export function EducationFormBlock({ block, block: { settingsProperties } }: EducationFormBlockProps) {
	const { colorTheme } = settingsProperties || {};
	const { title, subtitle, educationData, defaultDepartment } = block.contentProperties;
	const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false);
	const { mutateAsync, isError, isLoading: isSubmitting } = useEducationPlanCreateEducationPlan();
	const departmentsRef = useRef<DepartmentDto[]>([]);

	const { data: departments, error: departmentError, isLoading: departmentsLoading } = useGetDepartmentsByFilter(
		{
			pageSize: 100,
			showOnEductaionPlan: true,
		},
		{
			query: {
				onSettled: (val) => {
					departmentsRef.current = val?.items || [];
				},
			},
		});

	const educationOptions = educationData.properties.educations
		.map((education) => ({
			value: education.contentProperties.title,
			label: education.contentProperties.title,
		})).sort((a, b) => sortDanish(a.label, b.label));

	const departmentOptions = departments?.items.map((department) => {
		return {
			value: department.departmentId,
			label: department.name,
		};
	}).sort((a, b) => sortDanish(a.label, b.label));

	const situationOptions = [
		{ value: 'efteruddannelse', label: 'Efteruddannelse' },
		{ value: 'videreuddannelse', label: 'Videreuddannelse' },
		{ value: 'brancheskift', label: 'Brancheskift' },
	];

	const { control, reset, getValues, setValue, handleSubmit, formState } = useForm<FormValues, object>({
		defaultValues: {
			firstName: '',
			lastName: '',
			birthdate: '',
			phoneNumber: '',
			email: '',
			department: { value: '', label: '' },
			education: { value: '', label: '' },
			situation: {
				efteruddannelse: true,
				videreuddannelse: false,
				brancheskift: false,
			},
			comment: '',
		},
		reValidateMode: 'onSubmit',
		resolver: yupResolver(schema),
	});

	async function onSubmit(formData) {
		try {
			if (!formData) {
				throw new Error('ata not ready');
			}

			const educationWish = Object.keys(formData.situation).find((key) => formData.situation[key]);

			if (educationWish === undefined) {
				throw new TypeError('The value was promised to always be there!');
			}

			let receivingDepartment = '';

			if (formData.education.value === 'Øvrige uddannelser') {
				receivingDepartment = defaultDepartment;
			} else {
				receivingDepartment = educationWish === 'efteruddannelse' ? formData.department.value : defaultDepartment;
			}

			const payload: EducationPlanCreateEducationPlanBody = {
				firstname: formData.firstName,
				lastname: formData.lastName,
				birthdate: formData.birthdate,
				phone: formData.phoneNumber,
				email: formData.email,
				educationWish,
				education: formData.education.value,
				department: formData.department.value,
				receivingDepartment,
				comment: formData.comment,
				source: 'Homepage',
			};

			await mutateAsync({
				data: payload,
			});

			setShouldShowConfirmation(true);
			reset();
			window.scrollTo(0, 0);
		} catch (error) {
			console.error(error);
		}
	}

	const handleSituationChange = (e) => {
		const targetValue = e.target.value;
		const intervalValues = getValues('situation');


		Object.keys(intervalValues).forEach((key) => {
			intervalValues[key] = key === targetValue;
		});

		setValue('situation', intervalValues);
	};

	const formStateErrors = formState.errors.firstName ||
	formState.errors.lastName ||
	formState.errors.birthdate ||
	formState.errors.phoneNumber ||
	formState.errors.email ||
	formState.errors.department ||
	formState.errors.education ||
	formState.errors.situation ||
	formState.errors.comment;

	if (departmentError) {
		throw crmError({ message: 'Error resolving departments', cause: departmentError });
	}

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme?.theme),
			})}
		>
			{shouldShowConfirmation ? (
				<Container>
					<div className={styles.confirmation}>
						<div className={styles.confirmation__header}>
							<h2>
								Tillykke!<br/>
								Du er nu et skridt nærmere dine drømme
							</h2>
							<p>Vi ringer dig op inden for 24 timer (på hverdage) for at aftale nærmere med dig.</p>
						</div>
						<div className={styles.confirmation__subtexts}>
							<p><Icon icon='tick-alt' size={vars['--size-6']} /> <span>En skræddersyet uddannelsesplan, som giver dig overblik over din efter- eller videreuddannelse</span></p>
							<p><Icon icon='tick-alt' size={vars['--size-6']} /> <span>Et overblik over din økonomi under uddannelse</span></p>
							<p><Icon icon='tick-alt' size={vars['--size-6']} /> <span>Et overblik over eventuelle adgangskrav</span></p>
						</div>
					</div>
				</Container>
			) : (
				<Container className={styles.form_container}>
					<div className={styles.header}>
						<h2>{title}</h2>
						{subtitle && (
							<Richtext content={subtitle} />
						)}
					</div>
					<form onSubmit={handleSubmit(onSubmit)} className={styles.form_wrapper}>
						<FormWrapper>
							{(formStateErrors || isError) && (
								<div className={styles.banner_wrapper}>
									<FormHeader type='error' message={isError ? 'Noget gik galt - prøv igen senere' : 'Der er fejl i et eller flere felter'} />
								</div>
							)}
							<div className={styles.form_inputs}>
								<Controller
									control={control}
									name="firstName"
									render={({ field, fieldState }) => (
										<InputText
											label='Fornavn'
											fieldState={fieldState}
											{...field}
											noOptionalLabel
											disabled={isSubmitting}
										/>
									)}
								/>
								<Controller
									control={control}
									name="lastName"
									render={({ field, fieldState }) => (
										<InputText
											label='Efternavn'
											fieldState={fieldState}
											{...field}
											noOptionalLabel
											disabled={isSubmitting}
										/>
									)}
								/>
								<Controller
									control={control}
									name="birthdate"
									render={({ field, fieldState }) => (
										<InputText
											type='date'
											fieldState={fieldState}
											label='Fødselsdato'
											{...field}
											noOptionalLabel
											disabled={isSubmitting}
										/>
									)}
								/>
								<Controller
									control={control}
									name="phoneNumber"
									render={({ field, fieldState }) => (
										<InputText
											label='Telefonnummer'
											fieldState={fieldState}
											{...field}
											noOptionalLabel
											disabled={isSubmitting}
										/>
									)}
								/>
								<Controller
									control={control}
									name="email"
									render={({ field, fieldState }) => (
										<InputText
											label='E-mail'
											fieldState={fieldState}
											{...field}
											noOptionalLabel
											disabled={isSubmitting}
										/>
									)}
								/>
								<Controller
									control={control}
									name='department'
									render={({ field, fieldState }) => {
										return (
											<Select
												{...field}
												fieldState={fieldState}
												noOptionalLabel
												options={departmentOptions || []}
												required
												isLoading={departmentsLoading}
												label="Lokalafdeling"
												placeholder='Vælg'
												isSearchable
												disabled={isSubmitting}
											/>
										);
									}}
								/>
								<Controller
									control={control}
									name='education'
									render={({ field, fieldState }) => (
										<Select
											{...field}
											fieldState={fieldState}
											noOptionalLabel
											options={educationOptions}
											required
											label="Nuværende uddannelse"
											placeholder='Vælg'
											isSearchable
											disabled={isSubmitting}
										/>
									)}
								/>
								<Controller
									control={control}
									name="situation"
									render={({ field, fieldState }) => (
										<RadioGroup
											label='Hvad er du mest interesseret i at høre mere om?'
											className={styles.radio_group}
											fieldState={fieldState}
											{...field}
											options={situationOptions}
											noOptionalLabel
											onChange={handleSituationChange}
											disabled={isSubmitting}
										/>
									)}
								/>
								<Controller
									control={control}
									name="comment"
									render={({ field, fieldState }) => (
										<TextArea
											label='Dine ønsker eller kommentar'
											fieldState={fieldState}
											{...field}
											disabled={isSubmitting}
										/>
									)}
								/>
							</div>
						</FormWrapper>
						<FormFooter>
							<Button disabled={isSubmitting} icon={isSubmitting ? { icon: 'spinner' } : { icon: 'arrow-right', size: 24 }} type="submit">Send</Button>
						</FormFooter>
					</form>
				</Container>
			)}
		</Theme>
	);
}

EducationFormBlock.blockName = blockName;
