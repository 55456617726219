import React, { createContext, useContext } from 'react';

import { useForm } from '@dansk-metal/ui';

import { FormDefinition } from './types';

interface FormContextProps {
	definition: FormDefinition;
	formHook: ReturnType<typeof useForm>;
	children: React.ReactNode;
}

export const FormContext = createContext<{
	definition: FormDefinition;
	formHook: ReturnType<typeof useForm>;
}>({} as FormContextProps);

/**
 * @see {@link FormContextProps}
 */
export function useFormContext() {
	const context = useContext(FormContext);

	return context;
}

export function FormContextProvider({ definition, formHook, children }: FormContextProps) {
	return (
		<FormContext.Provider value={{ definition, formHook }} >
			{children}
		</FormContext.Provider>
	);
}
