import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export class NumberConverter {
	static UmbracoType: Omit<BasicProperty, 'value'> & {
		editorAlias: 'Umbraco.Integer' | 'Umbraco.Decimal';
		value: {
			value: number;
		};
	};

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.Integer' || input.editorAlias === 'Umbraco.Decimal';
	}

	public static mapProperty(prop: typeof this.UmbracoType): number {
		return prop.value.value;
	}
}
