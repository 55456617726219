import { Container, Icon } from '@dansk-metal/ui';

import styles from './news-share-buttons.module.scss';

export interface NewsShareButtonsProps {
	shareUrl: string;
}

export function NewsShareButtons({ shareUrl }: NewsShareButtonsProps) {
	const openPopup = (e) => {
		const { currentTarget: el } = e;

		// Prevent default anchor event
		e.preventDefault();

		// Set values for window
		const w = '500';
		const h = '400';
		const rs = 'no';
		const strTitle = typeof el.getAttribute('title') !== 'undefined' ? el.getAttribute('title') : 'Social Share';
		const strParam = `width=${w},height=${h},resizable=${rs}`;
		window.open(el.getAttribute('href'), strTitle, strParam)?.focus();
	};

	return (
		<Container>
			<div className={styles.content}>
				<div className={styles.buttons}>
					<a href={`https://www.facebook.com/sharer.php?u=${shareUrl}`} onClick={openPopup} title="Share on Facebook">
						<Icon icon="facebook" size={32} aria-label="Facebook" />
					</a>
					<a href={`https://twitter.com/share?url=${shareUrl}`} onClick={openPopup} title="Share on Twitter">
						<Icon icon="twitter" size={32} aria-label="Twitter" />
					</a>
					<a
						href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
						onClick={openPopup}
						title="Share on Linkedin"
					>
						<Icon icon="linkedin" size={32} aria-label="LinkedIn" />
					</a>
				</div>
				Del indholdet
			</div>
		</Container>
	);
}
