/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Umbraco Forms API
 * Describes the Umbraco Forms API available for rendering and submitting forms. You can find out more about the API in [the documentation](https://docs.umbraco.com/umbraco-forms/v/12.forms.latest/developer/ajaxforms)
 * OpenAPI spec version: Latest
 */
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

import type { FormDto, FormEntryDto, GetUmbracoFormsApiV1DefinitionsIdParams } from '../form.schemas';

export const getUmbracoFormsApiV1DefinitionsId = <TData = AxiosResponse<FormDto>>(
	id: string,
	params?: GetUmbracoFormsApiV1DefinitionsIdParams,
	options?: AxiosRequestConfig,
): Promise<TData> => {
	return axios.get(`/umbraco/forms/api/v1/definitions/${id}`, {
		...options,
		params: { ...params, ...options?.params },
	});
};
export const postUmbracoFormsApiV1EntriesId = <TData = AxiosResponse<void>>(
	id: string,
	formEntryDto: FormEntryDto,
	options?: AxiosRequestConfig,
): Promise<TData> => {
	return axios.post(`/umbraco/forms/api/v1/entries/${id}`, formEntryDto, options);
};
