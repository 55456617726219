import { useEffect, useState } from 'react';

/**
 * A simple hook to get the user agent and check if the user is on a mobile device.
 */
export function useUserAgent() {
	const [userAgent, setUserAgent] = useState<string | null>(null);
	const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);

	useEffect(() => {
		const ua = window?.navigator?.userAgent;
		setUserAgent(ua);

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)) {
			setIsMobileDevice(true);
		} else {
			setIsMobileDevice(false);
		}
	}, []);

	return {
		userAgent,
		isMobileDevice,
	};
}
