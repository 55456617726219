export const useLockScroll = () => {
	const disableScroll = () => {
		document.body.style.overflow = 'hidden';
	};

	const enableScroll = () => {
		document.body.style.overflow = 'hidden auto';
	};

	return {
		disableScroll,
		enableScroll,
	};
};
