import { useCallback, useState } from 'react';
import cn from 'classnames';
import type { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { Button, Container, Icon } from '@dansk-metal/ui';
import { formatDate } from '@dansk-metal/utils/date';

import { LinkButton } from '@web/components/link-button/link-button';
import { PublicationItem } from '@web/components/publication-item/publication-item';
import { Theme } from '@web/components/theme/theme';

import { PublicationSearchItem } from '@web/services/umbraco/rest';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { Link as LinkValue } from '@web/services/umbraco/types/basic/Link';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';
import { LinkStyle } from '@web/services/umbraco/types/elements/Action';

import { PublicationPageData } from '@web/templates/publication-page';

import 'swiper/css';

import styles from './publications-list-block.module.scss';

const blockName = 'publicationsListBlock' as const;

export interface PublicationsListBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: HTMLString;
			publications: PublicationPageData[];
			link?: LinkValue;
		}
	>;
}

export function PublicationsListBlock({ block }: PublicationsListBlockProps) {
	const { title } = block.contentProperties;
	const { publications } = block.contentProperties;
	const [activeIndex, setActiveIndex] = useState(0);
	const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);

	function getSpaceBetween(items: number) {
		return publications.length <= items ? 0 : 16;
	}

	const slidesPerView = (number: number) => {
		return publications.length < number ? publications.length : number;
	};

	const numberOfSlides = () => {
		if (swiperRef) {
			if (swiperRef.currentBreakpoint < 375) return slidesPerView(1);
			if (swiperRef.currentBreakpoint < 640) return slidesPerView(1);
			if (swiperRef.currentBreakpoint < 960) return slidesPerView(2);
			if (swiperRef.currentBreakpoint < 1280) return slidesPerView(3);
			return slidesPerView(4);
		}
	};

	const swiperProps: SwiperProps = {
		breakpoints: {
			1: {
				slidesPerView: 1,
				spaceBetween: getSpaceBetween(1),
			},
			375: {
				slidesPerView: 1,
				spaceBetween: getSpaceBetween(1),
			},
			640: {
				slidesPerView: 2,
				spaceBetween: getSpaceBetween(2),
			},
			960: {
				slidesPerView: 3,
				spaceBetween: getSpaceBetween(3),
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: getSpaceBetween(4),
			},
		},
	};

	const handleLeftClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slidePrev();
	}, [swiperRef]);

	const handleRightClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slideNext();
	}, [swiperRef]);

	const handleOnSwiper = (swiper: SwiperClass) => {
		if (swiperProps?.onSwiper) {
			swiperProps.onSwiper(swiper);
		}

		if (!swiperRef) {
			setSwiperRef(swiper);
		}
	};

	const onSwiperChange = (swiper: SwiperClass) => {
		if (swiper.realIndex || swiper.realIndex === 0) {
			setActiveIndex(swiper.realIndex);
		} else {
			setActiveIndex(swiper.clickedIndex);
		}
	};

	return (
		<Theme className={styles.publication_list}>
			<Container className={styles.container}>
				<h2 dangerouslySetInnerHTML={{ __html: title }} />
				{publications?.length === 1 ? (
					<div className={cn({ [styles.one_item]: publications.length === 1 })}>
						<PublicationItem
							publication={mapPublicationBlockToPublicationSearchItem(publications[0])}
							extendedVersion={true}
						/>
					</div>
				) : (
					<Swiper
						{...swiperProps}
						className={cn(
							styles.swiper,
							{ [styles.two_items]: publications.length === 2 },
							{ [styles.three_items]: publications.length === 3 },
							{ [styles.four_items]: publications.length === 4 },
						)}
						onSwiper={handleOnSwiper}
						onSlideChange={onSwiperChange}
					>
						{publications.map((publicationItem) => {
							return (
								<SwiperSlide className={styles.swiper_item} key={publicationItem.id}>
									<PublicationItem publication={mapPublicationBlockToPublicationSearchItem(publicationItem)} />
								</SwiperSlide>
							);
						})}
						<Button
							className={cn(styles.nav_left, { [styles.disable]: activeIndex === 0 })}
							onClick={handleLeftClick}
							variant="fill-secondary"
						>
							<Icon size={24} icon="arrow-left" />
						</Button>
						<Button
							className={cn(styles.nav_right,
								{ [styles.disable]: activeIndex === publications.length - (numberOfSlides() || 0) },
							)}
							onClick={handleRightClick}
							variant="fill-secondary"
						>
							<Icon size={24} icon="arrow-right" />
						</Button>
					</Swiper>
				)}
				{block.contentProperties.link && (
					<LinkButton link={block.contentProperties.link} type={LinkStyle.TextLink} />
				)}
			</Container>
		</Theme>
	);
}

PublicationsListBlock.blockName = blockName;

function mapPublicationBlockToPublicationSearchItem(publication: PublicationPageData): PublicationSearchItem {
	return {
		id: publication.id,
		url: publication.url,
		name: publication.name,
		title: publication.properties.title,
		subtitle: publication.properties.subtitle,
		issuuURL: publication.properties.issuuURL,
		file: {
			...publication.properties.file,
			mediaType: publication.properties.file?.type,
		},
		tags: publication.properties.tags?.map((item) => item.name) || null,
		publishDate: formatDate(publication.properties.publicationDate, '22. feb 2022'),
		downloadTitle: publication.properties.downloadButton.contentProperties.title,
	};
}
