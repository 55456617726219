export enum Alignment {
	Left = 'Left',
	Right = 'Right',
}

export enum CtaAlignment {
	Left = 'alignLeft',
	Center = 'alignCenter',
}

export enum HeroAlignment {
	Left = 'left',
	Center = 'center',
}

export enum TextPosition {
	Last = 'text_last',
	First = 'text_first',
}
