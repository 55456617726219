import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import { Richtext } from '@web/components/richtext/richtext';
import { Theme } from '@web/components/theme/theme';
import { UmbracoAction } from '@web/components/umbraco-action/umbraco-action';

import { CtaAlignment } from '@web/services/umbraco/types/basic/Alignment';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ColorTheme, ThemeDefinition } from '@web/services/umbraco/types/basic/Color';
import { HTMLString, RichTextContent } from '@web/services/umbraco/types/basic/RichTextContent';
import { Action } from '@web/services/umbraco/types/elements/Action';

import styles from './basic-cta-block.module.scss';

const blockName = 'basicCTABlock' as const;

export interface BasicCtaBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title?: HTMLString;
			bodyText?: RichTextContent;
			actions: Action[];
		},
		{
			alignment?: CtaAlignment;
			colorTheme?: ThemeDefinition;
		}
	>;
}

export function BasicCtaBlock({ block }: BasicCtaBlockProps) {
	const { title, bodyText, actions } = block.contentProperties;
	const { alignment = CtaAlignment.Left, colorTheme = { theme: ColorTheme.None } } = block.settingsProperties;

	return (
		<Theme themeDef={colorTheme}>
			<Container>
				<div
					className={cn(styles.content, {
						[styles.has_color_theme]: hasColorPallette(colorTheme.theme),
						[styles.center]: alignment === CtaAlignment.Center,
					})}
				>
					{(title || bodyText) && (
						<div
							className={cn(styles.content__text, {
								[styles.center__text]: alignment === CtaAlignment.Center,
							})}
						>
							{title && <h2 className={'h3'} dangerouslySetInnerHTML={{ __html: title }} />}
							{bodyText && <Richtext content={bodyText} />}
						</div>
					)}
					<div
						className={cn(styles.content__buttons, {
							[styles.content__buttons__left]: alignment === CtaAlignment.Left,
						})}
					>
						{actions.map((item) => (
							<UmbracoAction key={item.key} action={item} />
						))}
					</div>
				</div>
			</Container>
		</Theme>
	);
}

BasicCtaBlock.blockName = blockName;
