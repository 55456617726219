import Img, { ImageProps } from 'next/legacy/image';
import classNames from 'classnames';
import { OmitStrict } from 'type-zoo/types';

import { Aspect } from '@web/components/media/aspectRatios';
import { determineAltText } from '@web/components/qbank-image/alt-text';
import { QBankExtension, useQBankImageBuilder } from '@web/components/qbank-image/loader';

import { QBankDocumentType } from '@web/services/umbraco/types/basic/QBank';

import styles from './qbank-document.module.scss';

export type QBankDocumentProps = {
	media: QBankDocumentType;
	alt?: string;
	aspectRatio?: Aspect;
} & OmitStrict<ImageProps, 'src' | 'width' | 'height'>;

export function QBankDocument({
	media,
	className,
	alt,
	sizes = '90vw',
	layout = 'responsive',
	aspectRatio,
	...rest
}: QBankDocumentProps) {
	const { width, height, src } = useQBankImageBuilder(media, {
		extension: QBankExtension.WEBP,
		aspectRatio,
	});

	return (
		<div className={classNames(styles.document, className)}>
			<Img
				alt={determineAltText(media, alt)}
				objectFit="cover"
				quality="100"
				sizes={layout === 'responsive' || layout === 'fill' ? sizes : undefined}
				layout={layout}
				src={src}
				{...rest}
				{...(layout !== 'fill' && {
					width,
					height,
				})}
			/>
		</div>
	);
}
