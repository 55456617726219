import React, { CSSProperties } from 'react';

import { kebabToCamelCase } from '@dansk-metal/utils/common';

export function parseStyle(style?: string): CSSProperties | undefined {
	if (!style) {
		return undefined;
	}

	const styleItems = style.split(';');

	return styleItems.reduce((col, item) => {
		const [key, value] = item.split(':');

		if (!value) {
			return col;
		}

		return {
			...col,
			[kebabToCamelCase(key)]: value.trim(),
		};
	}, {} as CSSProperties);
}

/**
 * Check if the only child of the given node is a whitespace character.
 */
export function hasWhitespaceChild(child: React.ReactNode) {
	if (React.isValidElement(child)) {
		return typeof child.props?.children === 'string' && child.props?.children.trim() === '';
	}

	return false;
}

export function filterOutWhitespace(children: React.ReactNode | React.ReactNode[]) {
	if (Array.isArray(children)) {
		return children.filter((child) => !hasWhitespaceChild(child));
	}

	if (hasWhitespaceChild(children)) {
		return [];
	}

	return [children];
}

export function convertToSafeReactHTMLProps(props) {
	const safeProps = {};

	for (const [key, value] of Object.entries(props)) {
		// Convert kebab-case attributes to camelCase
		const camelKey = kebabToCamelCase(key);

		// Check if the attribute is unsafe
		if (!isUnsafeAttribute(camelKey)) {
			// Add the attribute to the safeProps object
			safeProps[camelKey] = value;
		}
	}

	return safeProps;
}

function isUnsafeAttribute(attr) {
	const unsafeAttrs = [
		'onload',
		'onclick',
		'onsubmit',
		'ondblclick',
		'onmousedown',
		'onmousemove',
		'onmouseout',
		'onmouseover',
		'onmouseup',
		'onkeydown',
		'onkeypress',
		'onkeyup',
		'onfocus',
		'onblur',
		'onchange',
		'oninput',
		'onafterprint',
		'onbeforeprint',
		'autofocus',
		'autoplay',
		'contenteditable',
		'contextmenu',
		'draggable',
		'dropzone',
		'hidden',
		'inputmode',
		'itemprop',
		'spellcheck',
		'translate',
	];

	// Check if the attribute is in the list of unsafe attributes
	return unsafeAttrs.includes(attr);
}
