/* eslint-disable max-classes-per-file */
import set from 'lodash/set';

import { BasicProperty, CustomBlockListItem, CustomBlockListModel, CustomContentPicker, CustomContentPickerItem } from '@web/services/gql/graphql';
import { BaseConverter } from '@web/services/umbraco/converters/base.converter';
import { BonsaiPickerConverter } from '@web/services/umbraco/converters/bonsai.converter';
import { BreadcrumbsConverter } from '@web/services/umbraco/converters/breadcrumbs.converter';
import { CheckboxConverter } from '@web/services/umbraco/converters/checkbox.converter';
import { DateTimeConverter } from '@web/services/umbraco/converters/datetime.converter';
import { FormPickerConverter } from '@web/services/umbraco/converters/form-picker.converter';
import { LimitedCheckboxListConverter } from '@web/services/umbraco/converters/limitedCheckboxList.converter';
import { MapConverter } from '@web/services/umbraco/converters/map.converter';
import { MediaConverter } from '@web/services/umbraco/converters/media.converter';
import { MultiUrlConverter } from '@web/services/umbraco/converters/multi-url.converter';
import { NumberConverter } from '@web/services/umbraco/converters/number.converter';
import { QBankConverter } from '@web/services/umbraco/converters/qbank.converter';
import { RichTextConverter } from '@web/services/umbraco/converters/richtext.converter';
import { SelectConverter } from '@web/services/umbraco/converters/select.converter';
import { SimpleTextConverter } from '@web/services/umbraco/converters/simpletext.converter';
import { TableConverter } from '@web/services/umbraco/converters/table.converter';
import { TagsConverter } from '@web/services/umbraco/converters/tags.converter';
import { ThemeConverter } from '@web/services/umbraco/converters/theme.converter';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';

export type BaseDocument = {
	id: number;
	url: string;
	name: string;
	updateDate: string;
	writerId: number | null;
	contentType: string;
	properties: Record<string, unknown>;
	children?: unknown[] | null;
};

export type InputDocument = {
	id?: number | null;
	url?: string | null;
	name?: string | null;
	updateDate?: string | null;
	writerId?: number | null;
	contentType?: {
		alias?: string | null;
	} | null;
	properties?: InputProperty[] | null;
	children?: (undefined | null | InputDocument)[] | undefined | null;
};

export function mapDocument<T extends BaseDocument>(pageData: InputDocument): T {
	return {
		id: pageData.id,
		url: pageData.url,
		name: pageData.name,
		updateDate: pageData.updateDate,
		writerId: pageData.writerId || null,
		contentType: pageData?.contentType?.alias,
		properties: mapProperties(pageData?.properties || []),
		children: (pageData?.children
			? pageData.children
				.filter((child) => !!child)
				.map((child) => mapDocument(child as InputDocument))
			: null
		),
	} as T;
}

type Property = {
	__typename?: string;
	editorAlias?: string;
	alias?: string | null;
	value?: unknown;
};

type InputProperty = Omit<Property, 'editorAlias'> & { editorAlias?: string | null } | null;
type OutputProperty<Prop> = { [alias: string]: Prop };

function mapProperties<Prop extends Property>(properties: InputProperty[]): OutputProperty<Prop> {
	return properties.reduce((collector, prop) => {
		if (prop === null || !prop.editorAlias || !prop.alias) {
			return collector;
		}

		const value = mapProp(prop as Prop);

		if (!value) {
			return collector;
		}

		const keyPath = prop.alias.replaceAll('_', '.');

		set(collector, keyPath, value);
		return collector;
	}, {} as { [alias: string]: Prop }) as { [alias: string]: Prop };
}

function mapProp<Prop extends Property>(property: Prop) {
	if (SimpleTextConverter.isPropertyType(property)) {
		return SimpleTextConverter.mapProperty(property);
	}

	if (NumberConverter.isPropertyType(property)) {
		return NumberConverter.mapProperty(property);
	}

	if (DateTimeConverter.isPropertyType(property)) {
		return DateTimeConverter.mapProperty(property);
	}

	if (CheckboxConverter.isPropertyType(property)) {
		return CheckboxConverter.mapProperty(property);
	}

	if (LimitedCheckboxListConverter.isPropertyType(property)) {
		return LimitedCheckboxListConverter.mapProperty(property);
	}

	if (SelectConverter.isPropertyType(property)) {
		return SelectConverter.mapProperty(property);
	}

	if (RichTextConverter.isPropertyType(property)) {
		return RichTextConverter.mapProperty(property);
	}

	if (MultiUrlConverter.isPropertyType(property)) {
		return MultiUrlConverter.mapProperty(property);
	}

	if (MediaConverter.isPropertyType(property)) {
		return MediaConverter.mapProperty(property);
	}

	if (QBankConverter.isPropertyType(property)) {
		return QBankConverter.mapProperty(property);
	}

	if (BlockListConverter.isPropertyType(property)) {
		return BlockListConverter.mapProperty(property);
	}

	if (ContentConverter.isPropertyType(property)) {
		return ContentConverter.mapProperty(property);
	}

	if (TagsConverter.isPropertyType(property)) {
		return TagsConverter.mapProperty(property);
	}

	if (MapConverter.isPropertyType(property)) {
		return MapConverter.mapProperty(property);
	}

	if (FormPickerConverter.isPropertyType(property)) {
		return FormPickerConverter.mapProperty(property);
	}

	if (TableConverter.isPropertyType(property)) {
		return TableConverter.mapProperty(property);
	}

	if (BreadcrumbsConverter.isPropertyType(property)) {
		return BreadcrumbsConverter.mapProperty(property);
	}

	if (ThemeConverter.isPropertyType(property)) {
		return ThemeConverter.mapProperty(property);
	}

	if (BonsaiPickerConverter.isPropertyType(property)) {
		return BonsaiPickerConverter.mapProperty(property);
	}

	console.warn('unkown property type:', property);

	if (BaseConverter.isPropertyType(property)) {
		return BaseConverter.mapProperty(property);
	}

	return property;
}

export type UmbracoBlockList = BasicProperty & { editorAlias: 'Umbraco.BlockList' | 'Noa.CustomBlockList'; value: CustomBlockListModel };

export class BlockListConverter {
	static UmbracoType: UmbracoBlockList;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.BlockList' || input.editorAlias === 'Noa.CustomBlockList';
	}

	public static mapProperty(prop: typeof this.UmbracoType): UmbracoBlock<string> | UmbracoBlock<string>[] | undefined {
		if (prop.value.isMultiPicker === false) {
			return prop.value.blocks?.[0]
				? this.convertValue(prop.value.blocks?.[0])
				: undefined;
		}

		return (prop.value.blocks || [])
			.map((block) => this.convertValue(block));
	}

	public static convertValue(block: CustomBlockListItem): UmbracoBlock<string> {
		return {
			...block,
			contentAlias: block.contentAlias as string,
			contentProperties: mapProperties((block.contentProperties || []) as InputProperty[]),
			settingsProperties: mapProperties((block.settingsProperties || []) as InputProperty[]),
		};
	}
}

type UmbracoContentPicker = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Umbraco.MultiNodeTreePicker';
	value: CustomContentPicker;
};

export class ContentConverter {
	static UmbracoType: UmbracoContentPicker;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.MultiNodeTreePicker';
	}

	public static mapProperty(prop: typeof this.UmbracoType): undefined | BaseDocument | BaseDocument[] {
		if (prop.value.multiPicker) {
			return (prop.value.contentList || [])
				.filter((e) => !!e)
				.map((e) => mapDocument(e as CustomContentPickerItem));
		}

		if (!prop.value.contentList?.[0]) {
			return undefined;
		}

		return mapDocument(prop.value.contentList?.[0]);
	}
}
