import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import { ThemeDefinition } from '@web/services/umbraco/types/basic/Color';

import { Theme } from '@apps/web/src/components/theme/theme';
import { UmbracoBlock } from '@apps/web/src/services/umbraco/types/basic/Block';

import styles from './basic-divider-block.module.scss';

const blockName = 'dividerBlock' as const;

export interface BasicDividerBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{},
		{
			colorTheme: ThemeDefinition;
		}
	>;
}

export function BasicDividerBlock({ block }: BasicDividerBlockProps) {
	const { colorTheme } = block.settingsProperties;

	return (
		<Theme className={cn(styles.theme, { [styles.theme__color]: hasColorPallette(colorTheme?.theme) })} themeDef={colorTheme}>
			<Container>
				<hr className={styles.line} />
			</Container>
		</Theme>
	);
}

BasicDividerBlock.blockName = blockName;
