import { AspectRatio } from '@apps/web/src/components/media/aspectRatios';
import { FocalPoint, ImageCropperCropCoordinates, UmbracoImageType } from '@apps/web/src/services/umbraco/types/basic/Media';

enum Mode {
	Focal = 'Focal',
	Crop = 'Crop',
	Scale = 'Scale'
}

enum Source {
	Local = 'Local',
	Global = 'Global'
}

type FocalDefinition = {
	mode: Mode.Focal;
	source: Source;
};

type CropDefinition = {
	mode: Mode.Crop;
	source: Source;
	crop?: string;
};

type ScaleDefinition = {
	mode: Mode.Scale;
	aspectRatio?: AspectRatio;
};

type Definition = FocalDefinition | CropDefinition | ScaleDefinition;

const pipeline: Definition[] = [
	{
		mode: Mode.Crop,
		source: Source.Local,
	},
	{
		mode: Mode.Crop,
		source: Source.Global,
	},
	{
		mode: Mode.Focal,
		source: Source.Local,
	},
	{
		mode: Mode.Focal,
		source: Source.Global,
	},
	{
		mode: Mode.Scale,
	},
];

export type ImageBuilderOptions = {
	pipeline?: Definition[];

	quality?: number;

	width?: number;
	forceWidth?: number;
	forceHeight?: number;

	/** Fallback Crop alias, if none is defined in pipeline (default behavior) */
	crop?: string;

	/** Fallback Aspect ratio, if none is defined in pipeline (default behavior) */
	aspectRatio?: AspectRatio;
};

export function getImageUrl(image: UmbracoImageType, options: ImageBuilderOptions = { pipeline }) {
	const queryParams = new URLSearchParams();
	const finalWidth = options.forceWidth || options.width || image.width;

	if (options.quality) {
		queryParams.append(...getQuality(options.quality));
	}

	for (const definition of (options.pipeline || pipeline)) {
		if (definition.mode === Mode.Scale) {
			const aspectRatio = definition.aspectRatio || options.aspectRatio;

			if (aspectRatio) {
				const height = options.forceHeight ? options.forceHeight : Math.floor(finalWidth / aspectRatio);
				queryParams.append(...getHeight(height));
				break;
			}
		}

		if (definition.mode === Mode.Focal) {
			if (definition.source === Source.Global && image.focalPoint) {
				queryParams.append(...getFocalPoint(image.focalPoint));

				const aspectRatio = options.aspectRatio;
				if (aspectRatio) {
					const height = options.forceHeight ? options.forceHeight : Math.floor(finalWidth / aspectRatio);
					queryParams.append(...getHeight(height));
				}

				break;
			}

			if (definition.source === Source.Local && image.localFocalPoint) {
				queryParams.append(...getFocalPoint(image.localFocalPoint));

				const aspectRatio = options.aspectRatio;
				if (aspectRatio) {
					const height = options.forceHeight ? options.forceHeight : Math.floor(finalWidth / aspectRatio);
					queryParams.append(...getHeight(height));
				}

				break;
			}
		}

		if (definition.mode === Mode.Crop) {
			const cropAlias = definition.crop || options.crop;
			const globalCrop = image.crops?.find((e) => e.alias === cropAlias);
			if (definition.source === Source.Global && globalCrop?.coordinates) {
				queryParams.append(...getCoordinates(globalCrop.coordinates));
				queryParams.append(...getCropHeight(finalWidth, image, globalCrop.coordinates));
				queryParams.append(...getResizeMode(RMODE.min));
				break;
			}

			const localCrop = image.localCrops?.find((e) => e.alias === cropAlias);
			if (definition.source === Source.Local && localCrop?.coordinates) {
				queryParams.append(...getCoordinates(localCrop.coordinates));
				queryParams.append(...getCropHeight(finalWidth, image, localCrop.coordinates));
				queryParams.append(...getResizeMode(RMODE.min));
				break;
			}
		}
	}

	if (finalWidth) {
		queryParams.append(...getWidth(finalWidth));
	}

	if (options.forceHeight) {
		queryParams.append(...getHeight(options.forceHeight));
	}

	return queryParams;
}

export function buildUrl(src: string, queryParams: URLSearchParams) {
	const queryString = queryParams.toString();

	if (queryString) {
		return `${src}?${queryString}`;
	}

	return src;
}

enum RMODE {
	/** Resizes the image until the shortest side reaches the set given dimension. Upscaling is disabled in this mode and the original image will be returned if attempted. */
	min = 'min',

	/** Constrains the resized image to fit the bounds of its container maintaining the original aspect ratio. */
	max = 'max',

	/** Pads the resized image to fit the bounds of its container. If only one dimension is passed, will maintain the original aspect ratio. */
	pad = 'pad',

	/** Pads the image to fit the bound of the container without resizing the original source. When downscaling, performs the same functionality as */
	BoxPad = 'boxpad',

	/** Stretches the resized image to fit the bounds of its container. */
	stretch = 'stretch',

	/** Crops the resized image to fit the bounds of its container. */
	Crop = 'crop',
}

function getResizeMode(mode: RMODE) {
	return ['rmode', `${mode}`] as const;
}

function getQuality(quality: number) {
	return ['quality', `${quality}`] as const;
}

function getWidth(width: number) {
	return ['width', `${width}`] as const;
}

function getHeight(height: number) {
	return ['height', `${height}`] as const;
}

function getCoordinates({ x1, x2, y1, y2 }: ImageCropperCropCoordinates) {
	const cc = `${x1},${y1},${x2},${y2}` as const;

	return ['cc', cc] as const;
}

function getCropHeight(width: number, image: { width: number; height: number }, { y1, y2, x1, x2 }: ImageCropperCropCoordinates) {
	const newHeight = (image.height) * (1 - (y1 + y2));
	const newWidth = (image.width) * (1 - (x1 + x2));
	const scaling = width / newWidth;

	return getHeight(Math.round(newHeight * scaling));
}

function getFocalPoint({ top, left }: FocalPoint) {
	const cc = `${left},${top}` as const;

	return ['rxy', cc] as const;
}
