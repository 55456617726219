import { useSession } from 'next-auth/react';

import { getPallette } from '@dansk-metal/theme';
import { Button } from '@dansk-metal/ui';

import { useHeaderContext } from '@web/components/header/context';
import { NavigationHorizontalScroll } from '@web/components/navigation/navigation-horizontal-scroll/navigation-horizontal-scroll';
import { Theme } from '@web/components/theme/theme';

import { useGetUser } from '@web/services/crm-api/hooks';
import { CRMUserType } from '@web/services/crm-api/token';
import { isUserType } from '@web/services/crm-api/user-type.guard';
import { crmError } from '@web/services/errorhandler/appError';

import { UserMemberPageData } from '@web/templates/user-member-page';

import { useSingleSignOut } from '@web/hooks/use-single-sign-out';

export interface HeroMemberProps {
	userMemberPage: UserMemberPageData;
}

export function HeroMember({ userMemberPage }: HeroMemberProps) {
	const { menu, unionRepOverviewPage } = userMemberPage.properties;
	const { subnavColor } = useHeaderContext();
	const { data: session, status } = useSession();
	const { singleSignOut: logOut } = useSingleSignOut();
	const { data, error } = useGetUser();

	if (status !== 'authenticated') {
		return null;
	}

	if (error) {
		throw crmError({ cause: error });
	}

	const showUnionRepoverviewPage = (isUserType(session, CRMUserType.EmployedAtDM)
		|| data?.flags?.isTillidsRepresentant
		|| data?.flags?.isArbejdsmarkedRepresentant
	) && unionRepOverviewPage;

	return (
		<Theme palette={getPallette(subnavColor)}>
			<NavigationHorizontalScroll
				title={(
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div>
							{data?.firstName} {data?.lastName}
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
							<Button
								title='Log ud'
								style={{ padding: 0, fontSize: '12px' }}
								variant='inline'
								onClick={() => logOut({ callbackUrl: '/' })}
							>
								Log ud
							</Button>
						</div>
					</div>
				)}
				menu={showUnionRepoverviewPage ? [...menu, unionRepOverviewPage] : menu}
			/>
		</Theme>
	);
}
