import React, { createContext, useContext } from 'react';

import { isRouteAncestorMenu } from '@web/components/ancestor-menu/ancestor.helper';

import { useGlobalContext } from '@web/layouts/page/context';

import { PageContext } from '@web/services/umbraco/fetchers/page-context';
import { BackgroundColor } from '@web/services/umbraco/types/basic/Color';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';
import { HeroBlockType } from '@web/services/umbraco/types/elements/Hero';

interface HeaderContextType {
	currentMenu: string | null;
	setCurrentMenu: (icon: string | null) => void;
	subnavColor: BackgroundColor;
}

export const HeaderContext = createContext<HeaderContextType>({} as HeaderContextType);

/**
 * @see {@link HeaderContextProps}
 */
export function useHeaderContext() {
	return useContext(HeaderContext);
}

interface HeaderContextProps {
	value: {
		currentMenu: string | null;
		setCurrentMenu: (icon: string | null) => void;
	};
	children: React.ReactNode;
}

export function HeaderContextProvider({ value: { currentMenu, setCurrentMenu }, children }: HeaderContextProps) {
	const { routeData, pageContext } = useGlobalContext();

	const context: HeaderContextType = {
		currentMenu,
		setCurrentMenu,
		subnavColor: getSubnavColor({ routeData, pageContext }),
	};

	return <HeaderContext.Provider value={context}>{children}</HeaderContext.Provider>;
}

function getSubnavColor({ routeData, pageContext }: { routeData?: UmbracoPage; pageContext?: PageContext }): BackgroundColor {
	if (pageContext?.ancestor || (isRouteAncestorMenu(routeData))) {
		return BackgroundColor.Darkblue;
	}

	if (routeData && (routeData?.properties?.hero as HeroBlockType)?.contentProperties?.heroBackground) {
		return (routeData.properties.hero as HeroBlockType).contentProperties.heroBackground as BackgroundColor;
	}

	return BackgroundColor.White;
}
