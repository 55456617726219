import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

import Config from '@web/config';

export const AXIOS_INSTANCE = Axios.create({ baseURL: Config.UMBRACO_URL });

export interface CancellablePromise<T> extends Promise<T> {
	cancel: () => void;
}

// Add a second `options` argument here if you want to pass extra options to each generated query
export const umbracoAxios = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const source = Axios.CancelToken.source();

	const promise = AXIOS_INSTANCE({
		...config,
		...options,
		cancelToken: source.token,
	}).then(({ data }) => data) as CancellablePromise<T>;

	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};

// Override the return error for React Query
export type ErrorType<Error> = AxiosError<Error>;
