
import { Button, ButtonProps } from '@dansk-metal/ui';

import styles from './contact-call-confirmation.module.scss';

type ContactCallConfirmationProps = {
	onClose: ButtonProps['onClick'];
};

export function ContactCallConfirmation({ onClose }: ContactCallConfirmationProps) {
	return (
		<div className={styles.container}>
			<div className={styles.head}>
				<h2>Tak for din anmodning</h2>
			</div>

			<div className={styles.block}>
				<p>
					Tak for din anmodning. Vi kontakter dig på det ønskede tidspunkt.
				</p>
			</div>

			<div className={styles.block}>
				<div>
					<div className={styles.button}>
						<Button icon={{ icon: 'arrow-right', size: 24 }} onClick={onClose}>
							Luk
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
