import cn from 'classnames';

import { getTestAttr } from '@dansk-metal/service/testing';
import { hasColorPallette } from '@dansk-metal/theme';
import { Button, Container } from '@dansk-metal/ui';

import { MembershipLists } from '@web/blocks/memberships-overview-block/components/memberships-lists';
import { blockName, Membership } from '@web/blocks/memberships-overview-block/types';

import { Richtext } from '@web/components/richtext/richtext';
import { TabBar } from '@web/components/tab-bar/tab-bar';
import { useQueryParamsTabBar } from '@web/components/tab-bar/use-tab-bar.hook';
import { Theme } from '@web/components/theme/theme';

import { trackEventSync } from '@web/services/tracking';
import { Action, Context } from '@web/services/tracking/config';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ThemeDefinition } from '@web/services/umbraco/types/basic/Color';

import styles from './memberships-overview-block.module.scss';

export type MembershipsOverviewBlockProps = {
	block: UmbracoBlock<
		typeof blockName,
		{
			memberships: Membership[];
		},
		{
			colorTheme?: ThemeDefinition;
		}
	>;
};

export function MembershipsOverviewBlock({ block, block: { settingsProperties } }: MembershipsOverviewBlockProps) {
	const { memberships } = block.contentProperties;
	const tabNames = memberships.map((membership) => membership.properties.title);
	const { colorTheme } = settingsProperties || {};
	const { activeTab, setActiveTab } = useQueryParamsTabBar({ tabs: tabNames, queryParam: 'tab' });
	const activeMembership = memberships.find((membership) => activeTab === membership.properties.title);

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme?.theme),
			})}
		>
			<Container className={styles.content} {...getTestAttr('membership__content')}>
				<div className={styles.tab_wrapper}>
					<TabBar activeTab={activeTab} tabs={tabNames} setActiveTab={setActiveTab} />
				</div>
				{activeMembership && (
					<Container className={styles.content__container}>
						<div className={styles.info}>
							{activeMembership.properties.title && <h2 className="h3">{activeMembership.properties.title}</h2>}
							{activeMembership.properties.description && (
								<Richtext
									className={styles.desc}
									content={activeMembership.properties.description}
								/>
							)}
							{activeMembership.properties.price && <p className={cn(styles.price, 'h2')}>{activeMembership.properties.price}*</p>}
							{activeMembership.properties.link && (
								<Button
									className={styles.cta}
									href={activeMembership.properties.link.url}
									icon={{ icon: 'arrow-right', size: 24 }}
									target={activeMembership.properties.link.target}
									onClick={() => trackEventSync({
										event: [Context.Membership, Action.Register],
										options: { membership: activeMembership.properties.title },
									})}
								>
									{activeMembership.properties.link.name}
								</Button>
							)}
							{activeMembership.properties.publicPriceDescription && (
								<Richtext
									className={styles.price_desc}
									content={activeMembership.properties.publicPriceDescription}
								/>
							)}
						</div>
						{!!activeMembership.properties.lists &&
							<MembershipLists membershipLists={activeMembership.properties.lists} />
						}
					</Container>
				)}
			</Container>
		</Theme>
	);
}

MembershipsOverviewBlock.blockName = blockName;
