import { useState } from 'react';
import Image from 'next/image';

import { Icon } from '@dansk-metal/ui';

import { EmployeeDto } from '@web/services/crm-api/rest';

import { getPhoneNumber } from '@web/utils/format-number';

import placeholderImage from '../../../../public/assets/placeholder.png';

import styles from './phonebook-person-card.module.scss';

interface EmployeeCardProps {
	employee: EmployeeDto;
}

export function EmployeeCard({ employee }: EmployeeCardProps) {
	const [errorLoadingImage, setImageLoadError] = useState(false);

	return (
		<div className={styles.person_card_wrapper}>
			{employee && ((employee?.imageUrl && !errorLoadingImage) ? (
				<div className={styles.person_image_wrapper}>
					<Image
						className={styles.person_image}
						src={employee.imageUrl}
						width={737}
						height={737}
						loader={() => employee.imageUrl as string}
						unoptimized={true}
						alt={`Billedet af ${employee.name}`}
						onError={() => setImageLoadError(true)}
					/>
				</div>
			) : (
				<div className={styles.person_image_wrapper}>
					<Image
						className={styles.person_image}
						src={placeholderImage}
						width={737}
						height={737}
						loader={() => ''}
						unoptimized={true}
						alt="placeholder image"
					/>
				</div>
			))}
			<div className={styles.person_info}>
				<div className={styles.person_details}>
					<h3>{employee.name}</h3>
					<p>{employee.jobTitle}</p>
					<p>{employee.departmentName}</p>
				</div>
				<div className={styles.person_contacts}>
					{employee.phoneNumber && (
						<div>
							<Icon className={styles.person_contacts__icon} size={24} icon='phone'/>
							<a href={`tel:${employee.phoneNumber}`}>{getPhoneNumber(employee.phoneNumber)}</a>
						</div>
					)}
					{employee.email && (
						<div>
							<Icon className={styles.person_contacts__icon} size={24} icon='mail'/>
							<a href={`mailto:${employee.email}`}>{employee.email}</a>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
