

import { LinkButton } from '@web/components/link-button/link-button';

import { LinkItemBlock } from '@web/services/umbraco/types/elements/Action';

export interface LinkItemButtonProps {
	action: LinkItemBlock;
	className?: string;
}

export function LinkItemButton({ action, className = '' }: LinkItemButtonProps) {
	const { link, style: linkStyle } = action.contentProperties;

	if (!link) {
		// This might happen if the link is set to an unpublished document in Umbraco
		console.warn('LinkItemButton: No link found', action);
		return null;
	}

	return (
		<LinkButton link={link} type={linkStyle} className={className} />
	);
}
