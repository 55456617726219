import { getContentPreview } from '@web/services/umbraco/types/documents/ContentPreview';

import { MosaicCard, MosaicSection } from '../types';

export function resolveMosaicSectionReferences(sections: MosaicSection[]): MosaicSection[] {
	return sections.map((section) => {
		return {
			...section,
			contentProperties: {
				...section.contentProperties,
				cards: section.contentProperties.cards.map((card) => {
					const resolvedRef = card.contentProperties?.reference?.contentType
						? getContentPreview(card.contentProperties.reference)
						: null;

					return {
						...card,
						contentProperties: {
							...card.contentProperties,
							title: card.contentProperties.title || resolvedRef?.title || '',
							subject: card.contentProperties.subject || '',
						},
						contentAlias: card.contentAlias,
					} as MosaicCard;
				}),
			},
		};
	});
}
