import { useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';

import { useHeaderContext } from '@web/components/header/context';

import { useGlobalContext } from '@web/layouts/page/context';

import { NavigationTextItem } from '@apps/web/src/components/navigation/navigation-text-item/navigation-text-item';

import dropdownStyles from '../../dropdown-menu/dropdown-menu.module.scss';
import styles from './menu-view.module.scss';

const MenuSubjects = ({ subjects, showAll }) => {
	if (!subjects) {
		return null;
	}

	return subjects.map((subject, i) => (
		<ul
			key={subject.id}
			className={cn(dropdownStyles.item, {
				[dropdownStyles.hidden]: i >= 4 && !showAll,
			})}
		>
			<li>
				<NavigationTextItem
					className={dropdownStyles.item__title}
					text={subject.name}
					icon={'arrow-right'}
					iconSize={16}
					link={subject.url}
					reverse
				/>
			</li>
		</ul>
	));
};

export const MenuView = () => {
	const { rootData } = useGlobalContext();
	const { setCurrentMenu } = useHeaderContext();
	const [showAll, setShowAll] = useState<boolean>(false);
	const subjects = rootData?.subjects;
	const primaryMenu = rootData?.properties?.header?.primaryMenu;
	const secondaryMenu = rootData?.properties?.header?.secondaryMenu;

	const onSeeAllClick = (e) => {
		e.stopPropagation();
		setShowAll(true);
	};

	return (
		<div className={styles.menu_view}>
			<div className={styles.subjects}>
				<NavigationTextItem
					className={dropdownStyles.title}
					text="Få svar"
					icon="chevron-up"
					resetMenuOnClick={false}
				/>
				<MenuSubjects subjects={subjects?.[0].children} showAll={showAll} />
				{!showAll && (
					<button onClick={onSeeAllClick} className={dropdownStyles.show_all}>
						Se alle emner
					</button>
				)}
			</div>
			{primaryMenu && (
				<ul className={styles.primary}>
					{primaryMenu.map((nav) => (
						<li key={nav.key}>
							<Link href={nav.url} onClick={() => setCurrentMenu(null)}>
								{nav.name}
							</Link>
						</li>
					))}
				</ul>
			)}
			{secondaryMenu && (
				<ul className={styles.info}>
					{secondaryMenu.map((item) => (
						<li key={item.key}>
							<Link href={item.url} onClick={() => setCurrentMenu(null)} style={{ textDecoration: 'none' }}>
								<p key={item.name}>{item.name}</p>
							</Link>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};
