import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjsUtc from 'dayjs/plugin/utc';

import 'dayjs/locale/da';

/**
 * DayJS with UTC support
 * The purpose is to get i18n for date string
 */
dayjs.locale('da');
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.updateLocale('da', {
	monthsShort: [
		'jan', 'feb', 'mar', 'apr', 'maj', 'jun',
		'jul', 'aug', 'sep', 'okt', 'nov', 'dec',
	],
});
dayjs.extend(dayjsUtc);
dayjs.extend(timezone);
dayjs.extend(isBetween);


dayjs.tz.setDefault('CET');

export const locale = dayjs.locale();
export const dayJS = dayjs;
