import { useState } from 'react';
import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { useForm, yup, yupResolver } from '@dansk-metal/ui';

import { SelectValueType } from '@web/blocks/apprentice-salary-calculator-block/types';
import { SalaryCalculatorForm } from '@web/blocks/salary-calculator-block/components/salary-calculator-form';
import { SalaryCalculatorResults } from '@web/blocks/salary-calculator-block/components/salary-calculator-results';

import { Theme } from '@web/components/theme/theme';

import { validationMessages } from '@web/constants/messages/validation-messages';

import { trackEventSync } from '@web/services/tracking';
import { Action, Context } from '@web/services/tracking/config';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ThemeDefinition } from '@web/services/umbraco/types/basic/Color';

import { Richtext } from '@apps/web/src/components/richtext/richtext';

import styles from './salary-calculator-block.module.scss';

const blockName = 'salaryCalculator' as const;


const schema = yup.object({
	region: yup
		.object<SelectValueType>({
			value: yup.string().required(),
			label: yup.string().required(),
		})
		.test('has_value', validationMessages.region.empty, (value: SelectValueType) => !!value.value),
	profession: yup
		.object<SelectValueType>({
			value: yup.string().required(),
			label: yup.string().required(),
		})
		.test('has_lable', validationMessages.profession.empty, (value: SelectValueType) => !!value.label)
		.test('has_value', validationMessages.profession.error, (value: SelectValueType) => !!value.value),
}).required();

export interface FormValues {
	profession: SelectValueType;
	region: SelectValueType;
}

export interface SalaryCalculatorBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: string;
			subtitle: string;
		},
		{
			colorTheme?: ThemeDefinition;
		}
	>;
}

export function SalaryCalculatorBlock({ block }: SalaryCalculatorBlockProps) {
	const { settingsProperties = {} } = block;
	const { colorTheme } = settingsProperties || {};
	const { title, subtitle } = block.contentProperties || {};
	const [selectedRegion, setSelectedRegion] = useState<SelectValueType>();
	const [selectedProfession, setSelectedProfession] = useState<SelectValueType>();

	const { ...form } = useForm<FormValues, object>({
		defaultValues: {
			profession: undefined,
			region: undefined,
		},
		resolver: yupResolver(schema),
	});

	const [showResult, setShowResult] = useState(false);

	const handleSubmit = async () => {
		const formValues = form.getValues();
		const { profession, region } = formValues;
		setSelectedRegion(region);
		setSelectedProfession(profession);
		setShowResult(true);
		trackEventSync({ event: [Context.ApprenticeSalaryCalculator, Action.Complete] });
	};

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme?.theme),
			})}
		>
			<div className={styles.container}>
				<div className={cn(styles.content)}>
					<div className={styles.header}>
						<h2>{title}</h2>
						<Richtext content={subtitle} />
					</div>
					{!showResult ?
						<SalaryCalculatorForm
							setValue={form.setValue}
							control={form.control}
							formHandleSubmit={form.handleSubmit}
							formSubmitHandler={handleSubmit}
						/> :
						<SalaryCalculatorResults
							handleReturnToForm={setShowResult}
							selectedRegion={selectedRegion}
							selectedProfession={selectedProfession}
						/>
					}
				</div>
			</div>
		</Theme>
	);
}

SalaryCalculatorBlock.blockName = blockName;
