import { BasicProperty, CustomMultiUrlPicker, CustomMultiUrlPickerItem } from '@apps/web/src/services/gql/graphql';
import { Link } from '@apps/web/src/services/umbraco/types/basic/Link';

export type UmbracoMultiUrlPicker = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Umbraco.MultiUrlPicker' | 'Noa.CustomLinkPicker';
	value: CustomMultiUrlPicker;
};

export class MultiUrlConverter {
	static UmbracoType: UmbracoMultiUrlPicker;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		if (input.editorAlias === 'Umbraco.MultiUrlPicker') {
			console.warn('Unexpected usage of Umbraco.MultiUrlPicker', input);
		}


		return input.editorAlias === 'Umbraco.MultiUrlPicker' || input.editorAlias === 'Noa.CustomLinkPicker';
	}

	public static mapProperty(prop: typeof this.UmbracoType): undefined | Link | Link[] {
		if (prop.value.isMultiPicker) {
			return (prop.value.links || [])
				.filter((e) => !!e)
				.map((e) => this.convertValue(e));
		}

		if (!prop.value.links?.[0]) {
			return undefined;
		}

		return this.convertValue(prop.value.links?.[0]);
	}

	private static convertValue(link: CustomMultiUrlPickerItem): Link {
		if (!link.key) {
			console.warn('No key was set for link', link);
		}

		return {
			key: link.key || null,
			type: link.type,
			name: link.overrideTitle || link.name || '',
			url: link.url || '',
			target: (link.target as undefined | '_blank'),
		};
	}
}
