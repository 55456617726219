import { useState } from 'react';

export interface WizardProps<Page> {
	pages: Page[];
}

export function useWizard<Page = unknown>({ pages }: WizardProps<Page>) {
	const [activePage, setActivePage] = useState(0);

	function goTo(index: number) {
		if (index < 0) {
			return setActivePage(0);
		}

		if (index > (pages.length - 1)) {
			return setActivePage(pages.length - 1);
		}

		setActivePage(index);
	}

	function next() {
		goTo(activePage + 1);
	}

	function previous() {
		goTo(activePage - 1);
	}

	return {
		activePage,
		goTo,
		next,
		previous,
	};
}
