import type { NextPage } from 'next';

import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';

import { BlockType } from '@apps/web/src/blocks';
import { ContentBlock } from '@apps/web/src/components/block-list/content-block';
import { HeroDepartment } from '@apps/web/src/components/hero-department/hero-department';
import TemplateLayout from '@apps/web/src/layouts/template/template';
import { Link } from '@apps/web/src/services/umbraco/types/basic/Link';
import { HTMLString } from '@apps/web/src/services/umbraco/types/basic/RichTextContent';
import { UmbracoPage } from '@apps/web/src/services/umbraco/types/documents/UmbracoDocument';

export const contentAlias = 'departmentPage' as const;

export type DepartmentPageData = UmbracoPage<
	typeof contentAlias,
	{
		title: HTMLString;
		menu: Link[];
		heroImage: QBankImageType;
		phone: string;
		email: string;
		contactPageLink: Link;
		blocks: BlockType[];
	}
>;

const DepartmentPageTemplate: NextPage<{ pageData: DepartmentPageData }> = ({ pageData }) => {
	return (
		<TemplateLayout>
			<HeroDepartment
				image={pageData.properties.heroImage}
				phone={pageData.properties.phone}
				email={pageData.properties.email}
				contactPageLink={pageData.properties.contactPageLink}
			/>
			<ContentBlock blocks={pageData.properties.blocks} />
		</TemplateLayout>
	);
};

export default DepartmentPageTemplate;
