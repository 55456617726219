/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Default API
 * All endpoints not defined under specific APIs
 * OpenAPI spec version: Latest
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { umbracoAxios } from '../axios';
import type { GetApiQBankBehindLoginDownloadParams } from '../umbraco.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

export const getApiQBankBehindLoginDownload = (
	params: GetApiQBankBehindLoginDownloadParams,
	options?: SecondParameter<typeof umbracoAxios>,
	signal?: AbortSignal,
) => {
	return umbracoAxios<void>({ url: '/api/qBankBehindLogin/Download', method: 'get', params, signal }, options);
};

export const getGetApiQBankBehindLoginDownloadQueryKey = (params: GetApiQBankBehindLoginDownloadParams) =>
	['/api/qBankBehindLogin/Download', ...(params ? [params] : [])] as const;

export const getGetApiQBankBehindLoginDownloadQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiQBankBehindLoginDownload>>,
	TError = ErrorType<unknown>,
>(
	params: GetApiQBankBehindLoginDownloadParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiQBankBehindLoginDownload>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getApiQBankBehindLoginDownload>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiQBankBehindLoginDownloadQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiQBankBehindLoginDownload>>> = ({ signal }) =>
		getApiQBankBehindLoginDownload(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetApiQBankBehindLoginDownloadQueryResult = NonNullable<
	Awaited<ReturnType<typeof getApiQBankBehindLoginDownload>>
>;
export type GetApiQBankBehindLoginDownloadQueryError = ErrorType<unknown>;

export const useGetApiQBankBehindLoginDownload = <
	TData = Awaited<ReturnType<typeof getApiQBankBehindLoginDownload>>,
	TError = ErrorType<unknown>,
>(
	params: GetApiQBankBehindLoginDownloadParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiQBankBehindLoginDownload>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiQBankBehindLoginDownloadQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
