import { useQuery, UseQueryOptions } from 'react-query';

import { getRaffleSuggestions, RaffleResult, searchRaffle } from '@web/services/raffle';

export function useRaffleSearch(opts?: UseQueryOptions<RaffleResult[], unknown, RaffleResult[]>, query?: string) {
	const hook = useQuery<RaffleResult[]>({
		...opts,
		queryKey: ['raffle', 'search', query],
		queryFn: (val) => {
			return searchRaffle(val.queryKey[2] as string);
		},
		enabled: !!query,
	});

	return {
		...hook,
		query,
	};
}

export function useRaffleSuggestions(opts?: UseQueryOptions<string[] | null, unknown, string[] | null>, query?: string) {
	const hook = useQuery<string[] | null>({
		...opts,
		queryKey: ['raffle', 'suggestions', query],
		queryFn: (val) => {
			return getRaffleSuggestions(val.queryKey[2] as string);
		},
		enabled: !!query && query.length > 2,
	});

	return {
		...hook,
		query,
	};
}
