import { signIn, SignInOptions } from 'next-auth/react';

import { Button } from '@dansk-metal/ui';

import { trackEventSync } from '@web/services/tracking';
import { Action, Context } from '@web/services/tracking/config';

export interface LoginButtonProps extends Pick<SignInOptions, 'callbackUrl'> {
	label?: string;
}

export function LoginButton({ label = 'Log in', callbackUrl }: LoginButtonProps) {
	return (
		<Button
			variant={'fill-primary'}
			icon={{
				icon: 'lock',
				position: 'after',
			}}
			onClick={() => {
				signIn('signaturgruppen', { callbackUrl });
				trackEventSync({ event: [Context.MemberArea, Action.Signin] });
			}}
		>
			{label}
		</Button>
	);
}
