/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.6.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	CompanyDto,
	ErrorResult,
	GetCompanyByFilterParams,
	GetCompanyMembersParams,
	GetCompanyUnionRepresentativesParams,
	PagingResultOfCompanyDto,
	PagingResultOfCompanyMemberDto,
	PagingResultOfCompanyUnionRepresentativeDto,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Retrieves all companies.
Uses pagination through limit and offset
 */
export const getCompanyByFilter = (
	params?: GetCompanyByFilterParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfCompanyDto>({ url: '/api/companies', method: 'get', params, signal }, options);
};

export const getGetCompanyByFilterQueryKey = (params?: GetCompanyByFilterParams) =>
	['/api/companies', ...(params ? [params] : [])] as const;

export const getGetCompanyByFilterQueryOptions = <
	TData = Awaited<ReturnType<typeof getCompanyByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetCompanyByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getCompanyByFilter>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetCompanyByFilterQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyByFilter>>> = ({ signal }) =>
		getCompanyByFilter(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetCompanyByFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyByFilter>>>;
export type GetCompanyByFilterQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves all companies.
Uses pagination through limit and offset
 */
export const useGetCompanyByFilter = <
	TData = Awaited<ReturnType<typeof getCompanyByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetCompanyByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetCompanyByFilterQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves a specific company.
 */
export const getCompanyById = (companyId: string, options?: SecondParameter<typeof crmAxios>, signal?: AbortSignal) => {
	return crmAxios<CompanyDto>({ url: `/api/companies/${companyId}`, method: 'get', signal }, options);
};

export const getGetCompanyByIdQueryKey = (companyId: string) => [`/api/companies/${companyId}`] as const;

export const getGetCompanyByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getCompanyById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	companyId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getCompanyById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetCompanyByIdQueryKey(companyId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyById>>> = ({ signal }) =>
		getCompanyById(companyId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!companyId, staleTime: 10000, ...queryOptions };
};

export type GetCompanyByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyById>>>;
export type GetCompanyByIdQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves a specific company.
 */
export const useGetCompanyById = <
	TData = Awaited<ReturnType<typeof getCompanyById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	companyId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetCompanyByIdQueryOptions(companyId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Fetch all members of a specific company
 */
export const getCompanyMembers = (
	companyId: string,
	params?: GetCompanyMembersParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfCompanyMemberDto>(
		{ url: `/api/companies/${companyId}/members`, method: 'get', params, signal },
		options,
	);
};

export const getGetCompanyMembersQueryKey = (companyId: string, params?: GetCompanyMembersParams) =>
	[`/api/companies/${companyId}/members`, ...(params ? [params] : [])] as const;

export const getGetCompanyMembersQueryOptions = <
	TData = Awaited<ReturnType<typeof getCompanyMembers>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	companyId: string,
	params?: GetCompanyMembersParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyMembers>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getCompanyMembers>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetCompanyMembersQueryKey(companyId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyMembers>>> = ({ signal }) =>
		getCompanyMembers(companyId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!companyId, staleTime: 10000, ...queryOptions };
};

export type GetCompanyMembersQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyMembers>>>;
export type GetCompanyMembersQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Fetch all members of a specific company
 */
export const useGetCompanyMembers = <
	TData = Awaited<ReturnType<typeof getCompanyMembers>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	companyId: string,
	params?: GetCompanyMembersParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyMembers>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetCompanyMembersQueryOptions(companyId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Fetch all union representatives of a specific company
 */
export const getCompanyUnionRepresentatives = (
	companyId: string,
	params?: GetCompanyUnionRepresentativesParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfCompanyUnionRepresentativeDto>(
		{ url: `/api/companies/${companyId}/unionrepresentatives`, method: 'get', params, signal },
		options,
	);
};

export const getGetCompanyUnionRepresentativesQueryKey = (
	companyId: string,
	params?: GetCompanyUnionRepresentativesParams,
) => [`/api/companies/${companyId}/unionrepresentatives`, ...(params ? [params] : [])] as const;

export const getGetCompanyUnionRepresentativesQueryOptions = <
	TData = Awaited<ReturnType<typeof getCompanyUnionRepresentatives>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	companyId: string,
	params?: GetCompanyUnionRepresentativesParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyUnionRepresentatives>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getCompanyUnionRepresentatives>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetCompanyUnionRepresentativesQueryKey(companyId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyUnionRepresentatives>>> = ({ signal }) =>
		getCompanyUnionRepresentatives(companyId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!companyId, staleTime: 10000, ...queryOptions };
};

export type GetCompanyUnionRepresentativesQueryResult = NonNullable<
	Awaited<ReturnType<typeof getCompanyUnionRepresentatives>>
>;
export type GetCompanyUnionRepresentativesQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Fetch all union representatives of a specific company
 */
export const useGetCompanyUnionRepresentatives = <
	TData = Awaited<ReturnType<typeof getCompanyUnionRepresentatives>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	companyId: string,
	params?: GetCompanyUnionRepresentativesParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getCompanyUnionRepresentatives>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetCompanyUnionRepresentativesQueryOptions(companyId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
