export type	UmbracoMedia = UmbracoImageType | UmbracoVideoType | UmbracoFileType;

export enum MediaType {
	Image = 'umbracoImage',
	Video = 'umbracoMediaVideo',
	File = 'umbracoFile',
}

export type UmbracoImageType = {
	alias: 'umbracoImage';
	id: number;
	url: string;
	size: number;
	crops: ImageCropperCrop[];
	focalPoint: FocalPoint | null;
	localCrops?: ImageCropperCrop[];
	localFocalPoint: FocalPoint | null;
	height: number;
	width: number;
};

export type UmbracoVideoType = {
	alias: 'umbracoMediaVideo';
	id: number;
	url: string;
	size: number;
};

export type UmbracoFileType = {
	alias: 'umbracoFile';
	id: number;
	url: string;
	size: number;
	extension?: string;
};

export type ImageCropperCrop = {
	alias: string;
	coordinates: ImageCropperCropCoordinates | null;
	height: number;
	width: number;
};

export type ImageCropperCropCoordinates = {
	x1: number;
	x2: number;
	y1: number;
	y2: number;
};

export type FocalPoint = {
	/** Decimal */
	left: number;

	/** Decimal */
	top: number;
};

/** Represents a media item. */
export type CustomMediaPickerItem = {
	/** Type of media */
	alias?: string;
	/** Globally configured crops (image only) */
	crops?: ImageCropperCrop[];
	/** Height of media (image only) */
	height?: number;
	/** Gets the id of a media item. */
	id: number;
	/** Locally configured crops (image only) */
	localCrops?: Array<ImageCropperCrop>;
	/** Size of media in bytes */
	size: number;
	/** Gets the absolute url of a media item. */
	url: string;
	/** Size of media (image only) */
	width?: number;
};
