/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Default API
 * All endpoints not defined under specific APIs
 * OpenAPI spec version: Latest
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { umbracoAxios } from '../axios';
import type {
	GetApiSearchNewsParams,
	GetApiSearchPublicationsParams,
	NewsSearchResult,
	PublicationsSearchResult,
} from '../umbraco.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Returns a list of news under the parent route, containing the specified text with a chosen tag
 */
export const getApiSearchNews = (
	params?: GetApiSearchNewsParams,
	options?: SecondParameter<typeof umbracoAxios>,
	signal?: AbortSignal,
) => {
	return umbracoAxios<NewsSearchResult>({ url: '/api/search/news', method: 'get', params, signal }, options);
};

export const getGetApiSearchNewsQueryKey = (params?: GetApiSearchNewsParams) =>
	['/api/search/news', ...(params ? [params] : [])] as const;

export const getGetApiSearchNewsQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiSearchNews>>,
	TError = ErrorType<unknown>,
>(
	params?: GetApiSearchNewsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSearchNews>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getApiSearchNews>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiSearchNewsQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSearchNews>>> = ({ signal }) =>
		getApiSearchNews(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetApiSearchNewsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSearchNews>>>;
export type GetApiSearchNewsQueryError = ErrorType<unknown>;

/**
 * @summary Returns a list of news under the parent route, containing the specified text with a chosen tag
 */
export const useGetApiSearchNews = <TData = Awaited<ReturnType<typeof getApiSearchNews>>, TError = ErrorType<unknown>>(
	params?: GetApiSearchNewsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSearchNews>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiSearchNewsQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Returns a list of publications under the parent route, containing the specified text with a chosen type(tag)
 */
export const getApiSearchPublications = (
	params?: GetApiSearchPublicationsParams,
	options?: SecondParameter<typeof umbracoAxios>,
	signal?: AbortSignal,
) => {
	return umbracoAxios<PublicationsSearchResult>(
		{ url: '/api/search/publications', method: 'get', params, signal },
		options,
	);
};

export const getGetApiSearchPublicationsQueryKey = (params?: GetApiSearchPublicationsParams) =>
	['/api/search/publications', ...(params ? [params] : [])] as const;

export const getGetApiSearchPublicationsQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiSearchPublications>>,
	TError = ErrorType<unknown>,
>(
	params?: GetApiSearchPublicationsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSearchPublications>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getApiSearchPublications>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiSearchPublicationsQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSearchPublications>>> = ({ signal }) =>
		getApiSearchPublications(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetApiSearchPublicationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSearchPublications>>>;
export type GetApiSearchPublicationsQueryError = ErrorType<unknown>;

/**
 * @summary Returns a list of publications under the parent route, containing the specified text with a chosen type(tag)
 */
export const useGetApiSearchPublications = <
	TData = Awaited<ReturnType<typeof getApiSearchPublications>>,
	TError = ErrorType<unknown>,
>(
	params?: GetApiSearchPublicationsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSearchPublications>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiSearchPublicationsQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
