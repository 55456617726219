import { umbracoFetchError } from '@apps/web/src/services/errorhandler/appError';
import { BaseContentFragmentFragment, SubjectsQuery, SubjectsQueryVariables } from '@apps/web/src/services/gql/graphql';
import { urqlClient } from '@apps/web/src/services/umbraco/client';
import { MappedSubject, subjectsQuery } from '@apps/web/src/services/umbraco/queries/subjects.query';
import { getRootUrl } from '@apps/web/src/services/umbraco/route-mapping';
import { mapDocument } from '@apps/web/src/services/umbraco/type-mapping';
import { SlugMap } from '@apps/web/src/templates';

export async function fetchAllSubjects(slugMap: SlugMap) {
	const nodes = await fetchSubjectsRecursive(slugMap);
	const subjects = structureSubjects(nodes);

	return subjects;
}

async function fetchSubjectsRecursive(slugMap: SlugMap, cursor?: string): Promise<SubjectNode[]> {
	const subjectsVariables: SubjectsQueryVariables = {
		...slugMap.queryParams,
		rootSlug: getRootUrl(slugMap.queryParams.slug),
		cursor,
	};

	const response = await urqlClient.query<SubjectsQuery>(subjectsQuery, subjectsVariables).toPromise();

	if (response.error) {
		throw umbracoFetchError({
			cause: response.error,
			info: {
				graphQLVariables: subjectsVariables,
			},
		});
	}

	const data = response?.data?.contentDescendantsByAbsoluteRoute;
	const nodes = (data?.nodes as SubjectNode[]) || [];

	if (data?.pageInfo.hasNextPage && data.pageInfo.endCursor) {
		const subResponse = await fetchSubjectsRecursive(slugMap, data.pageInfo.endCursor);

		return [...nodes, ...subResponse];
	}

	return nodes;
}

type SubjectNode = BaseContentFragmentFragment & {
	level?: number;
	parent: { id: number };
};

export function structureSubjects(nodes: SubjectNode[]): MappedSubject[] {
	const nodeDict = new Map<number, MappedSubject>();

	return nodes.reduce((subjects, node) => {
		const newNode = mapDocument<MappedSubject>({ ...node, children: [] });
		nodeDict.set(newNode.id, newNode);

		const foundParent = nodeDict.get(node.parent?.id);

		if (foundParent) {
			foundParent.children.push(newNode);
		} else {
			subjects.push(newNode);
		}

		return subjects;
	}, [] as MappedSubject[]);
}
