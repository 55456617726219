import { ReactNode, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { type FallbackRender, ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import type { Param0 } from 'type-zoo';

import { ErrorFrame } from '@web/components/error-frame/error-frame';

import TemplateLayout from '@web/layouts/template/template';

import { UmbracoErrorType } from '@web/services/umbraco/types/basic/ErrorTypes';

type ErrorBoundaryProps = {
	children?: ReactNode;
};

export function ErrorBoundary({ children }: ErrorBoundaryProps) {
	const ref = useRef<SentryErrorBoundary>(null);
	const { asPath, query } = useRouter();
	const callbackUrl = Array.isArray(query.callbackUrl) ? query.callbackUrl[0] : query.callbackUrl;

	useEffect(() => {
		if (ref.current?.state.error) {
			ref.current?.resetErrorBoundary();
		}
	}, [asPath]);

	return (
		<SentryErrorBoundary
			ref={ref}
			fallback={(props) => <FallbackComponent {...props} callbackUrl={callbackUrl}/>}
		>
			{children}
		</SentryErrorBoundary>
	);
}

function FallbackComponent({ eventId, callbackUrl }: Param0<FallbackRender> & { callbackUrl?: string }) {
	return (
		<TemplateLayout>
			<ErrorFrame eventId={eventId} errorType={UmbracoErrorType.Generic} callbackUrl={callbackUrl} />
		</TemplateLayout>
	);
}
