export function getPhoneNumber(input: string) {
	const prefixRegex = /^(0045|\+45)/;
	const digitsOnly = input.replace(prefixRegex, '').replace(/\D/g, '');

	if (digitsOnly.length !== 8) {
		return digitsOnly.length < 8 ? '!Ikke nok numre i telefonnummer' : '!For mange numre i telefonnummer';
	}

	if (prefixRegex.test(input)) {
		return `+45 ${digitsOnly.slice(0, 4)} ${digitsOnly.slice(4)}`;
	}

	return `${digitsOnly.slice(0, 4)} ${digitsOnly.slice(4)}`;
}
