import { Fragment } from 'react';
import cn from 'classnames';

import styles from './definition-list.module.scss';

export interface DefinitionListProps {
	data: {
		key: string;
		value: string | string[];
	}[];
	className?: string;
}

export default function DefinitionList({ data, className }: DefinitionListProps) {
	return (
		<dl className={cn(styles.definition_list, className)}>
			{data.map(({ key, value }) => (
				<Fragment key={key}>
					<dd>{key}</dd>
					<dt>
						{Array.isArray(value)
							?
							value.map((item) => {
								return <>{item}<br/></>;
							})
							:
							value
						}
					</dt>
				</Fragment>
			))}
		</dl>
	);
}
