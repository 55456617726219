import cn from 'classnames';

import { Container } from '@dansk-metal/ui';
import { toTitleCase } from '@dansk-metal/utils/common';

import { TabBar } from '@web/components/tab-bar/tab-bar';
import { useQueryParamsTabBar } from '@web/components/tab-bar/use-tab-bar.hook';
import { Theme } from '@web/components/theme/theme';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { GMap } from '@web/services/umbraco/types/basic/GMap';

import styles from './offices-list-block.module.scss';

const blockName = 'officesListBlock' as const;

export interface OfficesListBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			offices: Office[];
		}
	>;
}

export type Office = UmbracoBlock<'office', {
	title: string;
	hours: string;
	map: GMap;
}>;

export function OfficesListBlock({ block }: OfficesListBlockProps) {
	const { offices } = block.contentProperties;
	const tabNames = offices.map((office) => office.contentProperties.title);
	const { activeTab, setActiveTab } = useQueryParamsTabBar({ tabs: tabNames, queryParam: 'tab' });

	return (
		<Theme className={styles.theme}>
			<Container className={styles.container}>
				<div className={styles.offices_list}>
					{offices.length > 1 && (
						<TabBar activeTab={activeTab} tabs={tabNames} setActiveTab={setActiveTab}/>
					)}

					<div className={styles.tab_content}>
						{offices.map(({ contentProperties: { title, hours, map } }, index) => (
							<div key={index} className={cn(styles.office, {
								[styles.active]: title === activeTab,
							})}
							>
								<div className={styles.map_container}>
									{map?.address && (
										<iframe
											title={`Map of ${title}`}
											width="100%"
											height="100%"
											style={{ border: 0 }}
											src={getGoogleMapsEmbeded(map)}
											allowFullScreen
										/>
									)}
								</div>
								<div className={styles.info}>
									<h2 className={styles.info_title}>{title}</h2>

									{map?.address && (
										<div className={styles.address}>
											<address>
												{`${toTitleCase(map.address.street)} ${map.address.streetNumber}`}<br />
												{`${map.address.postalCode} ${toTitleCase(map.address.city)}`}<br />
												{toTitleCase(map.address.country)}
											</address>
											<a href={getGoogleMapsLink(map.address.coordinates.latitude, map.address.coordinates.longitude)} target="_blank" rel="noreferrer">Find vej</a>
										</div>
									)}

									<div>
										<h3>Åbningstider</h3>
										<p className={styles.hours} dangerouslySetInnerHTML={{ __html: hours }} />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</Container>
		</Theme>
	);
}

OfficesListBlock.blockName = blockName;

function getGoogleMapsLink(lat: number, long: number): string {
	const link = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;

	return link;
}

function getGoogleMapsEmbeded(map: GMap) {
	return `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
		map.address.fullAddress,
	)}&key=${map.mapConfig.apiKey}&zoom=${map.mapConfig.zoom}`;
}
