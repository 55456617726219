import { slugify } from '@dansk-metal/utils/common';

import { getGuidFixture } from '@web/services/umbraco/__fixtures__/guid.fixture';
import { Link } from '@web/services/umbraco/types/basic/Link';

export const internalLinkFixture: Link = {
	key: getGuidFixture('link'),
	name: 'About us',
	url: '/about-us/',
	type: 'CONTENT',
};

export const externalLinkFixture: Link = {
	key: getGuidFixture('link'),
	name: 'Google',
	url: 'https://google.com',
	type: 'CONTENT',
	target: '_blank',
};

export function getLinkFixture(type: 'internal' | 'external', name: string, url?: string): Link {
	if (type === 'internal') {
		return {
			key: getGuidFixture('link'),
			name,
			url: url || `/${slugify(name)}`,
			type: 'CONTENT',
		};
	}

	return {
		key: getGuidFixture('link'),
		name,
		url: url || `https://example.com/${slugify(name)}}`,
		type: 'CONTENT',
		target: '_blank',
	};
}
