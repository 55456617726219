export type TestIds<Blocks extends string = string> =
	| 'desktop_menu'
	| 'header_search'
	| 'header_content'
	| 'form'
	| 'search_dialog'
	| 'form_demo'
	| 'form_newsletter'
	| 'form_contact'
	| 'footer'
	| 'footer-social-media-links'
	| 'header'
	| 'logo'
	| 'mobile_menu'
	| 'nested_navigation'
	| 'template_layout'
	| 'block_contentBlock'
	| 'news_contentSection'
	| 'campaign-footer'
	| 'hero-title'
	| 'content_drawer'
	| 'drawer_opener'
	| 'tab1'
	| 'tab2'
	| 'membership__content'
	| 'activeTab-0'
	| 'activeTab-1'
	| 'hero-full-width-image'
	| 'service-banner'
	| 'suggestion'
	| 'search-result'
	| `block_${Blocks}`;

export const getTestAttr = <Blocks extends string = string>(id: TestIds<Blocks>) => {
	return {
		'data-testid': id,
	};
};

export const getTestId = <Blocks extends string = string>(id: TestIds<Blocks>) => {
	return id;
};
