import { BasicProperty } from '@apps/web/src/services/gql/graphql';
import { GMap } from '@apps/web/src/services/umbraco/types/basic/GMap';

export type UmbracoGMaps = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Our.Umbraco.GMaps';
	value: {
		value: GMap;
	};
};

export class MapConverter {
	static UmbracoType: UmbracoGMaps;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Our.Umbraco.GMaps';
	}

	public static mapProperty(prop: typeof this.UmbracoType): GMap {
		return prop.value.value;
	}
}
