export interface MacroParameterProps {
	data: {
		enableinlinemacro: '0' | '1';
		macroalias: 'macroParameter';
		reference: `${number}`;
		reference_value: string;
	};
}

export function MacroParameter({ data }: MacroParameterProps) {
	return <span>{data['reference_value']}</span>;
}
