import { Controller, useForm } from 'react-hook-form';

import { Button, InputText, RadioGroup, yup, yupResolver } from '@dansk-metal/ui';
import { getContactTimeDate } from '@dansk-metal/utils/date';

import { FormHeader } from '@web/components/form-wrapper/form-wrapper';

import { validationMessages } from '@web/constants/messages/validation-messages';

import { useContactPostCallSubmission } from '@web/services/crm-api/rest';

import styles from './contact-call-form.module.scss';

const schema = yup.object().shape({
	telephoneNumber: yup.string().required(validationMessages.phone_number.empty).matches(/^[0-9+]+$/, validationMessages.phone_number.error)
		.max(20, validationMessages.phone_number.error)
		.min(6, validationMessages.phone_number.error),
	timeIntervals: yup.object().test('no-null-values', validationMessages.time_intervals.empty, (value) => {
		return Object.values(value).some((val) => val === true);
	}),
});

export type ContactCallFormProps = {
	onShowConfirmation: (showConfirmation: boolean) => void;
};

export function ContactCallForm({ onShowConfirmation }: ContactCallFormProps) {
	const { mutateAsync, isError, isLoading: isSubmitting } = useContactPostCallSubmission();

	const { control, handleSubmit, setValue, getValues, formState, reset, setError } = useForm({
		defaultValues: {
			telephoneNumber: '',
			timeIntervals: {
				timeInterval1: true,
				timeInterval2: false,
				timeInterval3: false,
			},
			fromDateTime: '',
			toDateTime: '',
		},
		resolver: yupResolver(schema),
		reValidateMode: 'onSubmit',
	});

	const timeIntervalOptions = [
		{ value: 'timeInterval1', label: 'Mellem kl 8 - 13' },
		{ value: 'timeInterval2', label: 'Mellem kl 13 - 16' },
		{ value: 'timeInterval3', label: 'Mellem kl 16 - 20' },
	];

	const timeIntervalMappings = {
		timeInterval1: { fromDateTime: '08:00', toDateTime: '13:00' },
		timeInterval2: { fromDateTime: '13:00', toDateTime: '16:00' },
		timeInterval3: { fromDateTime: '16:00', toDateTime: '20:00' },
	};

	async function onSubmit(data) {
		try {
			if (!data) {
				throw new Error('Contact data not ready');
			}

			const selectedInterval = Object.keys(data.timeIntervals).find((key) => data.timeIntervals[key]);

			if (selectedInterval) {
				const selectedTimeData = timeIntervalMappings[selectedInterval];

				await mutateAsync({
					data: {
						telephoneNumber: data.telephoneNumber.replaceAll(' ', ''),
						fromDateTime: getContactTimeDate(selectedTimeData.fromDateTime),
						toDateTime: getContactTimeDate(selectedTimeData.toDateTime),
					},
				});
			}

			reset({
				telephoneNumber: '',
				timeIntervals: {
					timeInterval1: false,
					timeInterval2: false,
					timeInterval3: false,
				},
				fromDateTime: '',
				toDateTime: '',
			});

			onShowConfirmation(true);
		} catch (err) {
			setError('root', {
				message: 'Der skete en fejl. Prøv igen senere.',
			});
		}
	}

	const handleIntervalChange = (e) => {
		const targetValue = e.target.value;
		const intervalValues = getValues('timeIntervals');
		const selectedInterval = timeIntervalMappings[targetValue];

		Object.keys(intervalValues).forEach((key) => {
			intervalValues[key] = key === targetValue;
		});
		setValue('timeIntervals', intervalValues);
		setValue('fromDateTime', selectedInterval.fromDateTime);
		setValue('toDateTime', selectedInterval.toDateTime);
	};

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<div>
				<h2 className={styles.head}>Bestil opringning</h2>
				<p>Vælg et tidspunkt som passer dig. Vi ringer dig op næste hverdag.</p>
			</div>
			<div className={styles.section}>
				<div className={styles.banner_wrapper}>
					{(formState.errors.telephoneNumber || formState.errors.timeIntervals ||	isError) && (
						<FormHeader type='error' message={'Der er fejl i et eller flere felter'} />
					)}
				</div>
				<div className={styles.input_text_container}>
					<div className={styles.input_text}>
						<Controller
							control={control}
							name="telephoneNumber"
							render={({ field, fieldState }) => (
								<InputText disabled={isSubmitting} label='Telefonnummer' fieldState={fieldState} {...field} noOptionalLabel />
							)}
						/>
					</div>
				</div>
				<div className={styles.radio_group_container}>
					<Controller
						control={control}
						name="timeIntervals"
						render={({ field, fieldState }) => (
							<RadioGroup
								disabled={isSubmitting}
								label='Hvornår vil du ringes op?'
								className={styles.radio_group}
								fieldState={fieldState}
								{...field}
								options={timeIntervalOptions}
								noOptionalLabel
								onChange={handleIntervalChange}
							/>
						)}
					/>
				</div>
			</div>
			<Button disabled={isSubmitting} icon={isSubmitting ? { icon: 'spinner' } : { icon: 'arrow-right' }} type='submit' className={styles.submit}>
				Bestil opringning
			</Button>
		</form>
	);
}
