import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { getTestAttr } from '@dansk-metal/service/testing';
import { Button, Container, Icon } from '@dansk-metal/ui';
import { MOTION_OUT, TRANSITION } from '@dansk-metal/utils/animations';

import { Richtext } from '@web/components/richtext/richtext';

import styles from './service-banner.module.scss';

export interface ServiceBannerProps {
	isOpen?: boolean;
	isClosable?: boolean;
	onClose?: () => void;
	message: string;
	bannerType?: 'error' | 'warning' | 'notice';
}

export function ServiceBanner({
	bannerType = 'notice',
	onClose = () => {},
	message,
	isClosable = false,
	isOpen = true,
}: ServiceBannerProps) {
	const bannerStyle = cn(styles.service_banner, styles[bannerType]);

	if (!bannerStyle) {
		console.warn('Missing bannerStyle:', bannerType);
		return null;
	}

	const event = new Event('serviceBannerChanged');

	const onUpdate = () => {
		window.dispatchEvent(event);
	};

	return (
		<AnimatePresence>
			{isOpen === true && (
				<motion.div
					{...MOTION_OUT['shrink']()}
					transition={TRANSITION.medium}
					onUpdate={onUpdate}
					className={bannerStyle}
					{...getTestAttr('service-banner')}
				>
					<Container className={styles.container}>
						<Richtext content={message} />
						{isClosable === true && (
							<Button unstyled onClick={onClose} className={styles.icon} aria-label='Luk'>
								<Icon icon={'dismiss'} size={24} />
							</Button>
						)}
					</Container>
				</motion.div>
			)}
		</AnimatePresence>
	);
}
