export const defaultStatisticsResult = [
	{
		name: 'Syddanmark',
		selected: true,
		people: 0,
		basicSalaryData: {
			average: null,
			increase: null,
		},
		pensionData: {
			selfPaid: null,
			employerPaid: null,
		},
		totalData: {
			average: null,
			increase: null,
		},
	},
	{
		name: 'Nordjylland',
		selected: false,
		people: 0,
		basicSalaryData: {
			average: null,
			increase: null,
		},
		pensionData: {
			selfPaid: null,
			employerPaid: null,
		},
		totalData: {
			average: null,
			increase: null,
		},
	},
	{
		name: 'Midtjylland',
		selected: false,
		people: 0,
		basicSalaryData: {
			average: null,
			increase: null,
		},
		pensionData: {
			selfPaid: null,
			employerPaid: null,
		},
		totalData: {
			average: null,
			increase: null,
		},
	},
	{
		name: 'Hovedstaden',
		selected: false,
		people: 0,
		basicSalaryData: {
			average: null,
			increase: null,
		},
		pensionData: {
			selfPaid: null,
			employerPaid: null,
		},
		totalData: {
			average: null,
			increase: null,
		},
	},
	{
		name: 'Sjælland',
		selected: false,
		people: 0,
		basicSalaryData: {
			average: null,
			increase: null,
		},
		pensionData: {
			selfPaid: null,
			employerPaid: null,
		},
		totalData: {
			average: null,
			increase: null,
		},
	},
	{
		name: 'Hele landet',
		selected: false,
		people: 0,
		basicSalaryData: {
			average: null,
			increase: null,
		},
		pensionData: {
			selfPaid: null,
			employerPaid: null,
		},
		totalData: {
			average: null,
			increase: null,
		},
	},
];
