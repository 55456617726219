import React, { createContext, useContext } from 'react';

import { PageContext } from '@web/services/umbraco/fetchers/page-context';
import { MappedRootData } from '@web/services/umbraco/queries/root.query';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';

interface GlobalContextType {
	rootData: MappedRootData;
	preview: boolean;
	routeData?: UmbracoPage;
	pageContext?: PageContext;
}

export const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);

/**
 * @see {@link GlobalContextProps}
 */
export function useGlobalContext() {
	const context = useContext(GlobalContext);

	return context;
}

interface GlobalContextProps {
	rootData: MappedRootData;
	children: React.ReactNode;
	preview?: boolean;
	routeData?: UmbracoPage;
	pageContext?: PageContext;
}

export function GlobalContextProvider({
	rootData,
	routeData,
	pageContext,
	preview = false,
	children,
}: GlobalContextProps) {
	const contextValue: GlobalContextType = {
		rootData,
		preview,
		routeData,
		pageContext,
	};

	return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
}
