import { useEffect, useMemo, useState } from 'react';

import { Container } from '@dansk-metal/ui';

import { ContentBlock } from '@web/components/block-list/content-block';
import { TabBar } from '@web/components/tab-bar/tab-bar';
import { useQueryParamsTabBar } from '@web/components/tab-bar/use-tab-bar.hook';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';

import type { BlockType } from '../index';

import styles from './tab-bar-block.module.scss';

const blockName = 'tabBarBlock' as const;

export type TapBarBlock = UmbracoBlock<typeof blockName, {
	tabs: TapItem[];
}>;

export type TapItem = UmbracoBlock<typeof blockName, {
	title: string;
	blocks: Exclude<BlockType, TapBarBlock>[];
}>;

export type TabBarBlockProps = {
	block: TapBarBlock;
};

export function TabBarBlock({ block }: TabBarBlockProps) {
	const { contentProperties: { tabs = [] } } = block;
	const tabTitles = useMemo(() => tabs.map((tab) => tab.contentProperties.title), [tabs]);
	const { activeTab, setActiveTab } = useQueryParamsTabBar({ tabs: tabTitles, queryParam: 'tab' });
	const [lastActiveIframeTab, setLastActiveIframeTab] = useState<TapItem | null>(null);

	useEffect(() => {
		// The point of this use effect is to make sure that only one authenticatedIframe is rendered at a time.
		// We don't want to unload all non-active iframes as we use tabs as 'guides' to the content.
		// We therefore want to allow the user to switch between an authenticated iframe and other blocks without unloading
		// the last used iframe, as this would otherwise cause the user to loose their place in the iframe.
		const activeTabItem = getTab(tabs, activeTab);

		if (activeTabItem && isAuthIframe(activeTabItem)) {
			setLastActiveIframeTab(activeTabItem);
		}
	}, [activeTab, tabs]);

	return (
		<div>
			<Container className={styles.container}>
				<TabBar tabs={tabTitles} activeTab={activeTab} setActiveTab={setActiveTab} />
			</Container>
			<div className={styles.content}>
				{tabs.map((tab) => (
					<div key={tab.key} hidden={tab.contentProperties.title !== activeTab}>
						{(!isAuthIframe(tab) || lastActiveIframeTab?.contentProperties.title === tab.contentProperties.title) && (
							<ContentBlock blocks={tab.contentProperties.blocks} />
						)}
					</div>
				))}
			</div>
		</div>
	);
}

TabBarBlock.blockName = blockName;

function getTab(tabs: TapItem[], activeTab: string) {
	return tabs.find((e) => e.contentProperties.title === activeTab);
}

function isAuthIframe(tab: TapItem) {
	return tab.contentProperties.blocks.some((e) => e.contentAlias === 'authenticatedIframeBlock');
}
