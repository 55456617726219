export enum ErrorType {
	App = 'AppError',
	CRM = 'CRMError',
	UmbracoFetch = 'UmbracoFetchError'
}

export const ErrorMessages: {
	[key in ErrorType]: string;
} = {
	[ErrorType.App]: 'Unexpected error',
	[ErrorType.CRM]: 'CRM error',
	[ErrorType.UmbracoFetch]: 'Error while retrieving content from umbraco',
};
