export enum SmallColumns {
	'C2' = '2_columns',
	'C3' = '3_columns',
	'C4' = '4_columns',
}

export enum SmallContentWidth {
	XXS = 'xxs',
	XS = 'xs',
	Small = 'small',
}

export enum ContentWidth {
	Medium = 'medium',
	Large = 'large',
	XL = 'xl',
	XXL = 'xxl',
}
