import { BasicProperty } from '@apps/web/src/services/gql/graphql';
import { TableCell, TableData, TableRow } from '@apps/web/src/services/umbraco/types/basic/Table';

export type TableDTO = {
	value: {
		useFirstColumnAsHeader: boolean;
		useFirstRowAsHeader: boolean;
		cells: {
			rowIndex: number;
			columnIndex: number;
			value: string;
		}[];
	};
};

export type UmbracoTable = Omit<BasicProperty, 'value'> & { editorAlias: 'Limbo.Umbraco.Tables'; value: TableDTO };

export class TableConverter {
	static UmbracoType: UmbracoTable;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Limbo.Umbraco.Tables';
	}

	public static mapProperty(prop: typeof this.UmbracoType): TableData {
		return {
			useFirstColumnAsHeader: prop.value?.value?.useFirstColumnAsHeader || false,
			useFirstRowAsHeader: prop.value?.value?.useFirstRowAsHeader || false,
			rows: (prop.value?.value?.cells || []).reduce((rows, item) => {
				const matchingRow = rows[item.rowIndex];
				const cell: TableCell = { value: item.value };

				if (!matchingRow) {
					const row: TableRow = {
						cols: [cell],
					};

					rows.push(row);
				} else {
					matchingRow.cols.push(cell);
				}

				return rows;
			}, [] as TableData['rows']),
		};
	}
}
