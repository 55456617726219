import MediaQuery from 'react-responsive';
import cn from 'classnames';

import { getTestAttr } from '@dansk-metal/service/testing';
import { getPallette, hasColorPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import HeroLine from '@web/components/hero-line/hero-line';
import { buildUrl } from '@web/components/qbank-image/loader';
import { Theme } from '@web/components/theme/theme';

import { useGlobalContext } from '@web/layouts/page/context';

import { HeroAlignment } from '@web/services/umbraco/types/basic/Alignment';
import { BackgroundColor } from '@web/services/umbraco/types/basic/Color';
import { QBankVectorType } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';

import styles from './hero-title.module.scss';
export interface HeroTitleProps {
	title: HTMLString;
	prefixTitle?: string;
	subtitle?: string;
	illustration?: QBankVectorType;
	background?: BackgroundColor;
	alignment?: HeroAlignment;
}

export function HeroTitle({
	title,
	prefixTitle,
	subtitle,
	illustration,
	background = BackgroundColor.Darkblue,
	alignment = HeroAlignment.Left,
}: HeroTitleProps) {
	const { pageContext } = useGlobalContext();

	return (
		<Theme
			palette={getPallette(background)}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(background),
			})}
		>
			<div className={cn(styles.outerWrapper, {
				[styles.outerWrapper__members_page]: pageContext?.ancestor?.contentType === 'userMemberPage' && background === BackgroundColor.White,
				[styles.outerWrapper__webpage]: pageContext?.ancestor?.contentType !== 'userMemberPage' && background === BackgroundColor.White,
			})} {...getTestAttr('hero-title')}>
				<Container className={styles.heroWrapper}>
					<div
						className={cn(styles.inner_container, {
							[styles.inner_container__hasWhiteBackground]: background === 'white',
						})}
					>
						<div
							className={cn(
								styles[`column__${alignment}`],
								illustration
									? styles[`column__${alignment}__hasIllustration`]
									: styles[`column__${alignment}__noIllustration`],
							)}
						>
							{prefixTitle ? <p className={cn('h4', styles.prefix_title)}>{prefixTitle}</p> : <HeroLine />}
							<div className={styles.header_container}>
								<h1 className={styles.header_container__title} dangerouslySetInnerHTML={{ __html: title }} />
							</div>
							{subtitle && <p className={styles.subtitle}>{subtitle}</p>}
						</div>
						{alignment === 'left' && (
							<MediaQuery minWidth={styles['breakpoint-md']}>
								{illustration && (
									<div
										className={cn(
											styles[`column__${alignment}__illustration`],
											styles[`column__${alignment}__hasIllustration`],
										)}
									>
										<div
											className={cn(styles.image_container, {
												[styles.image_container__hasWhiteBackground]: background === 'white',
											})}
										>
											{/* eslint-disable-next-line @next/next/no-img-element */}
											<img src={buildUrl(illustration.filename)} alt={'heroImage'} />
										</div>
									</div>
								)}
							</MediaQuery>
						)}
					</div>
				</Container>
			</div>
		</Theme>
	);
}

export default HeroTitle;
