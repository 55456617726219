import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';

import { contentAlias as departmentPageAlias, DepartmentPageData } from '@web/templates/department-page';
import { contentAlias as userMemberPageAlias, UserMemberPageData } from '@web/templates/user-member-page';

const ancestorMenuContentTypes = [
	departmentPageAlias,
	userMemberPageAlias,
];

export function isAncestorMenuContentType(contentType: string): boolean {
	return (ancestorMenuContentTypes as string[]).includes(contentType);
}

export function isRouteAncestorMenu(routeData?: UmbracoPage): routeData is DepartmentPageData | UserMemberPageData {
	if (!routeData) {
		return false;
	}

	return isAncestorMenuContentType(routeData.contentType);
}
