import { appError } from '@apps/web/src/services/errorhandler/appError';
import { BasicProperty, CustomMediaPicker, FilePickerItem, ImagePickerItem, VideoPickerItem } from '@apps/web/src/services/gql/graphql';
import { MediaType, UmbracoFileType, UmbracoImageType, UmbracoMedia, UmbracoVideoType } from '@apps/web/src/services/umbraco/types/basic/Media';
import { Overwrite } from '@apps/web/src/types/overwrite';

export type UmbracoMediaPicker = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Umbraco.MediaPicker3';
	value: Overwrite<CustomMediaPicker, {
		mediaItems: (VideoPickerItem | ImagePickerItem)[];
	}>;
};

export class MediaConverter {
	static UmbracoType: UmbracoMediaPicker;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.MediaPicker3';
	}

	public static mapProperty(prop: typeof this.UmbracoType): UmbracoMedia | UmbracoMedia[] | undefined {
		if (prop.value.multiPicker) {
			return (prop.value.mediaItems || [])
				.filter((e) => !!e)
				.map((e) => this.convertValue(e));
		}

		if (prop.value.mediaItems?.[0]) {
			return this.convertValue(prop.value.mediaItems[0]);
		}

		return undefined;
	}

	private static convertValue(item: VideoPickerItem | ImagePickerItem | FilePickerItem): UmbracoMedia {
		if (item.__typename === 'ImagePickerItem') {
			return { ...item, alias: MediaType.Image } as UmbracoImageType;
		}

		if (item.__typename === 'VideoPickerItem') {
			return { ...item, alias: MediaType.Video } as UmbracoVideoType;
		}

		if (item.__typename === 'FilePickerItem') {
			return { ...item, alias: MediaType.File } as UmbracoFileType;
		}

		throw appError({
			message: 'Unknown media type encountered',
			info: {
				item,
			},
		});
	}
}
