/**
 * The claculations made in this script are based on the document found here: https://makingwaves.atlassian.net/wiki/spaces/DM/pages/2596929842/Tjenester
 */

export type OKCalculatorInput = {
	salary: number;
	unit: 'hour' | 'month';
};

export type OKCalculatorConstants = {
	hoursPerYear: number;
	selectionWageAccountPercentage: number; // fritvalgslønkonto
	vacationBenefitPercentage: number; // feriepenge
	pensionPercentage: number;
	ikufPercentage: number; // industriens kompetenceudviklingsfond
	hourlySicknessBenefit: number; // sygedagpenge
	hourlyDailyAllowance: number; // dagpenge
};

export type OKCalculatorResult = {
	valueOfFiveFreeDays: number; // 5 feriefridage med løn
	pension: number;
	ikuf: number; // industriens kompetenceudviklingsfond
	childsFirstSickDay: number;
	selectionWageAccount: number; // fritvalgslønkonto
	fullWageDuringSickness: number;
	sicknessBenefit: number;
	childInHospital: number;
	twoWeeksCourse: number;
	seniority3Years: number; // fratrædelsesgodtgørelse
	seniority6Years: number;
	seniority8Years: number;
	valueOfFiveVacationDays: number;
};

export type OKCalculatorProps = {
	input: OKCalculatorInput;
	constants: OKCalculatorConstants;
};

const hoursPerMonth = 160.33;
const hoursPerWeek = 37;

function roundTwoDecimals(num: number): number {
	return Math.round((num + Number.EPSILON) * 100) / 100;
}

function getHourlySalary(input: OKCalculatorInput): number {
	return input.unit === 'hour' ? input.salary : input.salary / hoursPerMonth;
}

function calculateValueOfFiveFreeDays({ input, constants }: OKCalculatorProps): number {
	const weeklySalary = getHourlySalary(input) * hoursPerWeek;
	const selectionWageAccount = weeklySalary * constants.selectionWageAccountPercentage;
	const vacationBenefit = weeklySalary * constants.vacationBenefitPercentage;
	const pensionBase = weeklySalary + selectionWageAccount + vacationBenefit;
	const pension = pensionBase * constants.pensionPercentage;

	return pensionBase + pension;
}

function calculatePension({ input, constants }: OKCalculatorProps): number {
	const yearlySalary = getHourlySalary(input) * constants.hoursPerYear;
	const selectionWageAccount = yearlySalary * constants.selectionWageAccountPercentage;
	const vacationBenefit = yearlySalary * constants.vacationBenefitPercentage;
	const yearlyBase = yearlySalary + selectionWageAccount + vacationBenefit;

	return yearlyBase * constants.pensionPercentage;
}

function calculateIkuf({ input, constants }: OKCalculatorProps): number {
	const twoWeekSalary = getHourlySalary(input) * hoursPerWeek * 2;
	const selectionWageAccount = twoWeekSalary * constants.selectionWageAccountPercentage;
	const vacationBenefit = twoWeekSalary * constants.vacationBenefitPercentage;
	const ikufBase = twoWeekSalary + selectionWageAccount + vacationBenefit;
	const pension = ikufBase * constants.pensionPercentage;

	return (ikufBase + pension) * constants.ikufPercentage;
}

function calculateChildsFirstSickDay({ input, constants }: OKCalculatorProps): number {
	const sixDaySalary = getHourlySalary(input) * (hoursPerWeek / 5) * 6;
	const selectionWageAccount = sixDaySalary * constants.selectionWageAccountPercentage;
	const vacationBenefit = sixDaySalary * constants.vacationBenefitPercentage;
	const sickPayBase = sixDaySalary + selectionWageAccount + vacationBenefit;
	const pension = sickPayBase * constants.pensionPercentage;

	return sickPayBase + pension;
}

function calculateSelectionWageAccount({ input, constants }: OKCalculatorProps): number {
	const yearlySalary = getHourlySalary(input) * constants.hoursPerYear;

	return yearlySalary * constants.selectionWageAccountPercentage;
}

function calculateSicknessBenefit({ constants }: OKCalculatorProps): number {
	return constants.hourlySicknessBenefit * hoursPerWeek * 2;
}

function calculateSeniorityBenefit({ input, constants }: OKCalculatorProps): number {
	const hourlySalary = getHourlySalary(input);
	const hourlySelectionWage = hourlySalary * constants.selectionWageAccountPercentage;
	const hourlySalaryWithSelectionWage = hourlySalary + hourlySelectionWage;
	const taxDecuction = hourlySalaryWithSelectionWage * 0.15;
	const hourlyDifference = hourlySalaryWithSelectionWage - (taxDecuction + constants.hourlyDailyAllowance);
	const monthlyDifference = hourlyDifference * hoursPerMonth;

	if (monthlyDifference < 15000) {
		if (monthlyDifference > 2500) {
			return monthlyDifference;
		}

		return 2500;
	}

	return 15000;
}


export function calculate({ input, constants }: OKCalculatorProps): OKCalculatorResult {
	const valueOfFiveFreeDays = roundTwoDecimals(calculateValueOfFiveFreeDays({ input, constants }));
	const seniorityBenefit = roundTwoDecimals(calculateSeniorityBenefit({ input, constants }));

	return {
		valueOfFiveFreeDays,
		pension: roundTwoDecimals(calculatePension({ input, constants })),
		ikuf: roundTwoDecimals(calculateIkuf({ input, constants })),
		childsFirstSickDay: roundTwoDecimals(calculateChildsFirstSickDay({ input, constants })),
		selectionWageAccount: roundTwoDecimals(calculateSelectionWageAccount({ input, constants })),
		fullWageDuringSickness: valueOfFiveFreeDays,
		sicknessBenefit: roundTwoDecimals(calculateSicknessBenefit({ input, constants })),
		childInHospital: valueOfFiveFreeDays,
		twoWeeksCourse: valueOfFiveFreeDays * 2,
		seniority3Years: seniorityBenefit,
		seniority6Years: seniorityBenefit * 2,
		seniority8Years: seniorityBenefit * 3,
		valueOfFiveVacationDays: valueOfFiveFreeDays,
	};
}
