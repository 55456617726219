import { useState } from 'react';
import cn from 'classnames';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';

import { Container } from '@dansk-metal/ui';

import { CalendarItem } from '@web/components/calendar-item/calendar-item';

import { Link as UmbracoLinkType } from '@web/services/umbraco/types/basic/Link';

import { filterByPastOrFutureAppointments } from '@web/utils/filter-appointments-by-past-or-future-appointments';

import { UmbracoLink } from '@apps/web/src/components/umbraco-link/umbraco-link';
import { useAuthenticatedCRMRequest } from '@apps/web/src/services/crm-api/hooks';
import { CalendarItemDto, getMemberById } from '@apps/web/src/services/crm-api/rest';
import { crmError } from '@apps/web/src/services/errorhandler/appError';

import 'swiper/css';

import styles from './timeline.module.scss';

export interface TimelineProps {
	allCalendarItemsLink?: UmbracoLinkType;
	calendarItems: CalendarItemDto[];
	title?: string;
}

export function Timeline({
	allCalendarItemsLink,
	title,
	calendarItems,
}: TimelineProps) {
	const {
		error,
	} = useAuthenticatedCRMRequest({
		request: (session, config) => getMemberById(session.user.memberId, config),
	});

	if (error) {
		throw crmError({ cause: error as unknown as Error });
	}

	const upcomingCalendarItems = filterByPastOrFutureAppointments(calendarItems, true);
	const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);

	const swiperProps: SwiperProps = {
		breakpoints: {
			375: {
				slidesPerView: 1,
				spaceBetween: 8,
			},
			640: {
				slidesPerView: 2,
				spaceBetween: 8,
			},
			960: {
				slidesPerView: 3,
				spaceBetween: 8,
			},
		},
	};

	const handleOnSwiper = (swiper: SwiperClass) => {
		if (swiperProps?.onSwiper) {
			swiperProps.onSwiper(swiper);
		}

		if (!swiperRef) {
			setSwiperRef(swiper);
		}
	};

	return (
		<Container className={cn(styles.container)}>
			<div className={cn(styles.cards_container)}>
				{upcomingCalendarItems.length > 0 && (
					<h2 className={styles.header}>{title}</h2>
				)}

				{calendarItems.length > 0 ? (
					<Swiper
						{...swiperProps}
						className={styles.swiper}
						onSwiper={handleOnSwiper}
					>
						{calendarItems.map((item) => (
							<SwiperSlide
								key={item.id}
								className={styles.swiper_item}
							>
								<button className={styles.button}>
									<CalendarItem
										startTime={item.start}
										endTime={item.end}
										title={item.name}
										location={item.location}
										href={`/medlem/booking/${item.id}`}
									/>
								</button>
							</SwiperSlide>
						))}
					</Swiper>
				) : (
					<div className={styles.empty_state_container}>
						<p>
							Du har ingen kommende aftaler lige nu - tjek igen senere.
						</p>
					</div>
				)}
				{allCalendarItemsLink && (
					<div>
						<UmbracoLink
							className={styles.link}
							icon={{ icon: 'arrow-right', size: 24 }}
							link={allCalendarItemsLink}
						/>
					</div>
				)}
			</div>
		</Container>
	);
}
