
import { useState } from 'react';

import { Container } from '@dansk-metal/ui';

import { Benefit } from '@web/blocks/memberships-overview-block/types';

import { ContentDrawer } from '@web/components/content-drawer/content-drawer';
import { InfoButton } from '@web/components/info-button/info-button';
import { LinkButton } from '@web/components/link-button/link-button';
import { Richtext } from '@web/components/richtext/richtext';

import { LinkStyle } from '@web/services/umbraco/types/elements/Action';

import styles from '../memberships-overview-block.module.scss';

export function Benefits({ benefitList }: { benefitList: Benefit[] }) {
	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedBenefit, setSelectedBenefit] = useState<Benefit | null>(null);

	const handleSelectBenefit = (benefit: Benefit) => {
		setSelectedBenefit(benefit);
		setOpenDrawer(true);
	};

	return (
		<>
			<ul>
				{benefitList.map((benefitItem) => (
					<InfoButton key={benefitItem.key} benefitItem={benefitItem} selectBenefitHandler={handleSelectBenefit} />
				))}
			</ul>
			<ContentDrawer
				open={openDrawer}
				onClose={() => {
					setOpenDrawer(false);
				}}
			>
				<Container>
					<h3>{selectedBenefit?.contentProperties.title}</h3>
					<Richtext className={styles.drawer__body_text} content={selectedBenefit?.contentProperties.bodyText || ''} />
					{selectedBenefit?.contentProperties.link && (
						<LinkButton type={LinkStyle.TextLink} link={selectedBenefit?.contentProperties.link}/>
					)}
				</Container>
			</ContentDrawer>
		</>
	);
}
