import Image from 'next/image';

import { Icon } from '@dansk-metal/ui';

import { Aspect } from '@web/components/media/aspectRatios';
import { QBankImage } from '@web/components/qbank-image/qbank-image';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';

import { getPhoneNumber } from '@web/utils/format-number';

import placeholderImage from '../../../../public/assets/placeholder.png';

import styles from './phonebook-person-card.module.scss';

export const CMSPersonType = 'person';

export type PhoneBookPerson = UmbracoBlock<
typeof CMSPersonType,
{
	person: {
		name: string;
	};
	phoneNumber?: string;
	jobTitle?: string;
	department?: string;
	email?: string;
	image?: QBankImageType;
}
>;

export interface PhoneBookPersonProps {
	block: PhoneBookPerson;
}

export function PhonebookPersonCard({ block }: PhoneBookPersonProps) {
	const person = block.contentProperties || {};

	return (
		<div key={person.person.name} className={styles.person_card_wrapper}>
			<div className={styles.person_image_wrapper}>
				{person.image ?
					<QBankImage
						className={styles.person_image}
						loading="lazy"
						media={person.image}
						aspectRatio={Aspect['1:1']}
					/>
					:
					<Image
						className={styles.person_image}
						src={placeholderImage}
						width={737}
						height={737}
						loader={() => ''}
						unoptimized={true}
						alt="placeholder image"
					/>
				}
			</div>
			<div className={styles.person_info}>
				<div className={styles.person_details}>
					<h3>{person.person.name}</h3>
					<p>{person.jobTitle}</p>
					<p>{person.department}</p>
				</div>
				<div className={styles.person_contacts}>
					{person.phoneNumber &&
						<div>
							<Icon className={styles.person_contacts__icon} size={24} icon='phone'/>
							<a href={`tel:${person.phoneNumber.split(' ').join('')}`}>
								{getPhoneNumber(person.phoneNumber)}
							</a>
						</div>
					}
					{person.email &&
						<div>
							<Icon className={styles.person_contacts__icon} size={24} icon='mail'/>
							<a href={`mailto:${person.email}`}>
								{person.email}
							</a>
						</div>
					}
				</div>
			</div>
		</div>
	);
}
