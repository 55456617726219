import type { NextPage } from 'next';

import Config from '@web/config';

import { BlockType } from '@web/blocks';

import { ContentBlock } from '@web/components/block-list/content-block';
import { HeroBlock } from '@web/components/hero-block/hero-block';
import { NewsMetadata } from '@web/components/news-metadata/news-metadata';
import { NewsShareButtons } from '@web/components/news-share-buttons/news-share-buttons';

import TemplateLayout from '@web/layouts/template/template';

import { BreadcrumbElement } from '@web/services/umbraco/types/basic/Breadcrumb';
import { TDateISO } from '@web/services/umbraco/types/basic/Date';
import { UmbracoDocument, UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';
import { HeroBlockType } from '@web/services/umbraco/types/elements/Hero';

export const contentAlias = 'newsPage';

export type NewsPageData = UmbracoPage<
	typeof contentAlias,
	{
		hero: HeroBlockType;

		publishDate: TDateISO;
		author?: string;
		tags?: UmbracoDocument<'newsTag', {}>[];
		blocks: BlockType[];
	},
	{
		relatedContent: NewsPageData[];
	}
>;

function getOverviewPagePath(elements: BreadcrumbElement[], contentType: string): BreadcrumbElement['path'] | undefined {
	const element = elements
		.slice()
		.reverse()
		.find((e) => e.contentType === contentType);

	return element?.path;
}

const NewsPageTemplate: NextPage<{ pageData: NewsPageData }> = ({ pageData }) => {
	const { publishDate, author, tags, hero, blocks, breadcrumbs } = pageData.properties;

	return (
		<TemplateLayout>
			{hero && <HeroBlock block={hero} />}
			<NewsMetadata
				date={publishDate}
				author={author}
				tags={tags?.map((tag) => tag.name)}
				parentUrl={getOverviewPagePath(breadcrumbs?.items || [], 'newsOverviewPage')}
			/>
			{blocks && <ContentBlock blocks={blocks} />}
			<NewsShareButtons shareUrl={Config.BASE_URL + pageData.url} />
		</TemplateLayout>
	);
};

export default NewsPageTemplate;
