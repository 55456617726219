import { Button } from '@dansk-metal/ui';

import styles from './modal-content.module.scss';

interface WarningModalProps {
	title: string;
	bodyText: string;
	handleOnClick: () => void;
}

export function WarningModalContent({ title, bodyText, handleOnClick }: WarningModalProps) {
	return (
		<div className={styles.modal_content}>
			<h3>{title}</h3>
			<p>{bodyText}</p>
			<Button icon={{ icon: 'arrow-right', size: 24 }} className={styles.single_button} onClick={handleOnClick}>Okay</Button>
		</div>
	);
}
