import { gql } from '@urql/core';

import type { PageUrlQuery, PageUrlQueryVariables } from '@web/services/gql/graphql';

export { PageUrlQuery, PageUrlQueryVariables };

export const pageUrlQuery = gql<PageUrlQuery, PageUrlQueryVariables>`
query PageUrl ($contentType: String!,){
	contentByContentType (contentType: $contentType, first: 1) {
		nodes {
			url,
		}
	}
}
`;

