import { Optional } from 'ts-toolbelt/out/Object/Optional';

import { FormDto } from '@apps/web/src/services/umbraco/rest';
import { Link } from '@apps/web/src/services/umbraco/types/basic/Link';

export {
	FieldConditionActionType as ActionType,
	FieldConditionLogicType as LogicType,
	FieldConditionRuleOperator as Operator,
} from '@apps/web/src/services/umbraco/rest';

export type FormDefinition = Omit<FormDto, 'gotoPageOnSubmit'> & {
	gotoPageOnSubmit?: Optional<Link, 'key'>;
};

export type FileType = {
	fileName: string;
	fileContents: string;
};
