import { Controller } from 'react-hook-form';

import { Button, Form, InputText, Select } from '@dansk-metal/ui';

import { FormValues } from '@web/blocks/ok-calculator-block/ok-calculator-block';
import { optionTimeValue, timeOptions } from '@web/blocks/ok-calculator-block/types';

import { FormFooter, FormHeader, FormWrapper } from '@web/components/form-wrapper/form-wrapper';

import styles from './ok-calculator-form.module.scss';

interface OKCalculatorFormProps {
	handleSubmit: Form.UseFormHandleSubmit<FormValues>;
	onSubmit: () => void;
	getValues: (payload?: string | string[]) => string;
	select: {
		value: { value: optionTimeValue; label: string };
		isMulti: boolean;
		onChange: (option: unknown) => unknown;
	};
	control: Form.Control<FormValues>;
	formState: Form.FormState<FormValues>;
}

export function OKCalculatorForm({ handleSubmit, onSubmit, getValues, select, control, formState }: OKCalculatorFormProps) {
	const selectedOption = timeOptions.find((option) => option.value === getValues('unit'));

	return (
		<form className={styles.form_container} onSubmit={handleSubmit(onSubmit)}>
			<FormWrapper>
				{(formState.errors.salary || formState.errors.unit) && <FormHeader type='error' message={'Der er fejl i et eller flere felter'} />}
				<div className={styles.form}>
					<Select
						{...select}
						className={styles.form__inputs}
						noOptionalLabel
						required
						label="Hvordan er du aflønnet?"
						options={timeOptions}
					/>
					<Controller
						control={control}
						name="salary"
						render={({ field, fieldState }) => (
							<InputText
								{...field}
								fieldState={fieldState}
								required
								noOptionalLabel
								className={styles.form__inputs}
								label={`Hvad er din ${selectedOption?.value === 'hour' ? 'timeløn' : 'månedsløn'}?`}
							/>
						)}
					/>
				</div>
			</FormWrapper>
			<FormFooter>
				<Button
					icon={{ icon: 'arrow-right', size: 24 }}
					type="submit"
				>
					Se resultat
				</Button>
			</FormFooter>
		</form>
	);
}
