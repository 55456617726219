import cn from 'classnames';

import styles from './loader.module.scss';

export interface LoaderProps {
	size?: 'large' | undefined;
}

export const Loader = ({ size }: LoaderProps) => {
	return <span className={cn(styles.loader, { [styles.large]: size === 'large' })} />;
};
