import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';

import { Icon } from '@dansk-metal/ui';
import { formatDate, formatDateRange, getDaysBetween } from '@dansk-metal/utils/date';

import EventMessage from '@web/components/event-card/event-message';
import { eventMessages, EventType } from '@web/components/event-card/types';

import { EventDtoAllOfLocation, LocationType } from '@web/services/crm-api/rest';

import styles from './event-card.module.scss';

export type EventCardProps = {
	startTime: string;
	endTime?: string;
	title: string;
	location?: EventDtoAllOfLocation;
	eventType: EventType;
	locationType: LocationType;
	href?: string;
};

export function EventCard({
	startTime,
	endTime,
	title,
	location,
	eventType,
	locationType,
	href,
}: EventCardProps) {
	const event = eventMessages[eventType];
	const dayCount = endTime ? getDaysBetween(startTime, endTime) : 0;
	const [time, setTime] = useState('');
	const Container = href ? Link : 'div';

	useEffect(() => {
		return endTime ? setTime(formatDateRange(startTime, endTime)) :
			setTime(formatDate(startTime, 'Tir 22. feb, 09.00'));
	}, [endTime, startTime]);

	const Label = ({ className }) => useMemo(() => {
		if (EventMessage.OverviewMessage in event) {
			return (
				<div className={cn(className, styles[`label__${eventType}`])}>
					{event.overviewMessage}
				</div>
			);
		}

		if (EventMessage.Message in event) {
			return (
				<div className={cn(className, styles[`label__${eventType}`])}>
					{event.message}
				</div>
			);
		}

		return null;
	}, [className]);

	return (
		<Container className={styles.container} href={href || ''}>
			<div className={styles.date}>
				<p>{formatDate(startTime, '22 FEB')}</p>
				{dayCount > 1 && (
					<p className={styles.date__day_count}>{dayCount} dage</p>
				)}
			</div>
			<div className={styles.details}>
				<div className={styles.details__info}>
					<p className={styles.title}>{title}</p>
					<div className={styles.info}>
						<div className={styles.info__item}>
							<Icon icon="calendar" size={24} />
							<p>{time}</p>
						</div>
						{(locationType === LocationType.Online || location?.locationName) && (
							<div className={styles.info__item}>
								<Icon icon="location" size={24} />
								{locationType === LocationType.Online && (
									<p>Online</p>
								)}
								{(location && location.locationName) && (
									<p>{location.locationName}</p>
								)}
							</div>
						)}

					</div>
				</div>
				<Label className={cn(styles.label, styles.label__screen_md, styles.details__label)} />
			</div>
			<div className={styles.label_wrapper}>
				<Label className={cn(styles.label, styles.label__screen_md)} />
			</div>
		</Container>
	);
}
