import Link from 'next/link';

import { Container } from '@dansk-metal/ui';

import { QBankVector } from '@web/components/qbank-vector/qbank-vector';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { QBankVectorType } from '@web/services/umbraco/types/basic/QBank';

import { Theme } from '@apps/web/src/components/theme/theme';
import { UmbracoPage } from '@apps/web/src/services/umbraco/types/documents/UmbracoDocument';

import styles from './country-navigation-block.module.scss';

const blockName = 'countryNavigationBlock' as const;

export interface CountryNavigationBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			countries: CountryBlock[];
		}
	>;
}

type CountryBlock = UmbracoBlock<
	string,
	{
		title: string;
		flag: QBankVectorType;
		page: UmbracoPage;
	}
>;

export function CountryNavigationBlock({ block }: CountryNavigationBlockProps) {
	const { countries } = block.contentProperties || {};

	return (
		<Theme className={styles.theme}>
			<Container>
				<div className={styles.content}>
					{(countries || []).map(({ key, contentProperties: { title, flag, page } }) => {
						return (
							<Link href={page?.url || '#'} key={key} className={styles.country}>
								<QBankVector media={flag} className={styles.flag} layout="fill" />
								<span>{title}</span>
							</Link>
						);
					})}
				</div>
			</Container>
		</Theme>
	);
}

CountryNavigationBlock.blockName = blockName;
