import { getPallette } from '@dansk-metal/theme';

import { useHeaderContext } from '@web/components/header/context';
import { HeroMember } from '@web/components/hero-member/hero-member';
import { NavigationHorizontalScroll } from '@web/components/navigation/navigation-horizontal-scroll/navigation-horizontal-scroll';

import { useGlobalContext } from '@web/layouts/page/context';

import { contentAlias as userMemberPageAlias } from '@web/templates/user-member-page';

import { Theme } from '../theme/theme';

import { isRouteAncestorMenu } from './ancestor.helper';

export function AncestorMenu() {
	const { subnavColor } = useHeaderContext();
	const { pageContext, routeData } = useGlobalContext();

	const ancestor = pageContext?.ancestor || (isRouteAncestorMenu(routeData)
		? routeData
		: undefined
	);

	if (!ancestor) {
		return null;
	}

	if (ancestor.contentType === userMemberPageAlias) {
		return (
			<HeroMember userMemberPage={ancestor} />
		);
	}

	return (
		<Theme palette={getPallette(subnavColor)}>
			<NavigationHorizontalScroll title={ancestor.properties.title} menu={ancestor.properties.menu} />
		</Theme>
	);
}
