
import { Price } from '@web/blocks/memberships-overview-block/types';

import { Richtext } from '@web/components/richtext/richtext';

import styles from '../memberships-overview-block.module.scss';

export function Prices({ priceList }: { priceList: Price[] }) {
	return (
		<ul>
			{priceList.map((priceItem) => {
				const { price, priceDescription } = priceItem.contentProperties;

				return (
					<div className={styles.price} key={priceItem.key}>
						<li>
							{price && <span className={styles.price__title}>{price}</span>}
							{priceDescription && <Richtext content={priceDescription } />}
						</li>
					</div>
				);
			})}
		</ul>
	);
}
