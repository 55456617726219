import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';

import { dayJS } from '@dansk-metal/utils/date';

import { ReAuthModal } from '@web/components/re-auth-modal/re-auth-modal';

import { useGlobalContext } from '@web/layouts/page/context';

import { isAuthenticatedTemplate } from '@web/services/umbraco/helpers';

import { TemplateKey } from '@web/templates';

import { useSingleSignOut } from '@web/hooks/use-single-sign-out';

export function useGlobalAuthHandler() {
	const { routeData } = useGlobalContext();
	const { data: session } = useSession();
	const { singleSignOut } = useSingleSignOut();
	const [isReAuthModalOpen, showReAuthModal] = useState(false);
	const [isDismissed, dismiss] = useState(false);

	useEffect(() => {
		let firstTimer: ReturnType<typeof setTimeout> | null = null;
		let secondTimer: ReturnType<typeof setTimeout> | null = null;
		let logoutTimer: ReturnType<typeof setTimeout>;
		const now = new Date();

		if (session?.user?.ssoExp) {
			const msToExpiry = dayJS(session?.user.ssoExp)
				.diff(now, 'milliseconds');

			const firstTriggerReAuthAt = msToExpiry - (1000 * 60 * 15);
			const secondTriggerReAuthAt = msToExpiry - (1000 * 60 * 5);

			if (firstTriggerReAuthAt > 0) {
				firstTimer = setTimeout(() => {
					dismiss(false);
					showReAuthModal(true);
				}, firstTriggerReAuthAt);
			}

			if (secondTriggerReAuthAt > 0) {
				secondTimer = setTimeout(() => {
					dismiss(false);
					showReAuthModal(true);
				}, secondTriggerReAuthAt);
			}

			if (!isDismissed && msToExpiry > 0 && (!firstTimer && !secondTimer)) {
				showReAuthModal(true);
			}

			if (msToExpiry > 0) {
				logoutTimer = setTimeout(() => {
					singleSignOut({
						callbackUrl: isAuthenticatedTemplate(routeData?.contentType as TemplateKey) ? `/logged-out?callbackUrl=${routeData?.url}` : routeData?.url,
					});
				}, msToExpiry);
			} else {
				singleSignOut({
					callbackUrl: isAuthenticatedTemplate(routeData?.contentType as TemplateKey) ? `/logged-out?callbackUrl=${routeData?.url}` : routeData?.url,
				});
			}
		}

		return () => {
			if (firstTimer) {
				clearTimeout(firstTimer);
			}

			if (secondTimer) {
				clearTimeout(secondTimer);
			}

			if (logoutTimer) {
				clearTimeout(logoutTimer);
			}
		};
	}, [session?.user?.ssoExp, singleSignOut, routeData?.contentType, routeData?.url, isDismissed]);

	useEffect(() => {
		if (session?.error === 'RefreshAccessTokenError') {
			singleSignOut({
				callbackUrl: routeData?.url,
			});
		}
	}, [session, routeData?.url, singleSignOut]);

	return {
		AuthModal: () => {
			if (!session || !isReAuthModalOpen || isDismissed) {
				return null;
			}

			return (
				<ReAuthModal onDismiss={() => {
					dismiss(true);
					showReAuthModal(false);
				}} />
			);
		},
	};
}
