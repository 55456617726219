import { useEffect, useState } from 'react';

import { Container } from '@dansk-metal/ui';

import { Richtext } from '@web/components/richtext/richtext';
import { loadFormDefinition } from '@web/components/umbraco-form/helpers/form.loader';
import { FormDefinition } from '@web/components/umbraco-form/helpers/types';
import { UmbracoForm } from '@web/components/umbraco-form/umbraco-form';

import { useGlobalContext } from '@web/layouts/page/context';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { UmbracoDocument } from '@web/services/umbraco/types/documents/UmbracoDocument';

import styles from './form-block.module.scss';

const blockName = 'formBlock' as const;

export type FormData = UmbracoDocument<
	'formEntity',
	{
		title: string;
		subtitle: string;
		action: string;
		confirm: { title: string; subtitle: string };
		definition: string;
	},
	{
		children: null;
	}
>;
export interface FormBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			formData: FormData;
		}
	>;
}

export function FormBlock({ block }: FormBlockProps) {
	const { preview, routeData } = useGlobalContext();
	const { formData } = block.contentProperties;
	const [formDefinition, setFormDefinition] = useState<FormDefinition>();
	const contentId = routeData?.id?.toString();

	useEffect(() => {
		if (formData && contentId) {
			loadFormDefinition(formData.properties.definition, { contentId, preview })
				.then((formDef) => setFormDefinition(formDef))
				.catch((error) => console.error('Error loading form definition', error));
		}
	}, [formData, contentId, preview]);

	return (
		<Container className={styles.container}>
			<div className={styles.header}>
				<h2>{formData.properties.title}</h2>
				<Richtext content={formData.properties.subtitle} />
			</div>
			{formDefinition && <UmbracoForm definition={formDefinition} contentId={contentId} confirm={formData.properties.confirm} />}
		</Container>
	);
}

FormBlock.blockName = blockName;
