import { HeroAlignment } from '@web/services/umbraco/types/basic/Alignment';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { BackgroundColor } from '@web/services/umbraco/types/basic/Color';
import { QBankImageType, QBankVectorType } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';

export enum HeroType {
	Title = 'title',
	FullWidthImage = 'fullWidthImage'
}

export type HeroBlockType = UmbracoBlock<
	'hero',
	{
		// Common
		heroTitle: HTMLString;
		heroPrefixTitle?: string;
		heroType: HeroType;

		// Hero (title)
		heroSubtitle?: string;
		illustration?: QBankVectorType;
		heroBackground?: BackgroundColor;
		heroAlignment?: HeroAlignment;

		// Hero (title)
		heroImage?: QBankImageType;
	}
>;
