import dynamic from 'next/dynamic';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import Config from '@web/config';

import { fetchContentType } from '@web/services/umbraco/fetch';
import { PageQuery, pageQuery, PageQueryVariables } from '@web/services/umbraco/queries/page.query';
import {
	PageWithChildrenQuery,
	pageWithChildrenQuery,
	PageWithChildrenQueryVariables,
} from '@web/services/umbraco/queries/page-with-children.query';
import {
	PageWithRelatedContentQuery,
	pageWithRelatedContentQuery,
	PageWithRelatedContentQueryVariables,
} from '@web/services/umbraco/queries/page-with-related-content.query';
import {
	convertSlugToRoute,
	getChildSegment,
	getParentRoute,
	hasParentRoute,
} from '@web/services/umbraco/route-mapping';

import { AmrContentPageData } from '@web/templates/amr-content-page';
import { CalendarOverviewPageData } from '@web/templates/calendar-overview-page';
import { CookiePageData } from '@web/templates/cookie-page';
import { DepartmentPageData } from '@web/templates/department-page';
import { DepartmentsOverviewPageData } from '@web/templates/departments-overview-page';
import { ErrorPageData } from '@web/templates/errorpage';
import { EventPageData } from '@web/templates/event-page';
import { EventsOverviewPageData } from '@web/templates/events-overview-page';
import { FrontpageData } from '@web/templates/frontpage';
import { LoginPageData } from '@web/templates/login';
import { MemberBookingsDetailPageData } from '@web/templates/member-bookings-detail-page';
import { MemberBookingsOverviewPageData } from '@web/templates/member-bookings-overview-page';
import { MemberContentPageData } from '@web/templates/member-content-page';
import { NewsOverviewPageData } from '@web/templates/news-overview-page';
import { NewsPageData } from '@web/templates/news-page';
import { PageData } from '@web/templates/page';
import { PersonsOverviewPageData } from '@web/templates/persons-overview-page';
import { PublicationPageData } from '@web/templates/publication-page';
import { PublicationsOverviewPageData } from '@web/templates/publications-overview-page';
import { SearchResultPageData } from '@web/templates/search-result-page';
import { SelfServiceOverviewPageData } from '@web/templates/self-service-overview-page';
import { TasksOverviewPageData } from '@web/templates/tasks-overview-page';
import { TransportPageData } from '@web/templates/transport-page';
import { UnionRepContentPageData } from '@web/templates/union-rep-content-page';
import { UnionRepOverviewPageData } from '@web/templates/union-rep-overview-page';
import { UnionRepServicesPageData } from '@web/templates/union-rep-services-page';
import { UserConsentsPageData } from '@web/templates/user-consents-page';
import { UserContactInfoPageData } from '@web/templates/user-contact-info-page';
import { UserEventsOverviewPageData } from '@web/templates/user-events-overview-page';
import { UserMemberPageData } from '@web/templates/user-member-page';
import { UserMembershipInfoPageData } from '@web/templates/user-membership-info-page';
import { UserPaymentInfoPageData } from '@web/templates/user-payment-info-page';
import { UserProfilePageData } from '@web/templates/user-profile-page';
import { UserWorkplaceInfoPageData } from '@web/templates/user-workplace-info-page';

export type DocumentTypeMap = {
	amrContentPage: AmrContentPageData;
	calenderItemsOverviewPage: CalendarOverviewPageData;
	cookiePage: CookiePageData;
	departmentPage: DepartmentPageData;
	departmentsOverviewPage: DepartmentsOverviewPageData;
	errorPage: ErrorPageData;
	eventPage: EventPageData;
	eventsOverviewPage: EventsOverviewPageData;
	login: LoginPageData;
	memberBookingsDetailPage: MemberBookingsDetailPageData;
	memberBookingsOverviewPage: MemberBookingsOverviewPageData;
	memberContentPage: MemberContentPageData;
	newsOverviewPage: NewsOverviewPageData;
	newsPage: NewsPageData;
	page: PageData;
	personsOverviewPage: PersonsOverviewPageData;
	publicationPage: PublicationPageData;
	publicationsOverviewPage: PublicationsOverviewPageData;
	searchResultPage: SearchResultPageData;
	selfServiceOverviewPage: SelfServiceOverviewPageData;
	siteRoot: FrontpageData;
	tasksOverviewPage: TasksOverviewPageData;
	transportPage: TransportPageData;
	unionRepContentPage: UnionRepContentPageData;
	unionRepOverviewPage: UnionRepOverviewPageData;
	unionRepServicesPage: UnionRepServicesPageData;
	userConsentsPage: UserConsentsPageData;
	userContactInfoPage: UserContactInfoPageData;
	userEventsOverviewPage: UserEventsOverviewPageData;
	userMemberPage: UserMemberPageData;
	userMembershipInfoPage: UserMembershipInfoPageData;
	userPaymentInfoPage: UserPaymentInfoPageData;
	userProfilePage: UserProfilePageData;
	userWorkplaceInfoPage: UserWorkplaceInfoPageData;
};
export type QueryTypeMap = {
	amrContentPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	calenderItemsOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	cookiePage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	departmentPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	departmentsOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	errorPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	eventPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables & {
			eventId: string;
		};
	};
	eventsOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	login: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	memberBookingsDetailPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables & {
			calendarItemId: string;
		};
	};
	memberBookingsOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	memberContentPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	newsOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	newsPage: {
		QueryResult: PageWithRelatedContentQuery;
		Variables: PageWithRelatedContentQueryVariables;
	};
	page: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	personsOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	publicationPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	publicationsOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	searchResultPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	selfServiceOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	siteRoot: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	tasksOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	transportPage: {
		QueryResult: PageWithChildrenQuery;
		Variables: PageWithChildrenQueryVariables;
	};
	unionRepContentPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	unionRepOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	unionRepServicesPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userConsentsPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userContactInfoPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userEventsOverviewPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userMemberPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userMembershipInfoPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userPaymentInfoPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userPostPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userProfilePage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
	userWorkplaceInfoPage: {
		QueryResult: PageQuery;
		Variables: PageQueryVariables;
	};
};
export type TemplateMap = DocumentTypeMap;
export type TemplateKey = keyof DocumentTypeMap;
export type TemplateComponent<PageData> = React.ComponentType<{
	pageData: PageData;
}>;
export type GraphQLResult<Key extends TemplateKey> = QueryTypeMap[Key]['QueryResult'];
export type GraphQLVariables<Key extends TemplateKey> = QueryTypeMap[Key]['Variables'];
export type Templates = {
	[Key in TemplateKey]: {
		component: TemplateComponent<Key extends keyof DocumentTypeMap ? DocumentTypeMap[Key] : never>;
		query: TypedDocumentNode<GraphQLResult<Key>, GraphQLVariables<Key>>;
		selector: Key extends keyof QueryTypeMap ? keyof Omit<GraphQLResult<Key>, '__typename'> : never;
		auth: boolean;
	};
};
export const templates = {
	amrContentPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./amr-content-page')),
		selector: 'contentByAbsoluteRoute',
	},
	calenderItemsOverviewPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./calendar-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	cookiePage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./cookie-page')),
		selector: 'contentByAbsoluteRoute',
	},
	departmentPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./department-page')),
		selector: 'contentByAbsoluteRoute',
	},
	departmentsOverviewPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./departments-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	errorPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./errorpage')),
		selector: 'contentByAbsoluteRoute',
	},
	eventPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./event-page')),
		selector: 'contentByAbsoluteRoute',
	},
	eventsOverviewPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./events-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	login: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./login')),
		selector: 'contentByAbsoluteRoute',
	},
	memberBookingsDetailPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./member-bookings-detail-page')),
		selector: 'contentByAbsoluteRoute',
	},
	memberBookingsOverviewPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./member-bookings-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	memberContentPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./member-content-page')),
		selector: 'contentByAbsoluteRoute',
	},
	newsOverviewPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./news-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	newsPage: {
		auth: false,
		query: pageWithRelatedContentQuery,
		component: dynamic(import('./news-page')),
		selector: 'contentByAbsoluteRoute',
	},
	page: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./page')),
		selector: 'contentByAbsoluteRoute',
	},
	personsOverviewPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./persons-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	publicationPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./publication-page')),
		selector: 'contentByAbsoluteRoute',
	},
	publicationsOverviewPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./publications-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	searchResultPage: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./search-result-page')),
		selector: 'contentByAbsoluteRoute',
	},
	selfServiceOverviewPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./self-service-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	siteRoot: {
		auth: false,
		query: pageQuery,
		component: dynamic(import('./frontpage')),
		selector: 'contentByAbsoluteRoute',
	},
	tasksOverviewPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./tasks-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	transportPage: {
		auth: false,
		query: pageWithChildrenQuery,
		component: dynamic(import('./transport-page')),
		selector: 'contentByAbsoluteRoute',
	},
	unionRepContentPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./union-rep-content-page')),
		selector: 'contentByAbsoluteRoute',
	},
	unionRepOverviewPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./union-rep-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	unionRepServicesPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./union-rep-services-page')),
		selector: 'contentByAbsoluteRoute',
	},
	userConsentsPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./user-consents-page')),
		selector: 'contentByAbsoluteRoute',
	},
	userContactInfoPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./user-contact-info-page')),
		selector: 'contentByAbsoluteRoute',
	},
	userEventsOverviewPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./user-events-overview-page')),
		selector: 'contentByAbsoluteRoute',
	},
	userMemberPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./user-member-page')),
		selector: 'contentByAbsoluteRoute',
	},
	userMembershipInfoPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./user-membership-info-page')),
		selector: 'contentByAbsoluteRoute',
	},
	userPaymentInfoPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./user-payment-info-page')),
		selector: 'contentByAbsoluteRoute',
	},
	userProfilePage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./user-profile-page')),
		selector: 'contentByAbsoluteRoute',
	},
	userWorkplaceInfoPage: {
		auth: true,
		query: pageQuery,
		component: dynamic(import('./user-workplace-info-page')),
		selector: 'contentByAbsoluteRoute',
	},
} satisfies Templates;
export const authenticatedTemplates = Object.entries(templates)
	.filter(([, template]) => template.auth)
	.map(([key]) => key);
export type SlugMap<T extends TemplateKey = TemplateKey> = {
	docType: T;
	queryParams: GraphQLVariables<T>;
};
export async function mapSlugToDocumentType<T extends TemplateKey>(
	slug: string[],
	queries: Omit<GraphQLVariables<T>, 'slug' | 'baseUrl'>,
): Promise<SlugMap> {
	const route = convertSlugToRoute(slug);

	const contentType = await fetchContentType({
		preview: queries.preview || false,
		baseUrl: Config.UMBRACO_URL,
		route,
	});
	if (!contentType && hasParentRoute(route)) {
		const parentRoute = getParentRoute(route);

		const parentContentType = await fetchContentType({
			preview: queries.preview || false,
			baseUrl: Config.UMBRACO_URL,
			route: parentRoute,
		});
		if (parentContentType === 'eventsOverviewPage') {
			return {
				docType: 'eventPage',
				queryParams: {
					...(queries || {}),
					eventId: getChildSegment(route),
					baseUrl: Config.UMBRACO_URL,
					slug: parentRoute,
				},
			};
		}

		if (parentContentType === 'memberBookingsOverviewPage') {
			return {
				docType: 'memberBookingsDetailPage',
				queryParams: {
					...(queries || {}),
					calendarItemId: getChildSegment(route),
					baseUrl: Config.UMBRACO_URL,
					slug: parentRoute,
				},
			};
		}
	}

	if (contentType === 'loginPage') {
		return {
			docType: 'login',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'errorPage') {
		return {
			docType: 'errorPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'transportPage') {
		return {
			docType: 'transportPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'newsPage') {
		return {
			docType: 'newsPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'newsOverviewPage') {
		return {
			docType: 'newsOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'publicationsOverviewPage') {
		return {
			docType: 'publicationsOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'publicationPage') {
		return {
			docType: 'publicationPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'departmentsOverviewPage') {
		return {
			docType: 'departmentsOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'departmentPage') {
		return {
			docType: 'departmentPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'siteRoot') {
		return {
			docType: 'siteRoot',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'userMemberPage') {
		return {
			docType: 'userMemberPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'userProfilePage') {
		return {
			docType: 'userProfilePage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'memberContentPage') {
		return {
			docType: 'memberContentPage',
			queryParams: {
				...(queries || {}),
				contentType: 'userMemberPage',
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'searchResultPage') {
		return {
			docType: 'searchResultPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'unionRepOverviewPage') {
		return {
			docType: 'unionRepOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'eventsOverviewPage') {
		return {
			docType: 'eventsOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'userWorkplaceInfoPage') {
		return {
			docType: 'userWorkplaceInfoPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'userPaymentInfoPage') {
		return {
			docType: 'userPaymentInfoPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'userMembershipInfoPage') {
		return {
			docType: 'userMembershipInfoPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'userConsentsPage') {
		return {
			docType: 'userConsentsPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'userContactInfoPage') {
		return {
			docType: 'userContactInfoPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'calenderItemsOverviewPage') {
		return {
			docType: 'calenderItemsOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'userEventsOverviewPage') {
		return {
			docType: 'userEventsOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'selfServiceOverviewPage') {
		return {
			docType: 'selfServiceOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'unionRepServicesPage') {
		return {
			docType: 'unionRepServicesPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'personsOverviewPage') {
		return {
			docType: 'personsOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'memberBookingsOverviewPage') {
		return {
			docType: 'memberBookingsOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'memberBookingsDetailPage') {
		return {
			docType: 'memberBookingsDetailPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'tasksOverviewPage') {
		return {
			docType: 'tasksOverviewPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'cookiePage') {
		return {
			docType: 'cookiePage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'amrContentPage') {
		return {
			docType: 'amrContentPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	if (contentType === 'unionRepContentPage') {
		return {
			docType: 'unionRepContentPage',
			queryParams: {
				...(queries || {}),
				baseUrl: Config.UMBRACO_URL,
				slug: route,
			},
		};
	}

	return {
		docType: 'page',
		queryParams: {
			...(queries || {}),
			baseUrl: Config.UMBRACO_URL,
			slug: route,
		},
	};
}
