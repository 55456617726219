import { RefObject, useEffect } from 'react';

export const useAdjustedDropdownHeight = (ref: RefObject<HTMLElement>) => {
	const updateHeight = () => {
		const { current } = ref;
		if (current === null) return;

		const { y } = current?.getBoundingClientRect() || 0;
		current.style.height = `${window.innerHeight - y}px`;
	};

	useEffect(() => {
		window.addEventListener('resize', updateHeight);
		window.addEventListener('serviceBannerChanged', updateHeight);
		updateHeight();

		return () => {
			window.removeEventListener('resize', updateHeight);
			window.removeEventListener('serviceBannerChanged', updateHeight);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);

	return { updateHeight };
};
