import { Object } from 'ts-toolbelt';

import { type ButtonProps, Button } from '@dansk-metal/ui';

import { Link } from '@web/services/umbraco/types/basic/Link';

export type UmbracoLinkProps = {
	link: Object.Optional<Link, 'key'>;
} & ButtonProps;

export function UmbracoLink({ link, children, variant, ...rest }: UmbracoLinkProps) {
	const isExternal =
		link.type === 'EXTERNAL'
		|| link.url?.includes('mailto:')
		|| link.url?.includes('tel:')
		|| link.type === 'MEDIA'
		|| link.target === '_blank';

	return (
		<Button
			href={hrefFromLink(link)}
			{...(link.type !== 'CONTENT' && {
				target: link.target,
			})}
			{...(isExternal && {
				// Disabled talk to Niclas
				// rel: 'noreferrer',
				target: '_blank',
			})}
			variant={variant || 'inline'}
			{...rest}
		>
			{children || link.name}
		</Button>
	);
}

function hrefFromLink(link: Object.Optional<Link, 'key'>) {
	return link.url;
}
