import { ClientOptions } from '@urql/core';
import fetch from 'cross-fetch';

import Config from '@apps/web/src/config';

if (!Config.UMBRACO_URL) {
	throw new Error('No project id configured - Please set the SANITY_PROJECT_ID environment variable\n');
}

export const config: ClientOptions = {
	url: `${Config.UMBRACO_URL}/graphql`,
	requestPolicy: 'network-only',
	fetch,
};

export const defaultLanguage = 'en';

export const mapping = {
	[defaultLanguage]: '/',
	da: '/da',
};

export enum SSGRouteSortOrder {
	SortOrder = 'sortOrder',
	UpdateDate = 'updateDate',
}

const defaultSSGConfig = { orderBy: SSGRouteSortOrder.SortOrder, limit: null };

/**
 * This is the global config for the umbraco template.
 * @param Sitemap: boolean - if true, the page will be included in the sitemap
 * @param SSG.orderBy: SSGRouteSortOrder - defines the sort order of the ssg routes.
 * @param SSG.limit: number - defines the limit of the ssg routes. If null, all routes will be rendered.
 */
export const umbracoTemplateConfig = {
	siteRoot: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	contentPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	newsOverviewPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	newsPage: {
		sitemap: true,
		ssg: { orderBy: SSGRouteSortOrder.UpdateDate, limit: 50 },
	},
	publicationsOverviewPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	publicationPage: {
		sitemap: true,
		ssg: { orderBy: SSGRouteSortOrder.UpdateDate, limit: 50 },
	},
	departmentsOverviewPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	departmentPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	userMemberPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	memberContentPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	transportPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	searchResultPage: {
		sitemap: false,
		ssg: defaultSSGConfig,
	},
	loginPage: {
		sitemap: true,
		ssg: defaultSSGConfig,
	},
	errorPage: {
		sitemap: false,
		ssg: defaultSSGConfig,
	},
};
