import Link from 'next/link';
import cn from 'classnames';

import { QBankImage } from '@web/components/qbank-image/qbank-image';
import { Theme } from '@web/components/theme/theme';

import { NewsSearchItem } from '@web/services/umbraco/rest';
import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';

import styles from './news-item.module.scss';

export type NewsItemProps = {
	newsItem: NewsSearchItem;
	extendedVersion?: boolean;
};

export function NewsItem({ newsItem, extendedVersion }: NewsItemProps) {
	const { title, image, publishDate, tags } = newsItem;

	return (
		<Theme className={cn(styles.container, { [styles.extended]: !!extendedVersion })}>
			<Link
				href={newsItem.url}
				className={cn(styles.content, {
					[styles.content__has_img]: !!image,
				})}
			>
				{image && (
					<div className={styles.content__image_container}>
						<QBankImage media={image as QBankImageType} />
					</div>
				)}
				<div className={styles.content__text_container}>
					<div className={styles.info_line}>
						<span className={styles.tag}>{tags ? tags[0] : ' '}</span>
						{publishDate && (
							<span className={styles.date}>
								{publishDate}
							</span>
						)}
					</div>
					<div className={styles.headline__container}>
						{title && (
							<h3 className={styles.content__headline} dangerouslySetInnerHTML={{ __html: title }} />
						)}
					</div>
				</div>
			</Link>
		</Theme>
	);
}
