import { useEffect, useState } from 'react';

import { Container } from '@dansk-metal/ui';

import { Feedback } from '@apps/web/src/components/feedback/feedback';
import { getSiteimproveGlobal, submitFeedback } from '@apps/web/src/services/siteimprove/feedback';
import { SiteImproveGlobal } from '@apps/web/src/services/siteimprove/types';
import { waitForProp } from '@apps/web/src/utils/wait-for-prop';

// TODO: Mount page in [...slug] route, when we got umbraco control figured out.
export function SiteimproveFeedback() {
	const [hidden, setHidden] = useState<boolean>(true);

	const [text, setTexts] = useState<SiteImproveGlobal['feedback']['config']['texts']>({
		button: 'Submit',
		close: 'Close',
		comment: 'Please leave your feedback here',
		confirmation: 'Tak for dit svar',
		question: 'Har du forslag til, hvordan vi kan forbedre indholdet?',
		title: 'Fandt du, hvad du ledte efter?',
		commentHeader: '',
		supportLinkText: '',
		supportUrl: '#',
		errors: {
			grade: 'Please choose a rating',
			comment: 'Please leave feedback for us in the comment area',
		},
		dontShowAgain: "Don't show this again",
	});

	useEffect(() => {
		getSiteimproveGlobal()
			.then((sz) => {
				waitForProp(() => !!sz?.feedback?.feedbackid, 10, 1000)
					.then(() => {
						setTexts((defaultTexts) => ({ ...defaultTexts, ...sz.feedback.config.texts }));
						return setHidden(false);
					})
					.catch(() => {
						setHidden(true);
					});
			});
	}, []);

	function onFeedbackSubmit({ rating, comment }: { rating: number; comment?: string }) {
		submitFeedback(rating, comment);
	}

	if (hidden) {
		return null;
	}

	return (
		<Container>
			<Feedback onSubmit={onFeedbackSubmit} text={text} />
		</Container>
	);
}
