import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export class BaseConverter {
	static UmbracoType: Omit<BasicProperty, 'value'> & { editorAlias: string; value: unknown };

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return true;
	}

	public static mapProperty(prop: typeof this.UmbracoType) {
		return prop.value;
	}
}
