import { useRef, useState } from 'react';
import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container, Select, useForm, yup, yupResolver } from '@dansk-metal/ui';

import { OKCalculatorForm } from '@web/blocks/ok-calculator-block/components/ok-calculator-form';
import { OKCalculatorResult } from '@web/blocks/ok-calculator-block/components/ok-calculator-result';
import { optionTimeValue, timeOptions } from '@web/blocks/ok-calculator-block/types';

import { Richtext } from '@web/components/richtext/richtext';
import { Theme } from '@web/components/theme/theme';

import { validationMessages } from '@web/constants/messages/validation-messages';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ThemeDefinition } from '@web/services/umbraco/types/basic/Color';

import styles from './ok-calculator-block.module.scss';

const blockName = 'okCalculator' as const;

export type OKCalculatorBlockProps = {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: string;
			subtitle?: string;
			hoursPerYear: number;
			selectionWageAccountPercentage: number; // fritvalgslønkonto
			vacationBenefitPercentage: number; // feriepenge
			pensionPercentage: number;
			ikufPercentage: number; // industriens kompetenceudviklingsfond
			hourlySicknessBenefit: number; // sygedagpenge
			hourlyDailyAllowance: number; // dagpenge
		},
		{
			colorTheme?: ThemeDefinition;
		}
	>;
};


const schema = yup.object({
	salary: yup.string().required(validationMessages.salary.empty).matches(/^[-,0-9]+$/, validationMessages.salary.error).typeError(validationMessages.salary.error),
	unit: yup.string().required().oneOf(['hour', 'month']),
});
export interface FormValues extends yup.InferType<typeof schema> {
	unit: optionTimeValue;
}

export function OKCalculatorBlock({ block }: OKCalculatorBlockProps) {
	const { settingsProperties = {} } = block;
	const { colorTheme } = settingsProperties || {};
	const ref = useRef<HTMLDivElement>(null);

	const handleScrollIntoView = () => {
		ref?.current?.scrollIntoView();
	};

	const {
		title,
		subtitle,
		hoursPerYear,
		selectionWageAccountPercentage,
		vacationBenefitPercentage,
		pensionPercentage,
		ikufPercentage,
		hourlySicknessBenefit,
		hourlyDailyAllowance,
	} = block.contentProperties || {};

	const constants = {
		hoursPerYear,
		selectionWageAccountPercentage: selectionWageAccountPercentage / 100,
		vacationBenefitPercentage: vacationBenefitPercentage / 100,
		pensionPercentage: pensionPercentage / 100,
		ikufPercentage: ikufPercentage / 100,
		hourlySicknessBenefit,
		hourlyDailyAllowance,
	};

	const [showResult, setShowResult] = useState(false);

	const { ...form } = useForm<FormValues>(
		{
			defaultValues: {
				salary: undefined,
				unit: 'hour',
			},
			resolver: yupResolver(schema),
			reValidateMode: 'onSubmit',
		});

	const salary = form.getValues('salary');
	const unit = form.getValues('unit');

	const onSubmit = () => {
		setShowResult(true);
	};

	function setRegionValue(value) {
		form.setValue('unit', value?.value);
	}

	const select = Select.useSelect({
		isMulti: false,
		initialSelect: timeOptions[0],
		onChange: setRegionValue,
	});

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme?.theme),
			})}
		>
			<Container className={styles.container} ref={ref}>
				<div className={cn(styles.content)}>
					<div className={styles.header}>
						<h2>{title}</h2>
						{subtitle &&
							<Richtext content={subtitle} />
						}
					</div>
					{!showResult ?
						<OKCalculatorForm
							onSubmit={onSubmit}
							handleSubmit={form.handleSubmit}
							getValues={form.getValues}
							select={select}
							control={form.control}
							formState={form.formState}
						/> :
						<OKCalculatorResult
							handleReturnToForm={setShowResult}
							salary={salary}
							unit={unit}
							constants={constants}
							scrollIntoViewHandler={handleScrollIntoView}
						/>
					}
				</div>
			</Container>
		</Theme>
	);
}

OKCalculatorBlock.blockName = blockName;
