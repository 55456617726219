import cn from 'classnames';

import { getPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import { Aspect } from '@web/components/media/aspectRatios';
import { QBankImage } from '@web/components/qbank-image/qbank-image';
import { Theme } from '@web/components/theme/theme';
import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import { ColorTheme } from '@web/services/umbraco/types/basic/Color';
import { Link } from '@web/services/umbraco/types/basic/Link';
import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';

import { formatPhoneNumber } from '@web/utils/format-string';

import styles from './hero-department.module.scss';

export interface HeroDepartmentProps {
	image: QBankImageType;
	phone: string;
	email: string;
	contactPageLink: Link;
}

export function HeroDepartment(props: HeroDepartmentProps) {
	const { image, phone, email, contactPageLink } = props;

	return (
		<Theme palette={getPallette(ColorTheme.Darkblue)} className={cn(styles.theme, styles.has_color_theme)}>
			<div className={styles.relative}>
				{image && <QBankImage className={styles.image} media={image} aspectRatio={Aspect['4:3']} priority />}
				<div className={styles.overlay}>
					<Container className={styles.container}>
						<div className={cn(styles.content, styles.has_color_theme)}>
							<div>
								<a href={`tel:${phone}`} className={styles.phone}>
									{formatPhoneNumber(phone)}
								</a>
							</div>
							<div>
								<a href={`mailto:${email}`} className={styles.email}>
									{email}
								</a>
							</div>
							{contactPageLink && (
								<div className={styles.contact_link}>
									<UmbracoLink icon={{ icon: 'arrow-right' }} link={contactPageLink} />
								</div>
							)}
						</div>
					</Container>
				</div>
			</div>
		</Theme>
	);
}
