import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { Button, Container, Icon } from '@dansk-metal/ui';

import { SelectValueType } from '@web/blocks/apprentice-salary-calculator-block/types';
import { mapRegionData, RegionData } from '@web/blocks/salary-calculator-block/calculation/salary-calculation';
import { SalaryDrawerDetails } from '@web/blocks/salary-calculator-block/components/drawer-details';
import { defaultStatisticsResult } from '@web/blocks/salary-calculator-block/mocks/default-result';

import { ContentDrawer } from '@web/components/content-drawer/content-drawer';
import { ThemedSkeleton } from '@web/components/themed-skeleton/themed-skeleton';

import { useGetSalaryStatistic } from '@web/services/crm-api/rest';

import { formatNumberThousandsDots } from '@web/utils/format-number-thousands-dots';

import 'swiper/css';

import styles from './salary-calculator-results.module.scss';

export interface SalaryCalculatorResultsProps {
	handleReturnToForm: (boolean) => void;
	selectedRegion: SelectValueType | undefined;
	selectedProfession: SelectValueType | undefined;
}

export function SalaryCalculatorResults({
	handleReturnToForm,
	selectedRegion,
	selectedProfession,
}: SalaryCalculatorResultsProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedRegionStatistics, setSelectedRegionStatistics] = useState<RegionData>();
	const [selectedTab, setSelectedTab] = useState<'hourly' | 'monthly'>('hourly');
	const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const [resultOutput, setResultOutput] = useState<RegionData[]>(defaultStatisticsResult);
	const professionId = selectedProfession?.value || 0;
	const { data, isLoading } = useGetSalaryStatistic(Number(professionId));

	const handleOpenDrawer = (region: RegionData, hasData) => {
		if (hasData) {
			setSelectedRegionStatistics(region);
			setIsOpen(true);
		}
	};

	function getLastIndex() {
		if (swiperRef) {
			return swiperRef.currentBreakpoint < 640 ? 4 : 3;
		}
	}

	const calculateHeight = (value: number) => {
		const arrayOfValues = resultOutput.map((item) => (item.totalData.average ? item.totalData.average : 0));
		const highestValue = Math.max(...arrayOfValues);
		if (value === highestValue) return 400;
		return (value * 400) / highestValue;
	};

	const selectTimeTab = (value) => {
		setSelectedTab(value);
	};

	const swiperProps: SwiperProps = {
		breakpoints: {
			1: {
				slidesPerView: 2,
				spaceBetween: 8,
			},
			375: {
				slidesPerView: 2,
				spaceBetween: 8,
			},
			640: {
				slidesPerView: 3,
				spaceBetween: 8,
			},
			960: {
				slidesPerView: 6,
				spaceBetween: 8,
			},
			1280: {
				slidesPerView: 6,
				spaceBetween: 8,
			},
		},
	};

	const handleLeftClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slidePrev();
	}, [swiperRef]);

	const handleRightClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slideNext();
	}, [swiperRef]);

	const handleOnSwiper = (swiper: SwiperClass) => {
		if (swiperProps?.onSwiper) {
			swiperProps.onSwiper(swiper);
		}

		if (!swiperRef) {
			setSwiperRef(swiper);
		}
	};

	const onSwiperChange = (swiper: SwiperClass) => {
		if (swiper.realIndex || swiper.realIndex === 0) {
			setActiveIndex(swiper.realIndex);
		} else {
			setActiveIndex(swiper.clickedIndex);
		}
	};

	useEffect(() => {
		if (!data || !selectedRegion || !selectedTab) return;
		const result = mapRegionData(data, selectedRegion?.value, selectedTab);
		setResultOutput(result);
	}, [data, selectedRegion, selectedTab]);


	return (
		<Container>
			<h3 className={cn('h5', styles.sub_header)}>Så meget tjener andre af faggruppen “{selectedProfession?.label.toLowerCase()}”</h3>
			<div className={styles.tabs_container}>
				<ul className={styles.tabs}>
					<li
						onClick={() => selectTimeTab('hourly')}
						className={`${styles.tabs__item} ${selectedTab === 'hourly' && styles.selected}`}
					>
						Timeløn
					</li>
					<li
						onClick={() => selectTimeTab('monthly')}
						className={`${styles.tabs__item} ${selectedTab === 'monthly' && styles.selected}`}
					>
						Pr. måned
					</li>
				</ul>
				<div className={styles.line}/>
			</div>
			<p className={styles.info_text}>Tryk på søjlerne for at se detaljer for udregningerne</p>
			<Swiper
				{...swiperProps}
				className={styles.swiper}
				onSwiper={handleOnSwiper}
				onSlideChange={onSwiperChange}
			>
				{resultOutput && resultOutput.map((region) => {
					const hasData = region.people > 0;
					const height = { height: `${region.totalData.average ? calculateHeight(region.totalData.average) : 114}px` };

					return (
						<SwiperSlide
							key={region.name}
							className={styles.swiper_item}
							onClick={() => handleOpenDrawer(region, hasData)}
						>
							{isLoading ? (
								<ThemedSkeleton containerClassName={styles.loader_slider}/>
							) : (
								<>
									{hasData ? (
										<div className={styles.swiper__bar} style={height}>
											<p>
												<span className={styles.bold}>
													{formatNumberThousandsDots(region.totalData.average)}
												</span> kr.
											</p>
										</div>
									) : (
										<div className={styles.swiper__no_data}>
											<p>Der er ikke nok data til at lave et gennemsnit.</p>
										</div>
									)}
								</>
							)}
							<div>
								<p className={styles.bold}>{region.name}</p>
								<p>{region.people} personer</p>
							</div>
						</SwiperSlide>
					);
				})}
				<>
					<Button
						className={cn(styles.nav_left, { [styles.disable]: activeIndex === 0 })}
						onClick={handleLeftClick}
						variant="fill-secondary"
					>
						<Icon size={24} icon="arrow-left" />
					</Button>
					<Button
						className={cn(styles.nav_right, { [styles.disable]: activeIndex === getLastIndex() })}
						onClick={handleRightClick}
						variant="fill-secondary"
					>
						<Icon size={24} icon="arrow-right" />
					</Button>
				</>
			</Swiper>
			<Button
				onClick={() => handleReturnToForm(false)}
				variant='stroke'
				icon={{ icon: 'edit' }}
			>
				Redigér dine indtastninger
			</Button>
			{selectedRegionStatistics && (
				<ContentDrawer
					open={isOpen}
					onClose={() => setIsOpen(false)}
				>
					<SalaryDrawerDetails region={selectedRegionStatistics} timePeriod={selectedTab}/>
				</ContentDrawer>
			)}
		</Container>
	);
}
