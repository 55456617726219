import { treatAsUTC } from '@dansk-metal/utils/date';

/**
 * Get the number of days between two dates
 * If the time between the dates is more than 24 hours, the result will be 2
 */
export function getDaysBetween(startDate: string, endDate: string) {
	const millisecondsPerDay = 24 * 60 * 60 * 1000;

	return Math.ceil((treatAsUTC(endDate).valueOf() - treatAsUTC(startDate).valueOf()) / millisecondsPerDay);
}
