export async function waitForProp(evaluate: () => boolean, interval = 50, timeout = 5000): Promise<void> {
	return new Promise((resolve, reject) => {
		let timeoutRef: ReturnType<typeof setTimeout>;

		const intervalRef = setInterval(() => {
			if (evaluate()) {
				clearInterval(intervalRef);
				resolve();

				if (timeoutRef) {
					clearTimeout(timeoutRef);
				}
			}
		}, interval);

		timeoutRef = setTimeout(() => {
			clearInterval(intervalRef);
			reject(new Error('waitForProp timed out'));
		}, timeout);
	});
}
