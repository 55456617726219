import cn from 'classnames';

import { Container } from '@dansk-metal/ui';

import { ContactCard, ContactCardBlock } from '@web/blocks/contact-cards-block/contact-card/contact-card';
import { CTACard, CTACardBlock } from '@web/blocks/contact-cards-block/cta-card/cta-card';

import { Theme } from '@web/components/theme/theme';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';

import styles from './contact-cards-block.module.scss';

const contactCards = 'contactCardsBlock' as const;

export type ContactCardsBlockProps = {
	block: UmbracoBlock<
		typeof contactCards,
		{
			cards: Card[];
		}
	>;
};

type Card = ContactCardBlock | CTACardBlock;

export function ContactCardsBlock({ block }: ContactCardsBlockProps) {
	const { cards } = block.contentProperties || {};

	return (
		<Theme className={styles.theme}>
			<Container>
				<div className={styles.content}>
					{cards.map((card) => (
						<RenderCard card={card} key={card.key} />
					))}
				</div>
			</Container>
		</Theme>
	);
}

ContactCardsBlock.blockName = contactCards;

function RenderCard({ card }: { card: Card }) {
	if (card.contentAlias === 'contactCard') {
		return (
			<div className={cn(styles.card, styles[`card__${'half'}`])}>
				<ContactCard card={card} />
			</div>
		);
	}

	if (card.contentAlias === 'ctaCard') {
		return (
			<div className={styles.card}>
				<CTACard card={card} />
			</div>
		);
	}

	return null;
}
