import { useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { Button, Container } from '@dansk-metal/ui';

import { ContentDrawer } from '@web/components/content-drawer/content-drawer';
import { Loader } from '@web/components/loader/loader';
import { Richtext } from '@web/components/richtext/richtext';
import { getUmbracoButtonVariant } from '@web/components/umbraco-action/helper';
import { useFormDefinitionLoader } from '@web/components/umbraco-form/helpers/form.loader';
import { UmbracoDrawerForm } from '@web/components/umbraco-form/umbraco-drawer-form';

import { useGlobalContext } from '@web/layouts/page/context';

import { postMemberEventRegistration } from '@web/services/crm-api/rest';
import { FormDrawerAction } from '@web/services/umbraco/types/elements/Action';
import { FormEntity, FormEntityAction } from '@web/services/umbraco/types/entities/form.entity';

import styles from './form-drawer-button.module.scss';

export interface FormDrawerButtonProps {
	action: FormDrawerAction;
}

export function FormDrawerButton({ action }: FormDrawerButtonProps) {
	const { form, style: buttonStyle, title: buttonLabel } = action.contentProperties;
	const [visible, setVisible] = useState(false);

	function openDrawer() {
		setVisible(true);
	}

	function onDrawerClose() {
		setVisible(false);
	}

	return (<>
		<EntityFormDrawer
			entity={form}
			open={visible}
			onClose={onDrawerClose}
		/>
		<Button
			onClick={openDrawer}
			icon={{ icon: 'arrow-right', size: 24, position: 'after' }}
			variant={getUmbracoButtonVariant(buttonStyle) || 'inline'}
		>
			{buttonLabel}
		</Button>
	</>);
}

interface EntityFormDrawerProps {
	open: boolean;
	onClose: () => void;
	entity: FormEntity;
}

function EntityFormDrawer({ entity, open, onClose }: EntityFormDrawerProps) {
	const [formSubmitted, setFormSubmitted] = useState(false);
	const { preview, routeData } = useGlobalContext();
	const contentId = routeData?.id?.toString();

	const {
		error,
		data: formDefinition,
		status,
	} = useFormDefinitionLoader(entity.properties.definition, { contentId, preview });

	async function onSubmit(values: FieldValues) {
		if (entity.properties.action !== FormEntityAction.SaveInUmbraco) {
			await handleSubmitAction(entity.properties.action, values);
		}

		setFormSubmitted(true);
	}

	return (
		<ContentDrawer open={open} onClose={onClose}>
			{!formSubmitted && (
				<Container>
					<div className={styles.header}>
						<h2>{entity.properties.title}</h2>
						{entity.properties.subtitle && (
							<Richtext content={entity.properties.subtitle} />
						)}
					</div>
					{status === 'loading' && (
						<Loader size='large' />
					)}
					{status === 'error' && (
						JSON.stringify(error)
					)}
					{status === 'success' && formDefinition && (
						<UmbracoDrawerForm
							definition={formDefinition}
							contentId={contentId}
							submitToUmbraco={entity.properties.action === FormEntityAction.SaveInUmbraco}
							onSubmit={onSubmit}
						/>
					)}
				</ Container>
			)}

			{formSubmitted && (
				<Container>
					<h2>{entity.properties.confirm.title}</h2>
					{entity.properties.confirm.subtitle && (
						<Richtext content={entity.properties.confirm.subtitle} />
					)}
					<Button onClick={onClose} variant="fill-primary" icon={{ icon: 'arrow-right', position: 'after', size: 24 }}>Luk</Button>
				</ Container>
			)}
		</ContentDrawer>
	);
}

async function handleSubmitAction(action: FormEntityAction, values: FieldValues) {
	if (action === FormEntityAction.CRM_Contact) {
		// TODO: Replace with the real api endpoint
		return postMemberEventRegistration('', '', values);
	}

	if (action === FormEntityAction.CRM_CallMe) {
		// TODO: Replace with the real api endpoint
		return postMemberEventRegistration('', '', values);
	}
}
