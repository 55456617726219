/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Default API
 * All endpoints not defined under specific APIs
 * OpenAPI spec version: Latest
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { umbracoAxios } from '../axios';
import type { RedirectItem } from '../umbraco.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Returns a list of all redirects from Skybrud redirects plugin
 */
export const getApiRedirects = (options?: SecondParameter<typeof umbracoAxios>, signal?: AbortSignal) => {
	return umbracoAxios<RedirectItem[]>({ url: '/api/redirects', method: 'get', signal }, options);
};

export const getGetApiRedirectsQueryKey = () => ['/api/redirects'] as const;

export const getGetApiRedirectsQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiRedirects>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getApiRedirects>>, TError, TData>;
	request?: SecondParameter<typeof umbracoAxios>;
}): UseQueryOptions<Awaited<ReturnType<typeof getApiRedirects>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiRedirectsQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRedirects>>> = ({ signal }) =>
		getApiRedirects(requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetApiRedirectsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRedirects>>>;
export type GetApiRedirectsQueryError = ErrorType<unknown>;

/**
 * @summary Returns a list of all redirects from Skybrud redirects plugin
 */
export const useGetApiRedirects = <
	TData = Awaited<ReturnType<typeof getApiRedirects>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getApiRedirects>>, TError, TData>;
	request?: SecondParameter<typeof umbracoAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiRedirectsQueryOptions(options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
