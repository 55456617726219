
import { entries } from '@dansk-metal/utils/common';

import { defaultLanguage, mapping } from './config';

export function getCultureFromUrl(url: string): keyof typeof mapping {
	const match = entries(mapping)
		.filter(([key]) => key !== defaultLanguage)
		.find(([_key, value]) => {
			return url.split('/')?.[1] === value.split('/')?.[1];
		});

	if (match) {
		return match[0];
	}

	return defaultLanguage;
}

export function getRootUrl(url: string): string {
	return mapping[getCultureFromUrl(url)];
}

export function hasParentRoute(route: string): boolean {
	return route.split('/').length > 2;
}

export function getParentRoute(route: string): string {
	return route.split('/').slice(0, -1).join('/');
}

export function getChildSegment(route: string): string {
	return route.split('/').pop() || '';
}

export function convertSlugToRoute(slug: string[]): string {
	if (slug.length === 0) {
		return '/';
	}

	const route = slug.join('/');

	if (route.startsWith('/')) {
		return route;
	}

	return `/${route}`;
}

export function normalizeSlug(slug: undefined | string | string[]): string[] {
	if (!slug) {
		return [];
	}

	return Array.isArray(slug) ? slug : [slug];
}
