import { showReportDialog } from '@sentry/nextjs';

import { Button, Container } from '@dansk-metal/ui';

import { LoginButton, LoginButtonProps } from '@web/components/login-button/login-button';
import { QBankVector } from '@web/components/qbank-vector/qbank-vector';
import { Richtext } from '@web/components/richtext/richtext';

import { useGlobalContext } from '@web/layouts/page/context';

import { getLinkFixture } from '@web/services/umbraco/__fixtures__/link.fixture';
import { UmbracoErrorType } from '@web/services/umbraco/types/basic/ErrorTypes';
import { LinkStyle } from '@web/services/umbraco/types/elements/Action';

import { ErrorPageData } from '@web/templates/errorpage';

import styles from './error-frame.module.scss';

export interface ErrorFrameProps extends Pick<LoginButtonProps, 'callbackUrl'> {
	errorType: UmbracoErrorType;
	eventId?: string | null;
	pageData?: ErrorPageData;
}

const fallbackErrorData: Partial<ErrorPageData['properties']> = {
	title: 'Noget gik galt',
	subtitle: '<p>Det ser ud til, at siden ikke kan hentes. Prøv at genindlæse siden og se om problemet forsvinder.</p>',
	link: {
		contentAlias: 'linkItem',
		key: '0-0-0-0-0',
		settingsProperties: {},
		contentProperties: {
			link: getLinkFixture('internal', 'Gå til forsiden', '/'),
			style: LinkStyle.DarkBlueButton,
		},
	},
};

export function ErrorFrame({ errorType, callbackUrl, eventId, pageData }: ErrorFrameProps) {
	const { rootData } = useGlobalContext();

	const { title, subtitle, illustration, textBelowLink, link } =
		(pageData?.properties as ErrorPageData['properties'] | undefined)
		|| (rootData[errorType]?.properties as ErrorPageData['properties'] | undefined)
			|| fallbackErrorData;

	return (
		<Container className={styles.container}>
			{illustration && (
				<QBankVector className={styles.img_container} media={illustration} width="160px" height="160px" />
			)}
			<h2>{title}</h2>
			<div className={styles.subheading_container}>
				{subtitle && <Richtext className={styles.subheading} content={subtitle} />}
				{eventId && <p className={styles.subheading}><br/>Fejl ID: {eventId}</p>}
			</div>
			<div className={styles.link_container}>
				{eventId && <Button onClick={() => showReportDialog({ eventId })}>Meld fejl</Button>}
				{errorType === UmbracoErrorType.Unauthorized && (
					<LoginButton callbackUrl={callbackUrl} label={link?.contentProperties.link.name || 'Log ind'} />
				)}
				{errorType !== UmbracoErrorType.Unauthorized && link && (
					<Button
						href={link.contentProperties.link.url}
						variant="fill-primary"
						icon={{ icon: 'house', position: 'after', size: 16 }}
					>
						{link?.contentProperties.link.name || 'Gå til forsiden'}
					</Button>
				)}
			</div>
			{textBelowLink && <Richtext className={styles.paragraph} content={textBelowLink} />}
		</Container>
	);
}
