import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import cn from 'classnames';

import { getTestAttr } from '@dansk-metal/service/testing';
import { vars } from '@dansk-metal/theme';
import { Container, Icon } from '@dansk-metal/ui';

import { Breadcrumbs } from '@web/components/breadcrumbs/breadcrumbs';
import { HeaderContextProvider } from '@web/components/header/context';
import { DropdownMenu } from '@web/components/header/menu/dropdown-menu/dropdown-menu';
import { NavigationCard } from '@web/components/header/menu/navigation-card/navigation-card';
import { NavigationTextItem } from '@web/components/navigation/navigation-text-item/navigation-text-item';
import { NavigationIcon } from '@web/components/navigation/navigations-icon/navigation-icon';

import { useGlobalContext } from '@web/layouts/page/context';

import { useLockScroll } from '@web/hooks/useLockScroll';

import { AncestorMenu } from '../ancestor-menu/ancestor-menu';

import styles from './header.module.scss';

function Logo({ hasLink }: { hasLink?: boolean }) {
	return hasLink ? (
		<Link aria-label='Gå til forside' href={'/'} className={styles.logo}>
			<Icon icon="logo" color={vars['--color-secondary']} />
		</Link>
	) : (
		<div className={styles.logo}>
			<Icon icon="logo" color={vars['--color-secondary']} />
		</div>
	);
}

export type OpenHeaderIcon = 'account' | 'search' | 'menu' | 'showMore';

export function Header() {
	const ref = useRef<HTMLElement>(null);
	const router = useRouter();
	const pathname = usePathname();
	const { enableScroll, disableScroll } = useLockScroll();
	const { status } = useSession();
	const [currentMenu, setCurrentMenu] = useState<string | null>(null);

	const { routeData, rootData: { properties: {
		header: { primaryMenu = [] } = {} } = {},
	memberPageUrl,
	rootTransportPageUrl,
	} = {
		memberPageUrl: undefined,
		rootTransportPageUrl: undefined,
	},
	} =	useGlobalContext() || {};

	const isCampaign = routeData?.properties.headerType === 'campaign';
	const isCampaignLogoLink: boolean = routeData?.properties?.linkHeaderToSiteRoot === true;
	const pastScrollY = useRef<number>(0);

	const setCurrentMenuOrReset = (icon: OpenHeaderIcon | null) => {
		if (icon) {
			setCurrentMenu(currentMenu === icon ? null : icon);
		} else {
			setCurrentMenu(null);
		}
	};

	useEffect(() => {
		let distance: number | null = null;
		const onScroll = () => {
			const { current } = ref;
			if (current) {
				const { height } = current.getBoundingClientRect();
				const { scrollY } = window;
				const scrollingBackwards = scrollY - pastScrollY.current <= 0;
				const step = pastScrollY.current - scrollY;

				if (scrollingBackwards) {
					if (distance === null) {
						distance = height;
					} else {
						distance = Math.max(distance - step, 0);
					}
				} else {
					distance = null;
				}

				const d = distance || 0;
				const shouldStick = scrollY > d;
				current.style.position = shouldStick && scrollingBackwards ? 'sticky' : 'relative';
				if (shouldStick) current.style.transform = `translateY(${-d}px)`;
				pastScrollY.current = scrollY;
			}
		};

		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	useEffect(() => {
		if ((currentMenu && window.innerWidth < 640) || currentMenu === 'showMore') {
			disableScroll();
			return () => enableScroll();
		}

		enableScroll();
	}, [currentMenu, disableScroll, enableScroll]);

	const isCurrentOrParentPath = (path) => {
		if (typeof pathname !== 'string') return false;
		return pathname.indexOf(path) === 0;
	};

	return (
		<HeaderContextProvider value={{ currentMenu, setCurrentMenu: setCurrentMenuOrReset }}>
			<header ref={ref} className={cn(styles.header, { [styles.campaign]: isCampaign })} {...getTestAttr('header')}>
				{isCampaign ? (
					<Logo hasLink={isCampaignLogoLink} />
				) : (
					<Container {...getTestAttr('header_content')} className={styles.content}>
						<div className={styles.centered}>
							<div onClick={() => setCurrentMenuOrReset(null)}>
								<Logo hasLink={true} />
							</div>
							<div className={cn(styles.menu)}>
								<NavigationTextItem
									text="Få svar"
									onClick={() => setCurrentMenuOrReset('showMore')}
									icon={currentMenu === 'showMore' ? 'chevron-up' : 'chevron-down'}
									active={isCurrentOrParentPath(rootTransportPageUrl)}
								/>
								{(primaryMenu || []).map((link) => (
									<Link
										key={link.key}
										href={link.url}
										className={cn(styles.menu__primary, { [styles.menu__active]: isCurrentOrParentPath(link.url) })}
										onClick={() => setCurrentMenuOrReset(null)}
									>
										{link.name}
									</Link>
								))}
							</div>
							<DropdownMenu />
						</div>
						<div className={cn(styles.centered, styles.menu__icons)}>
							<NavigationIcon
								ariaLabel={status === 'authenticated' ? 'Gå til Mit Metal' : 'Åben Mit Metal menu'}
								name="account"
								filled={status === 'authenticated'}
								{...(status === 'authenticated' ? { onClick: () => router.push(memberPageUrl || '/') } : null)}
							/>
							<NavigationIcon ariaLabel='Åben søge menu' name="search" />
							<NavigationIcon ariaLabel='Åben sekundær menu' name="menu" />
							<NavigationCard />
						</div>
					</Container>
				)}
			</header>
			<Breadcrumbs />
			<AncestorMenu />
		</HeaderContextProvider>
	);
}
