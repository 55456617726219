import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { Button, Form, Icon, Select } from '@dansk-metal/ui';

import { SelectValueType } from '@web/blocks/apprentice-salary-calculator-block/types';

import { FormFooter, FormWrapper } from '@web/components/form-wrapper/form-wrapper';

import { useGetSalaryStatisticProfessions, useGetSalaryStatisticRegions } from '@web/services/crm-api/rest';

import { sortDanish } from '@web/utils/sort-danish';

import { FormValues } from '../salary-calculator-block';

import styles from './salary-calculator-form.module.scss';

interface SalaryCalculatorFormProps {
	setValue: Form.UseFormSetValue<FormValues>;
	formHandleSubmit: Form.UseFormHandleSubmit<{
		profession: SelectValueType;
		region: SelectValueType;
	}, undefined>;
	formSubmitHandler: () => void;
	control: Form.Control<{ profession: SelectValueType; region: SelectValueType }, object>;
}

export function SalaryCalculatorForm({ formHandleSubmit, formSubmitHandler, control, setValue }: SalaryCalculatorFormProps) {
	const { data: professions, isLoading: isProfessionsLoading } = useGetSalaryStatisticProfessions();
	const { data: regions, isLoading: isRegionsLoading } = useGetSalaryStatisticRegions();
	const [professionInputText, setProfessionInputText] = useState('');
	const regionOrder = ['Nordjylland', 'Midtjylland', 'Syddanmark', 'Hovedstaden', 'Sjælland'];

	function handleSearch(inputText: string, event) {
		if (event.action !== 'input-blur' && event.action !== 'menu-close') {
			setProfessionInputText(inputText);
			setValue('profession', { value: '', label: inputText });
		}
	}

	return (
		<form className={styles.form_container} onSubmit={formHandleSubmit(formSubmitHandler)}>
			<FormWrapper>
				<div className={styles.form}>
					<Controller
						control={control}
						name="region"
						render={({ field, fieldState }) => {
							const regionOptions = (regions || [])
								.map((e) => ({ value: String(e.regionId), label: e.regionName }))
								.sort((a, b) => regionOrder.indexOf(a.label) - regionOrder.indexOf(b.label));

							return (
								<Select
									{...field}
									fieldState={fieldState}
									isLoading={isRegionsLoading}
									className={styles.form__inputs}
									noOptionalLabel
									required
									placeholder="Vælg region"
									label="Hvor ligger din arbejdsplads?"
									options={regionOptions}
								/>
							);
						}}
					/>
					<Controller
						control={control}
						name="profession"
						render={({ field, fieldState }) => {
							const professionOptions = (professions || [])
								.map((e) => ({ value: String(e.professionId), label: e.professionName }))
								.sort((a, b) => sortDanish(a.label, b.label));

							return (
								<Select
									{...field}
									fieldState={fieldState}
									isLoading={isProfessionsLoading}
									onInputChange={(inputText, event) => handleSearch(inputText, event)}
									inputValue={professionInputText}
									noOptionalLabel
									label="Hvilken faggruppe tilhører du?"
									className={styles.form__inputs}
									options={professionOptions}
									placeholder="Find din faggruppe"
									components={{ DropdownIndicator: () => <Icon icon="search" size="24px" /> }}
									isSearchable
								/>
							);
						}}
					/>
				</div>
			</FormWrapper>
			<FormFooter>
				<Button icon={{ icon: 'arrow-right', size: 24 }} type="submit">
					Se resultat
				</Button>
			</FormFooter>
		</form>
	);
}
