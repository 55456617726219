import { useAuthenticatedCRMRequestConfig } from '@web/services/crm-api/hooks';

import { getEventStatus } from '@web/utils/event-status';

import { EventDto } from '../../services/crm-api/rest';
import { EventCard } from '../event-card/event-card';

import styles from './event-card-list.module.scss';

export type EventCardListProps = {
	events: EventDto[];
	baseUrl?: string;
};

export function EventCardList({ events, baseUrl }: EventCardListProps) {
	const { status } = useAuthenticatedCRMRequestConfig();
	const isLoggedIn = status === 'authenticated';

	return (
		<ul className={styles.container}>
			{events.map((event) => (
				<li key={`${event.title}-${event.startTime}-${event.endTime}`}>
					<EventCard
						startTime={event.startTime || ''}
						endTime={event.endTime || ''}
						title={event.title || ''}
						location={event.location}
						href={baseUrl ? `${baseUrl}/${event.eventId}` : undefined}
						eventType={getEventStatus(event, isLoggedIn)}
						locationType={event.location?.locationType || 'Physical'}
					/>
				</li>
			))}
		</ul>
	);
}
