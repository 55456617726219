import { useState } from 'react';
import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import { EventCardList } from '@web/components/event-card-list/event-card-list';
import { Theme } from '@web/components/theme/theme';
import { ThemedSkeleton } from '@web/components/themed-skeleton/themed-skeleton';
import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import { useGlobalContext } from '@web/layouts/page/context';

import { useGetDepartmentById, useGetEventsByFilter } from '@web/services/crm-api/rest';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ColorTheme, ThemeDefinition } from '@web/services/umbraco/types/basic/Color';
import { Link } from '@web/services/umbraco/types/basic/Link';

import styles from './events-list-block.module.scss';

const blockName = 'eventsListBlock' as const;

export interface EventsListBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: string;
			department?: string;
			maxEvents?: number;
			link: Link;
		},
		{
			colorTheme?: ThemeDefinition;
		}
	>;
}


export function EventsListBlock({ block }: EventsListBlockProps) {
	const { title, department: departmentId, link, maxEvents = 3 } = block.contentProperties || {};
	const { colorTheme = { theme: ColorTheme.Lightgrey } } = block.settingsProperties;
	const { pageContext } = useGlobalContext();
	const { data: department, isLoading: isDepartmentLoading } = useGetDepartmentById(departmentId || '', { query: { enabled: !!departmentId } });
	const [today] = useState(new Date().toISOString());

	const { data, isLoading } = useGetEventsByFilter(
		{
			sort: 'Ascending',
			dateFilterDate: today,
			pageSize: maxEvents,
			statusCode: ['Cancelled', 'Published'],
			department: department ? [department.name] : null,
		},
		{
			query: {
				enabled: departmentId ? !!department : true,
			},
		});

	const eventsBaseUrl = pageContext?.eventsPageUrl;

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme?.theme),
			})}
		>
			<Container className={cn(styles.container)}>
				<h2>{title}</h2>

				{(isLoading || isDepartmentLoading) && (
					<ThemedSkeleton containerClassName={styles.loading} className={styles.loader} count={maxEvents} />
				)}

				{!isLoading && data?.items && data?.items?.length !== 0 && (
					<EventCardList
						events={data.items}
						baseUrl={eventsBaseUrl}
					/>
				)}

				{!isLoading && data?.items?.length === 0 && (
					<div className={styles.empty_state}>
						<p>
							Der er ingen arrangementer i lokalafdelingen lige nu. Kom tilbage igen senere.
						</p>
					</div>
				)}

				{link && (
					<UmbracoLink icon={{ icon: 'arrow-right', size: 24 }} link={link}>
						Se flere arrangementer
					</UmbracoLink>
				)}
			</Container>
		</Theme>
	);
}

EventsListBlock.blockName = blockName;
