import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { InputText } from '@dansk-metal/ui';

import { useHeaderContext } from '@web/components/header/context';

import { useGlobalContext } from '@web/layouts/page/context';

import { useRaffleSuggestions } from '@web/services/raffle/search.hook';

import { searchSuggestions as popularSearchSuggestions } from '@web/templates/search-result-page';

import { NavigationTextItem } from '@apps/web/src/components/navigation/navigation-text-item/navigation-text-item';
import { Link } from '@apps/web/src/services/umbraco/types/basic/Link';

import styles from '../navigation-card.module.scss';
import suggestionStyles from './search-view.module.scss';

type SearchViewProps = {
	primaryMenu?: Link[];
	secondaryMenu?: Link[];
};

export function SearchView({}: SearchViewProps) {
	const router = useRouter();
	const { setCurrentMenu } = useHeaderContext();
	const { rootData: { searchPageUrl } } = useGlobalContext();
	const [searchValue, setSearchValue] = useState<string>('');
	const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false);
	const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>(-1);
	const { data: suggestions } = useRaffleSuggestions({}, searchValue);

	const goToSearch = (s) => {
		router.push({
			pathname: searchPageUrl,
			query: { s },
		});
		setCurrentMenu(null);
		setShowAutoComplete(false);
	};

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		if (searchValue) {
			goToSearch(searchValue);
		}
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (!showAutoComplete || !suggestions) return;

		switch (e.key) {
			case 'ArrowDown':
				e.preventDefault();
				setSelectedSuggestionIndex((prevIndex) =>
					(prevIndex + 1) % suggestions.length,
				);
				break;
			case 'ArrowUp':
				e.preventDefault();
				setSelectedSuggestionIndex((prevIndex) =>
					(prevIndex - 1 + suggestions.length) % suggestions.length,
				);
				break;
			case 'Enter':
				e.preventDefault();
				if (selectedSuggestionIndex >= 0) {
					goToSearch(suggestions[selectedSuggestionIndex]);
				} else {
					goToSearch(searchValue);
				}

				break;
			default:
				break;
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSuggestionIndex, showAutoComplete, suggestions]);

	return (
		<div className={suggestionStyles.search}>
			<p className={styles.header}>Søgning</p>
			<form onSubmit={handleSubmit}>
				<InputText
					className={styles.input_field}
					type="search"
					placeholder="Fx “dagpengekort”"
					icon={{ icon: 'search' }}
					autoFocus
					value={searchValue}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setSearchValue(e.target.value);
						setShowAutoComplete(true);
					}}
				/>
			</form>
			{suggestions && suggestions.length > 0 && showAutoComplete && (
				<ul className={suggestionStyles.suggestions}>
					{suggestions.map((suggestion, index) => (
						<li
							className={cn(suggestionStyles.suggestions__item, {
								[suggestionStyles.selected]: index === selectedSuggestionIndex,
							})}
							key={index}
							onClick={() => goToSearch(suggestion)}
							tabIndex={0}
							onFocus={() => setSelectedSuggestionIndex(index)}
						>
							{suggestion}
						</li>
					))}
				</ul>
			)}
			<div className={styles.shortcuts}>
				<p className={styles.shortcuts__title}>Populære søgninger</p>
				<ul>
					{popularSearchSuggestions.map((suggestion, index) => (
						<li key={index}>
							<NavigationTextItem
								text={suggestion}
								icon="search"
								iconSize={16}
								reverse
								toUpperCase={false}
								onClick={() => goToSearch(suggestion)}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
