import { Fragment } from 'react';
import Link from 'next/link';
import cn from 'classnames';

import { getTestAttr } from '@dansk-metal/service/testing';

import { RaffleResult, submitRaffleFeedback } from '@web/services/raffle';

import styles from './search-result.module.scss';

export interface SuggestionProps {
	title: string;
	link: string;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}
export function Suggestion({ link, title, onClick }: SuggestionProps) {
	return (
		<Link href={link} shallow onClick={onClick} className={cn(styles.result, styles.result__suggestion)} {...getTestAttr('suggestion')}>
			<p>{title}</p>
		</Link>
	);
}
export interface SearchResultProps {
	result: RaffleResult;
}
export function SearchResult({ result }: SearchResultProps) {
	const { title, content, url } = result;
	const paths = url.split('/').splice(3);

	function handleClick() {
		submitRaffleFeedback(result.feedback_data)
			.catch((e) => console.warn('Unable to submit feedback', e));
	}

	return (
		<div className={styles.result} {...getTestAttr('search-result')}>
			<div className={styles.paths}>
				{paths.map((path, i) => (
					<Fragment key={`${path}-${i}`}>
						{i !== 0 && <span className={styles.divider}>/</span>}
						{path}
					</Fragment>
				))}
			</div>
			<h2>{title}</h2>
			<div className={styles.textContent} dangerouslySetInnerHTML={{ __html: content }} />
			<Link onClick={handleClick} href={url} />
		</div>
	);
}
