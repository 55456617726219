import { gql } from '@urql/core';

import type { SubjectsQuery, SubjectsQueryVariables } from '@web/services/gql/graphql';
import { baseContentFragment } from '@web/services/umbraco/fragments/baseContent';
import { BaseDocument } from '@web/services/umbraco/type-mapping';

export type { SubjectsQuery, SubjectsQueryVariables };

export const subjectsQuery = gql<SubjectsQuery, SubjectsQueryVariables>`
query Subjects ($rootSlug: String!, $baseUrl: String!, $preview: Boolean, $cursor: String) {
	contentDescendantsByAbsoluteRoute(
		route: $rootSlug
		preview: $preview
		baseUrl: $baseUrl
		routeMode: CACHE_OR_ROUTING,
		after: $cursor,
		first: 50,
		where: {
			or: [
				{
					contentType: {
						alias: {
							eq: "transportPage"
						}
					}
				},
				{
					parent: {
						contentType: {
							alias: {
								eq: "transportPage"
							}
						}
					}
				}
			]
		}
	)
	{
		pageInfo {
			hasNextPage,
			startCursor
			endCursor,
		}
		nodes {
			...BaseContentFragment
			level,
			parent {
				id
			}
		}
	}
}

${baseContentFragment}
`;

export type MappedSubject = Omit<BaseDocument, 'children'> & {
	children: MappedSubject[];
};

