import React from 'react';
import cn from 'classnames';

import { Icon } from '@dansk-metal/ui';

import { FormHeaderProps } from '@web/components/form-wrapper/form-wrapper.types';

import styles from './form-wrapper.module.scss';

const FormWrapper = ({ children }) => {
	return (
		<div className={styles.form_container}>
			{children}
		</div>
	);
};

const FormHeader = ({ type, message }: FormHeaderProps) => {
	const icon = type === 'error' ? 'info' : 'checkmark';

	return (
		<div className={cn(styles.form_header, styles[`form_header__${type}`])}>
			<Icon size={24} icon={icon} />
			<p>{message}</p>
		</div>
	);
};

const Form = ({ children }) => {
	return <div className={styles.form}>{children}</div>;
};

const FormFooter = ({ children }) => {
	return <div className={styles.form_footer}>{children}</div>;
};

export {
	FormWrapper,
	FormHeader,
	Form,
	FormFooter,
};
