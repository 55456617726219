import cn from 'classnames';
import IframeResizer from 'iframe-resizer-react';

import { Container } from '@dansk-metal/ui';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ContentWidth } from '@web/services/umbraco/types/basic/sizes';

import styles from './basic-iframe-block.module.scss';

const blockName = 'basicIframeBlock' as const;

export interface BasicIframeBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			source: string;
			heightPixels?: string;
		},
		{ width?: ContentWidth }
	>;
}

export function BasicIframeBlock({ block }: BasicIframeBlockProps) {
	const { source, heightPixels } = block.contentProperties;
	const { width = ContentWidth.Medium } = block.settingsProperties;

	return (
		<Container className={styles.container}>
			<IframeResizer
				src={source}
				className={cn(styles.iframe, styles[width])}
				style={heightPixels ? { height: `${heightPixels}px` } : {}}
			/>
		</Container>
	);
}

BasicIframeBlock.blockName = blockName;
