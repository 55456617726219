/* eslint-disable class-methods-use-this */
import { hasCookie, setCookie } from 'cookies-next';

export const EDIT_POPUP_COOKIE_NAME = 'editPopup';

class UserPreferenceStorage {
	public async isEditProfilePopupDismissed() {
		return hasCookie(EDIT_POPUP_COOKIE_NAME);
	}

	public async setDismissEditProfilePopup() {
		// maxAge(seconds) calc is 90 days in seconds
		setCookie(EDIT_POPUP_COOKIE_NAME, '', { maxAge: 60 * 60 * 24 * 90 });
	}
}

export const userPreferencesStorage = new UserPreferenceStorage();
