import { QBankMedia } from '@web/services/umbraco/types/basic/QBank';

export function determineAltText(media: QBankMedia, alt?: string) {
	if (typeof alt !== 'undefined') {
		// Allow the alt text to be overridden with an empty string
		// This is useful for when the image is purely decorative
		return alt;
	}

	if (typeof media?.altText !== 'undefined') {
		return media.altText;
	}

	return '';
}
