import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, FieldError, Icon, InputText, RadioGroup, TextArea, yup, yupResolver } from '@dansk-metal/ui';

import { FormHeader } from '@web/components/form-wrapper/form-wrapper';

import { validationMessages } from '@web/constants/messages/validation-messages';

import { Receiver, useContactPostContactForm } from '@web/services/crm-api/rest';

import { getBase64FromFile } from '@web/utils/get-base64-from-file';

import styles from './contact-message-form.module.scss';

const twentyMB = 20000000;
const acceptedFileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'doc', 'docx', 'pdf'];

const schema = yup.object().shape({
	name: yup.string().required(validationMessages.first_name.empty)
		.max(50, validationMessages.first_name.error)
		.min(2, validationMessages.first_name.error),
	lastName: yup.string().required(validationMessages.last_name.empty)
		.max(50, validationMessages.last_name.error)
		.min(2, validationMessages.last_name.error),
	phone: yup.string().required(validationMessages.phone_number.empty).matches(/^[0-9+]+$/, validationMessages.phone_number.error)
		.max(20, validationMessages.phone_number.error)
		.min(6, validationMessages.phone_number.error),
	email: yup.string().required(validationMessages.email.empty).matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, validationMessages.email.error),
	message: yup.string().test('validEmpty', validationMessages.message.error, (value) => {
		if (value === '') {
			return true;
		}

		if (value && value?.length >= 2 && value?.length <= 1000) {
			return true;
		}

		return false;
	}),
	contact: yup.object().test('no-null-values', validationMessages.contact.empty, (value) => {
		return Object.values(value).some((val) => val === true);
	}),
	files: yup.array().of(
		yup.object<{ fileName: string; fileType: string; fileBase64: string; fileSize: number }>({
			fileName: yup.string().required(),
			fileType: yup.string().required(),
			fileBase64: yup.string().required(),
			fileSize: yup.number().required(),
		}),
	).test('file_validation', validationMessages.files.error, (value: { fileName: string; fileType: string; fileBase64: string; fileSize: number }[]) => {
		const filesValid = value.map((file) => {
			return file.fileName.length <= 50 && file.fileSize <= twentyMB && acceptedFileTypes.includes(file.fileType);
		});

		return !(filesValid.some((val) => val !== true));
	}),
});

export type ContactMessageFormProps = {
	onShowConfirmation: (showConfirmation: boolean) => void;
};

export function ContactMessageForm({ onShowConfirmation }: ContactMessageFormProps) {
	const { mutateAsync, isError, isLoading: isSubmitting } = useContactPostContactForm();
	const [uploadedFiles, setUploadedFiles] = useState<{ fileName: string; fileType: string; fileBase64: string; fileSize: number }[]>([]);

	const { control, handleSubmit, setValue, getValues, formState, setError, reset } = useForm({
		defaultValues: {
			name: '',
			lastName: '',
			phone: '',
			email: '',
			contact: {
				faglige: true,
				akasse: false,
			},
			message: '',
			files: [] as { fileName: string; fileType: string; fileBase64: string; fileSize: number }[],
		},
		resolver: yupResolver(schema),
		reValidateMode: 'onSubmit',
	});

	const contactOptions = [
		{ label: 'Dansk Metal', value: 'faglige' },
		{ label: 'Metal A-kasse', value: 'akasse' },
	];

	async function onSubmit(data) {
		try {
			if (!data) {
				throw new Error('Contact data not ready');
			}

			const receiver = Object.keys(data.contact).filter((key) => data.contact[key]).includes('faglige')
				? Receiver.Faglige
				: Receiver.Akasse;

			const customEncodedFiles = data.files.map((file) => {
				return {
					fileName: file.fileName,
					fileType: file.fileType,
					fileBase64: file.fileBase64,
				};
			});

			await mutateAsync({
				data: {
					firstName: data.name,
					lastName: data.lastName,
					phoneNumber: data.phone,
					email: data.email,
					message: data.message,
					receiver,
					customEncodedFiles,
				},
			});

			reset({
				name: '',
				lastName: '',
				phone: '',
				email: '',
				contact: {
					faglige: false,
					akasse: false,
				},
				message: '',
			});

			onShowConfirmation(true);
		} catch (err) {
			setError('root', {
				message: 'Der skete en fejl. Prøv igen senere.',
			});
		}
	}

	const handleChange = (e) => {
		const targetValue = e.target.value;
		const contactValues = getValues('contact');

		Object.keys(contactValues).forEach((key) => {
			contactValues[key] = key === targetValue;

			return contactValues;
		});
		setValue('contact', contactValues);
	};


	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const filesUploaded = event.target.files as FileList;

		if (filesUploaded.length > 0) {
			const filePromises = Array.from(filesUploaded).map(async (file) => {
				const fileBase64 = await getBase64FromFile(file);
				const fileType = file.name.split('.').pop() || '';

				return {
					fileName: file.name,
					fileType,
					fileBase64: fileBase64 as string,
					fileSize: file.size,
				};
			});

			const newFileObjects = await Promise.all(filePromises);
			const existingFiles = getValues('files') || [];
			const updatedFiles = [...existingFiles, ...newFileObjects];
			setUploadedFiles(updatedFiles);
			setValue('files', updatedFiles);
		}
	};


	const hiddenFileInput = React.useRef<HTMLInputElement>(null);

	const handleUpload = () => {
		hiddenFileInput.current?.click();
	};

	const handleFileRemove = (indexToRemove: number) => {
		setUploadedFiles((prevFiles) =>
			prevFiles.filter((_, index) => index !== indexToRemove),
		);
		setValue('files', uploadedFiles.filter((_, index) => index !== indexToRemove));
	};

	const error = formState.errors.name ||
		formState.errors.lastName ||
		formState.errors.phone ||
		formState.errors.email ||
		formState.errors.contact ||
		formState.errors.message ||
		isError;

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.header}>
				<h2 className={styles.head}>Send besked</h2>
				<br />
				<p>Vi svarer dig hurtigst muligt, men der kan gå op til 2 hverdage, før du får svar fra os. Har du brug for
					hurtigere svar, anbefaler vi at du ringer til os eller kontakter din lokale Metalafdeling.</p><br />
				<p>Hvis du er medlem, så anbefaler vi at du logger ind på Mit Metal og skriver din besked der.</p>
			</div>
			{/* TODO: handle validation for endpoint */}
			{error && (
				<div className={styles.banner_wrapper}>
					<FormHeader type='error' message={'Der er fejl i et eller flere felter'} />
				</div>
			)}
			<div className={styles.section}>
				<div className={styles.input_text_container}>
					<div className={styles.input_section}>
						<div className={styles.input_text}>
							<Controller
								control={control}
								name="name"
								render={({ field, fieldState }) => (
									<InputText disabled={isSubmitting} label='Fornavn' fieldState={fieldState} {...field} noOptionalLabel />
								)}
							/>
						</div>
						<div className={styles.input_text}>
							<Controller
								control={control}
								name="lastName"
								render={({ field, fieldState }) => (
									<InputText disabled={isSubmitting} label='Efternavn' fieldState={fieldState} {...field} noOptionalLabel />
								)}
							/>
						</div>
					</div>
					<div className={styles.input_section}>
						<div className={styles.input_text}>
							<Controller
								control={control}
								name="phone"
								render={({ field, fieldState }) => (
									<InputText disabled={isSubmitting} label='Telefonnummer' fieldState={fieldState} {...field} noOptionalLabel />
								)}
							/>
						</div>
						<div className={styles.input_text}>
							<Controller
								control={control}
								name="email"
								render={({ field, fieldState }) => (
									<InputText disabled={isSubmitting} label='Email' fieldState={fieldState} {...field} noOptionalLabel />
								)}
							/>
						</div>
					</div>
				</div>
				<div className={styles.radio_button_container}>
					<Controller
						control={control}
						name="contact"
						render={({ field, fieldState }) => (
							<RadioGroup
								disabled={isSubmitting}
								label='Hvem vil du kontakte?'
								className={styles.radio_group}
								fieldState={fieldState}
								{...field}
								options={contactOptions}
								noOptionalLabel
								onChange={(e) => handleChange(e)}
							/>
						)}
					/>
				</div>
				<div className={styles.message_container}>
					<Controller
						control={control}
						name="message"
						render={({ field, fieldState }) => (
							<TextArea disabled={isSubmitting} label='Besked' fieldState={fieldState} {...field} noOptionalLabel />
						)}
					/>
				</div>

				<Controller
					control={control}
					name='files'
					render={({ fieldState }) => {
						return (
							<div>
								<Button disabled={isSubmitting} icon={{ icon: 'attach', size: 24 }} type='button' className={styles.attach} onClick={handleUpload}>
									Vedhæft fil
								</Button>
								<input type="file" alt='input' ref={hiddenFileInput} onChange={handleFileChange} className={styles.file_input} multiple />
								{uploadedFiles.length > 0 && (
									<div className={styles.uploaded_files}>
										<ul>
											<div className={styles.files_container}>
												{uploadedFiles.map((file, index) => (
													<li className={styles.list_container} key={index}>
														<div className={styles.file_container}>
															{file.fileName}
														</div>
														<button
															type="button"
															disabled={isSubmitting}
															onClick={() => handleFileRemove(index)}
															className={styles.remove_button}
														>
															<Icon icon='remove-file' size={24} />
														</button>
													</li>
												))}
											</div>
										</ul>
									</div>
								)}
								<FieldError error={fieldState?.error} />
							</div>
						);
					}}
				/>
			</div>
			<Button disabled={isSubmitting} icon={isSubmitting ? { icon: 'spinner' } : { icon: 'arrow-right' }} type='submit' className={styles.submit}>
				Send besked
			</Button>
		</form>
	);
}
