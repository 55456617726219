import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export type UmbracoDatetime = Omit<BasicProperty, 'value'> & {
	editorAlias: 'Umbraco.DateTime';
	value: {
		date?: string;
	};
};

export class DateTimeConverter {
	static UmbracoType: UmbracoDatetime;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'Umbraco.DateTime';
	}

	public static mapProperty(prop: typeof this.UmbracoType) {
		return prop.value.date;
	}
}
