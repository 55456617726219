import HtmlMapper, { TagMap } from 'react-html-map';
import NextLink from 'next/link';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { OmitStrict } from 'type-zoo/types';

import { UmbracoImage } from '@apps/web/src/components/umbraco-image/umbraco-image';
import { UmbracoMacro } from '@apps/web/src/components/umbraco-macro/umbraco-macro';
import config from '@apps/web/src/config';
import { MediaType, UmbracoImageType } from '@apps/web/src/services/umbraco/types/basic/Media';

import { convertToSafeReactHTMLProps, filterOutWhitespace, parseStyle } from './utils';

import styles from './richtext.module.scss';

export interface RichtextProps {
	content: string;
	className?: string;
}

/**
 * Remove problematic properties from tags
 */
const tags: TagMap = (['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'em', 'li', 'td', 'th'] as const).reduce(
	(col, classType) => {
		const Component = classType;

		return {
			...col,
			[classType]: ({ children, ...rest }) => {
				const props = omit(rest, ['index', 'style', 'class']);

				return <Component {...convertToSafeReactHTMLProps(props)}>{children}</Component>;
			},
		};
	},
	{},
);

const tagMap: TagMap = {
	...tags,
	br: () => <br />,
	dl: (props) => <dl {...convertToSafeReactHTMLProps(props)} />,
	dt: (props) => <dt {...convertToSafeReactHTMLProps(props)} />,
	dd: (props) => <dd {...convertToSafeReactHTMLProps(props)} />,
	table: ({ children, style, ...props }) => (
		<table {...convertToSafeReactHTMLProps(props)} style={parseStyle(style as string | undefined)}>
			{filterOutWhitespace(children)}
		</table>
	),
	thead: ({ children, style, ...props }) => (
		<thead {...convertToSafeReactHTMLProps(props)} style={parseStyle(style as string | undefined)}>
			{filterOutWhitespace(children)}
		</thead>
	),
	tr: ({ children, style, ...props }) => (
		<tr {...convertToSafeReactHTMLProps(props)} style={parseStyle(style as string | undefined)}>
			{filterOutWhitespace(children)}
		</tr>
	),
	tbody: ({ children, style, ...props }) => (
		<tbody {...convertToSafeReactHTMLProps(props)} style={parseStyle(style as string | undefined)}>
			{filterOutWhitespace(children)}
		</tbody>
	),
	tfoot: ({ children, style, ...props }) => (
		<tfoot {...convertToSafeReactHTMLProps(props)} style={parseStyle(style as string | undefined)}>
			{filterOutWhitespace(children)}
		</tfoot>
	),
	p: ({ children, style, ...rest }) => {
		if (Array.isArray(children) && children.find((e) => e?.type?.name === 'img')) {
			return <>{children}</>;
		}

		const props = omit(rest, ['index', 'class']);
		const { class: className } = rest as typeof rest & { class?: string };

		return (
			<p className={className} style={parseStyle(style as string | undefined)} {...convertToSafeReactHTMLProps(props)}>
				{children}
			</p>
		);
	},
	span: ({ children, style, ...rest }) => {
		const props = omit(rest, ['index', 'class']);
		const { class: className } = rest as typeof rest & { class?: string };

		if (rest['data-macro-alias']) {
			return <UmbracoMacro alias={rest['data-macro-alias']} data={rest['data-macro-params']} />;
		}

		// Handles un-converted macro parameters
		// TODO: Remove this when the Limbo Table RTE supports macro parameters P30300951-775
		if (className?.includes('umb-macro-holder') && className.includes('macroParameter')) {
			const value = children?.[1]?.props?.children?.[0]?.props?.children?.[0]?.props?.children;

			if (value) {
				const data = JSON.stringify({
					enableinlinemacro: className.includes('inlined-macro') ? '1' : '0',
					macroalias: 'macroParameter',
					reference_value: value.split(':')[1].trim(),
				});

				return <UmbracoMacro alias={'macroParameter'} data={data} />;
			}
		}

		return (
			<span className={className} style={parseStyle(style as string | undefined)} {...convertToSafeReactHTMLProps(props)}>
				{children}
			</span>
		);
	},
	ul: ({ children, ...rest }) => {
		const props = omit(rest, ['style', 'class']);

		return (
			<ul style={parseStyle(rest.style as string | undefined)} {...convertToSafeReactHTMLProps(props)}>
				{children}
			</ul>
		);
	},
	ol: ({ children, ...rest }) => {
		const props = omit(rest, ['style', 'class']);

		return (
			<ol style={parseStyle(rest.style as string | undefined)} {...convertToSafeReactHTMLProps(props)}>
				{children}
			</ol>
		);
	},
	hr: (rest) => {
		const props = omit(rest, ['index', 'children']);

		return <hr {...convertToSafeReactHTMLProps(props)} />;
	},
	a: ({ href, children, title, ...rest }) => {
		const props = omit(rest, ['index', 'class']);

		if (href?.startsWith('/media')) {
			return (
				<a {...convertToSafeReactHTMLProps(props)} href={config.UMBRACO_URL + href}>
					{children}
				</a>
			);
		}

		if (href?.startsWith('/')) {
			return (
				<NextLink passHref={true} href={href} title={title} target={rest.target} rel={rest.target === '_blank' ? 'noreferrer' : undefined}>
					{children}
				</NextLink>
			);
		}

		return (
			<a href={href} {...convertToSafeReactHTMLProps(props)} target="_blank">
				{children}
			</a>
		);
	},
	img: ({ src, height, width, ...rest }) => {
		const parsedHeight = parseInt(height?.toString() || '400', 10);
		const parsedWidth = parseInt(width?.toString() || '400', 10);

		const image: UmbracoImageType = {
			id: 0,
			size: 0,
			url: config.UMBRACO_URL + (src?.split('?')?.[0] || ''),
			alias: MediaType.Image,
			crops: [],
			focalPoint: null,
			localFocalPoint: null,
			localCrops: [],
			height: parsedHeight,
			width: parsedWidth,
		};

		const props = omit(rest, ['children', 'placeholder', 'index']) as OmitStrict<
			typeof rest,
			'children' | 'placeholder'
		>;

		return <UmbracoImage layout="fixed" image={image} height={parsedHeight} width={parsedWidth} {...convertToSafeReactHTMLProps(props)} />;
	},
};

export function Richtext({ content, className }: RichtextProps) {
	return (
		<div className={classNames(styles.block, className)}>
			<HtmlMapper html={content} acceptUnknown>
				{tagMap}
			</HtmlMapper>
		</div>
	);
}
