
import cn from 'classnames';

import { getTestAttr } from '@dansk-metal/service/testing';

import Config from '@web/config';

import { BlockKey, blockTemplates, BlockType, BlockTypeMap } from '@web/blocks';

import { getBlockId } from '@web/utils/block';
import { blockSpacing } from '@web/utils/block-spacing';

import { ContentBlockProvider } from './provider';

import styles from './content-block.module.scss';

export interface ContentBlockProps {
	className?: string;
	blocks: BlockType[];
}

export function ContentBlock({ blocks, className }: ContentBlockProps) {
	return (
		<div className={className} {...getTestAttr('block_contentBlock')}>
			{blocks.map((block, index) => (
				<ContentBlockProvider key={block.key} columns={1} row={index}>
					<Block block={block} hasPadding={blockSpacing(blocks, index)} />
				</ContentBlockProvider>
			))}
		</div>
	);
}

function Block<T extends BlockKey = BlockKey>({ block, hasPadding }:
{ block: T extends BlockKey ? BlockTypeMap[T] : never; hasPadding: boolean }) {
	const contentAlias = block.contentAlias as T;
	if (blockTemplates[contentAlias]) {
		const Component = blockTemplates[contentAlias].component;

		return (
			<div key={block.key} data-testid={getBlockId(block)}>
				<div className={cn({ [styles.block_styling]: hasPadding })}>
					<Component block={block} />
				</div>
			</div>
		);
	}

	if (Config.APP_ENV === 'production') {
		console.warn(`Missing block type: ${block.contentAlias}`);
		return null;
	}

	return (
		<pre className="123" style={{ maxWidth: '100%', overflow: 'auto' }}>
			{JSON.stringify(block, null, 2)}
		</pre>
	);
}
