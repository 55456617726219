/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Default API
 * All endpoints not defined under specific APIs
 * OpenAPI spec version: Latest
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { umbracoAxios } from '../axios';
import type {
	GetApiTranslations200One,
	GetApiTranslations200Three,
	GetApiTranslations200Two,
	GetApiTranslationsKeyKey200One,
	GetApiTranslationsKeyKey200Three,
	GetApiTranslationsKeyKey200Two,
	GetApiTranslationsLangLang200One,
	GetApiTranslationsLangLang200Three,
	GetApiTranslationsLangLang200Two,
	GetApiTranslationsLangLangKeyKey200One,
	GetApiTranslationsLangLangKeyKey200Three,
	GetApiTranslationsLangLangKeyKey200Two,
} from '../umbraco.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Returns a list of all translations in the default language
 */
export const getApiTranslations = (options?: SecondParameter<typeof umbracoAxios>, signal?: AbortSignal) => {
	return umbracoAxios<GetApiTranslations200One | GetApiTranslations200Two | GetApiTranslations200Three>(
		{ url: '/api/translations', method: 'get', signal },
		options,
	);
};

export const getGetApiTranslationsQueryKey = () => ['/api/translations'] as const;

export const getGetApiTranslationsQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiTranslations>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTranslations>>, TError, TData>;
	request?: SecondParameter<typeof umbracoAxios>;
}): UseQueryOptions<Awaited<ReturnType<typeof getApiTranslations>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiTranslationsQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTranslations>>> = ({ signal }) =>
		getApiTranslations(requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetApiTranslationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTranslations>>>;
export type GetApiTranslationsQueryError = ErrorType<unknown>;

/**
 * @summary Returns a list of all translations in the default language
 */
export const useGetApiTranslations = <
	TData = Awaited<ReturnType<typeof getApiTranslations>>,
	TError = ErrorType<unknown>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTranslations>>, TError, TData>;
	request?: SecondParameter<typeof umbracoAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiTranslationsQueryOptions(options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Returns a list of translations starting from translation item matching the key and all of it's children in the default language
 */
export const getApiTranslationsKeyKey = (
	key: string,
	options?: SecondParameter<typeof umbracoAxios>,
	signal?: AbortSignal,
) => {
	return umbracoAxios<
		GetApiTranslationsKeyKey200One | GetApiTranslationsKeyKey200Two | GetApiTranslationsKeyKey200Three
	>({ url: `/api/translations/key/${key}`, method: 'get', signal }, options);
};

export const getGetApiTranslationsKeyKeyQueryKey = (key: string) => [`/api/translations/key/${key}`] as const;

export const getGetApiTranslationsKeyKeyQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiTranslationsKeyKey>>,
	TError = ErrorType<unknown>,
>(
	key: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsKeyKey>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsKeyKey>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiTranslationsKeyKeyQueryKey(key);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTranslationsKeyKey>>> = ({ signal }) =>
		getApiTranslationsKeyKey(key, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!key, staleTime: 10000, ...queryOptions };
};

export type GetApiTranslationsKeyKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTranslationsKeyKey>>>;
export type GetApiTranslationsKeyKeyQueryError = ErrorType<unknown>;

/**
 * @summary Returns a list of translations starting from translation item matching the key and all of it's children in the default language
 */
export const useGetApiTranslationsKeyKey = <
	TData = Awaited<ReturnType<typeof getApiTranslationsKeyKey>>,
	TError = ErrorType<unknown>,
>(
	key: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsKeyKey>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiTranslationsKeyKeyQueryOptions(key, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * If the language specified is not supported the default language will be returned
 * @summary Returns a list of all translations in the language specified
 */
export const getApiTranslationsLangLang = (
	lang: string,
	options?: SecondParameter<typeof umbracoAxios>,
	signal?: AbortSignal,
) => {
	return umbracoAxios<
		GetApiTranslationsLangLang200One | GetApiTranslationsLangLang200Two | GetApiTranslationsLangLang200Three
	>({ url: `/api/translations/lang/${lang}`, method: 'get', signal }, options);
};

export const getGetApiTranslationsLangLangQueryKey = (lang: string) => [`/api/translations/lang/${lang}`] as const;

export const getGetApiTranslationsLangLangQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiTranslationsLangLang>>,
	TError = ErrorType<unknown>,
>(
	lang: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsLangLang>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsLangLang>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiTranslationsLangLangQueryKey(lang);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTranslationsLangLang>>> = ({ signal }) =>
		getApiTranslationsLangLang(lang, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!lang, staleTime: 10000, ...queryOptions };
};

export type GetApiTranslationsLangLangQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTranslationsLangLang>>>;
export type GetApiTranslationsLangLangQueryError = ErrorType<unknown>;

/**
 * @summary Returns a list of all translations in the language specified
 */
export const useGetApiTranslationsLangLang = <
	TData = Awaited<ReturnType<typeof getApiTranslationsLangLang>>,
	TError = ErrorType<unknown>,
>(
	lang: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsLangLang>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiTranslationsLangLangQueryOptions(lang, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * If the language specified is not supported the default language will be returned
 * @summary Returns a list of translations starting from translation item matching the key and all of it's children in the language specified
 */
export const getApiTranslationsLangLangKeyKey = (
	lang: string,
	key: string,
	options?: SecondParameter<typeof umbracoAxios>,
	signal?: AbortSignal,
) => {
	return umbracoAxios<
		| GetApiTranslationsLangLangKeyKey200One
		| GetApiTranslationsLangLangKeyKey200Two
		| GetApiTranslationsLangLangKeyKey200Three
	>({ url: `/api/translations/lang/${lang}/key/${key}`, method: 'get', signal }, options);
};

export const getGetApiTranslationsLangLangKeyKeyQueryKey = (lang: string, key: string) =>
	[`/api/translations/lang/${lang}/key/${key}`] as const;

export const getGetApiTranslationsLangLangKeyKeyQueryOptions = <
	TData = Awaited<ReturnType<typeof getApiTranslationsLangLangKeyKey>>,
	TError = ErrorType<unknown>,
>(
	lang: string,
	key: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsLangLangKeyKey>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsLangLangKeyKey>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetApiTranslationsLangLangKeyKeyQueryKey(lang, key);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTranslationsLangLangKeyKey>>> = ({ signal }) =>
		getApiTranslationsLangLangKeyKey(lang, key, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(lang && key), staleTime: 10000, ...queryOptions };
};

export type GetApiTranslationsLangLangKeyKeyQueryResult = NonNullable<
	Awaited<ReturnType<typeof getApiTranslationsLangLangKeyKey>>
>;
export type GetApiTranslationsLangLangKeyKeyQueryError = ErrorType<unknown>;

/**
 * @summary Returns a list of translations starting from translation item matching the key and all of it's children in the language specified
 */
export const useGetApiTranslationsLangLangKeyKey = <
	TData = Awaited<ReturnType<typeof getApiTranslationsLangLangKeyKey>>,
	TError = ErrorType<unknown>,
>(
	lang: string,
	key: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTranslationsLangLangKeyKey>>, TError, TData>;
		request?: SecondParameter<typeof umbracoAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetApiTranslationsLangLangKeyKeyQueryOptions(lang, key, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
