import { dayJS } from '@dansk-metal/utils/date';

export function filterByPastOrFutureAppointments<T extends { start: string | Date }>(appointments: T[], upcoming: boolean): T[] {
	const currentDate = dayJS();

	// Filter appointments that occur today/future or in the past
	const upcomingAppointmentsByStatus = appointments.filter((appointment) => {
		const appointmentDate = dayJS(appointment.start);

		return upcoming
			? appointmentDate.isAfter(currentDate)
			: appointmentDate.isBefore(currentDate);
	});

	return upcomingAppointmentsByStatus;
}
