import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { slugify } from '@dansk-metal/utils/common';

import { useShallowQueryParams } from '@web/hooks/use-query-param';

export type UseTabBarParams = {
	tabs: string[];
	defaultTab?: string;
};

export function useTabBar({ tabs, defaultTab = tabs[0] }: UseTabBarParams) {
	const [activeTab, setActiveTab] = useState(defaultTab);
	const setActiveTabCallback = useCallback((tab: string) => setActiveTab(tab), [setActiveTab]);

	useDeepCompareEffect(() => {
		setActiveTab(defaultTab);
	}, [tabs]);

	if (new Set(tabs).size !== tabs.length) {
		throw new Error('Tabs must be unique');
	}

	return {
		activeTab,
		setActiveTab: setActiveTabCallback,
	};
}

export type UseQueryTabBarParams = UseTabBarParams & {
	queryParam: string;
};

export function useQueryParamsTabBar({ tabs, defaultTab = slugify(tabs[0]), queryParam }: UseQueryTabBarParams) {
	const slugifiedTabs = useMemo(
		() => tabs.map((tabName) => slugify(tabName)),
		[tabs],
	);

	const { activeTab, setActiveTab } = useTabBar({ tabs: slugifiedTabs, defaultTab });
	const setActiveTabCallback = useCallback((tab: string) => setActiveTab(slugify(tab)), [setActiveTab]);
	const activeTabIndex = slugifiedTabs.indexOf(activeTab);
	const router = useRouter();
	const tabFromUrl = router.query[queryParam] as string;

	const { updateUrlParam, urlStateLoaded } = useShallowQueryParams({
		routerQueryParamChanged: () => {
			if (slugifiedTabs.includes(tabFromUrl)) {
				setActiveTab(tabFromUrl);
			} else {
				setActiveTab(defaultTab);
			}
		},
	});

	useDeepCompareEffect(() => {
		if (!urlStateLoaded || !window) {
			return;
		}

		if (!activeTab && defaultTab) {
			setActiveTab(defaultTab);
		}

		if (activeTab && activeTab !== defaultTab) {
			updateUrlParam({ tab: activeTab });
		} else {
			updateUrlParam({ tab: undefined });
		}
	}, [activeTab, tabs, updateUrlParam, urlStateLoaded]);

	return {
		activeTab: tabs[activeTabIndex],
		setActiveTab: setActiveTabCallback,
	};
}
