import { gql } from 'urql/core';

import { PageQuery, PageQueryVariables } from '@apps/web/src/services/gql/graphql';
import { baseContentFragment } from '@apps/web/src/services/umbraco/fragments/baseContent';
import { propertyFragment } from '@apps/web/src/services/umbraco/fragments/propertyFragment';

export type { PageQuery, PageQueryVariables };

export const pageQuery = gql<PageQuery, PageQueryVariables>`
query Page ($slug: String!, $baseUrl: String!, $preview: Boolean) {
	contentByAbsoluteRoute (route: $slug, preview: $preview, baseUrl: $baseUrl, routeMode: CACHE_OR_ROUTING) {
		...BaseContentFragment
		properties {
			...PropertyFragment,
		}
	}
}

${baseContentFragment}
${propertyFragment}
`;
