import { gql } from '@urql/core';

export const baseContentFragment = gql`
fragment BaseContentFragment on BasicContent {
	id,
	url,
	name,
	updateDate,
	writerId,
	contentType {
		alias,
	},
}
`;

export interface BaseContent {
	id: number;
	url: string;
	name: string;
	contentType: string;
	updateDate: string;
	writerId: number | null;
}
