import { OmitStrict } from 'type-zoo/types';

import { Icons } from '@dansk-metal/ui';

import { getUmbracoButtonVariant } from '@web/components/umbraco-action/helper';
import { UmbracoLink } from '@web/components/umbraco-link/umbraco-link';

import { Link as LinkValue } from '@web/services/umbraco/types/basic/Link';
import { LinkStyle } from '@web/services/umbraco/types/elements/Action';

export interface LinkButtonProps {
	link: OmitStrict<LinkValue, 'name'> & Required<Pick<LinkValue, 'name'>>;
	type: LinkStyle;
	className?: string;
	icon?: Icons;
	text?: string;
}

export function LinkButton({ link, type, className = '', icon = 'arrow-right', text }: LinkButtonProps) {
	return (
		<UmbracoLink link={link} variant={getUmbracoButtonVariant(type)} icon={{ icon }} className={className}>
			{text || link.name}
		</UmbracoLink>
	);
}
