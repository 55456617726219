import Img, { ImageProps } from 'next/legacy/image';
import classNames from 'classnames';
import { OmitStrict } from 'type-zoo/types';

import { Aspect, AspectRatio } from '@apps/web/src/components/media/aspectRatios';
import { UmbracoImageType } from '@apps/web/src/services/umbraco/types/basic/Media';

import { useImageBuilder } from './loader';

import styles from './umbraco-image.module.scss';

export type UmbracoImageProps = {
	image: UmbracoImageType;
	aspectRatio?: Aspect;
	className?: string;
	height?: number;
	width?: number;
	alt?: string;
	initialHeight?: boolean;
} & OmitStrict<ImageProps, 'src' | 'alt' | 'width' | 'height'>;

/** @deprecated  */
export function UmbracoImage({
	image,
	aspectRatio,
	sizes = '100vw',
	layout = 'responsive',
	className,
	width: forceWidth,
	height: forceHeight,
	alt = '',
	initialHeight = false,
	...rest
}: UmbracoImageProps) {
	const { width, height, loader } = useImageBuilder(image, {
		forceHeight,
		forceWidth,
		crop: aspectRatio,
		aspectRatio: aspectRatio && AspectRatio[aspectRatio],
	});

	return (
		<div className={classNames(styles.image, { [styles.initialHeight]: initialHeight }, className)}>
			<Img
				alt={alt}
				objectFit="cover"
				quality="100"
				sizes={layout === 'responsive' || layout === 'fill' ? sizes : undefined}
				layout={layout}
				src={image.url}
				loader={loader}
				{...rest}
				{...(layout !== 'fill' && {
					width,
					height,
				})}
			/>
		</div>
	);
}
