import dynamic from 'next/dynamic';

import {
	ApprenticeSalaryCalculatorBlock,
	ApprenticeSalaryCalculatorBlockProps,
} from '@web/blocks/apprentice-salary-calculator-block/apprentice-salary-calculator-block';
import {
	AuthenticatedIframeBlock,
	AuthenticatedIframeBlockProps,
} from '@web/blocks/authenticated-iframe-block/authenticated-iframe-block';
import {
	AuthenticatedFilesListBlock,
	AuthenticatedFilesListBlockProps,
} from '@web/blocks/authenticated-publications-list-block-block/authenticated-files-block';
import { BasicCtaBlock, BasicCtaBlockProps } from '@web/blocks/basic-cta-block/basic-cta-block';
import { BasicDividerBlock, BasicDividerBlockProps } from '@web/blocks/basic-divider-block/basic-divider-block';
import { BasicIframeBlock, BasicIframeBlockProps } from '@web/blocks/basic-iframe-block/basic-iframe-block';
import { BasicMediaBlock, BasicMediaBlockProps } from '@web/blocks/basic-media-block/basic-media-block';
import { BasicTableBlock, BasicTableBlockProps } from '@web/blocks/basic-table-block/basic-table-block';
import { BasicTextBlock, BasicTextBlockProps } from '@web/blocks/basic-text-block/basic-text-block';
import { ContactCardsBlock, ContactCardsBlockProps } from '@web/blocks/contact-cards-block/contact-cards-block';
import { ContentGridBlock, ContentGridBlockProps } from '@web/blocks/content-grid-block/content-grid-block';
import {
	CountryNavigationBlock,
	CountryNavigationBlockProps,
} from '@web/blocks/country-navigation-block/country-navigation-block';
import { EducationFormBlock, EducationFormBlockProps } from '@web/blocks/education-form-block/education-form-block';
import { EventsListBlock, EventsListBlockProps } from '@web/blocks/events-list-block/events-list-block';
import { FAQListBlock, FAQListBlockProps } from '@web/blocks/faq-list-block/faq-list-block';
import { FormBlock, FormBlockProps } from '@web/blocks/form-block/form-block';
import { ImageGalleryBlock, ImageGalleryBlockProps } from '@web/blocks/image-gallery-block/image-gallery-block';
import {
	InPageNavigationBlock,
	InPageNavigationBlockProps,
} from '@web/blocks/in-page-navigation-block/in-page-navigation-block';
import { LinkListBlock, LinkListBlockProps } from '@web/blocks/link-list-block/link-list-block';
import {
	MembershipsOverviewBlock,
	MembershipsOverviewBlockProps,
} from '@web/blocks/memberships-overview-block/memberships-overview-block';
import { MosaicBlock, MosaicBlockProps } from '@web/blocks/mosaic-block/mosaic-block';
import { NewsListBlock, NewsListBlockProps } from '@web/blocks/news-list-block/news-list-block';
import { OfficesListBlock, OfficesListBlockProps } from '@web/blocks/offices-list-block/offices-list-block';
import { OKCalculatorBlock, OKCalculatorBlockProps } from '@web/blocks/ok-calculator-block/ok-calculator-block';
import { PersonsListBlock, PersonsListBlockProps } from '@web/blocks/persons-list-block/persons-list-block';
import {
	PublicationsListBlock,
	PublicationsListBlockProps,
} from '@web/blocks/publications-list-block/publications-list-block';
import { QuoteBlock, QuoteBlockProps } from '@web/blocks/quote-block/quote-block';
import {
	SalaryCalculatorBlock,
	SalaryCalculatorBlockProps,
} from '@web/blocks/salary-calculator-block/salary-calculator-block';
import { StepGuideBlock, StepGuideBlockProps } from '@web/blocks/step-guide-block/step-guide-block';
import { TabBarBlock, TabBarBlockProps } from '@web/blocks/tab-bar-block/tab-bar-block';
import { TextAndMediaBlock, TextAndMediaBlockProps } from '@web/blocks/text-and-media-block/text-and-media-block';
export type BlockTypeMap = {
	[ApprenticeSalaryCalculatorBlock.blockName]: ApprenticeSalaryCalculatorBlockProps['block'];
	[AuthenticatedIframeBlock.blockName]: AuthenticatedIframeBlockProps['block'];
	[AuthenticatedFilesListBlock.blockName]: AuthenticatedFilesListBlockProps['block'];
	[BasicCtaBlock.blockName]: BasicCtaBlockProps['block'];
	[BasicDividerBlock.blockName]: BasicDividerBlockProps['block'];
	[BasicIframeBlock.blockName]: BasicIframeBlockProps['block'];
	[BasicMediaBlock.blockName]: BasicMediaBlockProps['block'];
	[BasicTableBlock.blockName]: BasicTableBlockProps['block'];
	[BasicTextBlock.blockName]: BasicTextBlockProps['block'];
	[ContactCardsBlock.blockName]: ContactCardsBlockProps['block'];
	[ContentGridBlock.blockName]: ContentGridBlockProps['block'];
	[CountryNavigationBlock.blockName]: CountryNavigationBlockProps['block'];
	[EducationFormBlock.blockName]: EducationFormBlockProps['block'];
	[EventsListBlock.blockName]: EventsListBlockProps['block'];
	[FAQListBlock.blockName]: FAQListBlockProps['block'];
	[FormBlock.blockName]: FormBlockProps['block'];
	[ImageGalleryBlock.blockName]: ImageGalleryBlockProps['block'];
	[InPageNavigationBlock.blockName]: InPageNavigationBlockProps['block'];
	[LinkListBlock.blockName]: LinkListBlockProps['block'];
	[MembershipsOverviewBlock.blockName]: MembershipsOverviewBlockProps['block'];
	[MosaicBlock.blockName]: MosaicBlockProps['block'];
	[NewsListBlock.blockName]: NewsListBlockProps['block'];
	[OfficesListBlock.blockName]: OfficesListBlockProps['block'];
	[OKCalculatorBlock.blockName]: OKCalculatorBlockProps['block'];
	[PersonsListBlock.blockName]: PersonsListBlockProps['block'];
	[PublicationsListBlock.blockName]: PublicationsListBlockProps['block'];
	[QuoteBlock.blockName]: QuoteBlockProps['block'];
	[SalaryCalculatorBlock.blockName]: SalaryCalculatorBlockProps['block'];
	[StepGuideBlock.blockName]: StepGuideBlockProps['block'];
	[TabBarBlock.blockName]: TabBarBlockProps['block'];
	[TextAndMediaBlock.blockName]: TextAndMediaBlockProps['block'];
};
export type BlockKey = keyof BlockTypeMap;
export type BlockType = BlockTypeMap[BlockKey];
export type BlockComponent<Props> = React.ComponentType<{
	block: Props;
}>;
export type BlockTemplates = {
	[Key in BlockKey]: {
		component: BlockComponent<Key extends keyof BlockTypeMap ? BlockTypeMap[Key] : never>;
	};
};
export const blockTemplates: BlockTemplates = {
	[ApprenticeSalaryCalculatorBlock.blockName]: {
		component: dynamic(
			import('./apprentice-salary-calculator-block/apprentice-salary-calculator-block').then(
				(e) => e.ApprenticeSalaryCalculatorBlock,
			),
		),
	},
	[AuthenticatedIframeBlock.blockName]: {
		component: dynamic(
			import('./authenticated-iframe-block/authenticated-iframe-block').then((e) => e.AuthenticatedIframeBlock),
		),
	},
	[AuthenticatedFilesListBlock.blockName]: {
		component: dynamic(
			import('./authenticated-publications-list-block-block/authenticated-files-block').then(
				(e) => e.AuthenticatedFilesListBlock,
			),
		),
	},
	[BasicCtaBlock.blockName]: {
		component: dynamic(import('./basic-cta-block/basic-cta-block').then((e) => e.BasicCtaBlock)),
	},
	[BasicDividerBlock.blockName]: {
		component: dynamic(import('./basic-divider-block/basic-divider-block').then((e) => e.BasicDividerBlock)),
	},
	[BasicIframeBlock.blockName]: {
		component: dynamic(import('./basic-iframe-block/basic-iframe-block').then((e) => e.BasicIframeBlock)),
	},
	[BasicMediaBlock.blockName]: {
		component: dynamic(import('./basic-media-block/basic-media-block').then((e) => e.BasicMediaBlock)),
	},
	[BasicTableBlock.blockName]: {
		component: dynamic(import('./basic-table-block/basic-table-block').then((e) => e.BasicTableBlock)),
	},
	[BasicTextBlock.blockName]: {
		component: dynamic(import('./basic-text-block/basic-text-block').then((e) => e.BasicTextBlock)),
	},
	[ContactCardsBlock.blockName]: {
		component: dynamic(import('./contact-cards-block/contact-cards-block').then((e) => e.ContactCardsBlock)),
	},
	[ContentGridBlock.blockName]: {
		component: dynamic(import('./content-grid-block/content-grid-block').then((e) => e.ContentGridBlock)),
	},
	[CountryNavigationBlock.blockName]: {
		component: dynamic(
			import('./country-navigation-block/country-navigation-block').then((e) => e.CountryNavigationBlock),
		),
	},
	[EducationFormBlock.blockName]: {
		component: dynamic(import('./education-form-block/education-form-block').then((e) => e.EducationFormBlock)),
	},
	[EventsListBlock.blockName]: {
		component: dynamic(import('./events-list-block/events-list-block').then((e) => e.EventsListBlock)),
	},
	[FAQListBlock.blockName]: {
		component: dynamic(import('./faq-list-block/faq-list-block').then((e) => e.FAQListBlock)),
	},
	[FormBlock.blockName]: {
		component: dynamic(import('./form-block/form-block').then((e) => e.FormBlock)),
	},
	[ImageGalleryBlock.blockName]: {
		component: dynamic(import('./image-gallery-block/image-gallery-block').then((e) => e.ImageGalleryBlock)),
	},
	[InPageNavigationBlock.blockName]: {
		component: dynamic(
			import('./in-page-navigation-block/in-page-navigation-block').then((e) => e.InPageNavigationBlock),
		),
	},
	[LinkListBlock.blockName]: {
		component: dynamic(import('./link-list-block/link-list-block').then((e) => e.LinkListBlock)),
	},
	[MembershipsOverviewBlock.blockName]: {
		component: dynamic(
			import('./memberships-overview-block/memberships-overview-block').then((e) => e.MembershipsOverviewBlock),
		),
	},
	[MosaicBlock.blockName]: {
		component: dynamic(import('./mosaic-block/mosaic-block').then((e) => e.MosaicBlock)),
	},
	[NewsListBlock.blockName]: {
		component: dynamic(import('./news-list-block/news-list-block').then((e) => e.NewsListBlock)),
	},
	[OfficesListBlock.blockName]: {
		component: dynamic(import('./offices-list-block/offices-list-block').then((e) => e.OfficesListBlock)),
	},
	[OKCalculatorBlock.blockName]: {
		component: dynamic(import('./ok-calculator-block/ok-calculator-block').then((e) => e.OKCalculatorBlock)),
	},
	[PersonsListBlock.blockName]: {
		component: dynamic(import('./persons-list-block/persons-list-block').then((e) => e.PersonsListBlock)),
	},
	[PublicationsListBlock.blockName]: {
		component: dynamic(
			import('./publications-list-block/publications-list-block').then((e) => e.PublicationsListBlock),
		),
	},
	[QuoteBlock.blockName]: {
		component: dynamic(import('./quote-block/quote-block').then((e) => e.QuoteBlock)),
	},
	[SalaryCalculatorBlock.blockName]: {
		component: dynamic(
			import('./salary-calculator-block/salary-calculator-block').then((e) => e.SalaryCalculatorBlock),
		),
	},
	[StepGuideBlock.blockName]: {
		component: dynamic(import('./step-guide-block/step-guide-block').then((e) => e.StepGuideBlock)),
	},
	[TabBarBlock.blockName]: {
		component: dynamic(import('./tab-bar-block/tab-bar-block').then((e) => e.TabBarBlock)),
	},
	[TextAndMediaBlock.blockName]: {
		component: dynamic(import('./text-and-media-block/text-and-media-block').then((e) => e.TextAndMediaBlock)),
	},
} as const;
