import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';

import { Icon } from '@dansk-metal/ui';
import { formatDate, formatDateRange, getDaysBetween, getDaysUntilEvent } from '@dansk-metal/utils/date';

import { Address } from '@web/components/address/address';
import { omitCountry } from '@web/components/calendar-item/omit-country';

import { LocationDtoAddress } from '@web/services/crm-api/rest';

import styles from './calendar-item.module.scss';

export type CalendarItemProps = {
	startTime: string;
	endTime?: string;
	title: string;
	location?: LocationDtoAddress;
	href?: string | null;
	className?: string;
	dateClassName?: string;
};

export function CalendarItem({
	startTime,
	endTime,
	title,
	location,
	href,
	className,
	dateClassName,
}: CalendarItemProps) {
	const dayCount = endTime ? getDaysBetween(startTime, endTime) : 0;
	const [time, setTime] = useState('');
	const Container = href ? Link : 'div';
	const dayCountUntilEvent = getDaysUntilEvent(startTime);

	useEffect(() => {
		return endTime ? setTime(formatDateRange(startTime, endTime)) :
			setTime(formatDate(startTime, 'Tir 22. feb, 09.00'));
	}, [endTime, startTime]);

	return (
		<Container className={cn(styles.container, className)} href={href || ''}>
			<div className={cn(styles.date, dateClassName)}>
				<p>{formatDate(startTime, '22 FEB')}</p>
				{dayCount > 1 && (
					<p className={styles.date__day_count}>{dayCount} dage</p>
				)}
				{dayCountUntilEvent >= 1 && (
					<p className={styles.date__day_count}>Om {dayCountUntilEvent} dage</p>
				)}
			</div>
			<div className={styles.details}>
				<div className={styles.details__info}>
					<p className={styles.title}>{title}</p>
					<div className={styles.info}>
						<div className={styles.info__item}>
							<Icon icon="calendar" size={24} />
							<p>{time}</p>
						</div>
						<div className={styles.info__item}>
							<Icon icon="location" size={24} />
							{(location && location.addressLine ? (
								<Address address={omitCountry(location)} />
							) : (
								<p>-</p>
							)
							)}
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
}
