import { useCallback, useRef, useState } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { vars } from '@dansk-metal/theme';
import { Button, Fieldset, Icon, useForm, yup, yupResolver } from '@dansk-metal/ui';

import { FormFooter, FormHeader, FormWrapper } from '@web/components/form-wrapper/form-wrapper';
import { Richtext } from '@web/components/richtext/richtext';
import { isValidationError, submitForm } from '@web/components/umbraco-form/helpers/form.loader';
import { ActionType, FormDefinition } from '@web/components/umbraco-form/helpers/types';

import 'swiper/css';

import { Field } from './field/field';
import { applyCondition, hasCondition } from './helpers/condition.helper';
import { FormContextProvider } from './helpers/form-context';
import { buildFieldDictionary, mapPage } from './helpers/schema.helpers';
import { useWizard } from './helpers/wizard.hook';

import styles from './umbraco-form.module.scss';

export interface UmbracoFormProps {
	definition: FormDefinition;
	contentId?: string;
	submitToUmbraco?: boolean;
	confirm?: { title: string; subtitle: string };
	onSubmit?: (values: FieldValues) => void | Promise<void>;
}

export function UmbracoForm({
	definition,
	contentId,
	submitToUmbraco = true,
	confirm,
	onSubmit = () => { },
}: UmbracoFormProps) {
	const router = useRouter();
	const [remoteErrorMessage, setRemoteErrorMessage] = useState<string | null>(null);
	const pages = definition.pages || [];
	const formDictionary = buildFieldDictionary(pages);
	const formInput = pages.map((page) => mapPage(page, formDictionary));
	const pageLength = pages.length || 0;
	const hasPages = pageLength > 1;
	const tabListRef = useRef<HTMLDivElement>(null);
	const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);

	const swiperProps: SwiperProps = {
		slidesPerView: 'auto',
		spaceBetween: 24,
		freeMode: true,
		grabCursor: true,
		allowTouchMove: true,
	};

	const {
		activePage,
		goTo,
		next,
	} = useWizard({ pages });

	const handleOnSwiper = (swiper: SwiperClass) => {
		if (swiperProps?.onSwiper) {
			swiperProps.onSwiper(swiper);
		}

		if (!swiperRef) {
			setSwiperRef(swiper);
		}
	};

	const form = useForm<FieldValues>({
		defaultValues: {
			...formInput?.reduce((col, e) => ({
				...col,
				...e.defaultValues,
			}), {}),
		},
		resolver: yupResolver(yup.object({
			...formInput?.reduce((col, e) => {
				return {
					...col,
					...e.yupSchema,
				};
			}, {}),
		})),
		reValidateMode: 'onSubmit',
	});

	const getPageValidity = useCallback(() => {
		return formInput
			.map((page) => Object
				.keys(page.yupSchema)
				.some((key) => form.getFieldState(key).error),
			);
	}, [formInput, form]);

	const pageInputs = formInput.map((page) => Object.keys(page.yupSchema))[activePage];


	const handleClickPage = useCallback(async (index: number) => {
		await form.trigger(pageInputs);
		const validityOfActivePage = getPageValidity()[activePage];

		if (!validityOfActivePage || index < activePage) {
			if (swiperRef) {
				swiperRef.slideTo(index - 1);
			}

			goTo(index);
		}
	}, [form, pageInputs, getPageValidity, activePage, swiperRef, goTo]);

	const handleClickNext = useCallback(async () => {
		await form.trigger(pageInputs);
		const validityOfActivePage = getPageValidity()[activePage];

		if (!validityOfActivePage) {
			if (swiperRef) {
				swiperRef.slideNext();
			}

			next();
		} else if (tabListRef.current) {
			tabListRef.current.scrollIntoView();
		}
	}, [form, pageInputs, getPageValidity, activePage, swiperRef, next]);

	const [invalidPages, setInvalidPages] = useState(getPageValidity());

	form.watch(() => {
		setInvalidPages(getPageValidity());
	});

	const pageCondition = pages[activePage].condition;

	// Page conditions show/hide next & submit button
	const canContinue = hasCondition(pageCondition)
		? applyCondition(pageCondition, form.getValues(), formDictionary, ActionType.Show)
		: true;

	// Tab condition, disable tab if any page before is invalid
	const isTabDisabled = useCallback((tabPageIndex) => {
		return pages.some((page, index) => {
			if (index >= tabPageIndex) {
				return false;
			}

			if (!page.condition) return false;
			return !applyCondition(page.condition, form.getValues(), formDictionary, ActionType.Show);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps -- false positive on form since we use getValues
	}, [pages, form.getValues(), formDictionary]);

	async function handleSuccess(val: FieldValues) {
		try {
			await onSubmit(val);
		} catch (ex) {
			setRemoteErrorMessage(ex.response.data?.message);
			form.setError('serverError', { type: 'server', message: 'Remote error occurred' });
			return;
		}

		setInvalidPages(getPageValidity());

		if (!submitToUmbraco) {
			return;
		}

		try {
			await submitForm(definition.id as string, val, contentId);
		} catch (ex) {
			if (isValidationError(ex)) {
				Object.entries(ex.response.data.errors)
					.forEach(([key, value]) => {
						form.setError(key, {
							message: value[0],
							type: 'validate',
						});
					});

				setInvalidPages(getPageValidity());
				setRemoteErrorMessage(ex.response.data.title);
				return;
			}

			if (ex.response.data?.type === 'Error') {
				setRemoteErrorMessage(ex.response.data?.title);
				form.setError('serverError', { type: 'server', message: 'Remote error occurred' });
				return;
			}

			throw ex;
		}

		if (definition.gotoPageOnSubmit) {
			router.push(definition.gotoPageOnSubmit.url);
		}
	}

	function handleError(fieldErrors: FieldErrors) {
		setInvalidPages(getPageValidity());
		console.error('Error validating form', fieldErrors);
	}

	if (definition.messageOnSubmit && form.formState.isSubmitSuccessful) {
		return (
			<div className={styles.confirmaion_wrapper}>
				<FormWrapper>
					<div className={styles.confirmation_content}>
						<Icon icon="checkmark-fancy" size={48} color={vars['--color-secondary']}/>
						<h2>{confirm?.title}</h2>
						<Richtext content={confirm?.subtitle || definition.messageOnSubmit} />
					</div>
				</FormWrapper>
			</div>
		);
	}

	return (
		<form className={styles.form_wrapper} onSubmit={form.handleSubmit(handleSuccess, handleError)}>
			<FormContextProvider definition={definition} formHook={form}>
				{hasPages && (
					<div className={styles.tabs_wrapper} role="tablist" ref={tabListRef}>
						<Swiper {...swiperProps} onSwiper={handleOnSwiper} className={ styles.swiper}>
							{definition.pages?.map((page, index) => (
								<SwiperSlide
									key={index}
									className={cn(styles.swiwper_slide,
										{
											[styles.buttonError]: form.formState.isSubmitted && invalidPages[index],
											[styles.buttonActive]: index === activePage,
										},
									)}
								>
									<Button
										unstyled
										role="tab"
										disabled={isTabDisabled(index) && index > activePage}
										onClick={() => handleClickPage(index)}
										aria-selected={index === activePage}
									>
										{page.caption || `Page ${index + 1}`}
									</Button>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				)}
				{definition.pages?.map((page, pageIndex) => (
					<div
						key={pageIndex}
						hidden={pageIndex !== activePage}
						className={styles.section}
						role="tabpanel"
						aria-labelledby={page.caption || `Page ${pageIndex}`}
					>
						{page.fieldsets?.map((fieldSet) => {
							if (
								hasCondition(fieldSet.condition)
									&& applyCondition(fieldSet.condition, form.getValues(), formDictionary, ActionType.Hide)
							) {
								return null;
							}

							return (
								<Fieldset.Row key={fieldSet.id} fullWidth center className={styles.section}>
									<FormWrapper>
										<div className={styles.banner_wrapper}>
											{(Object.keys(form.formState.errors).length !== 0) && (
												<FormHeader type='error' message={'Der er fejl i et eller flere felter'} />
											)}
											{form.formState.isSubmitted && remoteErrorMessage && (
												<FormHeader type='error' message={remoteErrorMessage} />
											)}
										</div>
										<div className={styles.row}>
											{fieldSet.columns?.map((column, columnIndex) => (
												<div key={columnIndex} className={styles.column}>
													{column.caption && (
														<span>{column.caption}</span>
													)}
													{column.fields?.map((field) => {
														if (
															hasCondition(field.condition)
															&& applyCondition(
																field.condition,
																form.getValues(),
																formDictionary,
																ActionType.Hide,
															)
														) {
															return null;
														}

														return (
															<Field key={field.id} data={field} />
														);
													})}
												</div>
											))}
										</div>
									</FormWrapper>
								</Fieldset.Row>
							);
						})}
					</div>
				))}
				<FormFooter>
					{hasPages && (activePage !== (pageLength - 1)) && (
						<Button
							type='button'
							onClick={handleClickNext}
							aria-labelledby="Next page"
							disabled={!canContinue}
						>
							{definition.nextLabel || 'Next'}
						</Button>
					)}

					{(activePage === (pageLength - 1)) && (
						<Button icon={{ icon: 'checkmark', size: 24 }} disabled={!canContinue} type='submit' variant="fill-primary">
							{definition.submitLabel || 'Submit'}
						</Button>
					)}
				</FormFooter>
			</FormContextProvider>
		</form>
	);
}
