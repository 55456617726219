import Link from 'next/link';

import { Button } from '@dansk-metal/ui';

import { Address } from '@web/components/address/address';

import { AddressDto, CompanyDto, MemberDto, PositionDto } from '@web/services/crm-api/rest';
import { Link as UmbracoLinkType } from '@web/services/umbraco/types/basic/Link';

import styles from './update-info.module.scss';

export interface UpdateInfoModalContentProps {
	title: string;
	member?: MemberDto;
	position?: PositionDto;
	company?: CompanyDto;
	updateContactLink: UmbracoLinkType;
	updateWorkplaceLink: UmbracoLinkType;
	handleDismissPopup: () => void;
}

export function UpdateInfoModalContent({
	title,
	updateContactLink,
	updateWorkplaceLink,
	member,
	position,
	company,
	handleDismissPopup,
}: UpdateInfoModalContentProps) {
	return (
		<div className={styles.modal_content}>
			<h2>{title}</h2>
			<div className={styles.info}>
				<div className={styles.info_item}>
					<p className={styles.info_item__title}>Kontaktoplysninger</p>
					<div className={styles.info_item__details}>
						<p>{member?.email}</p>
						<p>{member?.phoneNumber}</p>
					</div>
					<Link className={styles.info_item__link} href={updateContactLink.url}>Redigér</Link>
				</div>
				<div className={styles.info_item}>
					<p className={styles.info_item__title}>Arbejdsplads</p>
					<div className={styles.info_item__details}>
						<p>{position?.name}</p>
						<div>
							<p>{company?.name}</p>
							<Address address={company?.address as AddressDto} />
						</div>
					</div>
					<Link className={styles.info_item__link} href={updateWorkplaceLink.url}>Redigér</Link>
				</div>
			</div>
			<Button onClick={handleDismissPopup} icon={{ icon: 'checkmark', size: 24 }}>Alt er ok</Button>
		</div>
	);
}
