import { Container } from '@dansk-metal/ui';

import { PageCard } from '@web/components/page-card/page-card';
import { ThemedSkeleton } from '@web/components/themed-skeleton/themed-skeleton';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { Link } from '@web/services/umbraco/types/basic/Link';

import { MemberOverviewGroupRuleset } from '@web/templates/user-member-page/helpers/should-render-page-group';

import styles from './page-group.module.scss';

export type MemberOverviewCardBlock = UmbracoBlock<
	'memberOverviewCard',
	{
		title: string;
		bodyText: string;
		link: Link;
	}
>;

export type MemberOverviewGroupBlock = UmbracoBlock<
	'memberOverviewGroup',
	{
		title: string;
		pages: MemberOverviewCardBlock[];
		userDataRuleset: MemberOverviewGroupRuleset;
	}
>;

export interface PageGroupProps {
	group: MemberOverviewGroupBlock;
}

export function PageGroup({ group }: PageGroupProps) {
	const { title, pages } = group.contentProperties;
	const skeletonCount = 3;

	return (
		<Container className={styles.container}>
			<h3 className="h4">{title || <ThemedSkeleton />}</h3>

			{pages === undefined ?
				(
					<ThemedSkeleton
						containerClassName={styles.pages}
						className={styles.skeleton_theme}
						count={skeletonCount}
						inline={true}
					/>
				) : (
					<div className={styles.pages}>
						{pages.map((page) => (
							<PageCard
								key={page.key}
								title={page.contentProperties.title}
								link={page.contentProperties.link}
								contentClassName={styles.content}
							>
								<p>{page.contentProperties.bodyText}</p>
							</PageCard>
						))}
					</div>
				)}
		</Container>
	);
}
