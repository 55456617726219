import { ColorTheme, ThemeDefinition } from '@web/services/umbraco/types/basic/Color';

import { BasicProperty } from '@apps/web/src/services/gql/graphql';

export type UmbracoThemeDropdown = Omit<BasicProperty, 'value'> & {
	editorAlias: 'NoA.ThemeDropdown';
	value: {
		value: {
			theme: ColorTheme;
			primary: string | null;
			secondary: string | null;
			tertiary: string | null;
		};
	};
};

export class ThemeConverter {
	static UmbracoType: UmbracoThemeDropdown;

	public static isPropertyType(input: { editorAlias?: string }): input is typeof this.UmbracoType {
		return input.editorAlias === 'NoA.ThemeDropdown';
	}

	public static mapProperty(prop: typeof this.UmbracoType): ThemeDefinition {
		return prop.value.value;
	}
}
