import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { Button, Container, Icon } from '@dansk-metal/ui';
import { formatDate } from '@dansk-metal/utils/date';

import { Period } from '@web/blocks/apprentice-salary-calculator-block/calculation/apprentice-salary-calculation';

import { ThemedSkeleton } from '@web/components/themed-skeleton/themed-skeleton';

import 'swiper/css';

import styles from './apprentice-salary-calculator-result.module.scss';

export interface ApprenticeSalaryCalculatorResultProps {
	handleReturnToForm: (boolean) => void;
	result: Period[];
	scrollIntoViewHandler: () => void;
}

export function ApprenticeSalaryCalculatorResult({
	handleReturnToForm,
	result,
	scrollIntoViewHandler,
}: ApprenticeSalaryCalculatorResultProps) {
	const [isLoading, setIsLoading] = useState(true);
	const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const currentStageIndex = result.map((stage) => stage.isCurrentStage).findIndex((stage) => stage === true);
	const resultCount = result.length;

	const handleGoBackToForm = () => {
		scrollIntoViewHandler();
		handleReturnToForm(false);
	};

	const slidesPerView = (number: number) => {
		return resultCount < number ? resultCount : number;
	};

	const numberOfSlides = () => {
		if (swiperRef) {
			if (swiperRef.currentBreakpoint < 375) return slidesPerView(2);
			if (swiperRef.currentBreakpoint < 640) return slidesPerView(2);
			if (swiperRef.currentBreakpoint < 960) return slidesPerView(3);
			if (swiperRef.currentBreakpoint < 1280) return slidesPerView(5);
			return slidesPerView(6);
		}
	};

	const calculateHeight = (value: number) => {
		const arrayOfValues = result.map((item) => (item.salary ? item.salary : 0));
		const highestValue = Math.max(...arrayOfValues);
		if (value === highestValue) return 400;
		return (value * 400) / highestValue;
	};

	const swiperProps: SwiperProps = {
		initialSlide: currentStageIndex - 1,
		breakpoints: {
			1: {
				slidesPerView: slidesPerView(2),
				spaceBetween: 8,
			},
			375: {
				slidesPerView: slidesPerView(2),
				spaceBetween: 8,
			},
			640: {
				slidesPerView: slidesPerView(3),
				spaceBetween: 8,
			},
			960: {
				slidesPerView: slidesPerView(5),
				spaceBetween: 8,
			},
			1280: {
				slidesPerView: slidesPerView(6),
				spaceBetween: 8,
			},
		},
	};



	const handleLeftClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slidePrev();
	}, [swiperRef]);

	const handleRightClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slideNext();
	}, [swiperRef]);

	const handleOnSwiper = (swiper: SwiperClass) => {
		if (swiperProps?.onSwiper) {
			swiperProps.onSwiper(swiper);
		}

		if (!swiperRef) {
			setSwiperRef(swiper);
		}
	};

	const onSwiperChange = (swiper: SwiperClass) => {
		if (swiper.realIndex || swiper.realIndex === 0) {
			setActiveIndex(swiper.realIndex);
		} else {
			setActiveIndex(swiper.clickedIndex);
		}
	};

	useEffect(() => {
		const loadingTimer = setTimeout(() => { setIsLoading(false); }, 1000);

		return () => {
			clearTimeout(loadingTimer);
		};
	}, []);

	return (
		<Container>
			<Swiper
				{...swiperProps}
				className={styles.swiper}
				onSwiper={handleOnSwiper}
				onSlideChange={onSwiperChange}
			>
				{result && result.map((stage) => {
					const height = { height: `${calculateHeight(stage.salary)}px` };

					return (
						<SwiperSlide
							key={formatDate(stage.period.start, '22/2 2022')}
							className={styles.swiper_item}
						>
							{isLoading ? (
								<ThemedSkeleton containerClassName={styles.loader_slider}/>
							) : (
								<>
									<div className={`${stage.isCurrentStage ? styles.swiper__bar_current : styles.swiper__bar}`} style={height}>
										<p>
											<span className={styles.bold}>{stage.salary}</span>
											<span className={styles.measure}> kr/time</span>
										</p>
									</div>
									<div>
										<p className={styles.dates}>
											<span className={styles.bold}>{formatDate(stage.period.start, '22/2 2022')} - {formatDate(stage.period.end, '22/2 2022')}</span><br/>
											<span>{stage.days} dage</span>
										</p>
									</div>
								</>
							)}
						</SwiperSlide>
					);
				})}
				{swiperRef?.currentBreakpoint > 960 && (
					<>
						<div className={styles.left_overlay} />
						<div className={styles.right_overlay} />
					</>
				)}
				<>
					<Button
						className={cn(styles.nav_left, { [styles.disable]: activeIndex === 0 })}
						onClick={handleLeftClick}
						variant="fill-secondary"
					>
						<Icon size={swiperRef?.currentBreakpoint < 1280 ? 16 : 24} icon="arrow-left" />
					</Button>
					<Button
						className={cn(styles.nav_right,
							{
								[styles.disable]: activeIndex === result.length - (numberOfSlides() || 0),
							})}
						onClick={handleRightClick}
						variant="fill-secondary"
					>
						<Icon size={swiperRef?.currentBreakpoint < 1280 ? 16 : 24} icon="arrow-right" />
					</Button>
				</>
			</Swiper>
			<Button
				onClick={handleGoBackToForm}
				variant='stroke'
				icon={{ icon: 'edit' }}
			>
				Redigér dine indtastninger
			</Button>
		</Container>
	);
}
