import { Benefits } from '@web/blocks/memberships-overview-block/components/benefits';
import { Prices } from '@web/blocks/memberships-overview-block/components/prices';
import { ListBenefits, ListPrices } from '@web/blocks/memberships-overview-block/types';

import styles from '../memberships-overview-block.module.scss';


export function MembershipLists({ membershipLists }: { membershipLists: (ListBenefits | ListPrices)[] }) {
	return (
		<div className={styles.lists}>
			{membershipLists.map((list: ListPrices | ListBenefits) => {
				const { title, benefits = [], prices = [] } = list.contentProperties as ListBenefits & ListPrices;

				return (
					<div className={styles.list} key={list.key}>
						{title && <h3 className="h4">{title}</h3>}
						{benefits && <Benefits benefitList={benefits} />}
						{prices.length > 0 && <Prices priceList={prices} />}
					</div>
				);
			})}
		</div>
	);
}
