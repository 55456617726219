import { gql } from '@urql/core';

import type { TagsQuery, TagsQueryVariables } from '@web/services/gql/graphql';

export type { TagsQuery, TagsQueryVariables };

export const tagsQuery = gql<TagsQuery, TagsQueryVariables>`
query Tags ($contentType: String!, $cursor: String) {
	contentByContentType (contentType: $contentType, after: $cursor, first: 50) {
		pageInfo {
			hasNextPage,
			startCursor
			endCursor,
		}
		nodes {
			id,
			name,
		}
	}
}
`;
