import { yup } from '@dansk-metal/ui';

import {
	FormConditionDto,
	FormFieldDto,
	FormPageDto,
} from '@apps/web/src/services/umbraco/rest';

import { applyCondition, hasCondition } from './condition.helper';
import { ActionType } from './types';

export type FieldDictionary = Map<string, string>;

// TODO: Consider doing this ahead of time.
export function buildFieldDictionary(pages: FormPageDto[]): FieldDictionary {
	const fieldDictionary: FieldDictionary = new Map();

	pages.forEach((page) => {
		page.fieldsets?.forEach((fieldset) => {
			fieldset.columns?.forEach((column) => {
				column.fields?.forEach((field) => {
					if (field.alias && field.id) {
						fieldDictionary.set(field.id, field.alias);
					}
				});
			});
		});
	});

	return fieldDictionary;
}

export function mapPage(
	page: FormPageDto,
	fieldDictionary: FieldDictionary,
): {
		defaultValues: { [key: string]: string };
		yupSchema: { [key: string]: yup.AnySchema };
	} {
	const defaultValues: { [key: string]: string } = {};
	const yupSchema: { [key: string]: yup.AnySchema } = {};

	page.fieldsets?.forEach((fieldset) => {
		fieldset.columns?.forEach((column) => {
			column.fields?.forEach((field) => {
				const alias = field.alias as string;

				const conditions = [fieldset.condition, field.condition]
					.filter(hasCondition);

				const fieldSchmea = getFieldSchema(field, conditions, fieldDictionary);
				yupSchema[alias] = fieldSchmea;
				defaultValues[alias] = fieldSchmea.getDefault();
			});
		});
	});

	return { defaultValues, yupSchema };
}

function getFieldSchema(
	field: FormFieldDto,
	conditions: FormConditionDto[],
	fieldDictionary: FieldDictionary,
): yup.AnySchema {
	let schema: yup.AnySchema = yup.mixed();

	if (field.type?.name === 'Data Consent') {
		schema = yup.string()
			.default('off')
			.oneOf(['on'], field.requiredErrorMessage || undefined);
	}

	if (field.type?.name === 'Checkbox') {
		if (field.required) {
			schema = yup.boolean().default(false).test('validateTruthy', field.requiredErrorMessage || 'Need to select', (value) => value === true);
		} else {
			schema = yup.boolean()
				.default(false);
		}
	}

	if (field.type?.name === 'Multiple choice') {
		const values = (field.preValues || []).map((e) => e.value as string);
		if (field.required) {
			schema = yup.array()
				.of(yup.mixed().oneOf(values))
				.default([]).test('validateArray', field.requiredErrorMessage || 'Need to select at least one', (value) => value.length > 0);
		} else {
			schema = yup.array()
				.of(yup.mixed().oneOf(values))
				.default([]);
		}
	}

	if (field.type?.name === 'Date') {
		schema = yup.string();
	}

	if (
		field.type?.name === 'Single choice'
		|| field.type?.name === 'Dropdown'
	) {
		const values = (field.preValues || []).map((e) => e.value as string);

		schema = yup.mixed().oneOf(values).default(values[0]);
	}

	if (
		field.type?.name === 'Long answer'
		|| field.type?.name === 'Short answer'
	) {
		schema = yup.string();
	}

	if (field.type?.name === 'Short answer') {
		const fieldType = field?.settings?.fieldType as string;

		if (fieldType === 'number') {
			schema = yup.mixed().test({
				name: 'Number',
				test(value) {
					if (value === '') {
						return true;
					}

					return !Number.isNaN(Number(value));
				},
			});
		} else if (fieldType === 'email') {
			schema = yup.string().email();
		} else if (fieldType === 'url') {
			schema = yup.string().url();
		} else {
			schema = yup.string();
		}
	}

	if (field.type?.name === 'File upload') {
		schema = yup.array().of(yup.object().shape({
			fileName: yup.string().required(),
			fileContents: yup.string().required(),
		}).default(undefined));

		if (field.required) {
			schema = schema.test('validateFileUpload', field.requiredErrorMessage || 'Venligst upload en fil', (value) => {
				return value.length > 0;
			});
		}

		const allowedExtentionsString = field.fileUploadOptions?.allowedUploadExtensions?.map((e, index) => {
			if (field.fileUploadOptions?.allowedUploadExtensions) {
				if (index === field.fileUploadOptions.allowedUploadExtensions.length - 1) {
					return `eller ${e.toUpperCase()}-format`;
				}

				return `${e.toUpperCase()}-, `;
			}

			return '';
		}).join('');

		const errorMessage = `Venligst upload filer i ${allowedExtentionsString}`;

		if (!field.fileUploadOptions?.allowAllUploadExtensions) {
			schema = schema.test('validateFileFormats', errorMessage, (value) => {
				value.forEach((file) => {
					const extension = file.fileName.split('.').pop();
					if (!extension) {
						return false;
					}

					if (field.fileUploadOptions?.allowedUploadExtensions) {
						return field.fileUploadOptions.allowedUploadExtensions.includes(extension);
					}

					return false;
				});
			});
		}
	}

	if (field.pattern && schema.type === yup.string().type) {
		schema = (schema as yup.StringSchema)
			.matches(new RegExp(field.pattern), { message: field.patternInvalidErrorMessage });
	}

	if (field.settings?.defaultValue) {
		if ((schema).type === yup.array().type) {
			schema = schema.default([field.settings?.defaultValue]);
		} else {
			schema = schema.default(field.settings?.defaultValue);
		}
	}

	if (field.required) {
		schema = schema.required(field.requiredErrorMessage || undefined);
	}

	if (conditions.length !== 0) {
		const subSchema = schema;
		schema = yup.mixed().test({
			name: 'Condition',
			test(value, ctx) {
				const fieldHidden = conditions.every((condition) => applyCondition(
					condition,
					ctx.parent,
					fieldDictionary,
					ActionType.Hide,
				));

				if (fieldHidden) {
					// skip validation if field is hidden
					return true;
				}

				try {
					subSchema.validateSync(value);
				} catch (error) {
					return false;
				}

				return true;
			},
		});
	}

	return schema;
}
