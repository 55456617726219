import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import Config from '@web/config';

import { FormDefinition } from '@web/components/umbraco-form/helpers/types';

import { urqlClient } from '@web/services/umbraco/client';
import { resolveGuidQuery } from '@web/services/umbraco/queries/resolve-guid.query';
import { FormEntryDtoValues, getUmbracoFormsApiV1DefinitionsId, postUmbracoFormsApiV1EntriesId } from '@web/services/umbraco/rest';

import { isAxiosError } from '@web/utils/is-axios-error';

export async function loadFormDefinition(definitionId: string, query?: { contentId?: string; preview?: boolean }): Promise<FormDefinition> {
	const response = await getUmbracoFormsApiV1DefinitionsId(
		definitionId,
		{ contentId: query?.contentId },
		{ baseURL: Config.UMBRACO_URL },
	);

	const { gotoPageOnSubmit, ...formDef } = response.data;

	// Consider applying formDictionary here.

	if (gotoPageOnSubmit) {
		const { data, error } = await urqlClient
			.query(resolveGuidQuery, { GUID: gotoPageOnSubmit, preview: query?.preview })
			.toPromise();

		if (error) {
			console.error(new Error(`Error resolving page by guid: ${gotoPageOnSubmit}`));
		}

		if (data?.contentByGuid?.url) {
			return {
				...formDef,
				gotoPageOnSubmit: {
					name: data?.contentByGuid.name as string,
					url: data?.contentByGuid?.url,
					type: 'CONTENT',
				},
			};
		}
	}

	return formDef;
}

export function useFormDefinitionLoader(definitionId: string, query?: { contentId?: string; preview?: boolean }) {
	return useQuery<FormDefinition, Error>(
		['form-definition', definitionId, query?.contentId, query?.preview],
		() => loadFormDefinition(definitionId, query),
		{
			enabled: !!definitionId,
			staleTime: Infinity,
		},
	);
}

export async function submitForm(definitionId: string, values: FormEntryDtoValues, contentId?: string): Promise<void> {
	await postUmbracoFormsApiV1EntriesId(definitionId, {
		values,
		contentId,
	}, { baseURL: Config.UMBRACO_URL });
}

interface UnprocessableEntityError {
	errors: {
		[key: string]: string[];
	};
	extensions: {};
	status: number;
	title: string;
}

type AxiosValidationError = Omit<AxiosError<UnprocessableEntityError>, 'response'>
	& { response: AxiosResponse<UnprocessableEntityError> };

export function isValidationError(error: Error | AxiosError): error is AxiosValidationError {
	return isAxiosError(error)
		&& error.response?.status === 422
		&& !!(error.response.data as UnprocessableEntityError).errors;
}
