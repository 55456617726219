import { useCallback, useState } from 'react';
import cn from 'classnames';
import type { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { Button, Container, Icon } from '@dansk-metal/ui';
import { formatDate } from '@dansk-metal/utils/date';

import { LinkButton } from '@web/components/link-button/link-button';
import { NewsItem } from '@web/components/news-item/news-item';
import { Theme } from '@web/components/theme/theme';

import { useGlobalContext } from '@web/layouts/page/context';

import { NewsSearchItem } from '@web/services/umbraco/rest';
import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { Link as LinkValue } from '@web/services/umbraco/types/basic/Link';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';
import { LinkStyle } from '@web/services/umbraco/types/elements/Action';

import { contentAlias as newsPageContentAlias, NewsPageData } from '@web/templates/news-page';

import 'swiper/css';

import styles from './news-list-block.module.scss';

const blockName = 'newsListBlock' as const;

export interface NewsListBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title: HTMLString;
			newsItems: NewsPageData[];
			link?: LinkValue;
		}
	>;
}

function isNewsPage(page: UmbracoPage): page is NewsPageData {
	return page.contentType === newsPageContentAlias;
}

export function NewsListBlock({ block }: NewsListBlockProps) {
	const { routeData } = useGlobalContext();
	const routeRelatedContent = routeData && isNewsPage(routeData) ? routeData.relatedContent : [];
	const { title } = block.contentProperties;
	const [activeIndex, setActiveIndex] = useState(0);
	const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
	const newsItems = block.contentProperties.newsItems.length === 0 ? routeRelatedContent : block.contentProperties.newsItems;

	function getSpaceBetween(items: number) {
		return newsItems.length <= items ? 0 : 16;
	}

	const slidesPerView = (number: number) => {
		return newsItems.length < number ? newsItems.length : number;
	};

	const numberOfSlides = () => {
		if (swiperRef) {
			if (swiperRef.currentBreakpoint < 375) return slidesPerView(1);
			if (swiperRef.currentBreakpoint < 640) return slidesPerView(1);
			if (swiperRef.currentBreakpoint < 960) return slidesPerView(2);
			if (swiperRef.currentBreakpoint < 1280) return slidesPerView(3);
			return slidesPerView(4);
		}
	};

	const swiperProps: SwiperProps = {
		breakpoints: {
			1: {
				slidesPerView: 1,
				spaceBetween: getSpaceBetween(1),
			},
			375: {
				slidesPerView: 1,
				spaceBetween: getSpaceBetween(1),
			},
			640: {
				slidesPerView: 2,
				spaceBetween: getSpaceBetween(2),
			},
			960: {
				slidesPerView: 3,
				spaceBetween: getSpaceBetween(3),
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: getSpaceBetween(4),
			},
		},
	};

	const handleLeftClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slidePrev();
	}, [swiperRef]);

	const handleRightClick = useCallback(() => {
		if (!swiperRef) return;
		swiperRef.slideNext();
	}, [swiperRef]);

	const handleOnSwiper = (swiper: SwiperClass) => {
		if (swiperProps?.onSwiper) {
			swiperProps.onSwiper(swiper);
		}

		if (!swiperRef) {
			setSwiperRef(swiper);
		}
	};

	const onSwiperChange = (swiper: SwiperClass) => {
		if (swiper.realIndex || swiper.realIndex === 0) {
			setActiveIndex(swiper.realIndex);
		} else {
			setActiveIndex(swiper.clickedIndex);
		}
	};

	return (
		<Theme className={styles.news_list}>
			<Container className={styles.container}>
				<h2 dangerouslySetInnerHTML={{ __html: title }} />
				{newsItems?.length === 1 ? (
					<div className={cn({ [styles.one_item]: newsItems.length === 1 })}>
						<NewsItem newsItem={mapNewsPageToNewsItem(newsItems[0])} extendedVersion={true} />
					</div>
				) : (
					<Swiper
						{...swiperProps}
						className={cn(
							styles.swiper,
							{ [styles.two_items]: newsItems.length === 2 },
							{ [styles.three_items]: newsItems.length === 3 },
							{ [styles.four_items]: newsItems.length === 4 },
						)}
						onSwiper={handleOnSwiper}
						onSlideChange={onSwiperChange}
					>
						{newsItems.map((newsItem) => {
							return (
								<SwiperSlide className={styles.swiper_item} key={newsItem.id}>
									<NewsItem newsItem={mapNewsPageToNewsItem(newsItem)} />
								</SwiperSlide>
							);
						})}
						<Button
							className={cn(styles.nav_left, { [styles.disable]: activeIndex === 0 })}
							onClick={handleLeftClick}
							variant="fill-secondary"
						>
							<Icon size={24} icon="arrow-left" />
						</Button>
						<Button
							className={cn(styles.nav_right,
								{ [styles.disable]: activeIndex === newsItems.length - (numberOfSlides() || 0) },
							)}
							onClick={handleRightClick}
							variant="fill-secondary"
						>
							<Icon size={24} icon="arrow-right" />
						</Button>
					</Swiper>
				)}
				{block.contentProperties.link && (
					<LinkButton link={block.contentProperties.link} type={LinkStyle.TextLink} />
				)}
			</Container>
		</Theme>
	);
}

NewsListBlock.blockName = blockName;

function mapNewsPageToNewsItem(newsItem: NewsPageData): NewsSearchItem {
	return {
		id: newsItem.id,
		url: newsItem.url,
		name: newsItem.name,
		image: newsItem.properties.hero.contentProperties.heroImage,
		tags: newsItem.properties.tags?.map((tag) => tag.name),
		publishDate: formatDate(newsItem.properties.publishDate, '22. feb 2022'),
		title: newsItem.properties.hero.contentProperties.heroTitle,
		subtitle: newsItem.properties.hero.contentProperties.heroSubtitle,
	};
}
