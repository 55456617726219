import { gql } from 'urql/core';

import { PageWithRelatedContentQuery, PageWithRelatedContentQueryVariables } from '@apps/web/src/services/gql/graphql';
import { baseContentFragment } from '@apps/web/src/services/umbraco/fragments/baseContent';
import { propertyFragment } from '@apps/web/src/services/umbraco/fragments/propertyFragment';

export type { PageWithRelatedContentQuery, PageWithRelatedContentQueryVariables };

export const pageWithRelatedContentQuery = gql<PageWithRelatedContentQuery, PageWithRelatedContentQueryVariables>`
query PageWithRelatedContent ($slug: String!, $baseUrl: String!, $preview: Boolean, $limit: Int! = 10) {
	contentByAbsoluteRoute (route: $slug, preview: $preview, baseUrl: $baseUrl, routeMode: CACHE_OR_ROUTING) {
		...BaseContentFragment
		properties {
			...PropertyFragment,
		}
	}

	relatedContentByRoute(route: $slug, first: $limit) {
    nodes {
			...BaseContentFragment
			properties {
				...PropertyFragment,
			}
    }
  }
}

${baseContentFragment}
${propertyFragment}
`;
