export type SelectValueType = {
	label: string;
	value: string;
};

export const employmentTypeOptions: SelectValueType[] = [
	{
		value: 'private',
		label: 'Privatansat',
	},
	{
		value: 'public',
		label: 'Offentlig ansat',
	},
];

export const yesOrNoOptions: SelectValueType[] = [
	{
		value: 'true',
		label: 'Ja',
	},
	{
		value: 'false',
		label: 'Nej',
	},
];

export type SelectType = {
	value: SelectValueType;
	isMulti: boolean;
	onChange: (option: unknown) => unknown;
};
