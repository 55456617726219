import type { FieldValues } from 'react-hook-form';

import {
	FormConditionDto,
	FormConditionRuleDto,
} from '@apps/web/src/services/umbraco/rest';

import type { FieldDictionary } from './schema.helpers';
import { ActionType, LogicType, Operator } from './types';

function applyOperator(operator: Operator | undefined, valA: unknown, valB: unknown) {
	if (operator === Operator.Is) {
		return valA === valB;
	}

	if (operator === Operator.IsNot) {
		return valA !== valB;
	}

	if (operator === Operator.GreaterThen) {
		return (valA as string | number) >= (valB as string | number);
	}

	if (operator === Operator.LessThen) {
		return (valA as string | number) <= (valB as string | number);
	}


	if (operator === Operator.Contains) {
		return (valA as string)?.includes(valB as string);
	}

	if (operator === Operator.StartsWith) {
		return (valA as string)?.startsWith(valB as string);
	}

	if (operator === Operator.EndsWith) {
		return (valA as string)?.endsWith(valB as string);
	}

	throw new Error(`Unkown operator: ${operator}`);
}

export function conditionMatched(
	condition: FormConditionDto,
	value: FieldValues,
	fieldDictionary: FieldDictionary,
): boolean {
	const rules = condition.rules || [];

	if (condition.logicType === LogicType.All) {
		return rules.every((rule) => applyRule(rule, fieldDictionary, value));
	}

	if (condition.logicType === LogicType.Any) {
		return rules.some((rule) => applyRule(rule, fieldDictionary, value));
	}

	throw new Error(`Unkown condition logic type: ${condition.logicType}`);
}

function applyRule(
	rule: FormConditionRuleDto,
	fieldDictionary: FieldDictionary,
	value: FieldValues,
) {
	const field = fieldDictionary.get(rule.field as string);
	if (!field) {
		console.warn('field not found', rule.field);
		return false;
	}

	return applyOperator(rule.operator, value[field], parseRuleValue(rule.value));
}

function parseRuleValue(value: string | null | undefined) {
	if (typeof value === 'undefined' || value === null) {
		return value;
	}

	try {
		// Attempting to parse simple value types ie. "54", "true" or "myValue" into expected values.
		return JSON.parse(value);
	} catch {
		// If the value is a string, JSON.parse will throw an error - we will attempt to match with this unparsed string anyways.
		return value;
	}
}

// TODO: Consider returning actionType instead of true/false
export function applyCondition(
	condition: FormConditionDto,
	value: FieldValues,
	fieldDictionary: FieldDictionary,
	action: ActionType,
) {
	const match = conditionMatched(
		condition,
		value,
		fieldDictionary,
	);

	return match === (condition.actionType === action);
}

export function hasCondition(condition: FormConditionDto | null | undefined): condition is FormConditionDto {
	if (!condition) {
		return false;
	}

	if (condition.actionType === ActionType.Show && condition.rules?.length === 0) {
		return false;
	}

	return true;
}
