import Config from '@web/config';

import { BlockType } from '@web/blocks';

import { PageUrlQuery } from '@web/services/gql/graphql';
import { urqlClient } from '@web/services/umbraco/client';
import { fetchRouteData } from '@web/services/umbraco/fetch';
import { getFirstNodeUrl } from '@web/services/umbraco/helpers';
import { pageUrlQuery } from '@web/services/umbraco/queries/url-by-content-type';
import { BreadcrumbElement } from '@web/services/umbraco/types/basic/Breadcrumb';

import { DocumentTypeMap, TemplateKey } from '@web/templates';
import { DepartmentPageData } from '@web/templates/department-page';
import { UserMemberPageData } from '@web/templates/user-member-page';

export type PageContext = {
	/** An ancestor is an ancestor document in the page hierarchy ie /root/..ancestor../page */
	ancestor?: DepartmentPageData | UserMemberPageData;
	unionRepOverviewPage?: DocumentTypeMap['unionRepOverviewPage'] | null;
	eventsPageUrl?: string | undefined;
};

export async function resolvePageContext(routeData: DocumentTypeMap[TemplateKey] | null): Promise<PageContext> {
	const pageContext: PageContext = {};

	if (!routeData || !routeData?.properties.breadcrumbs?.items) {
		return pageContext;
	}

	if (hasBlock(routeData, (block) => block.contentAlias === 'eventsListBlock') || routeData.contentType === 'userEventsOverviewPage') {
		const eventPageUrl = await urqlClient.query<PageUrlQuery>(pageUrlQuery, { contentType: 'eventsOverviewPage' }).toPromise();

		pageContext.eventsPageUrl = getFirstNodeUrl(
			(eventPageUrl.data?.contentByContentType?.nodes || []) as { url: string }[],
		);
	}

	// Remove current page from breadcrumb items
	const breadcrumbs = routeData.properties.breadcrumbs.items.slice(0, -1);

	// Detect if we should retrieve an ancestor document for menu data
	const ancestor = breadcrumbs
		.find((e) => e.contentType === 'departmentPage' || e.contentType === 'userMemberPage');

	if (ancestor) {
		const ancestorData = await resolveAncestor(ancestor);
		if (ancestorData) {
			pageContext.ancestor = ancestorData;
		}
	}

	return pageContext;
}

function hasBlock(routeData: DocumentTypeMap[TemplateKey], compare: (block: BlockType) => boolean) {
	if (!('blocks' in routeData.properties)) {
		return false;
	}

	return routeData.properties.blocks.some(compare);
}

async function resolveAncestor(ancestor: BreadcrumbElement): Promise<DepartmentPageData | UserMemberPageData | null> {
	if (ancestor.contentType === 'departmentPage') {
		return fetchRouteData({
			docType: 'departmentPage',
			queryParams: {
				baseUrl: Config.UMBRACO_URL,
				slug: ancestor.path,
			},
		});
	}

	if (ancestor.contentType === 'userMemberPage') {
		return fetchRouteData({
			docType: 'userMemberPage',
			queryParams: {
				baseUrl: Config.UMBRACO_URL,
				slug: ancestor.path,
			},
		});
	}

	return null;
}
