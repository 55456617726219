import cn from 'classnames';

import { getTestAttr } from '@dansk-metal/service/testing';
import { Container } from '@dansk-metal/ui';

import HeroLine from '@web/components/hero-line/hero-line';
import { QBankImage } from '@web/components/qbank-image/qbank-image';

import { QBankImageType } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';

import styles from './hero-full-width-image.module.scss';

export interface HeroFullWidthImageProps {
	title: HTMLString;
	prefixTitle?: string;
	image?: QBankImageType;
}

export function HeroFullWidthImage({ title, prefixTitle, image }: HeroFullWidthImageProps) {
	return (
		<div className={styles.theme} {...getTestAttr('hero-full-width-image')}>
			{image && <QBankImage className={styles.image} media={image} priority />}
			<div className={styles.gradient}>
				<Container className={styles.container}>
					<div className={styles.content}>
						{prefixTitle ? <p className={cn('h4', styles.title__prefix)}>{prefixTitle}</p> : <HeroLine />}
						<h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
					</div>
				</Container>
			</div>
		</div>
	);
}
