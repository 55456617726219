import Image from 'next/image';
import cn from 'classnames';

import { Button, Icon } from '@dansk-metal/ui';
import { formatDate } from '@dansk-metal/utils/date';

import { FileItemBlock } from '@web/blocks/authenticated-publications-list-block-block/authenticated-files-block';

import { QBankMediaDisplay } from '@web/components/qbank-media/qbank-media';
import { Theme } from '@web/components/theme/theme';

import placeholderImage from '../../../../public/assets/file-placeholder-img.png';

import styles from './authenticated-file-item.module.scss';

export type AuthenticatedFileItemProps = {
	publication: FileItemBlock;
	extendedVersion?: boolean;
};


export function AuthenticatedFileItem({ publication, extendedVersion }: AuthenticatedFileItemProps) {
	const { title, tag, publishDate, image, downloadAs } = publication.contentProperties;
	const downloadFileUrl = `/api/qbank/authenticated-file?id=${publication.contentProperties.fileId}${downloadAs ? `&filename=${downloadAs}` : ''}`;

	function downloadFile(e: { preventDefault: () => void }) {
		e.preventDefault();
		window.open(downloadFileUrl, '_blank');
	}

	return (
		<Theme className={cn({ [styles.extended]: !!extendedVersion })}>
			<div className={styles.content}>
				<Button unstyled href={downloadFileUrl} target="_blank" className={styles.content__image_container}>
					{image ? (
						<QBankMediaDisplay className={styles.image} media={image} />
					) : (
						<div className={styles.placeholder_img_wrapper}>
							<Image
								src={placeholderImage}
								width={160}
								loader={() => ''}
								unoptimized={true}
								alt='placeholder'
							/>
						</div>
					)}
				</Button>

				<div className={styles.content__container}>
					<div className={styles.info_line}>
						<span className={styles.tag}>{tag}</span>
						{publishDate && (
							<span className={styles.date}>{formatDate(publishDate, '22. feb 2022')}</span>
						)}
					</div>

					<div className={styles.title__container}>
						<h3 className={styles.content__headline}>{title}</h3>
					</div>
					<Button
						unstyled
						className={styles.download}
						target="_blank"
						href={downloadFileUrl}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								downloadFile(e);
							}
						}}
						role="link"
						tabIndex={0}
					>
						<Icon className={styles.download_icon} icon="download" size={24} />
						Hent
					</Button>
				</div>
			</div>
		</Theme>
	);
}
