import cn from 'classnames';

import { getPallette, hasColorPallette } from '@dansk-metal/theme';
import { Button, Container } from '@dansk-metal/ui';

import { useHeaderContext } from '@web/components/header/context';
import { Theme } from '@web/components/theme/theme';

import { useGlobalContext } from '@web/layouts/page/context';

import { UmbracoLink } from '@apps/web/src/components/umbraco-link/umbraco-link';
import { BreadcrumbElement } from '@apps/web/src/services/umbraco/types/basic/Breadcrumb';

import styles from './breadcrumbs.module.scss';

export function Breadcrumbs() {
	const { subnavColor } = useHeaderContext();
	const { routeData } = useGlobalContext();
	const { items = [], enabled } = routeData?.properties?.breadcrumbs || {};
	const parentItem = items[items.length - 2];

	if (!enabled || items.length < 3 || items.some((item: BreadcrumbElement) => item.contentType === 'userMemberPage')) {
		return null;
	}

	return (
		<Theme
			palette={getPallette(subnavColor)}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(subnavColor),
			})}
		>
			<Container className={styles.breadcrumbs}>
				{parentItem && (
					<Button
						className={styles.parent_link}
						href={parentItem.path}
						variant="inline"
						icon={{ icon: 'arrow-left', position: 'before', size: 16 }}
					>
						{parentItem.name}
					</Button>
				)}
				<ul role="list" className={styles.list}>
					{(items || []).slice(0, -1).map((item) => (
						<li key={item.id}>
							<BreadcrumbSegment item={item} />
						</li>
					))}
				</ul>
			</Container>
		</Theme>
	);
}

interface BreadcrumbSegmentProps {
	item: BreadcrumbElement;
}

function BreadcrumbSegment({ item }: BreadcrumbSegmentProps) {
	return (
		<UmbracoLink
			link={{
				type: 'CONTENT',
				name: item.name,
				url: item.path,
			}}
		/>
	);
}
