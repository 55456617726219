/**
 * React component that acts as an IIFFE (Immediately Invoked Function Expression).
 * It's usefull for handling complex conditional rendering.
 *
 * @example
 * <Evaluate input={() => {
 * 	if (fizz && buzz) {
 * 		return (
 * 			<div>Fizzbuzz</div>
 * 		);
 * 	}
 *
 * if (fizz) {
 * 	return (
 * 		<div>Fizz</div>
 * 	);
 * }
 *
 * 	return (
 * 		<div>Bar</div>
 * 	);
 * }} />;
 */
export function Evaluate({ input }: { input: () => React.ReactNode }) {
	return (input());
}
