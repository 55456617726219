import { MemberDto } from '@web/services/crm-api/rest';

export enum MemberOverviewGroupRuleset {
	'None' = 'none',
	'isUnemployed' = 'isUnemployed',
	'isEmployed' = 'isEmployed',
	'isStudent' = 'isStudent',
	'earlyRetirement' = 'earlyRetirement',
	'isRetired' = 'isRetired',
	'isApprentice' = 'isApprentice'
}

export function shouldRenderPageGroup(ruleset: MemberOverviewGroupRuleset, member?: MemberDto) {
	let visible;

	switch (ruleset) {
		case MemberOverviewGroupRuleset.None:
			visible = true;
			break;
		case MemberOverviewGroupRuleset.isUnemployed:
			visible = member?.flags?.isUnemployed;
			break;
		case MemberOverviewGroupRuleset.isEmployed:
			visible = member?.flags?.isCurrentlyWorking;
			break;
		case MemberOverviewGroupRuleset.isStudent:
			visible = member?.flags?.isMemberInGrundforloeb;
			break;
		case MemberOverviewGroupRuleset.isApprentice:
			visible = member?.flags?.isMemberApprentice;
			break;
		case MemberOverviewGroupRuleset.earlyRetirement:
			visible = member?.flags?.isMemberOnEfterloen;
			break;
		case MemberOverviewGroupRuleset.isRetired:
			visible = member?.flags?.isMemberOnPension;
			break;
		default:
			visible = false;
			break;
	}

	return visible;
}
