import Link from 'next/link';
import cn from 'classnames';

import { vars } from '@dansk-metal/theme';
import { Container, Icon } from '@dansk-metal/ui';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { HTMLString } from '@web/services/umbraco/types/basic/RichTextContent';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';
import { HeroBlockType } from '@web/services/umbraco/types/elements/Hero';

import styles from './in-page-navigation-block.module.scss';

const blockName = 'inPageNavigationBlock' as const;

export interface InPageNavigationBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			title?: HTMLString;
			prefixTitle?: string;
			showTitleInline?: 'titleInlineFalse' | 'titleInlineTrue';
			pages: UmbracoPage<string, { hero: HeroBlockType }>[];
		}
	>;
}

export function InPageNavigationBlock({ block }: InPageNavigationBlockProps) {
	const { title, prefixTitle, showTitleInline, pages } = block.contentProperties;

	return (
		<Container className={cn(styles.container, { [styles.container__inline]: showTitleInline === 'titleInlineTrue' })}>
			{title && (
				<div
					className={cn(styles.text, {
						[styles.text__inline]: showTitleInline === 'titleInlineTrue',
					})}
				>
					{prefixTitle && <p className="h4">{prefixTitle}</p>}
					<h2 dangerouslySetInnerHTML={{ __html: title }} />
				</div>
			)}
			<div
				className={cn(styles.items, {
					[styles.items__inline]: showTitleInline === 'titleInlineTrue',
				})}
			>
				{pages.map((page) => (
					<Link key={page.id} className={styles.item} href={page.url}>
						<span>{page.name}</span>
						<Icon icon="arrow-right-wide" size={{ width: 40, height: 24 }} color={vars['--color-middle-blue-base']} />
					</Link>
				))}
			</div>
		</Container>
	);
}

InPageNavigationBlock.blockName = blockName;
