import React from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

import { vars } from '@dansk-metal/theme';

import 'react-loading-skeleton/dist/skeleton.css';

export interface ThemedSkeletonProps extends SkeletonProps {
	backgroundColor?: string;
	highlightColor?: string;
	duration?: number;
	containerClassName?: string;
}

export function ThemedSkeleton({
	backgroundColor,
	highlightColor,
	duration,
	containerClassName,
	...skeletonProps
}: ThemedSkeletonProps) {
	return (
		<Skeleton
			baseColor={backgroundColor || vars['--color-loading-state']}
			highlightColor={highlightColor || vars['--color-loading-highlight']}
			duration={duration || 1}
			containerClassName={containerClassName}
			{...skeletonProps}
		/>
	);
}
