/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.6.0
 */
export type GetCompanyUnionRepresentativesParams = {
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type GetCompanyMembersParams = {
	/**
	 * Retrieves members who is in the union and hired by the company
	 */
	isInUnion?: boolean | null;
	/**
	 * Retrieves members who is in the akasse and hired by the company
	 */
	isInAkasse?: boolean | null;
	/**
 * Search by name of members hired by the company -
String of min 2 chars and max 160 chars
 */
	search?: string | null;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type GetCompanyByFilterParams = {
	/**
 * Filters companies on name -
String of min 3 chars and max 160 chars
 */
	search?: string | null;
	/**
 * Filters companies on zipcode -
Integer with value range of 1000-9999
 */
	zipCode?: number | null;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type ContactPostCallSubmissionBody = ContactCallDto | null;

export type ContactPostContactFormBody = ContactFormDto | null;

export type GetDepartmentsByFilterParams = {
	/**
 * Only retrieve departments with specified text in the name -
String of min 3 chars and max 160 chars
 */
	search?: string | null;
	/**
 * If true the endpoint  only retrieves departments with the field Vis paa karriererplan set to true.
If false the endpoint only retrieves departments with the field Vis paa karriererplan set to false.
If unspecified the endpoint returns alle departments regardless of what is specified in the field Vis paa karriererplan
 */
	showOnEductaionPlan?: boolean | null;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type EducationPlanCreateEducationPlanBody = EducationPlanDto | null;

export type GetEmployeeByFilterParams = {
	/**
 * Only retrieve employees with specified text in email or name -
String of min 2 chars and max 160 chars
 */
	search?: string | null;
	/**
	 * Only retrieve employees belonging to a specified Department
	 */
	department?: string | null;
	/**
 * Only retrieve employees shown or not shown in the website phonebook.
Retrieves employees shown and not shown if not specified.
 */
	showOnWeb?: boolean | null;
	/**
 * SortType currently only null or SortOrder is supported.
DEFAULT: null
 */
	sortBy?: SortType;
	/**
 * Ascending or descending sorting for the results -
DEFAULT: Descending
 */
	sortDirection?: SortDirection;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type GetEventsByFilterParams = {
	/**
	 * Retrieves events of specified types.
	 */
	type?: string[] | null;
	/**
	 * Retrieves events of specified groups
	 */
	group?: string[] | null;
	/**
	 * Retrieves events of specified departments
	 */
	department?: string[] | null;
	/**
 * Only retrieves events with specified text in Title, Intro or Body -
String of min 3 chars and max 160 chars
 */
	search?: string | null;
	/**
 * Ascending or descending sorting for the results -
DEFAULT: Descending
 */
	sort?: Sorting;
	/**
 * Date type sorting should be based on -
DEFAULT: StartDate
 */
	sortType?: DateType;
	/**
 * Date type filtering of events should be based on -
DEFAULT: GreaterThan
 */
	dateFilterOperator?: Filtering;
	/**
 * Type of filtering of events based on dates -
DEFAULT: StartDate
 */
	dateFilterType?: DateType;
	/**
	 * Filtering Date
	 */
	dateFilterDate?: string;
	/**
 * Filter for retrieving member specific events
DEFAULT: All
 */
	retrieve?: RetrieveType;
	/**
 * Filter parameter that can be used to only return events in a given status.
If the parameter is not specified IE null all events regardless of status is returned.
 */
	statusCode?: EventStatusCode[] | null;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type GetMemberDocumentsByFilterParams = {
	/**
	 * Retrieves documents created before the specified dated.
	 */
	createdBefore?: string | null;
	/**
	 * Retrieves documents created after the specified dated.
	 */
	createdAfter?: string | null;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type PutMemberBody = MemberPutDto | null;

export type GetMemberTasksByFilterParams = {
	/**
	 * Filter for type of task, one of the following (reportUnemployed, fillOutJobNetCV, fillOutUnemploymentDeclaration, watchVideo, bookMeeting, updateJobLog, fillOutBenefitCard)
	 */
	type?: TaskFilterType;
	/**
	 * Filter for status of task, one of the following (available, pending, unavailable, completed)
	 */
	statusFilter?: StatusFilter;
	/**
	 * Filter for date.
	 */
	dateFilter?: string;
	/**
	 * Operation for filtering, one of the following (LessThan,GreaterThan, LessThanEqual, GreaterThanEqual, Equals, NotEqual)
	 */
	dateFilterOperation?: TaskDateFilterOperation;
	/**
	 * Specifies which date type dateFilter is based on, one of the following (completedDate, availableDate, dueDate)
	 */
	dateFilterType?: TaskDateFilterType;
	sortByDateType?: TaskSortByDateType;
};

export type PutPaymentSessionParams = {
	/**
	 * Url the session will redirect to, incase of the session is canceled.
	 */
	cancelUrl: string | null;
	/**
	 * Url the session will redirect to, when payment session is completed.
	 */
	redirectUrl: string | null;
	/**
	 * The requested method of payment, ONLY MobilePay and Card are allowed.
	 */
	paymentMethod: PaymentForm;
};

export type PutPbsPaymentParams = {
	/**
	 * Must be 10 digits
	 */
	konto: string | null;
	/**
	 * Must be 4 digits
	 */
	reg: string | null;
};

export type PutMemberEventRegistrationBody = RegistrationDto | null;

export type PostMemberEventRegistrationBody = RegistrationDto | null;

export type GetMemberEventsByFilterParams = {
	/**
	 * Retrieves events of specified types.
	 */
	type?: string[] | null;
	/**
	 * Retrieves events of specified groups
	 */
	group?: string[] | null;
	/**
	 * Retrieves events of specified departments
	 */
	department?: string[] | null;
	/**
 * Only retrieves events with specified text in Title, Intro or Body -
String of min 3 chars and max 160 chars
 */
	search?: string | null;
	/**
 * Ascending or descending sorting for the results -
DEFAULT: Descending
 */
	sort?: Sorting;
	/**
 * Date type sorting should be based on -
DEFAULT: StartDate
 */
	sortType?: DateType;
	/**
 * Date type filtering of events should be based on -
DEFAULT: GreaterThan
 */
	dateFilterOperator?: Filtering;
	/**
 * Type of filtering of events based on dates -
DEFAULT: StartDate
 */
	dateFilterType?: DateType;
	/**
	 * Filtering Date
	 */
	dateFilterDate?: string;
	/**
 * Filter for retrieving member specific events
DEFAULT: All
 */
	retrieve?: RetrieveType;
	/**
 * Filter parameter that can be used to only return events in a given status.
If the parameter is not specified IE null all events regardless of status is returned.
 */
	statusCode?: EventStatusCode[] | null;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type GetMemberEmploymentsParams = {
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type RegisterConsentsBody = MemberPutConsentsDto | null;

export type GetMemberContributionsParams = {
	/**
 * Start date in contribution calculation.
Start date has to be the first day of a month.
 */
	startDate: string;
	/**
 * End date in contribution calculation.
End date has to be the last day of a month.
 */
	endDate: string;
};

export type GetMemberCalendarItemByFilterParams = {
	/**
 * The calendar item type to filter on.
DEFAULT: all
 */
	type?: CalendarItemType;
	/**
	 * Filter calendar items with start date greater or equal this
	 */
	start?: string;
	/**
	 * Filter calendar items with end date lesser or equal this
	 */
	end?: string;
	/**
 * Which date to sort returned calendar items by.
DEFAULT: start
 */
	sortBy?: SortDateBy;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type GetPositionsParams = {
	/**
 * Filters on names of positions -
String of min 3 chars and max 160 chars
 */
	search?: string | null;
	/**
 * Paging page number.
integer in range 1-250
 */
	page?: number | null;
	/**
 * Paging page size.
integer in range 1-250
 */
	pageSize?: number | null;
};

export type GetSalaryStatisticParams = {
	regionId?: number | null;
};

export type LogOutBody = LogOutRequest | null;

export type RefreshAccessTokenBody = RefreshAccessTokenRequest | null;

export type AuthenticateAsSystemBody = AuthenticateAsSystemRequest | null;

export type AuthenticateAsMemberBody = AuthenticateAsMemberRequest | null;

export interface CompanyUnionRepresentativeDto {
	/** Unique id for the union representative. */
	memberId: string;
	/** Union representative name. */
	fullName: string;
	/** Election date for the union representative */
	electedDate: string;
	/** End date for the union representative position. */
	endDate?: string | null;
	/** Number of people entitled to vote for union representative */
	entitledToVote?: number | null;
	/** Votes for member as union representative */
	votesFor?: number | null;
	/** Members department ID. */
	departmentId?: string;
	/** Members Birthdate */
	birthdate?: string | null;
	/** Name of the members education */
	educationName?: string | null;
	/** Names of the clubs the medlem is attached to */
	unionClubs?: KlubDto[] | null;
}

export interface PagingResultOfCompanyUnionRepresentativeDto {
	items: CompanyUnionRepresentativeDto[];
	meta: MetaData;
}

export interface CompanyMemberFlags {
	/** Shows if member is union member */
	isUnionMember?: boolean;
	/** Shows if member is akasse member */
	isAkasseMember?: boolean;
	/** Shows if member is tillidsrepresentant */
	isTillidsRepresentant?: boolean;
}

/**
 * Employment of member
 */
export type CompanyMemberDtoEmployment = EmploymentDto | null;

export type CompanyMemberDtoFlags = CompanyMemberFlags | null;

/**
 * Members adress.
 */
export type CompanyMemberDtoAddress = AddressDto | null;

export interface CompanyMemberDto {
	/** Unique id for the member. */
	memberId: string;
	/** Members first name. */
	firstName: string;
	/** Members last name. */
	lastName: string;
	/** Members phone number */
	phoneNumber?: string | null;
	/** Members email. */
	email?: string | null;
	/** Members adress. */
	address?: CompanyMemberDtoAddress;
	flags?: CompanyMemberDtoFlags;
	/** Members department ID. */
	departmentId?: string;
	/** Members Birthdate. */
	birthdate?: string | null;
	/** Members education name. */
	educationName?: string | null;
	/** Names of the clubs the medlem is attached to */
	unionClubs?: KlubDto[] | null;
	/** Id of members working position */
	positionId?: string | null;
	/** Name of members working position */
	positionName?: string | null;
	/** Employment of member */
	employment?: CompanyMemberDtoEmployment;
}

export interface PagingResultOfCompanyMemberDto {
	items: CompanyMemberDto[];
	meta: MetaData;
}

export interface CompanyAgreementDto {
	companyAgreementId: string;
	companyAgreementName: string;
}

export interface CompanyDto {
	companyId: string;
	name: string;
	pnumber: string;
	address: AddressDto;
	agreements?: CompanyAgreementDto[] | null;
}

export interface PagingResultOfCompanyDto {
	items: CompanyDto[];
	meta: MetaData;
}

export interface ContactCallDto {
	/** First name of the person -
String of min 2 chars and max 50 chars */
	firstName?: string | null;
	/** Last name of the person -
String of min 2 chars and max 50 chars */
	lastName?: string | null;
	fromDateTime: string;
	toDateTime: string;
	/** The topic to be discussed */
	topic?: string | null;
	/** The telephone number to call */
	telephoneNumber: string;
}

export interface CustomEncodedFileObject {
	fileName: string;
	fileType: string;
	fileBase64: string;
}

export type Receiver = (typeof Receiver)[keyof typeof Receiver];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Receiver = {
	Akasse: 'Akasse',
	Faglige: 'Faglige',
} as const;

export interface ContactFormDto {
	/** First name of the person -
String of min 2 chars and max 50 chars */
	firstName: string;
	/** Last name of the person -
String of min 2 chars and max 50 chars */
	lastName: string;
	/** The persons phonenumber */
	phoneNumber: string;
	/** The persons email */
	email: string;
	/** Optional custom message -
String max 1000 chars */
	message?: string | null;
	/** The entity to receive the contact form */
	receiver: Receiver;
	/** Files to be submitted to contact form -
Max total file size is 20mb */
	customEncodedFiles?: CustomEncodedFileObject[] | null;
}

export type DepartmentDtoAddress = AddressDto | null;

export interface DepartmentDto {
	departmentId: string;
	name: string;
	nameOnWebsite?: string | null;
	phoneNumber?: string | null;
	email?: string | null;
	address?: DepartmentDtoAddress;
}

export interface PagingResultOfDepartmentDto {
	items: DepartmentDto[];
	meta: MetaData;
}

export type Source = (typeof Source)[keyof typeof Source];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Source = {
	Homepage: 'Homepage',
	APP: 'APP',
} as const;

export interface EducationPlanDto {
	/** Current education of the person who created the education plan. */
	education: string;
	/** The education the person who created the education plan wish to take.
Value need to be either efteruddannelse, videreuddannelse or branche skift.
The value is caseinsensitve and needs to live up to the following regex validation ^(efteruddannelse|videreuddannelse|brancheskift)$ */
	educationWish: string;
	/** Birthdate of the person who created the education plan - */
	birthdate: string;
	/** First name of the person who created the education plan */
	firstname: string;
	/** Last name of the person who created the education plan */
	lastname: string;
	/** Persons phonenumber  */
	phone: string;
	/** Persons current working title  */
	position?: string | null;
	/** Email address of the person who created the educationplan */
	email: string;
	/** Id of the department chosen by the person who created the edcuationplan.
	 */
	department: string;
	/** Id of the department whos team should be set as owner of the education plan IE proces it.
String of min 2 chars and max 50 chars */
	receivingDepartment: string;
	/** Persons comment to the education plan. */
	comment?: string | null;
	source: Source;
}

export interface EmployedContactDto {
	name?: string | null;
	email?: string | null;
}

export type SortDirection = (typeof SortDirection)[keyof typeof SortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortDirection = {
	Ascending: 'Ascending',
	Descending: 'Descending',
} as const;

export type SortType = (typeof SortType)[keyof typeof SortType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortType = {
	SortOrder: 'SortOrder',
} as const;

export interface EmployeeDto {
	/** Employee distinct Id */
	employeeId: string;
	/** Employees fullname */
	name: string;
	/** Url to retrieve image of the employee */
	imageUrl?: string | null;
	/** Employees Phonenumber */
	phoneNumber?: string | null;
	/** Employees Email address */
	email?: string | null;
	/** Name of the department the employee is working at */
	departmentName: string;
	/** Employees job title */
	jobTitle?: string | null;
	/** Work area of the employee */
	area?: string | null;
	/** Is the employee shown in the website phonebook? */
	showOnWeb?: boolean | null;
	/** Sorting order */
	sortOrder?: number | null;
}

export interface PagingResultOfEmployeeDto {
	items: EmployeeDto[];
	meta: MetaData;
}

export interface IsAliveResponse {
	/** Flag that indicates the current state of the API.
False = The API is down this could be either expected or unexpected
True = The API is up an working as expected. */
	isAlive?: boolean;
	/** A description that gives some more context to the current state of the API. */
	systemStatusMessage?: string | null;
}

export interface MemberUnreadDocumentsResponse {
	/** Number of unread documents */
	count?: number;
}

export type DocumentDirection = (typeof DocumentDirection)[keyof typeof DocumentDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentDirection = {
	Incoming: 'Incoming',
	Outgoing: 'Outgoing',
} as const;

export interface DocumentDto {
	/** Id of the document in the system. */
	documentId: string;
	/** Title of the document. */
	title: string;
	/** Direction of the document. Either to the member (outgoing) or from the member (incoming). */
	direction: DocumentDirection;
	/** Date and time of creation. */
	createdOn: string;
}

export interface PagingResultOfDocumentDto {
	items: DocumentDto[];
	meta: MetaData;
}

export interface MemberPutPositionDto {
	/** Unique id for the members position.
If id and name is null position will be cleared on member */
	positionId?: string | null;
	/** Name for the members position.
If id and name is null position will be cleared on member */
	positionName?: string | null;
}

export interface MemberPutEmploymentDto {
	/** Id of the employer */
	companyId?: string | null;
	/** Name of the employer */
	name?: string | null;
	/** Zipcode for the employer */
	zipcode?: number | null;
	/** Employers address */
	address?: string | null;
	/** Date the employment started
Must be between 1900-01-01T00:00:00Z and 2099-12-31T23:59:59Z */
	startDate: string;
}

/**
 * Position object
 */
export type MemberPutDtoPosition = MemberPutPositionDto | null;

/**
 * Employer object
Either CompanyId or Name, Zipcode and Address is required
 */
export type MemberPutDtoEmployer = MemberPutEmploymentDto | null;

export interface MemberPutDto {
	/** Members phone number */
	phoneNumber?: string | null;
	/** Members email. */
	email?: string | null;
	/** Employer object
Either CompanyId or Name, Zipcode and Address is required */
	employer?: MemberPutDtoEmployer;
	/** Position object */
	position?: MemberPutDtoPosition;
}

export interface MemberBaseDto {
	/** Unique id for the member. */
	memberId: string;
}

export interface KlubDto {
	name?: string | null;
}

export interface MemberFlags {
	/** Shows if member is unemployed */
	isUnemployed?: boolean;
	/** Shows if member is union member */
	isUnionMember?: boolean;
	/** Shows if member is akasse member */
	isAkasseMember?: boolean;
	/** Shows if member is tillidsrepresentant */
	isTillidsRepresentant?: boolean;
	/** Shows if member currently working (I arbejde) */
	isCurrentlyWorking?: boolean;
	/** Shows if member is unemployed and above 50 years of age which makes the member a senior */
	isUnemployed50Plus?: boolean;
	/** Shows if member is on Efterloen */
	isMemberOnEfterloen?: boolean;
	/** Shows if member is an arbejdsmarked-representant */
	isArbejdsmarkedRepresentant?: boolean;
	/** Shows if member is in a grundforløb */
	isMemberInGrundforloeb?: boolean;
	/** Shows if member is an apprentice (lærling) */
	isMemberApprentice?: boolean;
	/** Shows if member is a part of EOES */
	isMemberEoes?: boolean | null;
	/** Shows if member is retired */
	isMemberOnPension?: boolean;
}

export type MemberDtoAllOfEmployer = EmploymentDto | null;

export type MemberDtoAllOfFlags = MemberFlags | null;

/**
 * Members address.
 */
export type MemberDtoAllOfAddress = AddressDto | null;

export type MemberDtoAllOf = {
	/** Members first name. */
	firstName?: string | null;
	/** Members last name. */
	lastName?: string | null;
	/** Members phone number */
	phoneNumber?: string | null;
	/** Members email. */
	email?: string | null;
	/** Members address. */
	address?: MemberDtoAllOfAddress;
	flags?: MemberDtoAllOfFlags;
	/** Membership number */
	membershipNumber?: string | null;
	/** Unique id for the members position. */
	positionId?: string | null;
	employer?: MemberDtoAllOfEmployer;
	/** Unique id for the members department. */
	departmentId?: string | null;
	/** Unique id for the members education */
	educationName?: string | null;
	/** Members birthdate */
	birthdate?: string | null;
	/** Names of the clubs the medlem is attached to */
	unionClubs?: KlubDto[] | null;
};

export type MemberDto = MemberBaseDto & MemberDtoAllOf;

export type TaskSortByDateType = (typeof TaskSortByDateType)[keyof typeof TaskSortByDateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskSortByDateType = {
	completedDate: 'completedDate',
	availableDate: 'availableDate',
	dueDate: 'dueDate',
} as const;

export type TaskDateFilterType = (typeof TaskDateFilterType)[keyof typeof TaskDateFilterType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskDateFilterType = {
	completedDate: 'completedDate',
	availableDate: 'availableDate',
	dueDate: 'dueDate',
} as const;

export type TaskDateFilterOperation = (typeof TaskDateFilterOperation)[keyof typeof TaskDateFilterOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskDateFilterOperation = {
	LessThan: 'LessThan',
	GreaterThan: 'GreaterThan',
	LessThanEqual: 'LessThanEqual',
	GreaterThanEqual: 'GreaterThanEqual',
	Equals: 'Equals',
	NotEqual: 'NotEqual',
} as const;

export type StatusFilter = (typeof StatusFilter)[keyof typeof StatusFilter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusFilter = {
	available: 'available',
	pending: 'pending',
	unavailable: 'unavailable',
	completed: 'completed',
	all: 'all',
} as const;

export type TaskFilterType = (typeof TaskFilterType)[keyof typeof TaskFilterType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskFilterType = {
	reportUnemployed: 'reportUnemployed',
	fillOutJobNetCV: 'fillOutJobNetCV',
	fillOutUnemploymentDeclaration: 'fillOutUnemploymentDeclaration',
	watchVideo: 'watchVideo',
	bookMeeting: 'bookMeeting',
	updateJobLog: 'updateJobLog',
	fillOutBenefitCard: 'fillOutBenefitCard',
	all: 'all',
} as const;

export type TaskActionType = (typeof TaskActionType)[keyof typeof TaskActionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskActionType = {
	link: 'link',
	video: 'video',
} as const;

export interface TaskActionDto {
	/** Beskrivelse. */
	navn: string;
	/** Type of action of the task. */
	type: TaskActionType;
	/** Url for link of action. */
	url?: string | null;
}

export type TaskStatus = (typeof TaskStatus)[keyof typeof TaskStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskStatus = {
	available: 'available',
	pending: 'pending',
	unavailable: 'unavailable',
	completed: 'completed',
} as const;

export type TaskType = (typeof TaskType)[keyof typeof TaskType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskType = {
	reportUnemployed: 'reportUnemployed',
	fillOutJobNetCV: 'fillOutJobNetCV',
	fillOutUnemploymentDeclaration: 'fillOutUnemploymentDeclaration',
	watchVideo: 'watchVideo',
	bookMeeting: 'bookMeeting',
	updateJobLog: 'updateJobLog',
	fillOutBenefitCard: 'fillOutBenefitCard',
	unknown: 'unknown',
} as const;

/**
 * Action of the task.
 */
export type TaskDtoAction = TaskActionDto | null;

export interface TaskDto {
	/** Id of the task in the system. */
	taskId: string;
	/** Title of the task. */
	title: string;
	/** Type of the task, one of the following (reportUnemployed, fillOutJobNetCV, fillOutUnemploymentDeclaration, watchVideo, bookMeeting, updateJobLog, fillOutBenefitCard) */
	type: TaskType;
	/** Date and time the task was completed. */
	completedDate?: string | null;
	/** Date and time the task was available from. */
	availableDate?: string | null;
	/** Date and time the task is due. */
	dueDate?: string | null;
	/** Status of the task, one of the following (available, pending, unavailable, completed) */
	status: TaskStatus;
	/** Action of the task. */
	action?: TaskDtoAction;
}

export interface PaymentSessionResponse {
	/** Url of the payment session. */
	sessionUrl: string;
}

export type PaymentForm = (typeof PaymentForm)[keyof typeof PaymentForm];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentForm = {
	MobilePay: 'MobilePay',
	Card: 'Card',
	Pbs: 'Pbs',
} as const;

export interface PaymentMethodResponse {
	paymentForm?: PaymentForm;
}

export interface RegistrationDto {
	/** List of event registration inputs */
	registrationInputs?: EventRegistrationInput[] | null;
}

export type RetrieveType = (typeof RetrieveType)[keyof typeof RetrieveType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RetrieveType = {
	Registered: 'Registered',
	Invited: 'Invited',
	Deregistered: 'Deregistered',
	All: 'All',
} as const;

export type Filtering = (typeof Filtering)[keyof typeof Filtering];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Filtering = {
	GreaterEqual: 'GreaterEqual',
	GreaterThan: 'GreaterThan',
	Equal: 'Equal',
	LessEqual: 'LessEqual',
	LessThan: 'LessThan',
	NotEqual: 'NotEqual',
} as const;

export type DateType = (typeof DateType)[keyof typeof DateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateType = {
	StartDate: 'StartDate',
	RegistrationDate: 'RegistrationDate',
	EndDate: 'EndDate',
} as const;

export type Sorting = (typeof Sorting)[keyof typeof Sorting];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Sorting = {
	Descending: 'Descending',
	Ascending: 'Ascending',
} as const;

/**
 * Keys and value of the facets found
 */
export type FacetHitsFacet = { [key: string]: number } | null;

export interface FacetHits {
	/** The type of filter used in facet search */
	filter?: string | null;
	/** Keys and value of the facets found */
	facet?: FacetHitsFacet;
}

export type EventMetaAllOf = {
	/** Shows available records in each filter option. Uses faceted search logic */
	facets?: FacetHits[] | null;
};

export type EventMeta = MetaData & EventMetaAllOf;

export interface EventBaseDto {
	/** Unique id for the Event. */
	eventId: string;
}

export interface EventRegistrationInput {
	/** Name of the input section */
	sectionName: string;
	/** Name of the input */
	label: string;
	/** The registered input value */
	value?: string | null;
}

export interface EventRegistration {
	/** Unique id for the members registration for an event */
	registrationId?: string | null;
	/** Array of inputs for a specific registration */
	inputs?: EventRegistrationInput[] | null;
}

export type RegistrationInputType = (typeof RegistrationInputType)[keyof typeof RegistrationInputType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationInputType = {
	CheckBox: 'CheckBox',
	TextField: 'TextField',
} as const;

/**
 * The type of registration input
 */
export type RegistrationInputTypeProperty = RegistrationInputType | null;

export interface RegistrationInput {
	/** Registration input Key name */
	key?: string | null;
	/** A boolean checking whether a registration input is required.  */
	required?: boolean;
	/** The order for the inputs */
	order: number;
	/** The type of registration input */
	type?: RegistrationInputTypeProperty;
}

export interface RegistrationSection {
	/** Section Name */
	label?: string | null;
	/** Registration sections input fields */
	fields?: RegistrationInput[] | null;
	/** The order for the registration section */
	order: number;
}

export interface RegistrationFormDto {
	/** Id of the related event */
	eventId: string;
	/** Sections of the Registrationform */
	sections?: RegistrationSection[] | null;
}

export type EventStatusCode = (typeof EventStatusCode)[keyof typeof EventStatusCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventStatusCode = {
	Published: 'Published',
	Draft: 'Draft',
	Cancelled: 'Cancelled',
} as const;

export interface EventCapacity {
	/** The total capacity of the event  */
	total?: number;
	/** The remaining capacity of event */
	remaining?: number;
}

export interface EventFlags {
	/** Shows if member is registered for event */
	isRegistered?: boolean | null;
	/** Shows if member is invited for event */
	isInvited?: boolean | null;
}

export type LocationType = (typeof LocationType)[keyof typeof LocationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocationType = {
	Physical: 'Physical',
	Hybrid: 'Hybrid',
	Online: 'Online',
} as const;

/**
 * The location Address
 */
export type LocationDtoAddress = AddressDto | null;

export interface LocationDto {
	/** Type of location. Either Physical, Hybrid or Online. */
	locationType: LocationType;
	/** The location Address */
	address?: LocationDtoAddress;
	/** The location name */
	locationName?: string | null;
	/** set proper link summary */
	link?: string | null;
}

/**
 * The different registrations for the event
 */
export type EventDtoAllOfRegistration = EventRegistration | null;

export type EventDtoAllOf = {
	/** Title of the event. */
	title: string;
	/** The location of the event (Type either: Physical, Hybrid or Online) */
	location?: EventDtoAllOfLocation;
	/** Shows if member is registered or invited for the event */
	flags?: EventDtoAllOfFlags;
	/** Intro text for the Event */
	intro?: string | null;
	/** Body text of the event */
	body?: string | null;
	/** Object defining the total and remaining capacity of the event */
	capacity?: EventDtoAllOfCapacity;
	/** The deadline for registering */
	registrationDeadline?: string | null;
	/** The start time of the event   */
	startTime: string;
	/** The ending time of the event */
	endTime?: string | null;
	/** The type of event */
	type?: string | null;
	/** The group which participates in the event  */
	group?: string | null;
	/** The departments which participates in the event */
	department?: string | null;
	/** Displays if the member needs an invitation to register for the event */
	isInvitationRequired: boolean;
	/** Displays the event status */
	status: EventStatusCode;
	/** The registration form */
	registrationForm?: EventDtoAllOfRegistrationForm;
	/** The different registrations for the event */
	registration?: EventDtoAllOfRegistration;
};

export type EventDto = EventBaseDto & EventDtoAllOf;

/**
 * The registration form
 */
export type EventDtoAllOfRegistrationForm = RegistrationFormDto | null;

/**
 * Object defining the total and remaining capacity of the event
 */
export type EventDtoAllOfCapacity = EventCapacity | null;

/**
 * Shows if member is registered or invited for the event
 */
export type EventDtoAllOfFlags = EventFlags | null;

/**
 * The location of the event (Type either: Physical, Hybrid or Online)
 */
export type EventDtoAllOfLocation = LocationDto | null;

/**
 * Meta for the request.
 */
export type EventsResponseMeta = EventMeta | null;

export interface EventsResponse {
	/** List of retrieved events. */
	items?: EventDto[] | null;
	/** Meta for the request. */
	meta?: EventsResponseMeta;
}

export interface EmploymentDto {
	companyId: string;
	startDate: string;
	endDate?: string | null;
}

export interface PagingResultOfEmploymentDto {
	items: EmploymentDto[];
	meta: MetaData;
}

export interface MemberPutConsentsDto {
	/** Consents to be changed */
	consents?: Consent[] | null;
}

export type Channel = (typeof Channel)[keyof typeof Channel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Channel = {
	Sms: 'Sms',
	Email: 'Email',
} as const;

export type ConsentType = (typeof ConsentType)[keyof typeof ConsentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsentType = {
	NewsDepartment: 'NewsDepartment',
	NewsUnion: 'NewsUnion',
	BenefitsDepartment: 'BenefitsDepartment',
	BenefitsUnion: 'BenefitsUnion',
	SmsDepartment: 'SmsDepartment',
	SmsUnion: 'SmsUnion',
} as const;

export interface Consent {
	/** Name of consent */
	label: string;
	/** Status of consent */
	consented: boolean;
	/** Id of consent */
	id: ConsentType;
	/** Type of communication channel */
	channel: Channel;
}

export interface ContributionDto {
	/** The sum of the total contribution for the period, paid by the employer. */
	contributionPaidByEmployer?: number | null;
	/** The sum of the total contribution for the period, to be paid by the member. */
	contributionPaidByMember?: number | null;
}

export type SortDateBy = (typeof SortDateBy)[keyof typeof SortDateBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortDateBy = {
	Start: 'Start',
	End: 'End',
} as const;

export interface PagingResultOfCalendarItemDto {
	items: CalendarItemDto[];
	meta: MetaData;
}

export interface AddressDto {
	/** Street name and number of the address. */
	addressLine?: string | null;
	/** Zipcode of the adress. */
	zipCode?: string | null;
	/** City of the address. */
	city?: string | null;
	/** Country of the address. */
	country?: string | null;
}

export type CalendarItemType = (typeof CalendarItemType)[keyof typeof CalendarItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarItemType = {
	Vacation: 'Vacation',
	JobEducation: 'JobEducation',
	ActivationOffer: 'ActivationOffer',
	Meeting: 'Meeting',
	All: 'All',
} as const;

/**
 * Location for the calendar item, if type is meeting
 */
export type CalendarItemDtoLocation = AddressDto | null;

export interface CalendarItemDto {
	/** The CRM Id of the calendar item. */
	id: string;
	/** The CRM name of the calendar item. */
	name: string;
	/** The type of entry of the calendar item. */
	type: CalendarItemType;
	/** Start time and date of the calendar item. */
	start: string;
	/** End time and date of the calendar item. */
	end: string;
	/** Location for the calendar item, if type is meeting */
	location?: CalendarItemDtoLocation;
	/** Url to bookingportal for the calendar item, if type is meeting */
	url?: string | null;
}

export interface MetaData {
	/** Total records matching current filtering */
	total?: number;
	/** Page returned */
	page?: number;
}

export interface PositionDto {
	positionId?: string | null;
	name: string;
}

export interface PagingResultOfPositionDto {
	items: PositionDto[];
	meta: MetaData;
}

export interface SalaryStatisticRegionDto {
	/** Name of region. */
	regionName: string;
	/** Id of the region. */
	regionId: number;
	/** Number of salaries reported in the region for the period. */
	count: number;
}

export interface SalaryStatisticProfessionDto {
	/** Name of profession. */
	professionName: string;
	/** Id of the profession. */
	professionId: number;
	/** Number of salaries reported in the profession for the period. */
	count: number;
}

export type AuthorizationErrorCode = (typeof AuthorizationErrorCode)[keyof typeof AuthorizationErrorCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthorizationErrorCode = {
	InvalidToken: 'InvalidToken',
	ExpiredToken: 'ExpiredToken',
	MissingToken: 'MissingToken',
	ExpiredRefreshToken: 'ExpiredRefreshToken',
	InvalidRefreshToken: 'InvalidRefreshToken',
	LoggedOut: 'LoggedOut',
} as const;

export interface AuthorizationErrorResult {
	/** Description of the error. */
	message?: string | null;
	/** Precise error code for the error. */
	errorCode?: AuthorizationErrorCode;
}

/**
 * Contains any validation errors for the submitted model.
 */
export type ValidationErrorResultModelState = { [key: string]: string[] };

export interface ValidationErrorResult {
	/** Description of the overall error. */
	message: string;
	/** Contains any validation errors for the submitted model. */
	modelState: ValidationErrorResultModelState;
}

export interface SalaryStatisticDto {
	/** Name of the region the statistic is based on. */
	regionName: string;
	/** Regions id */
	regionId: number;
	/** Name of the profession based for the statistic */
	professionName: string;
	/** Profession Id */
	professionId: number;
	/** Start time of the period the statistic is based on */
	periodStart: string;
	/** End time of the period the statistic is based on */
	periodEnd: string;
	/** Number of members salaries reported for the given profession and region in the last period (finished quarter). */
	memberCount: number;
	/** The average hourly salary in dkk */
	dkkSalaryHourly?: number | null;
	/** The average hourly salary percentage increase, based on same period previous year */
	pctIncreaseSalaryHourly?: number | null;
	/** The average pension hourly paid by member in dkk */
	dkkPensionHourlyMemberPaid?: number | null;
	/** The average pension hourly paid by members employee in dkk */
	dkkPensionHourlyEmployeePaid?: number | null;
	/** The average hourly salary and pension in dkk */
	dkkSalaryAndPensionSumHourly?: number | null;
	/** The average hourly salary and pension percentage increase, based on same period previous year */
	pctIncreaseSalaryAndPensionSumHourly?: number | null;
}

export interface LogOutRequest {
	/** The refresh token to log out with. This is the token that will be invalidated. */
	refreshToken?: string | null;
}

export interface RefreshAccessTokenRequest {
	/** The refresh token used to refresh the access token. */
	refreshToken?: string | null;
	/** The access token to refresh. */
	accessToken?: string | null;
}

export interface AuthenticateAsSystemRequest {
	/** Id for the client. */
	clientId: string;
	/** Secret for the client. */
	clientSecret: string;
}

export interface AuthenticateAsMemberRequest {
	/** Id for the client. */
	clientId: string;
	/** Secret for the client. */
	clientSecret: string;
	/** SSO token supplied by the login provider. */
	memberToken: string;
}

export interface ErrorResult {
	/** Technical message for the error. */
	message: string;
	/** Medlemsvendt fejlbesked. */
	publicMessage?: string | null;
}

export type AuthenticationErrorCode = (typeof AuthenticationErrorCode)[keyof typeof AuthenticationErrorCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthenticationErrorCode = {
	InvalidMemberToken: 'InvalidMemberToken',
	InvalidClient: 'InvalidClient',
} as const;

export interface AuthenticationErrorResult {
	/** Description of the error. */
	message?: string | null;
	/** Error code for the error. */
	authenticationErrorCode?: AuthenticationErrorCode;
}

export interface AuthenticateResult {
	/** The refresh token used to refresh the access token. */
	refreshToken?: string | null;
	/** The access token to be used when calling the API. */
	accessToken?: string | null;
}
