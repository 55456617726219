import cn from 'classnames';

import { hasColorPallette } from '@dansk-metal/theme';
import { Container } from '@dansk-metal/ui';

import { useBlockContext } from '@web/components/block-list/provider';
import { Aspect } from '@web/components/media/aspectRatios';
import { QBankMediaDisplay } from '@web/components/qbank-media/qbank-media';
import { Theme } from '@web/components/theme/theme';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';
import { ColorTheme, ThemeDefinition } from '@web/services/umbraco/types/basic/Color';
import { QBankMedia, QBankMediaType } from '@web/services/umbraco/types/basic/QBank';
import { ContentWidth } from '@web/services/umbraco/types/basic/sizes';

import styles from './basic-media-block.module.scss';

const blockName = 'basicMediaBlock' as const;

export interface BasicMediaBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			media: QBankMedia;
		},
		{
			width?: ContentWidth;
			colorTheme?: ThemeDefinition;
		}
	>;
}

export function BasicMediaBlock({ block }: BasicMediaBlockProps) {
	const { media } = block.contentProperties;
	const { colorTheme = { theme: ColorTheme.None }, width = ContentWidth.Medium } = block.settingsProperties;
	const { row } = useBlockContext();

	return (
		<Theme
			themeDef={colorTheme}
			className={cn(styles.theme, {
				[styles.has_color_theme]: hasColorPallette(colorTheme.theme),
			})}
		>
			<Container>
				<div className={cn(styles.content, styles[`content__${width || ContentWidth.Medium}`])}>
					<QBankMediaDisplay
						media={media}
						imageProps={{
							priority: row === 0,
							initialHeight: true,
							sizes: '(min-width: 768px) 80vw, 70vw',
						}}
						aspectRatio={media?.type === QBankMediaType.Video ? Aspect['4:3'] : undefined}
					/>
				</div>
			</Container>
		</Theme>
	);
}

BasicMediaBlock.blockName = blockName;
