import cn from 'classnames';

import { Container, Icon } from '@dansk-metal/ui';

import { UmbracoBlock } from '@web/services/umbraco/types/basic/Block';

import { Richtext } from '@apps/web/src/components/richtext/richtext';
import { Theme } from '@apps/web/src/components/theme/theme';
import { IllustrationColor } from '@apps/web/src/services/umbraco/types/basic/Color';
import { HTMLString } from '@apps/web/src/services/umbraco/types/basic/RichTextContent';

import styles from './quote-block.module.scss';

const blockName = 'quoteBlock' as const;

export interface QuoteBlockProps {
	block: UmbracoBlock<
		typeof blockName,
		{
			text: HTMLString;
			byline?: string;
		},
		{
			illustrationColor?: IllustrationColor;
		}
	>;
}

export function QuoteBlock({ block }: QuoteBlockProps) {
	const { text, byline } = block.contentProperties || {};
	const { illustrationColor = IllustrationColor.Blue } = block.settingsProperties || {};

	return (
		<Theme className={cn(styles.theme, styles[illustrationColor])}>
			<Container>
				<div className={styles.content}>
					<div className={styles.top}>
						<Icon icon="quote" size={64} className={styles.icon} />
						<hr />
					</div>
					<div className={styles.text}>
						<Richtext content={text} className={styles.text__body} />
						<p className={styles.text__byline}>{byline}</p>
					</div>
					<hr />
				</div>
			</Container>
		</Theme>
	);
}

QuoteBlock.blockName = blockName;
